<template>
  <div>
    <header class="home-header">
      <div class="home-logo">
        <img v-if="logourl != null" :src="logourl" /><span v-if="!isCollapse"
          >综合学工</span
        >
      </div>
      <div class="home-top-right">
        <div class="top-icons-btn">
          <el-badge is-dot class="item">
            <i class="fa fa-bell"></i>
          </el-badge>
        </div>
        <!--头像-->
        <el-avatar :src="userinfo.imgurl"></el-avatar>
        <!--个人信息-->
        <el-dropdown @command="tophandleCommand" class="ml5">
          <span class="el-dropdown-link">
            {{ userinfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user" command="profile"
              >个人信息</el-dropdown-item
            >
            <el-dropdown-item
              v-if="userinfo.roleId == 1"
              icon="el-icon-switch-button"
              command="qkhc"
              >清空缓存</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-switch-button" command="out"
              >安全退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </header>
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '220px'">
        <el-scrollbar :style="{ height: scrollbarheight + 'px' }">
          <el-menu
            :unique-opened="true"
            :collapse="isCollapse"
            :default-openeds="opends"
            :default-active="$route.path"
            :collapse-transition="true"
            @open="open"
            router
          >
            <template v-for="item in menulist">
              <el-submenu
                :index="item.id + ''"
                :key="item.id"
                :popper-class="'subcss'"
                v-if="item.f_IsMenu == 1 && item.f_EnabledMark == 1"
              >
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span>{{ item.text }}</span>
                </template>
                <template v-for="subItem in item.childNodes">
                  <el-submenu
                    v-if="
                      subItem.hasChildren &&
                      subItem.f_IsMenu == 1 &&
                      subItem.f_EnabledMark == 1
                    "
                    :index="subItem.id"
                    :key="subItem.id"
                  >
                    <template slot="title">
                      <!-- 图标 -->
                      <i :class="subItem.icon"></i>
                      <!-- 文本 -->
                      <span>{{ subItem.text }}</span>
                    </template>
                    <template v-for="menuItem in subItem.childNodes">
                      <el-menu-item
                        :index="menuItem.urladdress"
                        :key="menuItem.id"
                        v-if="
                          menuItem.f_IsMenu == 1 && menuItem.f_EnabledMark == 1
                        "
                      >
                        <template slot="title">
                          <!-- 图标 -->
                          <i :class="menuItem.icon"></i>
                          <!-- 文本 -->
                          <span>{{ menuItem.text }}</span>
                        </template>
                      </el-menu-item>
                    </template>
                  </el-submenu>
                  <el-menu-item
                    v-else
                    :key="subItem.id"
                    :index="subItem.urladdress"
                  >
                    <template slot="title">
                      <!-- 图标 -->
                      <i :class="subItem.icon"></i>
                      <!-- 文本 -->
                      <span>{{ subItem.text }}</span>
                    </template>
                  </el-menu-item>
                </template>
              </el-submenu>
            </template>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-container>
        <el-header>
          <div class="header_menu">
            <div class="home-top-left">
              <i
                :class="
                  isCollapse
                    ? 'el-icon-s-unfold home-top-fold'
                    : 'el-icon-s-fold home-top-fold'
                "
                @click="toggleCollapse"
              ></i>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item
                  v-for="item in headerrouter"
                  :key="item.router"
                  @click.native="toIndex(item.text)"
                  :to="{ path: item.router }"
                  >{{ item.text }}</el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
          </div>
        </el-header>
        <el-main :style="{ height: scrollbarheight - 30 + 'px' }">
          <!-- 路由占位符 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import store from '../store/index'
import { getModuleTree, getUserInfo, qkhc, getConfig } from '../api/system'

import { RHCASLogOut } from '../api/login'
import { baseUrl } from '../utils/http'
export default {
  data() {
    return {
      logourl: null,
      // 左侧菜单数据
      menulist: [],
      // 是否折叠
      isCollapse: false,
      opends: [], // 默认展开
      headerrouter: [{ text: '首页', router: '/index' }],
      userinfo: {},
      scrollbarheight: null,
    }
  },
  created() {
    getConfig().then((res) => {
      if (res.code === 200) {
        this.logourl = baseUrl + store.state.sysconfig.siteconfig.logoPath
      }
    })
    getModuleTree()
      .then((res) => {
        this.menulist = res.data
        if (this.menulist && this.menulist.length > 0) {
          if (store.state.menuexpend.length < 1) {
            this.opends.push(this.menulist[0].id)
          } else {
            //  this.opends = store.state.menuexpend;
            //  console.log(this.opends);
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getUserInfo().then((res) => {
      if (res.code === 200) {
        this.userinfo = res.data
        store.commit('setUserInfo', this.userinfo)
        this.userinfo.imgurl = baseUrl + this.userinfo.imgurl
      }
    })
  },
  methods: {
    open(index, indexpath) {
      store.commit('setmenuexpend', indexpath)
    },
    // 点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    toProfile() {
      this.$router.push('/profile')
    },
    tophandleCommand(command) {
      if (command === 'profile') {
        this.$router.push('/profile')
      } else if (command === 'out') {
        this.$confirm('是否确认退出?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(() => {
            // store.commit('setToken', null)
            // store.commit('setUserInfo', null)
            // store.commit('routes', null)
            localStorage.removeItem('SY_STORE')
            // 启用了cas 认证就 以CAS方式注销登录
            RHCASLogOut()
              .then((res) => {
                if (res.code === 200) {
                  location.href = res.data.caslogouturl
                } else {
                  this.$router.push('/login')
                }
              })
              .catch(() => {
                window.close()
                this.$router.push('/login')
              })
          })
          .catch(() => {})
      } else if (command === 'qkhc') {
        this.$confirm('是否确认清空缓存?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(() => {
            qkhc().then((res) => {
              if (res.code === 200) {
                store.commit('setToken', null)
                this.$router.push('/login')
              }
            })
          })
          .catch(() => {})
      }
    },
    // 保存当前路径
    saveNavState(activePath, activeName) {
      this.headerrouter = [
        { text: '首页', router: '/index' },
        { text: activeName, router: activePath },
      ]
    },
    toIndex(text) {
      if (text === '首页') {
        this.headerrouter = [{ text: '首页', router: '/index' }]
      }
      console.log(text)
    },
  },
  mounted() {
    this.opends = store.state.menuexpend
    this.scrollbarheight = document.documentElement.clientHeight - 70
    window.onresize = () => {
      return (() => {
        this.scrollbarheight = document.documentElement.clientHeight - 70
      })()
    }
  },
}
</script>

<style scoped>
.top_nav {
  background-color: #fff;
  padding: 15px;
}
.el-container {
  height: 100%;
}
.el-header {
  height: 50px !important;
  overflow: hidden;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}
.el-header .home-top-fold {
  font-size: 20px;
  cursor: pointer;
}

.header_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.header_menu i {
  color: #333;
  font-size: 20px;
}
.el-aside {
  border-right: 1px solid #e6e6e6;
}
.el-aside .el-menu {
  border-right: none;
}
.el-main {
  /* background-color: #eee; */
  padding: 10px;
  overflow: hidden;
}
.home-header {
  height: 50px;
  color: #fff;
  font-size: 24px;
  width: 100%;
  font-weight: 700;
  background-color: #409eff;
  display: flex;
  justify-content: space-between;
}
.home-logo {
  line-height: 60px;
  padding-left: 10px;
}
.home-logo img {
  height: 32px;
  width: 32px;
  vertical-align: text-bottom;
}
.home-logo span {
  margin-left: 5px;
}
.el-avatar {
  height: 30px;
  width: 30px;
}
.el-dropdown {
  color: #fff;
  margin-right: 5px;
  cursor: pointer;
}
.home-top-left {
  display: flex;
  align-items: center;
}
.home-top-right {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.el-icon-arrow-right {
  color: #fff;
  margin-left: 30px;
  font-size: 16px;
}
.home-top-right .top-icons-btn .item {
  font-size: 16px;
  margin-right: 20px;
}
.home-top-right .top-icons-btn .item i {
  font-size: 16px;
  cursor: pointer;
}
.menu-i {
  color: #fff;
}
.index-top {
  display: flex;
  justify-content: space-between;
}
.el-menu >>> i,
.el-menu-item >>> i,
.el-submenu >>> i {
  /* color: #fff; */
  margin-right: 10px;
}
.el-breadcrumb {
  margin-left: 30px;
}
.el-submenu >>> li > .el-submenu__title,
.el-menu-item,
.el-menu--popup li .el-submenu__title {
  /* background-color: #0a4373 !important; */
}
</style>
