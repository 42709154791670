<template>
  <div class="module">
    <div class="m_left">
      <div class="m_title">功能目录</div>
      <el-tree
        :data="data"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span> <i :class="data.icon"></i>{{ data.text }} </span>
        </span>
      </el-tree>
    </div>
    <div class="m_right">
      <div class="m_title">功能信息</div>
      <div class="m_tool">
        <div>
          <el-input v-model="keyword" placeholder="请输入内容" size="small"></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getModuleByParentId()"
            >搜索</el-button
          >
        </div>

        <el-button-group>
          <el-button icon="el-icon-refresh" @click="reload()" size="small"></el-button>
          <el-button icon="el-icon-plus" @click="dialogAddForm()" size="small"
            >新增</el-button
          >
        </el-button-group>
      </div>
      <div class="m_table">
        <el-table
          v-loading="tableloading"
          :data="tableData"
          border
          style="width: 100%"
          :height="windowHeight - 300"
          header-cell-class-name="tableStyle"
        >
          <el-table-column prop="f_EnCode" label="编号" width="150"> </el-table-column>
          <el-table-column prop="f_FullName" label="名称" width="150"> </el-table-column>
          <el-table-column prop="f_UrlAddress" label="地址" width="380">
          </el-table-column>
          <el-table-column prop="f_CompentPath" label="组件" width="180">
          </el-table-column>
          <el-table-column prop="f_Target" align="center" label="目标" width="80">
          </el-table-column>
          <el-table-column prop="f_IsMenu" align="center" label="菜单" width="80">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.f_IsMenu"
                :active-value="1"
                :inactive-value="0"
                @change="menuStateChanged(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="f_AllowExpand" align="center" label="展开" width="80">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.f_AllowExpand"
                :active-value="1"
                :inactive-value="0"
                @change="expendStateChanged(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="f_SortCode" align="center" label="排序码" width="80">
          </el-table-column>
          <el-table-column prop="f_EnabledMark" label="有效">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.f_EnabledMark"
                :active-value="1"
                :inactive-value="0"
                @change="markStateChanged(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  @click="editClick(scope.row)"
                  size="small"
                ></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="small"
                  @click="delClick(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--图标选择框-->
    <el-dialog title="选择图标" :visible.sync="dialogIconFormVisible">
      <el-scrollbar style="height: 500px" class="myscrollbar">
        <el-row :gutter="20">
          <el-col
            style="text-align: center; cursor: pointer"
            :span="3"
            v-for="(item, index) in Icons"
            :key="index"
            ><i class="iconstyle" :class="item" @click="selectIcon(item)"></i
          ></el-col>
        </el-row>
      </el-scrollbar>
    </el-dialog>
    <!--添加/修改功能-->
    <el-dialog
      :title="operation == 1 ? '添加功能' : '编辑功能'"
      :visible.sync="dialogAddFormVisible"
      @close="closeDialog"
    >
      <el-steps :active="active" simple>
        <el-step title="基础信息" icon="el-icon-info"></el-step>
        <el-step title="按钮信息" icon="el-icon-upload"></el-step>
      </el-steps>
      <div class="diabox">
        <!--基础信息-->
        <el-form
          v-show="active == 0"
          :model="moduleForm"
          ref="addFormRef"
          :rules="FormRules"
          :label-position="'right'"
          label-width="100px"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="编号" prop="F_EnCode">
                <el-input v-model="moduleForm.F_EnCode" placeholder="编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称" prop="F_FullName">
                <el-input
                  v-model="moduleForm.F_FullName"
                  placeholder="名称"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="上级">
                <el-cascader
                  :options="parentModule"
                  :props="parentProps"
                  v-model="moduleForm.F_ParentId"
                  style="display: block"
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="图标">
                <el-input
                  v-model="moduleForm.F_Icon"
                  placeholder="图标"
                  @focus="showIconDia()"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="目标" prop="F_Target">
                <el-select
                  v-model="moduleForm.F_Target"
                  placeholder="目标"
                  style="display: block"
                  clearable
                >
                  <el-option label="expand" value="expand"></el-option>
                  <el-option label="iframe" value="iframe"></el-option>
                  <el-option label="open" value="open"></el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="排序" prop="F_SortCode">
                <el-input
                  type="number"
                  v-model="moduleForm.F_SortCode"
                  placeholder="排序"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-form-item label="地址" prop="F_UrlAddress">
            <el-input v-model="moduleForm.F_UrlAddress" placeholder="地址"></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="组件" prop="F_CompentPath">
                <el-input
                  v-model="moduleForm.F_CompentPath"
                  placeholder="组件"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选项">
                <el-checkbox
                  v-model="moduleForm.F_IsMenu"
                  :true-label="checkvalue.sel"
                  :false-label="checkvalue.nosel"
                  >菜单</el-checkbox
                >
                <el-checkbox
                  v-model="moduleForm.F_AllowExpand"
                  :true-label="checkvalue.sel"
                  :false-label="checkvalue.nosel"
                  >展开</el-checkbox
                >
                <el-checkbox
                  v-model="moduleForm.F_EnabledMark"
                  :true-label="checkvalue.sel"
                  :false-label="checkvalue.nosel"
                  >有效</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="描述">
            <el-input type="textarea" v-model="moduleForm.F_Description"></el-input>
          </el-form-item>
        </el-form>
        <!--按钮信息-->
        <div v-show="active == 1">
          <div class="other_group_top">
            <span>编号</span>
            <span class="ml10">名称</span>
          </div>
          <div class="other_group_mid" v-for="(item, index) in btn_group" :key="index">
            <el-input v-model="btn_group[index].f_EnCode" style="width: 335px">
            </el-input>
            <el-input
              v-model="btn_group[index].f_FullName"
              style="width: 335px; margin-left: 10px"
            >
            </el-input>
            <i class="el-icon-remove-outline" @click="delbtn(index)"></i>
          </div>
          <div class="other_group_add" @click="addbtn">
            <i class="el-icon-circle-plus-outline">添加</i>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 12px" @click="back">上一步</el-button>
        <el-button style="margin-top: 12px" @click="next">下一步</el-button>
        <el-button @click="dialogAddFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveModuleInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import icon from "../../utils/icons";
import {
  getModuleTree,
  getModuleListByParentId,
  getFormData,
  getExpendModuleTree,
  saveModule,
  deleteForm,
  changeState,
} from "../../api/system";
export default {
  data() {
    return {
      checkvalue: { sel: 1, nosel: 0 },
      parentId: 0,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: "",
      dialogAddFormVisible: false,
      dialogIconFormVisible: false,
      active: 0,
      operation: 1, // 操作，1为添加，0为修改
      keyValue: 0,
      moduleForm: {
        F_EnCode: "", // 编号
        F_FullName: "", // 名称
        F_ParentId: "", // 上级
        F_Icon: "", // 图标
        F_Target: "", // 目标
        F_SortCode: "", // 排序
        F_UrlAddress: "", // 地址
        F_CompentPath: "", // 组件
        F_IsMenu: "", // 菜单
        F_AllowExpand: "", // 展开
        F_EnabledMark: "", // 有效
        F_Description: "", // 描述
      },
      parentModule: [],
      // 按钮信息
      btn_group: [
        {
          f_EnCode: "",
          f_FullName: "",
        },
      ],
      // 表单验证规则对象
      FormRules: {
        F_EnCode: [{ required: true, message: "请输入编号", trigger: "blur" }],
        F_FullName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        F_Target: [{ required: true, message: "请选择目标", trigger: "blur" }],
        F_SortCode: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      // 左侧功能列表
      data: [],
      defaultProps: {
        children: "childNodes",
        label: "text",
      },
      parentProps: {
        children: "childNodes",
        label: "text",
        value: "id",
        checkStrictly: true,
        emitPath: false,
      },
      // 基本信息
      tableData: [],
      // 图标库
      Icons: icon.icons,
      tableloading: true,
    };
  },
  created() {
    getModuleTree()
      .then((res) => {
        this.data = res.data;
        this.tableloading = false;
      })
      .catch((err) => {
        console.log(err);
        this.tableloading = false;
      });
    this.getModuleByParentId();
  },
  methods: {
    handleNodeClick(data) {
      this.parentId = data.id;
      this.getModuleByParentId();
    },
    headerStyle({ row, column, rowIndex, columnIndex }) {
      return "tableStyle";
    },
    // 关闭弹出框之前的回调
    closeDialog() {
      this.active = 0;
      this.$nextTick(() => {
        this.moduleForm = {
          F_EnCode: "", // 编号
          F_FullName: "", // 名称
          F_ParentId: "", // 上级
          F_Icon: "", // 图标
          F_Target: "", // 目标
          F_SortCode: "", // 排序
          F_UrlAddress: "", // 地址
          F_CompentPath: "", // 组件
          F_IsMenu: "", // 菜单
          F_AllowExpand: "", // 展开
          F_EnabledMark: "", // 有效
          F_Description: "", // 描述
        };
      });
      this.btn_group = [
        {
          f_EnCode: "",
          f_FullName: "",
        },
      ];
      this.$refs.addFormRef.resetFields();
    },
    // 保存信息
    saveModuleInfo() {
      if (this.operation === 1) {
        this.keyValue = "";
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return false;
        } else {
          if (
            this.moduleForm.F_ParentId === "" ||
            this.moduleForm.F_ParentId === "&nbsp;"
          ) {
            this.moduleForm.F_ParentId = "0";
          }
          var btns = [];
          for (var i = 0; i < this.btn_group.length; i++) {
            if (this.btn_group[i].f_EnCode && this.btn_group[i].f_FullName) {
              btns.push({
                F_EnCode: this.btn_group[i].f_EnCode,
                F_FullName: this.btn_group[i].f_FullName,
              });
            }
          }
          saveModule({
            keyValue: this.keyValue,
            moduleEntityJson: JSON.stringify(this.moduleForm),
            moduleButtonListJson: JSON.stringify(btns),
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(this.operation === 1 ? "添加成功" : "修改成功");
                this.getModuleByParentId();
                this.$refs.addFormRef.resetFields();
                this.dialogAddFormVisible = false;
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      });
    },
    // 显示图标
    showIconDia() {
      this.dialogIconFormVisible = true;
    },
    // 添加
    dialogAddForm() {
      getExpendModuleTree()
        .then((res) => {
          this.parentModule = res.data;
          this.setEmptyNode(res.data);
          this.operation = 1;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogAddFormVisible = true;
    },
    // 档子节点为空数组时设为null
    setEmptyNode(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].hasChildren) {
          this.setEmptyNode(data[i].childNodes);
        } else {
          data[i].childNodes = undefined;
        }
      }
    },
    // 编辑表单
    editClick(row) {
      getExpendModuleTree()
        .then((res) => {
          this.parentModule = res.data;
          this.setEmptyNode(res.data);
          this.operation = 0;
        })
        .catch((err) => {
          console.log(err);
        });
      getFormData(row.id)
        .then((res) => {
          this.keyValue = res.data.moduleEntity.id;
          this.operation = 0;
          this.dialogAddFormVisible = true;
          this.moduleForm.F_EnCode = res.data.moduleEntity.f_EnCode;
          this.moduleForm.F_FullName = res.data.moduleEntity.f_FullName;
          this.moduleForm.F_ParentId = res.data.moduleEntity.f_ParentId;
          this.moduleForm.F_Icon = res.data.moduleEntity.f_Icon;
          this.moduleForm.F_Target = res.data.moduleEntity.f_Target;
          this.moduleForm.F_SortCode = res.data.moduleEntity.f_SortCode;
          this.moduleForm.F_UrlAddress = res.data.moduleEntity.f_UrlAddress;
          this.moduleForm.F_CompentPath = res.data.moduleEntity.f_CompentPath;
          this.moduleForm.F_IsMenu = res.data.moduleEntity.f_IsMenu;
          this.moduleForm.F_AllowExpand = res.data.moduleEntity.f_AllowExpand;
          this.moduleForm.F_EnabledMark = res.data.moduleEntity.f_EnabledMark;
          this.moduleForm.F_Description = res.data.moduleEntity.f_Description;
          this.btn_group = res.data.moduleButtons;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除表单
    delClick(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteForm(row.id)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功!");
                this.getModuleByParentId();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    back() {
      if (this.active-- < 1) this.active = 0;
    },
    next() {
      if (this.active === 0) {
        this.$refs.addFormRef.validate(async (valid) => {
          if (!valid) {
            return false;
          } else {
            if (this.active++ > 0) this.active = 1;
          }
        });
      } else {
        if (this.active++ > 0) this.active = 1;
      }
    },
    // 添加按钮信息
    addbtn() {
      this.btn_group.push({ F_EnCode: "", F_FullName: "" });
    },
    // 删除按钮信息
    delbtn(index) {
      this.btn_group.splice(index, 1);
    },
    reload() {
      this.$router.go(0);
    },
    // 根据父ID获取菜单
    getModuleByParentId() {
      getModuleListByParentId(this.parentId, this.keyword)
        .then((res) => {
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择图标
    selectIcon(icon) {
      this.moduleForm.F_Icon = icon;
      this.dialogIconFormVisible = false;
    },
    // 监听菜单switch状态
    menuStateChanged(row) {
      console.log(row);
      changeState({ ID: row.id, F_IsMenu: row.f_IsMenu })
        .then((res) => {
          if (res.code !== 200) {
            row.f_IsMenu = row.f_IsMenu === 1 ? 0 : 1;
          }
        })
        .catch((err) => {
          console.log(err);
          row.f_IsMenu = row.f_IsMenu === 1 ? 0 : 1;
        });
    },
    // 监听展开switch状态
    expendStateChanged(row) {
      changeState({ ID: row.id, F_AllowExpand: row.f_AllowExpand })
        .then((res) => {
          if (res.code !== 200) {
            row.f_AllowExpand = row.f_AllowExpand === 1 ? 0 : 1;
          }
        })
        .catch((err) => {
          console.log(err);
          row.f_AllowExpand = row.f_AllowExpand === 1 ? 0 : 1;
        });
    },
    // 监听有效switch状态
    markStateChanged(row) {
      changeState({ ID: row.id, F_EnabledMark: row.f_EnabledMark })
        .then((res) => {
          if (res.code !== 200) {
            row.f_EnabledMark = row.f_EnabledMark === 1 ? 0 : 1;
          }
        })
        .catch((err) => {
          console.log(err);
          row.f_EnabledMark = row.f_EnabledMark === 1 ? 0 : 1;
        });
    },
  },
};
</script>

<style scoped>

.module {
  position: relative;
  padding-left: 210px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
.m_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
}
.m_right {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}
.m_title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
}
.m_table {
  width: 100%;
  padding: 1px;
  box-sizing: border-box;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.m_tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.m_tool .el-input {
  width: 200px;
}

.m_tool .el-button {
  margin-left: 10px;
}
.el-tree {
  font-size: 14px;
}
.el-tree i {
  margin-right: 5px;
}
.el-table .cell {
  font-size: 14px;
}
.diabox {
  height: 350px;
  padding-top: 30px;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 10px 20px;
}
.other_group_top {
  display: flex;
}
.other_group_top span {
  font-size: 16px;
  font-weight: 700;
  color: #409eff;
  width: 335px;
  text-align: center;
}
.other_group_add {
  text-align: center;
  border: 1px solid #ccc;
  background-color: #409eff;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 20px;
}
.other_group_mid {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.ml10 {
  margin-left: 10px;
}
.el-icon-remove-outline {
  font-size: 20px;
  color: red;
  margin-left: 20px;
  cursor: pointer;
}
.el-scrollbar >>> .el-scrollbar__wrap {
  overflow-x: hidden !important;
}
.iconstyle {
  margin: 10px auto;
}
</style>
