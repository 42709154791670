/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
// import qs from 'qs';
import { router } from '../router/index.js';
import store from '../store/index';
 // export const baseUrl = 'https://xgapi.zykqxy.com/';
 // export const baseUrl = 'https://xgxt.cdau.edu.cn/';
 // export const baseUrl = 'http://localhost:8088/';
export const baseUrl = process.env.VUE_APP_BASEURL; // vue 不同环境打包配置
axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = 10000;

// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; // 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = store.state.token != null ? store.state.token.access_token : false;
        // config.headers.client_id = 'syapi';
        // config.headers.client_secret = '123456';
        // config.headers.grant_type = 'password';
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.error(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    });
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 403:
                    // 清除token
                    store.commit('setToken', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 1000);
                    break;
                // 404请求不存在
                case 404:
                    break;
                case 400: {
                    break;
                }
                // 其他错误，直接抛出错误提示
                default:
            }
            return Promise.reject(error.response);
        }
    }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}
export default {
    baseUrl,
    post,
    get,
    upload,
    excel
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.log(err);
                reject(err.data);
            });
    });
}
// 上传文件
export function upload(url, filecontent) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, filecontent, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.log(err);
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function excel(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, { responseType: 'blob' })
            .then(res => {
                var content = res.data;
                var blob = new Blob([content]);
                var fileName = "download.xls"; // 要保存的文件名称
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    var elink = document.createElement("a");
                    elink.download = fileName;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, fileName);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function download(url, params, filename) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, { responseType: 'blob' })
            .then(res => {
                var content = res.data;
                var blob = new Blob([content]);
                var fileName = filename; // 要保存的文件名称
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    var elink = document.createElement("a");
                    elink.download = fileName;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, fileName);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err.data);
            });
    });
}
