<!--学生违纪处分申诉-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生违纪处分可申诉列表</div>
    <div class="c_table">
      <el-table
        :data="topdataPageList"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="80">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="cffwbh" label="发文编号" width="300">
        </el-table-column>
        <el-table-column prop="rdjgmc" label="认定结果" width="150">
        </el-table-column>
        <el-table-column prop="wjsj" label="违纪时间" width="120">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.wjsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="cfjgmc" label="处分结果" width="150">
        </el-table-column>
        <el-table-column prop="xq" label="学期" width="200"> </el-table-column>
        <el-table-column prop="sfjc" label="状态" width="200">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sfjc === 1" type="success">已解除</el-tag>
            <el-tag v-if="scope.row.sfjc === 0" type="danger"
              >处分执行中</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleSS(scope.row)"
              >申诉</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!----------------------------------顶部---------------------------------------------------->
      <!--=============================表格========================================-->
      <div class="page-title" style="border-top: 1px solid #ccc">
        学生违纪处分已申诉列表
      </div>
      <div class="c_table">
        <el-table
          :data="dataPageList"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          highlight-current-row
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '5px' }"
        >
          <el-table-column prop="xh" label="学号" width="150">
          </el-table-column>
          <el-table-column prop="xm" label="姓名" width="100">
          </el-table-column>
          <el-table-column prop="xbm" label="性别" width="80">
            <template slot-scope="scope">
              {{ scope.row.xbm == 1 ? '男' : '女' }}
            </template>
          </el-table-column>
          <el-table-column prop="cffwbh" label="发文编号" width="300">
          </el-table-column>
          <el-table-column prop="rdjgmc" label="认定结果" width="150">
          </el-table-column>
          <el-table-column prop="wjsj" label="违纪时间" width="120">
            <template slot-scope="scop">
              {{ dateFormat2(scop.row.wjsj) }}
            </template>
          </el-table-column>
          <el-table-column prop="cfjgmc" label="处分结果" width="150">
          </el-table-column>
          <el-table-column prop="xq" label="学期" width="200">
          </el-table-column>
          <el-table-column prop="spzt" label="状态" width="200">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
              <el-tag v-if="scope.row.spzt === 1000" type="success"
                >辅导员审核通过</el-tag
              >
              <el-tag v-if="scope.row.spzt === 2000" type="danger"
                >辅导员审核拒绝</el-tag
              >
              <el-tag v-if="scope.row.spzt === 1100" type="success"
                >系主任审核通过</el-tag
              >
              <el-tag v-if="scope.row.spzt === 1200" type="danger"
                >系主任审核拒绝</el-tag
              >
              <el-tag v-if="scope.row.spzt === 1110" type="success">{{
                scope.row.cflx == 1
                  ? '教学部部长审核通过'
                  : '学工部部长审核通过'
              }}</el-tag>
              <el-tag v-if="scope.row.spzt === 1120" type="danger">{{
                scope.row.cflx == 1
                  ? '教学部部长审核拒绝'
                  : '学工部部长审核拒绝'
              }}</el-tag>
              <el-tag v-if="scope.row.spzt === 1111" type="success"
                >分管副院长审核通过</el-tag
              >
              <el-tag v-if="scope.row.spzt === 1112" type="danger"
                >分管副院长审核拒绝</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="showEdit(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog
        title="学生违纪处分申诉"
        :visible.sync="dialogFormVisible"
        width="70%"
        :close-on-click-modal="false"
        @close="closeDialog"
        top="5vh"
      >
        <el-scrollbar style="height: 600px" class="myscrollbar">
          <div class="form-item-group">
            <i class="fa fa-address-book-o"></i>
            <div>审批流程</div>
          </div>
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in splist"
              :key="index"
              :timestamp="dateFormat2(item.cjsj)"
              placement="top"
            >
              <el-card>
                <h4 v-if="item.spzt == 1000 || item.spzt == 2000">
                  辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
                </h4>
                <h4 v-if="item.spzt == 1100 || item.spzt == 1200">
                  系主任审批{{ item.spzt == 1100 ? '通过' : '拒绝' }}
                </h4>
                <h4 v-if="item.spzt == 1110 || item.spzt == 1120">
                  学工/教学部长审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
                </h4>
                <h4 v-if="item.spzt == 1111 || item.spzt == 1112">
                  分管副院长审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
                </h4>
                <p>审批意见：{{ item.spyj }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <sy-form v-if="dialogFormVisible" :id="keyValue"></sy-form>
          <div class="form-item-group">
            <i class="fa fa-edit"></i>
            <div>申诉信息</div>
          </div>
          <el-form :model="forminfo" ref="formRef" :rules="forminfoRules">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="申诉理由" label-width="120px" prop="SSLY">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="请输入内容"
                    v-model="forminfo.SSLY"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
        <div slot="footer" class="dialog-footer" v-if="forminfo.SPZT == 0">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveForm">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  GetAllowApplyList,
  GetSSDetail,
  GetApplayList,
  SaveSS,
  GetWJSSSPJL,
} from '../../../api/wjcf'
import { getAuthorizeButtonColumnList } from '../../../api/system'
import moment from 'moment'
import form from '../../../components/wjcf/diacxform.vue'
export default {
  components: {
    'sy-form': form, // 挂载组件
  },
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      forminfo: {
        ID: '',
        CFID: '',
        SSLY: '',
        SPZT: 0,
      },
      splist: [],
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      topdataPageList: [],
      formdata: {},
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      forminfoRules: {
        SSLY: [{ required: true, message: '请填写申诉理由', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存信息
    saveForm() {
      this.forminfo.CFID = this.keyValue
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          SaveSS(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 显示编辑弹出框
    showEdit(row) {
      this.keyValue = row.cfid
      GetWJSSSPJL({ ssid: row.id }).then((res) => {
        if (res.code === 200) {
          this.splist = res.data
        }
      })
      GetSSDetail({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.forminfo.ID = res.data.id
          this.forminfo.CFID = res.data.cdid
          this.forminfo.SSLY = res.data.ssly
          this.forminfo.SPZT = res.data.spzt
          this.dialogFormVisible = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleSS(row) {
      this.splist = []
      this.keyValue = row.id
      this.dialogFormVisible = true
    },
    reload() {
      this.$router.go(0)
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetAllowApplyList()
        .then((res) => {
          if (res.code === 200) {
            this.topdataPageList = res.data
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
      GetApplayList()
        .then((res) => {
          if (res.code === 200) {
            this.dataPageList = res.data
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 200px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
