<!--校区管理-->
<template>
  <div style="height:100%;">
    <div
      class="container"
      v-show="showtable"
    >
      <el-row>
        <el-col
          :span="4"
          class="colleft"
        >
          <div class="page-title">院校目录</div>
          <el-scrollbar :style="{ height: windowHeight - 155 + 'px' }">
            <div class="ml10">
              <jcxx_dept_tree
                ref="bmtree"
                :layer="5"
                :hasstu="1"
                :iszd="1"
                @node-click="nodeclick"
              ></jcxx_dept_tree>
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="20">
          <!--=====================================顶部标题======================================-->
          <div class="page-title">学生信息完善情况</div>
          <!--=====================================工具栏========================================-->
          <div class="page-tool">
            <div style="display:flex;align-items:center">
              <el-input
                v-model="keyword"
                placeholder="请输入学号/姓名"
                size="small"
              ></el-input>
              <div style="display:flex;align-items:center">
                <div style="margin-left:10px;">审核状态：</div>
                <el-select
                  v-model="shzt"
                  size="small"
                  clearable
                >
                  <el-option
                    label="未完善"
                    value="0"
                  ></el-option>
                  <el-option
                    label="待审核"
                    value="1"
                  ></el-option>
                  <el-option
                    label="审核通过"
                    value="2"
                  ></el-option>
                  <el-option
                    label="审核未通过"
                    value="3"
                  ></el-option>
                </el-select>
              </div>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="small"
                @click="getDataPageList"
              >搜索</el-button>
            </div>

            <el-button-group>
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="reload()"
              ></el-button>
              <el-button
                size="small"
                icon="el-icon-download"
                @click="exportToExcel"
              >导出</el-button>
            </el-button-group>
          </div>

          <!--=============================表格========================================-->
          <div class="c_table">
            <el-table
              v-loading="tableloading"
              :data="dataPageList"
              :height="windowHeight - 270"
              border
              style="width: 100%"
              header-cell-class-name="tableStyle"
              highlight-current-row
              @current-change="currentRowChange"
              :row-style="{ height: '20px' }"
              :cell-style="{ padding: '5px' }"
            >
              <el-table-column
                prop="bmmc"
                label="院系"
                width="160"
              >
              </el-table-column>
              <el-table-column
                prop="zymc"
                label="专业"
                width="200"
              >
              </el-table-column>
              <el-table-column
                prop="bjmc"
                label="班级"
                width="200"
              >
              </el-table-column>
              <el-table-column
                prop="xh"
                label="学号"
                width="120"
              >
              </el-table-column>
              <el-table-column
                prop="xm"
                label="姓名"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="xbm"
                label="性别"
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.xbm == 1 ? '男' : '女' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mz"
                label="民族"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="shzt"
                label="信息完善状态"
              >
                <template slot-scope="scope">
                  <span
                    v-if="!scope.row.shzt"
                    style="color:#F56C6C"
                  >未完善</span>
                  <span
                    v-if="scope.row.shzt==1"
                    style="color:#409EFF"
                  >待辅导员审批</span>
                  <span
                    v-if="scope.row.shzt==2"
                    style="color:#67C23A"
                  >审核通过</span>
                  <span
                    v-if="scope.row.shzt==3"
                    style="color:#E6A23C"
                  >辅导员审核不通过</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="toDetail(scope.row.xh)"
                    type="text"
                    size="small"
                  >查看</el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="toSH(scope.row.xh, scope.row.shzt)"
                  >审核</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!--=======================================分页================================================-->
            <div class="pagebox">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pagination.page"
                :page-size="pagination.rows"
                layout="total, prev, pager, next, jumper"
                :total="pagination.records"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      v-show="!showtable"
      class="stucontainer"
    >
      <div class="tool">
        <el-button
          v-if="cz==1&&xsinfo.SHZT==1"
          type="success"
          size="mini"
          @click="sh(2)"
        >审核通过</el-button>
        <el-button
          v-if="cz==1&&xsinfo.SHZT==1"
          type="danger"
          size="mini"
          @click="shbtg(3)"
        >审核不通过</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="back"
        >返回</el-button>
      </div>
      <el-scrollbar
        style="height: 90%; width: 100%"
        class="myscrollbar"
      >
        <div class="form">
          <el-form
            :model="xsinfo"
            ref="formRef"
          >
            <div class="bt">基本信息</div>
            <el-row :gutter="20">
              <el-col :span="16">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="姓名"
                      label-width="120px"
                      prop="XM"
                    >
                      <el-input
                        v-model="xsinfo.XM"
                        autocomplete="off"
                        size="small"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="性别"
                      label-width="120px"
                      prop="XB"
                    >
                      <el-input
                        v-model="xsinfo.XB"
                        autocomplete="off"
                        size="small"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="身份证号"
                      label-width="120px"
                      prop="SFZH"
                    >
                      <el-input
                        v-model="xsinfo.SFZH"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="出生日期"
                      label-width="120px"
                      prop="CSRQ"
                    >
                      <el-input
                        v-model="xsinfo.CSRQ"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="籍贯"
                      label-width="120px"
                      prop="JG"
                    >
                      <el-input
                        v-model="xsinfo.JG"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="民族"
                      label-width="120px"
                      prop="MZ"
                    >
                      <el-input
                        v-model="xsinfo.MZ"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="政治面貌"
                      label-width="120px"
                      prop="ZZMM"
                    >
                      <el-input
                        v-model="xsinfo.ZZMM"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                        readonly
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row><el-row>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-upload
                  class="avatar-uploader"
                  disabled
                  :show-file-list="false"
                  :headers="headers"
                  :action="uploadurl"
                >
                  <img
                    v-if="xsinfo.imgurl"
                    :src="xsinfo.imgurl"
                    class="avatar"
                  />
                  <i
                    v-else
                    class="el-icon-plus avatar-uploader-icon"
                  ></i>
                </el-upload>
              </el-col>
            </el-row>
            <div class="bt">在校信息</div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  label="考生号"
                  label-width="120px"
                  prop="KSH"
                >
                  <el-input
                    v-model="xsinfo.KSH"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="学号"
                  label-width="120px"
                  prop="XH"
                >
                  <el-input
                    v-model="xsinfo.XH"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="年级"
                  label-width="120px"
                  prop="NJ"
                >
                  <el-input
                    v-model="xsinfo.NJ"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  label="入学年级"
                  label-width="120px"
                  prop="RXNJ"
                >
                  <el-select
                    v-model="xsinfo.RXNJ"
                    style="display: block"
                    size="small"
                    readonly
                  >
                    <el-option
                      v-for="(item, index) in nflist"
                      :key="index"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="学院"
                  label-width="120px"
                  prop="BMBH"
                >
                  <el-input
                    v-model="xsinfo.BMMC"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="专业"
                  label-width="120px"
                  prop="ZYBH"
                >
                  <el-input
                    v-model="xsinfo.ZYMC"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  label="班级"
                  label-width="120px"
                  prop="BJBH"
                >
                  <el-input
                    v-model="xsinfo.BJMC"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="学籍状态"
                  label-width="120px"
                  prop="ZDZTM"
                >
                  <el-input
                    v-model="xsinfo.ZDZT"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="房间号"
                  label-width="120px"
                  prop="FJBH"
                >
                  <el-input
                    v-model="xsinfo.FJBH"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly=""
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="bt">联系方式</div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  label="联系电话"
                  label-width="120px"
                  prop="LXDH"
                >
                  <el-input
                    v-model="xsinfo.LXDH"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="QQ号"
                  label-width="120px"
                  prop="QQ"
                >
                  <el-input
                    v-model="xsinfo.QQ"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="电子邮箱"
                  label-width="120px"
                  prop="DZYX"
                >
                  <el-input
                    v-model="xsinfo.DZYX"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="bt">家庭信息</div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  label="父亲姓名"
                  label-width="120px"
                  prop="FQXM"
                >
                  <el-input
                    v-model="xsinfo.FQXM"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="父亲联系方式"
                  label-width="120px"
                  prop="FQLXFS"
                >
                  <el-input
                    v-model="xsinfo.FQLXFS"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="母亲姓名"
                  label-width="120px"
                  prop="MQXM"
                >
                  <el-input
                    v-model="xsinfo.MQXM"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col> </el-row><el-row>
              <el-col :span="8">
                <el-form-item
                  label="母亲联系方式"
                  label-width="120px"
                  prop="MQLXFS"
                >
                  <el-input
                    v-model="xsinfo.MQLXFS"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item
                  label="家庭地址"
                  label-width="120px"
                  prop="JTDZ"
                >
                  <el-input
                    v-model="xsinfo.JTDZ"
                    autocomplete="off"
                    maxlength="20"
                    size="small"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-scrollbar>
    </div>
    <el-dialog
      title="审核不通过原因"
      :visible.sync="dialogFormVisible"
      top="5vh"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4}"
        placeholder="请输入不通过原因"
        v-model="xsinfo.SHSM"
      >
      </el-input>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getXSPageList,
  czmm,
  exportWSExcel,
  getXSDetailView,
  saveSH,
} from '../../api/jcxx'
import { baseUrl } from '../../utils/http'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
import jcxx_dept_tree from '../../components/common/jcxx_dept_tree.vue'
export default {
  data() {
    return {
      showtable: true,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      xqbh: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      shzt: null,
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      bmlist: [],
      zylist: [],
      bjlist: [],
      mzm: [],
      zzmm: [],
      nflist: [],
      zdzt: [],
      xh: '',
      dialogFormVisible: false, // 是否显示弹出框
      cz: 0,
      flag: 0,
      xsinfo: {
        XM: '',
        XBM: '',
        SFZH: '',
        CSRQ: '',
        JG: '',
        MZM: '',
        ZZMMM: '',
        LXDH: '',
        XX: '',
        imgurl: '',
        XH: '',
        NJ: '',
        BMBH: '',
        ZYBH: '',
        BJBH: '',
        ZDZTM: '',
        SFJBX: null,
        SFZX: null,
        FJBH: '',
        QQ: '',
        DZYX: '',
        FQXM: '',
        FQLXFS: '',
        MQXM: '',
        MQLXFS: '',
        JTDZ: '',
        HJDZ: '',
        RXNJ: '',
        KSH: '',
        RXCJ: null,
        YXX: '',
        BYRQ: null,
        GHYHKH: '',
        JHYHKH: '',
        SFWCBYDJ: null,
        BYDJH: '',
        SFTYJR: null,
        SHSM: null,
      },
      headers: null,
      uploadurl: baseUrl + 'File/uploadImg',
      baseurl: baseUrl,
    }
  },
  created() {
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  components: {
    jcxx_dept_tree: jcxx_dept_tree,
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    nodeclick(node) {
      // 当节点单击是触发
      if (node.lx === 1) {
        this.xqbh = ''
        this.bmbh = ''
        this.zybh = ''
        this.bjbh = ''
      } else if (node.lx === 2) {
        this.xqbh = node.bh
        this.bmbh = ''
        this.zybh = ''
        this.bjbh = ''
      } else if (node.lx === 3) {
        this.xqbh = ''
        this.bmbh = node.bh
        this.zybh = ''
        this.bjbh = ''
      } else if (node.lx === 4) {
        this.xqbh = ''
        this.bmbh = ''
        this.zybh = node.bh
        this.bjbh = ''
      } else if (node.lx === 5) {
        this.xqbh = ''
        this.bmbh = ''
        this.zybh = ''
        this.bjbh = node.bh
      }
      this.getDataPageList()
    },
    toDetail(xh) {
      // this.$router.push({
      //   path: '/jcxx/sy_cxxs/index',
      //   query: { xh: xh },
      // })
      getXSDetailView({ xh: xh }).then((res) => {
        if (res.code === 200) {
          this.xsinfo.ID = res.data.id
          this.xsinfo.XM = res.data.xm
          this.xsinfo.XB =
            res.data.xbm === '1' || res.data.xbm === 1 ? '男' : '女'
          this.xsinfo.SFZH = res.data.sfzh
          this.xsinfo.CSRQ = res.data.csrq ? res.data.csrq.substring(0, 10) : ''
          this.xsinfo.JG = res.data.jg
          this.xsinfo.MZ = res.data.mz
          this.xsinfo.ZZMM = res.data.zzmm
          this.xsinfo.LXDH = res.data.lxdh
          this.xsinfo.XX = res.data.xx
          this.xsinfo.imgurl =
            this.baseurl.substring(0, this.baseurl.length - 1) + res.data.imgurl
          this.xsinfo.XH = res.data.xh
          this.xsinfo.NJ = res.data.nj
          this.xsinfo.BMMC = res.data.bmmc
          this.xsinfo.ZYMC = res.data.zymc
          this.xsinfo.BJMC = res.data.bjmc
          this.xsinfo.ZDZT = res.data.zdzt
          this.xsinfo.SFJBX = res.data.sfjbx
          this.xsinfo.SFZX = res.data.sfzx
          this.xsinfo.FJBH = res.data.fjbh
          this.xsinfo.QQ = res.data.qq
          this.xsinfo.DZYX = res.data.dzyx
          this.xsinfo.FQXM = res.data.fqxm
          this.xsinfo.FQLXFS = res.data.fqlxfs
          this.xsinfo.MQXM = res.data.mqxm
          this.xsinfo.MQLXFS = res.data.mqlxfs
          this.xsinfo.JTDZ = res.data.jtdz
          this.xsinfo.HJDZ = res.data.hjdz
          this.xsinfo.RXNJ = res.data.rxnj
          this.xsinfo.KSH = res.data.ksh
          this.xsinfo.RXCJ = res.data.rxcj
          this.xsinfo.YXX = res.data.yxx
          this.xsinfo.BYRQ = res.data.byrq
          this.xsinfo.GHYHKH = res.data.ghyhkh
          this.xsinfo.JHYHKH = res.data.jhyhkh
          this.xsinfo.SFWCBYDJ = res.data.sfwcbydj
          this.xsinfo.BYDJH = res.data.bydjh
          this.xsinfo.SFTYJR = res.data.sftyjr
          this.xsinfo.SHZT = res.data.shzt
          this.flag = 1
          this.showtable = false
          this.cz = 0
        }
      })
    },
    toSH(xh) {
      // this.$router.push({
      //   path: '/jcxx/sy_cxxs/index',
      //   query: { xh: xh, cz: 'sh' },
      // })
      getXSDetailView({ xh: xh }).then((res) => {
        if (res.code === 200) {
          this.xsinfo.ID = res.data.id
          this.xsinfo.XM = res.data.xm
          this.xsinfo.XB =
            res.data.xbm === '1' || res.data.xbm === 1 ? '男' : '女'
          this.xsinfo.SFZH = res.data.sfzh
          this.xsinfo.CSRQ = this.xsinfo.CSRQ = res.data.csrq
            ? res.data.csrq.substring(0, 10)
            : ''
          this.xsinfo.JG = res.data.jg
          this.xsinfo.MZ = res.data.mz
          this.xsinfo.ZZMM = res.data.zzmm
          this.xsinfo.LXDH = res.data.lxdh
          this.xsinfo.XX = res.data.xx
          this.xsinfo.imgurl =
            this.baseurl.substring(0, this.baseurl.length - 1) + res.data.imgurl
          this.xsinfo.XH = res.data.xh
          this.xsinfo.NJ = res.data.nj
          this.xsinfo.BMMC = res.data.bmmc
          this.xsinfo.ZYMC = res.data.zymc
          this.xsinfo.BJMC = res.data.bjmc
          this.xsinfo.ZDZT = res.data.zdzt
          this.xsinfo.SFJBX = res.data.sfjbx
          this.xsinfo.SFZX = res.data.sfzx
          this.xsinfo.FJBH = res.data.fjbh
          this.xsinfo.QQ = res.data.qq
          this.xsinfo.DZYX = res.data.dzyx
          this.xsinfo.FQXM = res.data.fqxm
          this.xsinfo.FQLXFS = res.data.fqlxfs
          this.xsinfo.MQXM = res.data.mqxm
          this.xsinfo.MQLXFS = res.data.mqlxfs
          this.xsinfo.JTDZ = res.data.jtdz
          this.xsinfo.HJDZ = res.data.hjdz
          this.xsinfo.RXNJ = res.data.rxnj
          this.xsinfo.KSH = res.data.ksh
          this.xsinfo.RXCJ = res.data.rxcj
          this.xsinfo.YXX = res.data.yxx
          this.xsinfo.BYRQ = res.data.byrq
          this.xsinfo.GHYHKH = res.data.ghyhkh
          this.xsinfo.JHYHKH = res.data.jhyhkh
          this.xsinfo.SFWCBYDJ = res.data.sfwcbydj
          this.xsinfo.BYDJH = res.data.bydjh
          this.xsinfo.SFTYJR = res.data.sftyjr
          this.xsinfo.SHZT = res.data.shzt
          this.flag = 1
          this.showtable = false
          this.cz = 1
        }
      })
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      // if (!currentRow) {
      //   this.keyValue = ''
      //   this.xsinfo.BMBH = ''
      //   this.xsinfo.ZYBH = ''
      //   this.xh = ''
      // } else {
      //   this.keyValue = currentRow.id
      //   this.xsinfo.BMBH = currentRow.bmbh
      //   this.BMBH = currentRow.bmbh
      //   this.xsinfo.ZYBH = currentRow.zybh
      //   this.ZYBH = currentRow.zybh
      //   this.xh = currentRow.xh
      // }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getXSPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          XQBH: this.xqbh,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
          SHZT: this.shzt,
          ZDZTM: '01',
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    exportToExcel() {
      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportWSExcel({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          XQBH: this.xqbh,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
          SHZT: this.shzt,
          ZDZTM: '01',
        }),
        fileName: '学生信息',
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    toFormDetail() {
      this.$router.push({
        path: '/jcxx/studetail/index',
        query: { xh: this.xh },
      })
    },
    czmm() {
      czmm({ XH: this.xh }).then((res) => {
        this.$alert(res.message, {
          confirmButtonText: '确定',
          callback: (action) => {
            this.$message({
              type: 'info',
              message: `action: ${action}`,
            })
          },
        })
      })
    },
    back() {
      this.showtable = true
    },
    sh(shzt) {
      saveSH({ ID: this.xsinfo.ID, SHZT: shzt }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.showtable = true
          this.getDataPageList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    shbtg(shzt) {
      this.dialogFormVisible = true
    },
    saveForm() {
      if (!this.xsinfo.SHSM) {
        this.$message.error('请填写审核说明')
        return false
      } else {
        saveSH({ ID: this.xsinfo.ID, SHZT: 3, SHSM: this.xsinfo.SHSM }).then(
          (res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.showtable = true
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          }
        )
      }
    },
    closeDialog() {
      this.xsinfo.SHSM = ''
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.colleft {
  border-right: 1px solid #ccc;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.group-title {
  margin-left: 10px;
  margin-bottom: 10px;
  border-left: 5px solid #409eff;
  font-size: 18px;
  padding-left: 5px;
}
.stucontainer {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}
.tool {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  background: white;
  min-width: 600px;
}
.form {
  width: 1240px;
  margin: 0 auto;
  border: 1px solid #e2e4e5;
  padding: 16px;
  border-radius: 6px;
  background: white;
  min-height: 20px;
}
.bt {
  background-color: #eeeeee;
  padding: 5px;
  font-weight: 700;
}
>>> .el-upload {
  /* border: 1px dashed #d9d9d9; */
  margin: 20px 0 0 120px;
  border: 1px solid #8c939d !important;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 126px;
  height: 180px;
  line-height: 180px;
  text-align: center;
}
.avatar {
  width: 126px;
  height: 180px;
  display: block;
}
</style>
