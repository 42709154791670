<!--校区管理-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生信息</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">年级:</span>
            <el-select v-model="nj" size="small" clearable style="width: 200px">
              <el-option
                v-for="(item, index) in njlist"
                :key="index"
                :label="item.nj"
                :value="item.nj"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">学籍状态:</span>
            <el-select
              v-model="zdztm"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zdzt"
                :key="index"
                :label="item.zdz"
                :value="item.zddm"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_detail')"
          icon="el-icon-edit"
          @click="showEdit"
          >查看</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
        @row-dblclick="dbclick"
      >
        <el-table-column prop="bjmc" label="班级" width="200">
        </el-table-column>
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="mz" label="民族" width="100"> </el-table-column>
        <el-table-column prop="zt" label="状态"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <!-- <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination> -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-sizes="[10, 20, 30, 40, 50, 100, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="学生信息"
      :visible.sync="dialogFormVisible"
      width="70%"
      top="5vh"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form :model="xsinfo" v-if="dialogFormVisible" ref="formRef">
        <div class="group-title">学生基本信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="考生号" label-width="120px" prop="KSH">
              <el-input
                v-model="xsinfo.KSH"
                autocomplete="off"
                maxlength="20"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学号" label-width="120px" prop="XH">
              <el-input
                v-model="xsinfo.XH"
                autocomplete="off"
                maxlength="20"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名" label-width="120px" prop="XM">
              <el-input
                v-model="xsinfo.XM"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="性别" label-width="120px" prop="XBM">
              <el-select v-model="xsinfo.XBM" style="display: block">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期" label-width="120px" prop="CSRQ">
              <el-date-picker
                v-model="xsinfo.CSRQ"
                type="date"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="籍贯" label-width="120px" prop="JG">
              <el-input
                v-model="xsinfo.JG"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="家庭地址" label-width="120px" prop="JTDZ">
              <el-input
                v-model="xsinfo.JTDZ"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="民族" label-width="120px" prop="MZM">
              <el-select v-model="xsinfo.MZM" style="display: block">
                <el-option
                  v-for="(item, index) in mzm"
                  :key="index"
                  :label="item.zdz"
                  :value="item.zddm"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="政治面貌" label-width="120px" prop="ZZMMM">
              <el-select v-model="xsinfo.ZZMMM" style="display: block">
                <el-option
                  v-for="(item, index) in zzmm"
                  :key="index"
                  :label="item.zdz"
                  :value="item.zddm"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" label-width="120px" prop="SFZH">
              <el-input
                v-model="xsinfo.SFZH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="生源所在地" label-width="120px" prop="SYSZDMC">
              <el-input
                v-model="xsinfo.SYSZDMC"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="银行卡号" label-width="120px" prop="YHKH">
              <el-input
                v-model="xsinfo.YHKH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入学年级" label-width="120px" prop="RXNJ">
              <el-select v-model="xsinfo.RXNJ" style="display: block">
                <el-option
                  v-for="(item, index) in nflist"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="group-title">联系方式</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="联系电话" label-width="120px" prop="LXDH">
              <el-input
                v-model="xsinfo.LXDH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="家长联系电话"
              label-width="120px"
              prop="JZLXDH"
            >
              <el-input
                v-model="xsinfo.JZLXDH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电子邮箱" label-width="120px" prop="DZYX">
              <el-input
                v-model="xsinfo.DZYX"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="QQ" label-width="120px" prop="QQ">
              <el-input
                v-model="xsinfo.QQ"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="group-title">在校信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="学院" label-width="120px" prop="BMBH">
              <el-select
                v-model="xsinfo.BMBH"
                style="display: block"
                @change="bmchange"
              >
                <el-option
                  v-for="(item, index) in bmlist"
                  :key="index"
                  :label="item.bmmc"
                  :value="item.bmbh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="专业" label-width="120px" prop="ZYBH">
              <el-select
                v-model="xsinfo.ZYBH"
                style="display: block"
                @change="zychange"
              >
                <el-option
                  v-for="(item, index) in zylist"
                  :key="index"
                  :label="item.zymc"
                  :value="item.zybh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="班级" label-width="120px" prop="BJBH">
              <el-select v-model="xsinfo.BJBH" style="display: block">
                <el-option
                  v-for="(item, index) in bjlist"
                  :key="index"
                  :label="item.bjmc"
                  :value="item.bjbh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="年级" label-width="120px" prop="NJ">
              <el-select v-model="xsinfo.NJ" style="display: block">
                <el-option
                  v-for="(item, index) in nflist"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="毕业年份" label-width="120px" prop="BYNF">
              <el-date-picker
                v-model="xsinfo.BYNF"
                type="year"
                style="width: 100%"
                value-format="yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" label-width="120px" prop="ZDZTM">
              <el-select v-model="xsinfo.ZDZTM" style="display: block">
                <el-option
                  v-for="(item, index) in zdzt"
                  :key="index"
                  :label="item.zdz"
                  :value="item.zddm"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="房间编号" label-width="120px" prop="FJBH">
              <el-input v-model="xsinfo.FJBH" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getXYCXXSPageList,
  getStuBM,
  getZYList,
  getBJList,
  getCode,
  GetListByQX,
  GetZYListByQX,
  GetBJListByQX,
  getNJ,
} from '../../api/jcxx'
import { getNFList } from '../../utils/nllist.js'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      topbjlist: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      zdztm: '',
      nj: '',
      windowHeight: document.documentElement.clientHeight - 70, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      bmlist: [],
      zylist: [],
      bjlist: [],
      njlist: [],
      mzm: [],
      zzmm: [],
      nflist: [],
      zdzt: [],
      xsinfo: {
        ID: '',
        KSH: '',
        XH: '',
        XM: '',
        XBM: '',
        CSRQ: null,
        JG: '',
        JTDZ: '',
        MZM: '',
        ZZMMM: '',
        SFZH: '',
        ZYBH: '',
        BJBH: '',
        NJ: '',
        RXNJ: '',
        BYNF: '',
        ZDZTM: '',
        DZYX: '',
        LXDH: '',
        JZLXDH: '',
        SYSZDDM: '',
        SYSZDMC: '',
        QQ: '',
        YHKH: '',
        BMBH: '',
      },
      dialogFormVisible: false, // 是否显示弹出框
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.xsinfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getNJ().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data
      }
    })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XJZTM' }) })
      .then((res) => {
        if (res.code === 200) {
          this.zdzt = res.data
        } else {
          this.zdzt = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.zzmm = []
      })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    getFormInitData() {
      this.nflist = getNFList()
      getStuBM()
        .then((res) => {
          if (res.code === 200) {
            this.bmlist = res.data
          } else {
            this.bmlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bmlist = []
        })
      getZYList({ queryJson: JSON.stringify({ BMBH: this.BMBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.zylist = res.data
          } else {
            this.zylist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zylist = []
        })
      getBJList({ queryJson: JSON.stringify({ ZYBH: this.ZYBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.bjlist = res.data
          } else {
            this.bjlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bjlist = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'MZM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.mzm = res.data
          } else {
            this.mzm = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.mzm = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'ZZMMM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.zzmm = res.data
          } else {
            this.zzmm = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zzmm = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'XJZTM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.zdzt = res.data
          } else {
            this.zdzt = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zzmm = []
        })
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$router.push({
          path: '/jcxx/studetail/index',
          query: { xh: this.xh },
        })
        // this.xsinfo.BMBH = this.BMBH
        // this.getFormInitData()
        // this.operation = 0
        // getXSInfo({ id: this.keyValue }).then((res) => {
        //   if (res.code === 200) {
        //     this.xsinfo.ID = res.data.id
        //     this.xsinfo.KSH = res.data.ksh
        //     this.xsinfo.XH = res.data.xh
        //     this.xsinfo.XM = res.data.xm
        //     this.xsinfo.XBM = res.data.xbm
        //     this.xsinfo.CSRQ = res.data.csrq
        //     this.xsinfo.JG = res.data.jg
        //     this.xsinfo.JTDZ = res.data.jtdz
        //     this.xsinfo.MZM = res.data.mzm
        //     this.xsinfo.ZZMMM = res.data.zzmmm
        //     this.xsinfo.SFZH = res.data.sfzh
        //     this.xsinfo.ZYBH = res.data.zybh
        //     this.xsinfo.BJBH = res.data.bjbh
        //     this.xsinfo.NJ = res.data.nj
        //     this.xsinfo.RXNJ = res.data.rxnj
        //     this.xsinfo.BYNF = res.data.bynf
        //     this.xsinfo.JZLXDH = res.data.jzlxdh
        //     this.xsinfo.SYSZDDM = res.data.syszddm
        //     this.xsinfo.SYSZDMC = res.data.syszdmc
        //     this.xsinfo.QQ = res.data.qq
        //     this.xsinfo.YHKH = res.data.yhkh
        //     this.xsinfo.ZDZTM = res.data.zdztm
        //     this.xsinfo.LXDH = res.data.lxdh
        //     this.xsinfo.DZYX = res.data.dzyx
        //     this.xsinfo.FJBH = res.data.fjbh
        //     this.dialogFormVisible = true
        //   } else {
        //     this.$message.error(res.message)
        //   }
        // })
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.xsinfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    handleSizeChange(val) {
      this.pagination.page = 1
      this.pagination.rows = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.xsinfo.BMBH = ''
        this.xsinfo.ZYBH = ''
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.xsinfo.BMBH = currentRow.bmbh
        this.BMBH = currentRow.bmbh
        this.xsinfo.ZYBH = currentRow.zybh
        this.ZYBH = currentRow.zybh
        this.xh = currentRow.xh
      }
    },
    // 当前行发生变化
    dbclick(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.xsinfo.BMBH = ''
        this.xsinfo.ZYBH = ''
      } else {
        this.keyValue = currentRow.id
        this.xsinfo.BMBH = currentRow.bmbh
        this.BMBH = currentRow.bmbh
        this.xsinfo.ZYBH = currentRow.zybh
        this.ZYBH = currentRow.zybh
      }
      this.showEdit()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getXYCXXSPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          ZDZTM: this.zdztm,
          ZDNJ: this.nj,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.group-title {
  margin-left: 10px;
  margin-bottom: 10px;
  border-left: 5px solid #409eff;
  font-size: 18px;
  padding-left: 5px;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
