<!--学生违纪处分查询-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生违纪管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学期:</span>
            <sy-xueqi
              v-model="xq"
              :selectdqxq="true"
              style="display: inline"
            ></sy-xueqi>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">处分类型:</span>
            <el-select
              v-model="CFLX"
              size="small"
              style="width: 200px"
              clearable
            >
              <el-option label="教学处分" :value="1"></el-option>
              <el-option label="行为习惯处分" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_look')"
          icon="el-icon-edit"
          @click="showEdit"
          >查看</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_export')"
          icon="el-icon-download"
          @click="showExport"
          >导出</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="150"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="cffwbh" label="发文编号" width="300">
        </el-table-column>
        <el-table-column prop="rdjgmc" label="认定结果" width="150">
        </el-table-column>
        <el-table-column prop="wjsj" label="违纪时间" width="150">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.wjsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="cfjgmc" label="处分结果" width="150">
        </el-table-column>
        <el-table-column prop="xq" label="学期" width="250"> </el-table-column>
        <el-table-column prop="sfjc" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sfjc === 1" type="success">已解除</el-tag>
            <el-tag v-if="scope.row.sfjc === 0" type="danger"
              >处分执行中</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="学生违纪处分"
      :visible.sync="dialogFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 650px" class="myscrollbar">
        <sy-form v-if="dialogFormVisible" :id="keyValue" :issp="'1'"></sy-form>
      </el-scrollbar>
    </el-dialog>
    <!--导出日志弹出框-->
    <el-dialog title="导出Excel数据" :visible.sync="dialogExcelVisible">
      <div>
        <el-checkbox
          v-model="excelForm.XH"
          label="学号"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XM"
          label="姓名"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BMMC"
          label="院系"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.ZYMC"
          label="专业"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BJMC"
          label="班级"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.CFLX"
          label="处分类型"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XQ"
          label="学期"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.CFFWBH"
          label="处分发文编号"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.WJQK"
          label="违纪情况"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.RDJG"
          label="认定结果"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.WJSJ"
          label="违纪时间"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.CFJG"
          label="处分结果"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.CFSJ"
          label="处分时间"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BZ"
          label="备注"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.SFJC"
          label="是否解除处分"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.JCCFWH"
          label="解除处分文号"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.JCSJ"
          label="解除日期"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcelVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportToExcel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetSearchList, exportSearchExcel } from '../../api/wjcf'
import {
  getCode,
  GetListByQX,
  GetZYListByQX,
  GetBJListByQX,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getCurrentXQ } from '../../api/system'
import moment from 'moment'
import form from '../../components/wjcf/diacxform.vue'
export default {
  components: {
    'sy-form': form, // 挂载组件
    'sy-xueqi': () => import('../../components/dropdown/xueqi_name'),
  },
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      xq: '',
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      xsinfo: {},
      rdjglist: [], // 认定结果
      cfjglist: [], // 处分结果
      splist: [], // 审批列表
      xh: '',
      ZT: 0,
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      bmbh: '',
      zybh: '',
      bjbh: '',
      CFLX: '',
      dialogFormVisible: false, // 是否显示弹出框
      dialogExcelVisible: false,
      excelForm: {
        XH: true,
        XM: true,
        BMMC: true,
        ZYMC: true,
        BJMC: true,
        CFLX: true,
        XQ: true,
        CFFWBH: true,
        WJQK: true,
        RDJG: true,
        WJSJ: true,
        CFJG: true,
        CFSJ: true,
        BZ: true,
        SFJC: true,
        JCCFWH: true,
        JCSJ: true,
      },
    }
  },
  created() {
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getCurrentXQ().then((res) => {
      if (res.code === 200) {
        this.xq =
          res.data.ksnf + '-' + res.data.jsnf + '学年第' + res.data.xq + '学期'
        this.getDataPageList()
      }
    })
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        this.getLXCode()
        this.dialogFormVisible = true
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {},
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.ZT = 0
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.ZT = currentRow.zt
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetSearchList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          XQ: this.xq,
          CFLX: this.CFLX,
          ZYBH: this.zybh,
          BMBH: this.bmbh,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'WJRDJGDM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.rdjglist = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'CFJGDM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.cfjglist = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    showExport() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.XH) {
        exportFiles.push({ name: 'XH', label: '学号' })
      }
      if (this.excelForm.XM) {
        exportFiles.push({ name: 'XM', label: '姓名' })
      }
      if (this.excelForm.BMMC) {
        exportFiles.push({ name: 'BMMC', label: '院系' })
      }
      if (this.excelForm.ZYMC) {
        exportFiles.push({ name: 'ZYMC', label: '专业' })
      }
      if (this.excelForm.BJMC) {
        exportFiles.push({ name: 'BJMC', label: '班级' })
      }
      if (this.excelForm.CFLX) {
        exportFiles.push({ name: 'CFLX', label: '处分类型' })
      }
      if (this.excelForm.XQ) {
        exportFiles.push({ name: 'XQ', label: '学期' })
      }
      if (this.excelForm.CFFWBH) {
        exportFiles.push({ name: 'CFFWBH', label: '处分发文编号' })
      }
      if (this.excelForm.WJQK) {
        exportFiles.push({ name: 'WJQK', label: '违纪情况' })
      }
      if (this.excelForm.RDJG) {
        exportFiles.push({ name: 'RDJG', label: '认定结果' })
      }
      if (this.excelForm.WJSJ) {
        exportFiles.push({ name: 'WJSJ', label: '违纪时间' })
      }
      if (this.excelForm.CFJG) {
        exportFiles.push({ name: 'CFJG', label: '处分结果' })
      }
      if (this.excelForm.CFSJ) {
        exportFiles.push({ name: 'CFSJ', label: '处分时间' })
      }
      if (this.excelForm.BZ) {
        exportFiles.push({ name: 'BZ', label: '备注' })
      }
      if (this.excelForm.SFJC) {
        exportFiles.push({ name: 'SFJC', label: '是否解除处分' })
      }
      if (this.excelForm.JCCFWH) {
        exportFiles.push({ name: 'JCCFWH', label: '解除处分文号' })
      }
      if (this.excelForm.JCSJ) {
        exportFiles.push({ name: 'JCSJ', label: '解除日期' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }

      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportSearchExcel({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          XQ: this.xq,
          CFLX: this.CFLX,
          ZYBH: this.zybh,
          BMBH: this.bmbh,
        }),
        fileName: '违纪处分信息',
        exportField: JSON.stringify(exportFiles),
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 200px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
