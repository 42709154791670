<!--学生违纪处分管理-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生违纪管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学期:</span>
            <sy-xueqi
              v-model="xq"
              :selectdqxq="true"
              style="display: inline"
            ></sy-xueqi>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">处分类型:</span>
            <el-select
              v-model="CFLX"
              size="small"
              style="width: 200px"
              clearable
            >
              <el-option label="教学处分" :value="1"></el-option>
              <el-option label="行为习惯处分" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
          >编辑/查看</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 300"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="cffwbh" label="发文编号" width="300">
        </el-table-column>
        <el-table-column prop="rdjgmc" label="认定结果" width="150">
        </el-table-column>
        <el-table-column prop="wjsj" label="违纪时间" width="120">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.wjsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="cfjgmc" label="处分结果" width="150">
        </el-table-column>
        <el-table-column prop="xq" label="学期" width="250"> </el-table-column>
        <el-table-column prop="bmmc" label="院系" width="200">
        </el-table-column>
        <el-table-column prop="sfjc" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sfjc === 1" type="success">已解除</el-tag>
            <el-tag v-if="scope.row.sfjc === 0" type="danger"
              >处分执行中</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="学生违纪处分"
      :visible.sync="dialogFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-dialog
        title="学生信息(双击选择)"
        :visible.sync="dialogStuVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span class="mr5 ml20 fcolor42">学号/姓名:</span>
          <el-input
            v-model="selkeyword"
            placeholder="请输入学号/姓名"
            size="small"
            style="width: 200px"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getStuDataPageList"
            style="margin-left: 10px"
            >搜索</el-button
          >
        </div>
        <el-table
          :data="selectStuData"
          :height="300"
          border
          header-cell-class-name="tableStyle"
          @row-dblclick="selStu"
        >
          <el-table-column
            property="xh"
            label="学号"
            width="150"
          ></el-table-column>
          <el-table-column
            property="xm"
            label="姓名"
            width="150"
          ></el-table-column>
          <el-table-column property="bjmc" label="班级"></el-table-column>
        </el-table>
        <div class="pagebox">
          <el-pagination
            @current-change="handleStuCurrentChange"
            :current-page="stupagination.page"
            :page-size="stupagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="stupagination.records"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-scrollbar style="height: 600px" class="myscrollbar">
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <div class="form-item-group">
            <i class="fa fa-drivers-license-o"></i>
            <div>学生基础信息：</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="学号" label-width="120px">
                <el-input
                  v-model="xsinfo.xh"
                  readonly
                  @focus="selectstu"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="姓名" label-width="120px">
                <el-input v-model="xsinfo.xm" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别" label-width="120px">
                <el-radio v-model="xsinfo.xbm" label="1">男</el-radio>
                <el-radio v-model="xsinfo.xbm" label="2">女</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="院系" label-width="120px">
                <el-input v-model="xsinfo.bmmc" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="专业" label-width="120px">
                <el-input v-model="xsinfo.zymc" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="班级" label-width="120px">
                <el-input v-model="xsinfo.bjmc" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-item-group">
            <i class="fa fa-calendar"></i>
            <div>学生违纪信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="处分类型" label-width="120px" prop="CFLX">
                <el-select
                  v-model="forminfo.CFLX"
                  size="small"
                  clearable
                  style="width: 100%"
                >
                  <el-option label="教学处分" :value="1"></el-option>
                  <el-option
                    label="学生行为习惯违纪处分"
                    :value="2"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学期" label-width="120px" prop="XQ">
                <el-select
                  v-model="forminfo.XQ"
                  size="small"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in xqlist"
                    :key="index"
                    :label="
                      item.ksnf + '-' + item.jsnf + '学年第' + item.xq + '学期'
                    "
                    :value="
                      item.ksnf + '-' + item.jsnf + '学年第' + item.xq + '学期'
                    "
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="处分发文编号"
                label-width="120px"
                prop="CFFWBH"
              >
                <el-input
                  v-model="forminfo.CFFWBH"
                  placeholder="请输入处分发文编号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="违纪情况" label-width="120px" prop="WJQK">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="forminfo.WJQK"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="认定结果" label-width="120px" prop="RDJG">
                <el-select
                  v-model="forminfo.RDJG"
                  size="small"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in rdjglist"
                    :key="index"
                    :label="item.zdz"
                    :value="item.zddm"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="违纪时间" label-width="120px" prop="WJSJ">
                <el-date-picker
                  v-model="forminfo.WJSJ"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="form-item-group">
            <i class="fa fa-address-book-o"></i>
            <div>处理情况</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="处分结果" label-width="120px" prop="CFJG">
                <el-select
                  v-model="forminfo.CFJG"
                  size="small"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in cfjglist"
                    :key="index"
                    :label="item.zdz"
                    :value="item.zddm"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="处分时间" label-width="120px" prop="CFSJ">
                <el-date-picker
                  v-model="forminfo.CFSJ"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注" label-width="120px" prop="BZ">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="forminfo.BZ"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="是否解除处分"
                label-width="120px"
                prop="SFJC"
              >
                <el-radio v-model="forminfo.SFJC" :label="1">是</el-radio>
                <el-radio v-model="forminfo.SFJC" :label="0">否</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="解除处分文号" label-width="120px">
                <el-input v-model="forminfo.JCCFWH"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="解除日期" label-width="120px" prop="JCSJ">
                <el-date-picker
                  v-model="forminfo.JCSJ"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetManagePageList, ManageSave, GetPostDetail } from '../../api/wjcf'
import {
  getCode,
  getXSPageList,
  GetListByQX,
  GetZYListByQX,
  GetBJListByQX,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getCurrentXQ } from '../../api/system'
import moment from 'moment'
export default {
  components: {
    'sy-xueqi': () => import('../../components/dropdown/xueqi_name'),
  },
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      stupagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xq: '',
      keyword: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      bmlist: [],
      zylist: [],
      bjlist: [],
      selbjbh: '',
      selkeyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      selectStuData: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      xsinfo: {},
      rdjglist: [], // 认定结果
      cfjglist: [], // 处分结果
      xh: '',
      ZT: 0,
      forminfo: {
        ID: '',
        XH: '',
        CFLX: '',
        XQ: '',
        CFFWBH: '',
        WJQK: '',
        RDJG: '',
        WJSJ: '',
        CFJG: '',
        CFSJ: '',
        BZ: '',
        SPZT: 0,
        SFSX: 0,
        SXSJ: null,
        SFJC: 0,
        JCCFWH: '',
        JCSJ: null,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogStuVisible: false,
      // 表单验证规则对象
      forminfoRules: {
        XH: [{ required: true, message: '请选择学生', trigger: 'blur' }],
        CFLX: [{ required: true, message: '请选择处分类型', trigger: 'blur' }],
        XQ: [{ required: true, message: '请选择学期', trigger: 'blur' }],
        WJQK: [{ required: true, message: '请填写违纪情况', trigger: 'blur' }],
        RDJG: [{ required: true, message: '请选择认定结果', trigger: 'blur' }],
        WJSJ: [{ required: true, message: '请选择违纪时间', trigger: 'blur' }],
        CFJG: [{ required: true, message: '请选择处分结果', trigger: 'blur' }],
        CFSJ: [{ required: true, message: '请选择处分时间', trigger: 'blur' }],
        SFJC: [{ required: true, message: '请选择是否解除', trigger: 'blur' }],
      },
    }
  },
  created() {
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getCurrentXQ().then((res) => {
      if (res.code === 200) {
        this.xq =
          res.data.ksnf + '-' + res.data.jsnf + '学年第' + res.data.xq + '学期'
        this.getDataPageList()
      }
    })

    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.keyValue = ''
      this.xsinfo = {}
      this.dialogFormVisible = true
      this.xsinfo = {}
      this.getLXCode()
    },
    // 保存信息
    saveForm() {
      this.forminfo.ID = this.keyValue
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          ManageSave(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功！')
              this.getDataPageList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        this.getLXCode()
        GetPostDetail({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XH = res.data.xh
            this.forminfo.CFLX = res.data.cflx
            this.forminfo.XQ = res.data.xq
            this.forminfo.CFFWBH = res.data.cffwbh
            this.forminfo.WJQK = res.data.wjqk
            this.forminfo.RDJG = res.data.rdjg
            this.forminfo.WJSJ = res.data.wjsj
            this.forminfo.CFJG = res.data.cfjg
            this.forminfo.CFSJ = res.data.cfsj
            this.forminfo.BZ = res.data.bz
            this.forminfo.SPZT = res.data.spzt
            this.forminfo.SFSX = res.data.sfsx
            this.forminfo.SXSJ = res.data.sxsj
            this.forminfo.SFJC = res.data.sfjc
            this.forminfo.JCCFWH = res.data.jccfwh
            this.forminfo.JCSJ = res.data.jcsj
            this.xsinfo.xh = res.data.xh
            this.xsinfo.xm = res.data.xm
            this.xsinfo.xbm = res.data.xbm
            this.xsinfo.bmmc = res.data.bmmc
            this.xsinfo.zymc = res.data.zymc
            this.xsinfo.bjmc = res.data.bjmc
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    handleStuCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.stupagination.page = val
      this.getStuDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.ZT = 0
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.ZT = currentRow.zt
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetManagePageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          XQ: this.xq,
          CFLX: this.CFLX,
          ZYBH: this.zybh,
          BMBH: this.bmbh,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取分页信息
    getStuDataPageList() {
      getXSPageList({
        queryJson: JSON.stringify({
          keyword: this.selkeyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.stupagination.page = res.data.page
            this.stupagination.total = res.data.total
            this.stupagination.records = res.data.records
            this.selectStuData = res.data.rows
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'WJRDJGDM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.rdjglist = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'CFJGDM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.cfjglist = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectstu() {
      if (this.operation === 1) {
        this.dialogStuVisible = true
        this.getStuDataPageList()
      }
    },
    selStu(row, column, event) {
      this.xsinfo = row
      this.forminfo.XH = row.xh
      this.dialogStuVisible = false
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 200px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
