<!--校区管理-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <el-input
          v-model="keyword"
          placeholder="请输入学号/姓名"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
          >编辑</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delXs"
          >删除</el-button
        >
        <el-button size="small" icon="el-icon-edit" @click="toFormDetail"
          >数据中心</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_czmm')"
          icon="el-icon-edit"
          @click="czmm"
          >重置密码</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="bmmc" label="院系" width="200">
        </el-table-column>
        <el-table-column prop="zymc" label="专业" width="200">
        </el-table-column>
        <el-table-column prop="bjmc" label="班级" width="200">
        </el-table-column>
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="mz" label="民族" width="100"> </el-table-column>
        <el-table-column prop="zt" label="状态"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="70%"
      top="5vh"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="xsinfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="xsinfoRules"
      >
        <div class="group-title">学生基本信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="考生号" label-width="120px" prop="KSH">
              <el-input
                v-model="xsinfo.KSH"
                autocomplete="off"
                maxlength="20"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学号" label-width="120px" prop="XH">
              <el-input
                v-model="xsinfo.XH"
                autocomplete="off"
                maxlength="20"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名" label-width="120px" prop="XM">
              <el-input
                v-model="xsinfo.XM"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="性别" label-width="120px" prop="XBM">
              <el-select v-model="xsinfo.XBM" style="display: block">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期" label-width="120px" prop="CSRQ">
              <el-date-picker
                v-model="xsinfo.CSRQ"
                type="date"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="籍贯" label-width="120px" prop="JG">
              <el-input
                v-model="xsinfo.JG"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="家庭地址" label-width="120px" prop="JTDZ">
              <el-input
                v-model="xsinfo.JTDZ"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="民族" label-width="120px" prop="MZM">
              <el-select v-model="xsinfo.MZM" style="display: block">
                <el-option
                  v-for="(item, index) in mzm"
                  :key="index"
                  :label="item.zdz"
                  :value="item.zddm"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="政治面貌" label-width="120px" prop="ZZMMM">
              <el-select v-model="xsinfo.ZZMMM" style="display: block">
                <el-option
                  v-for="(item, index) in zzmm"
                  :key="index"
                  :label="item.zdz"
                  :value="item.zddm"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" label-width="120px" prop="SFZH">
              <el-input
                v-model="xsinfo.SFZH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="生源所在地" label-width="120px" prop="SYSZDMC">
              <el-input
                v-model="xsinfo.SYSZDMC"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="银行卡号" label-width="120px" prop="YHKH">
              <el-input
                v-model="xsinfo.YHKH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入学年级" label-width="120px" prop="RXNJ">
              <el-select v-model="xsinfo.RXNJ" style="display: block">
                <el-option
                  v-for="(item, index) in nflist"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="group-title">联系方式</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="联系电话" label-width="120px" prop="LXDH">
              <el-input
                v-model="xsinfo.LXDH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="家长联系电话"
              label-width="120px"
              prop="JZLXDH"
            >
              <el-input
                v-model="xsinfo.JZLXDH"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电子邮箱" label-width="120px" prop="DZYX">
              <el-input
                v-model="xsinfo.DZYX"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="QQ" label-width="120px" prop="QQ">
              <el-input
                v-model="xsinfo.QQ"
                autocomplete="off"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="group-title">在校信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="学院" label-width="120px" prop="BMBH">
              <el-select
                v-model="xsinfo.BMBH"
                style="display: block"
                @change="bmchange"
              >
                <el-option
                  v-for="(item, index) in bmlist"
                  :key="index"
                  :label="item.bmmc"
                  :value="item.bmbh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="专业" label-width="120px" prop="ZYBH">
              <el-select
                v-model="xsinfo.ZYBH"
                style="display: block"
                @change="zychange"
              >
                <el-option
                  v-for="(item, index) in zylist"
                  :key="index"
                  :label="item.zymc"
                  :value="item.zybh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="班级" label-width="120px" prop="BJBH">
              <el-select v-model="xsinfo.BJBH" style="display: block">
                <el-option
                  v-for="(item, index) in bjlist"
                  :key="index"
                  :label="item.bjmc"
                  :value="item.bjbh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="年级" label-width="120px" prop="NJ">
              <el-select v-model="xsinfo.NJ" style="display: block">
                <el-option
                  v-for="(item, index) in nflist"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="毕业年份" label-width="120px" prop="BYNF">
              <el-date-picker
                v-model="xsinfo.BYNF"
                type="year"
                style="width: 100%"
                value-format="yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" label-width="120px" prop="ZDZTM">
              <el-select v-model="xsinfo.ZDZTM" style="display: block">
                <el-option
                  v-for="(item, index) in zdzt"
                  :key="index"
                  :label="item.zdz"
                  :value="item.zddm"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getXSPageList,
  saveXSInfo,
  getXSInfo,
  deleteXsInfo,
  getStuBM,
  getZYList,
  getBJList,
  getCode,
  czmm,
} from '../../api/jcxx'
import { getNFList } from '../../utils/nllist.js'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          // 判断是否为数字
          value = value.toString() // 转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            // 判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    var isCardId = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg =
          /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/
        const card = reg.test(value)
        if (!card) {
          callback(new Error('身份证格式如:423024xxxx0216xxxx'))
        } else {
          callback()
        }
      }
    }
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      bmlist: [],
      zylist: [],
      bjlist: [],
      mzm: [],
      zzmm: [],
      nflist: [],
      zdzt: [],
      xh: '',
      xsinfo: {
        ID: '',
        KSH: '',
        XH: '',
        XM: '',
        XBM: '',
        CSRQ: null,
        JG: '',
        JTDZ: '',
        MZM: '',
        ZZMMM: '',
        SFZH: '',
        ZYBH: '',
        BJBH: '',
        NJ: '',
        RXNJ: '',
        BYNF: '',
        ZDZTM: '',
        DZYX: '',
        LXDH: '',
        JZLXDH: '',
        SYSZDDM: '',
        SYSZDMC: '',
        QQ: '',
        YHKH: '',
        BMBH: '',
      },
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      xsinfoRules: {
        KSH: [{ required: true, message: '请输入考生号', trigger: 'blur' }],
        XH: [{ required: true, message: '请输入学号', trigger: 'blur' }],
        XM: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        ZYBH: [{ required: true, message: '请选择专业', trigger: 'blur' }],
        BJBH: [{ required: true, message: '请选择班级', trigger: 'blur' }],
        NJ: [{ required: true, message: '请选择年级', trigger: 'blur' }],
        RXNJ: [{ required: true, message: '请选择入学年级', trigger: 'blur' }],
        BYNF: [{ required: true, message: '请选择毕业年份', trigger: 'blur' }],
        ZDZTM: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        LXDH: [{ validator: isMobileNumber, trigger: 'blur' }],
        JZLXDH: [{ validator: isMobileNumber, trigger: 'blur' }],
        SFZH: [{ validator: isCardId, trigger: 'blur' }],
        DZYX: [
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.xsinfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    getFormInitData() {
      this.nflist = getNFList()
      getStuBM()
        .then((res) => {
          if (res.code === 200) {
            this.bmlist = res.data
          } else {
            this.bmlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bmlist = []
        })
      getZYList({ queryJson: JSON.stringify({ BMBH: this.BMBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.zylist = res.data
          } else {
            this.zylist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zylist = []
        })
      getBJList({ queryJson: JSON.stringify({ ZYBH: this.ZYBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.bjlist = res.data
          } else {
            this.bjlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bjlist = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'MZM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.mzm = res.data
          } else {
            this.mzm = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.mzm = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'ZZMMM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.zzmm = res.data
          } else {
            this.zzmm = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zzmm = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'XJZTM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.zdzt = res.data
          } else {
            this.zdzt = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zzmm = []
        })
    },
    bmchange() {
      this.xsinfo.ZYBH = ''
      this.xsinfo.BJBH = ''
      getZYList({ queryJson: JSON.stringify({ BMBH: this.xsinfo.BMBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.zylist = res.data
          } else {
            this.zylist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zylist = []
        })
    },
    zychange() {
      this.xsinfo.BJBH = ''
      getBJList({ queryJson: JSON.stringify({ ZYBH: this.xsinfo.ZYBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.bjlist = res.data
          } else {
            this.bjlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bjlist = []
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.getFormInitData()
      this.operation = 1
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.xsinfo.BMBH = this.BMBH
        this.getFormInitData()
        this.operation = 0
        getXSInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.xsinfo.ID = res.data.id
            this.xsinfo.KSH = res.data.ksh
            this.xsinfo.XH = res.data.xh
            this.xsinfo.XM = res.data.xm
            this.xsinfo.XBM = res.data.xbm
            this.xsinfo.CSRQ = res.data.csrq
            this.xsinfo.JG = res.data.jg
            this.xsinfo.JTDZ = res.data.jtdz
            this.xsinfo.MZM = res.data.mzm
            this.xsinfo.ZZMMM = res.data.zzmmm
            this.xsinfo.SFZH = res.data.sfzh
            this.xsinfo.ZYBH = res.data.zybh
            this.xsinfo.BJBH = res.data.bjbh
            this.xsinfo.NJ = res.data.nj
            this.xsinfo.RXNJ = res.data.rxnj
            this.xsinfo.BYNF = res.data.bynf
            this.xsinfo.JZLXDH = res.data.jzlxdh
            this.xsinfo.SYSZDDM = res.data.syszddm
            this.xsinfo.SYSZDMC = res.data.syszdmc
            this.xsinfo.QQ = res.data.qq
            this.xsinfo.YHKH = res.data.yhkh
            this.xsinfo.ZDZTM = res.data.zdztm
            this.xsinfo.LXDH = res.data.lxdh
            this.xsinfo.DZYX = res.data.dzyx
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.xsinfo.ID = ''
      } else {
        this.xsinfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveXSInfo({ xs: this.xsinfo }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除学生
    delXs() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteXsInfo({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.xsinfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.xsinfo.BMBH = ''
        this.xsinfo.ZYBH = ''
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.xsinfo.BMBH = currentRow.bmbh
        this.BMBH = currentRow.bmbh
        this.xsinfo.ZYBH = currentRow.zybh
        this.ZYBH = currentRow.zybh
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getXSPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    toFormDetail() {
      this.$router.push({
        path: '/jcxx/studetail/index',
        query: { xh: this.xh },
      })
    },
    czmm() {
      czmm({ XH: this.xh }).then((res) => {
        this.$alert(res.message, {
          confirmButtonText: '确定',
          callback: (action) => {
            this.$message({
              type: 'info',
              message: `action: ${action}`,
            })
          },
        })
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.group-title {
  margin-left: 10px;
  margin-bottom: 10px;
  border-left: 5px solid #409eff;
  font-size: 18px;
  padding-left: 5px;
}
</style>
