<!--
* 创建人：邓国伦
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <xsindex v-if="userinfo.role=='学生'"></xsindex>
  <lsindex v-else></lsindex>
</template>
<script>
import lsindex from '../components/index/lsindex.vue'
import xsindex from '../components/index/xsindex.vue'
import store from '../store/index.js'
export default {
  components: {
    lsindex: lsindex, // 挂载组件
    xsindex: xsindex,
  },
  data() {
    // 这里存放数据
    return {
      userinfo: {},
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.userinfo = store.state.userinfo
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {},
}
</script>

<style scoped >
</style>
