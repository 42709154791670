<!--
* 创建人：罗兴
* 日 期：
* 描 述：添加学生
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="tool">
      <el-button type="primary" size="mini" @click="back">返回</el-button>
      <el-button type="primary" size="mini" @click="saveForm">保存</el-button>
    </div>
    <el-scrollbar style="height: 90%; width: 100%" class="myscrollbar">
      <div class="form">
        <el-form :model="xsinfo" ref="formRef" :rules="xsinfoRules">
          <div class="bt">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="16">
              <el-row :gutter="20">
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item label="姓名" label-width="120px" prop="XM">
                      <el-input
                        v-model="xsinfo.XM"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别" label-width="120px" prop="XBM">
                      <el-select
                        v-model="xsinfo.XBM"
                        style="display: block"
                        size="small"
                      >
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item
                      label="身份证号"
                      label-width="120px"
                      prop="SFZH"
                    >
                      <el-input
                        v-model="xsinfo.SFZH"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="出生日期"
                      label-width="120px"
                      prop="CSRQ"
                    >
                      <el-date-picker
                        v-model="xsinfo.CSRQ"
                        type="date"
                        placeholder="选择日期"
                        size="small"
                        style="width: 100%"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item label="籍贯" label-width="120px" prop="JG">
                      <el-cascader
                        ref="locationCascader"
                        size="small"
                        :value="JGFORM"
                        :options="xzqhList"
                        @change="xzqhhandleChange"
                        style="width: 100%"
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="民族" label-width="120px" prop="MZM">
                      <el-select
                        v-model="xsinfo.MZM"
                        style="display: block"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in mzm"
                          :key="index"
                          :label="item.zdz"
                          :value="item.zddm"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item
                      label="政治面貌"
                      label-width="120px"
                      prop="ZZMMM"
                    >
                      <el-select
                        v-model="xsinfo.ZZMMM"
                        style="display: block"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in zzmm"
                          :key="index"
                          :label="item.zdz"
                          :value="item.zddm"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="联系电话"
                      label-width="120px"
                      prop="LXDH"
                    >
                      <el-input
                        v-model="xsinfo.LXDH"
                        autocomplete="off"
                        maxlength="20"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item label="血型" label-width="120px" prop="XX">
                      <el-select
                        v-model="xsinfo.XX"
                        style="display: block"
                        size="small"
                      >
                        <el-option
                          label="未知血型"
                          value="未知血型"
                        ></el-option>
                        <el-option label="A血型" value="A血型"></el-option>
                        <el-option label="B血型" value="B血型"></el-option>
                        <el-option label="AB血型" value="AB血型"></el-option>
                        <el-option label="O血型" value="O血型"></el-option>
                        <el-option
                          label="RH阳性血型"
                          value="RH阳性血型"
                        ></el-option>
                        <el-option
                          label="RH阴性血型"
                          value="RH阴性血型"
                        ></el-option>
                        <el-option label="HLA血型" value="HLA血型"></el-option>
                        <el-option
                          label="未定血型"
                          value="未定血型"
                        ></el-option>
                        <el-option
                          label="其他血型"
                          value="其他血型"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-row>
            </el-col>
            <el-col :span="8">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :action="uploadurl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="xsinfo.imgurl" :src="xsinfo.imgurl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>
          <div class="bt">在校信息</div>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="学号" label-width="120px" prop="XH">
                <el-input
                  v-model="xsinfo.XH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="年级" label-width="120px" prop="NJ">
                <el-select
                  v-model="xsinfo.NJ"
                  style="display: block"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in nflist"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学院" label-width="120px" prop="BMBH">
                <el-select
                  v-model="xsinfo.BMBH"
                  style="display: block"
                  @change="bmchange"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in bmlist"
                    :key="index"
                    :label="item.bmmc"
                    :value="item.bmbh"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="专业" label-width="120px" prop="ZYBH">
                <el-select
                  v-model="xsinfo.ZYBH"
                  style="display: block"
                  @change="zychange"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in zylist"
                    :key="index"
                    :label="item.zymc"
                    :value="item.zybh"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="班级" label-width="120px" prop="BJBH">
                <el-select
                  v-model="xsinfo.BJBH"
                  style="display: block"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in bjlist"
                    :key="index"
                    :label="item.bjmc"
                    :value="item.bjbh"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学籍状态" label-width="120px" prop="ZDZTM">
                <el-select
                  v-model="xsinfo.ZDZTM"
                  style="display: block"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in zdzt"
                    :key="index"
                    :label="item.zdz"
                    :value="item.zddm"
                  ></el-option>
                </el-select>
              </el-form-item> </el-col
          ></el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="房间号" label-width="120px" prop="FJBH">
                <el-input
                  v-model="xsinfo.FJBH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  onkeyup="value=value.toUpperCase()"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="是否交医疗保险"
                label-width="120px"
                prop="SFJBX"
              >
                <el-select
                  v-model="xsinfo.SFJBX"
                  style="display: block"
                  size="small"
                >
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否住校" label-width="120px" prop="SFZX">
                <el-select
                  v-model="xsinfo.SFZX"
                  style="display: block"
                  size="small"
                >
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bt">联系方式</div>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="QQ号" label-width="120px" prop="QQ">
                <el-input
                  v-model="xsinfo.QQ"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱" label-width="120px" prop="DZYX">
                <el-input
                  v-model="xsinfo.DZYX"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bt">家庭信息</div>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="父亲姓名" label-width="120px" prop="FQXM">
                <el-input
                  v-model="xsinfo.FQXM"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="父亲联系方式"
                label-width="120px"
                prop="FQLXFS"
              >
                <el-input
                  v-model="xsinfo.FQLXFS"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="母亲姓名" label-width="120px" prop="MQXM">
                <el-input
                  v-model="xsinfo.MQXM"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item
                label="母亲联系方式"
                label-width="120px"
                prop="MQLXFS"
              >
                <el-input
                  v-model="xsinfo.MQLXFS"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="家庭地址"
                label-width="120px"
                prop="JTDZFORM"
              >
                <el-cascader
                  size="small"
                  v-model="JTDZFORM"
                  :options="xzqhList"
                  @change="jtdzhandleChange"
                  style="width: 100%"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细地址" label-width="120px" prop="JTDZ_MX">
                <el-input
                  v-model="xsinfo.JTDZ_MX"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="户籍地址" label-width="120px" prop="HJDZ">
                <el-input
                  v-model="xsinfo.HJDZ"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bt">在校其他信息</div>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="入学年级" label-width="120px" prop="RXNJ">
                <el-select
                  v-model="xsinfo.RXNJ"
                  style="display: block"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in nflist"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="考生号" label-width="120px" prop="KSH">
                <el-input
                  v-model="xsinfo.KSH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="入学成绩" label-width="120px" prop="RXCJ">
                <el-input
                  v-model="xsinfo.RXCJ"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="毕业学校" label-width="120px" prop="YXX">
                <el-input
                  v-model="xsinfo.YXX"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="毕业日期" label-width="120px" prop="BYRQ">
                <el-date-picker
                  v-model="xsinfo.BYRQ"
                  type="date"
                  placeholder="选择日期"
                  size="small"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工行卡号" label-width="120px" prop="GHYHKH">
                <el-input
                  v-model="xsinfo.GHYHKH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="建行卡号" label-width="120px" prop="JHYHKH">
                <el-input
                  v-model="xsinfo.JHYHKH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="是否兵役登记"
                label-width="120px"
                prop="SFWCBYDJ"
              >
                <el-select
                  v-model="xsinfo.SFWCBYDJ"
                  style="display: block"
                  size="small"
                >
                  <el-option label="是" :value="1"></el-option>
                  <el-option label="否" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="兵役登记号" label-width="120px" prop="BYDJH">
                <el-input
                  v-model="xsinfo.BYDJH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item
                label="是否退役人人员"
                label-width="120px"
                prop="SFTYJR"
              >
                <el-select
                  v-model="xsinfo.SFTYJR"
                  style="display: block"
                  size="small"
                >
                  <el-option label="是" :value="1"></el-option>
                  <el-option label="否" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-scrollbar>
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getCode,
  getStuBM,
  getZYList,
  getBJList,
  saveXSInfo,
  getXSDetailView,
} from '../../api/jcxx'
import sg from '../../api/gygl'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
import { getNFList } from '../../utils/nllist.js'
import { arealsit } from '../../utils/area'
export default {
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          // 判断是否为数字
          value = value.toString() // 转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            // 判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    var extfjbh = (r, v, callback) => {
      if (v === '' || v === null) {
        return callback(new Error('房间号不能为空'))
      } else {
        sg.exfjbh({ bh: v })
          .then((res) => {
            if (res.code === 200) {
              if (res.data.flag === true) {
                return callback()
              } else {
                return callback(new Error('房间号不存在，按照寝室门牌号填写！'))
              }
            } else {
              return callback(new Error('房间号验证失败'))
            }
          })
          .catch((e) => {
            return callback(new Error('房间号验证失败'))
          })
      }
    }

    return {
      flag: 0,
      xsinfo: {
        XM: '',
        XBM: '',
        SFZH: '',
        CSRQ: '',
        JG: '',
        MZM: '',
        ZZMMM: '',
        LXDH: '',
        XX: '',
        imgurl: '',
        XH: '',
        NJ: '',
        BMBH: '',
        ZYBH: '',
        BJBH: '',
        ZDZTM: '',
        SFJBX: null,
        SFZX: null,
        FJBH: '',
        QQ: '',
        DZYX: '',
        FQXM: '',
        FQLXFS: '',
        MQXM: '',
        MQLXFS: '',
        JTDZ: '',
        HJDZ: '',
        RXNJ: '',
        KSH: '',
        RXCJ: null,
        YXX: '',
        BYRQ: null,
        GHYHKH: '',
        JHYHKH: '',
        SFWCBYDJ: null,
        BYDJH: '',
        SFTYJR: null,
        JG_SHENG: '',
        JG_SHI: '',
        JG_QU: '',
        JTDZ_SHENG: '',
        JTDZ_SHI: '',
        JTDZ_QU: '',
        JTDZ_MX: '',
      },
      mzm: [],
      zzmm: [],
      zdzt: [],
      nflist: [],
      bmlist: [],
      zylist: [],
      bjlist: [],
      // 表单验证规则对象
      xsinfoRules: {
        KSH: [{ required: true, message: '请输入考生号', trigger: 'blur' }],
        XH: [{ required: true, message: '请输入学号', trigger: 'blur' }],
        XM: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        BMBH: [{ required: true, message: '请选择学院', trigger: 'blur' }],
        ZYBH: [{ required: true, message: '请选择专业', trigger: 'blur' }],
        BJBH: [{ required: true, message: '请选择班级', trigger: 'blur' }],
        NJ: [{ required: true, message: '请选择年级', trigger: 'blur' }],
        RXNJ: [{ required: true, message: '请选择入学年级', trigger: 'blur' }],
        BYNF: [{ required: true, message: '请选择毕业年份', trigger: 'blur' }],
        ZDZTM: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        LXDH: [{ validator: isMobileNumber, trigger: 'blur' }],
        JZLXDH: [{ validator: isMobileNumber, trigger: 'blur' }],
        SFZH: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
        FJBH: [{ required: true, validator: extfjbh, trigger: 'blur' }],
        DZYX: [
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
      },
      headers: null,
      uploadurl: baseUrl + 'File/uploadImg',
      baseurl: baseUrl,
      areaLsit: arealsit,
      xzqhList: [],
      JGFORM: [],
      JTDZFORM: [],
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    getCode({ queryJson: JSON.stringify({ LXDM: 'MZM' }) })
      .then((res) => {
        if (res.code === 200) {
          this.mzm = res.data
        } else {
          this.mzm = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.mzm = []
      })
    getCode({ queryJson: JSON.stringify({ LXDM: 'ZZMMM' }) })
      .then((res) => {
        if (res.code === 200) {
          this.zzmm = res.data
        } else {
          this.zzmm = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.zzmm = []
      })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XJZTM' }) })
      .then((res) => {
        if (res.code === 200) {
          this.zdzt = res.data
        } else {
          this.zdzt = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.zdzt = []
      })
    this.nflist = getNFList()
    getStuBM()
      .then((res) => {
        if (res.code === 200) {
          this.bmlist = res.data
        } else {
          this.bmlist = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.bmlist = []
      })
    this.getZXQH()
    getXSDetailView({ xh: this.$route.query.xh }).then((res) => {
      if (res.code === 200 && res.data) {
        this.bmchange(res.data.bmbh)
        this.zychange(res.data.zybh)
        this.xsinfo.ID = res.data.id
        this.xsinfo.XM = res.data.xm
        this.xsinfo.XBM = res.data.xbm
        this.xsinfo.SFZH = res.data.sfzh
        this.xsinfo.CSRQ = res.data.csrq
        this.xsinfo.JG = res.data.jg
        this.xsinfo.MZM = res.data.mzm
        this.xsinfo.ZZMMM = res.data.zzmmm
        this.xsinfo.LXDH = res.data.lxdh
        this.xsinfo.XX = res.data.xx
        this.xsinfo.imgurl = res.data.imgurlFJBH
        this.xsinfo.XH = res.data.xh
        this.xsinfo.NJ = res.data.nj
        this.xsinfo.BMBH = res.data.bmbh
        this.xsinfo.ZYBH = res.data.zybh
        this.xsinfo.BJBH = res.data.bjbh
        this.xsinfo.ZDZTM = res.data.zdztm
        this.xsinfo.SFJBX = res.data.sfjbx
        this.xsinfo.SFZX = res.data.sfzx
        this.xsinfo.FJBH = res.data.fjbh
        this.xsinfo.QQ = res.data.qq
        this.xsinfo.DZYX = res.data.dzyx
        this.xsinfo.FQXM = res.data.fqxm
        this.xsinfo.FQLXFS = res.data.fqlxfs
        this.xsinfo.MQXM = res.data.fqxm
        this.xsinfo.MQLXFS = res.data.fqlxfs
        this.xsinfo.JTDZ = res.data.jtdz
        this.xsinfo.HJDZ = res.data.hjdz
        this.xsinfo.RXNJ = res.data.rxnj
        this.xsinfo.KSH = res.data.ksh
        this.xsinfo.RXCJ = res.data.rxcj
        this.xsinfo.YXX = res.data.yxx
        this.xsinfo.BYRQ = res.data.byrq
        this.xsinfo.GHYHKH = res.data.ghyhkh
        this.xsinfo.JHYHKH = res.data.jhyhkh
        this.xsinfo.SFWCBYDJ = res.data.sfwcbydj
        this.xsinfo.BYDJH = res.data.bydjh
        this.xsinfo.SFTYJR = res.data.sftyjr
        this.xsinfo.JG_SHENG = res.data.jG_SHENG
        this.xsinfo.JG_SHI = res.data.jG_SHI
        this.xsinfo.JG_QU = res.data.jG_QU
        this.xsinfo.JTDZ_SHENG = res.data.jtdZ_SHENG
        this.xsinfo.JTDZ_SHI = res.data.jtdZ_SHI
        this.xsinfo.JTDZ_QU = res.data.jtdZ_QU
        this.xsinfo.JTDZ_MX = res.data.jtdZ_MX
        this.flag = 1
        if (res.data.jG_SHENG && res.data.jG_SHI && res.data.jG_QU) {
          this.JGFORM = [res.data.jG_SHENG, res.data.jG_SHI, res.data.jG_QU]
        }
        if (res.data.jtdZ_SHENG && res.data.jtdZ_SHI && res.data.jtdZ_QU) {
          this.JTDZFORM = [
            res.data.jtdZ_SHENG,
            res.data.jtdZ_SHI,
            res.data.jtdZ_QU,
          ]
        }
      }
    })
  },
  methods: {
    back() {
      this.$router.push({
        path: '/jcxx/sy_xsinfo/index',
      })
    },
    handleAvatarSuccess(res, file) {
      this.xsinfo.imgurl = this.baseurl + res.data.filePath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    bmchange(bmbh) {
      if (this.flag === 1) {
        this.xsinfo.ZYBH = ''
        this.xsinfo.BJBH = ''
      }
      getZYList({
        queryJson: JSON.stringify({
          BMBH: bmbh != null ? bmbh : this.xsinfo.BMBH,
        }),
      })
        .then((res) => {
          if (res.code === 200) {
            this.zylist = res.data
          } else {
            this.zylist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zylist = []
        })
    },
    zychange(zybh) {
      if (this.flag === 1) {
        this.xsinfo.BJBH = ''
      }
      getBJList({
        queryJson: JSON.stringify({
          ZYBH: zybh != null ? zybh : this.xsinfo.ZYBH,
        }),
      })
        .then((res) => {
          if (res.code === 200) {
            this.bjlist = res.data
          } else {
            this.bjlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bjlist = []
        })
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveXSInfo({ xs: this.xsinfo }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    getZXQH() {
      var ccc = this.areaLsit.districts[0]
      var tmp = []
      for (let i = 0; i < ccc.districts.length; i++) {
        tmp.push({
          value: ccc.districts[i].name,
          label: ccc.districts[i].name,
          children: [],
        })
        for (let j = 0; j < ccc.districts[i].districts.length; j++) {
          tmp[i].children.push({
            value: ccc.districts[i].districts[j].name,
            label: ccc.districts[i].districts[j].name,
            children: [],
          })
          for (
            let k = 0;
            k < ccc.districts[i].districts[j].districts.length;
            k++
          ) {
            tmp[i].children[j].children.push({
              value: ccc.districts[i].districts[j].districts[k].name,
              label: ccc.districts[i].districts[j].districts[k].name,
            })
          }
        }
      }
      this.xzqhList = tmp
    },
    xzqhhandleChange(value) {
      this.xsinfo.JG_SHENG = value[0]
      this.xsinfo.JG_SHI = value[1]
      this.xsinfo.JG_QU = value[2]
      this.JGFORM = value
    },
    jtdzhandleChange(value) {
      this.xsinfo.JTDZ_SHENG = value[0]
      this.xsinfo.JTDZ_SHI = value[1]
      this.xsinfo.JTDZ_QU = value[2]
      this.JTDZFORM = value
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}
.tool {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  background: white;
  min-width: 600px;
}
.form {
  width: 1240px;
  margin: 0 auto;
  border: 1px solid #e2e4e5;
  padding: 16px;
  border-radius: 6px;
  background: white;
  min-height: 20px;
}
.bt {
  background-color: #eeeeee;
  padding: 5px;
  font-weight: 700;
}
>>> .el-upload {
  /* border: 1px dashed #d9d9d9; */
  margin: 20px 0 0 120px;
  border: 1px solid #8c939d !important;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 126px;
  height: 180px;
  line-height: 180px;
  text-align: center;
}
.avatar {
  width: 126px;
  height: 180px;
  display: block;
}
</style>
