<!--困难生申请-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">困难生申请</div>
    <table
      id="Panel1"
      class="DivTable"
      cellpadding="3"
      cellspacing="0"
      v-if="showtop"
    >
      <tbody>
        <tr>
          <td width="15%" class="EdtTitleBack"><strong>当前学年</strong>：</td>
          <td class="EdtBack">
            <span id="TermYear" style="color: #ff0033; font-weight: bold">{{
              pcmodel.xn
            }}</span>
          </td>
        </tr>
        <tr>
          <td width="15%" class="EdtTitleBack">
            <strong>申请时间段</strong>：
          </td>
          <td class="EdtBack">
            <span id="BeginDate">{{ dateFormat2(pcmodel.kssj) }}</span
            >&nbsp;至&nbsp;<span id="EndDate">{{
              dateFormat2(pcmodel.jssj)
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack"><strong>重点提示</strong>：</td>
          <td class="EdtBack">
            <span id="MostSay" style="color: #ff0033; font-weight: bold">{{
              pcmodel.ts
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack">
            <strong><span style="font-family: 宋体">说明</span></strong
            >：
          </td>
          <td class="EdtBack">
            <span id="Memo">{{ pcmodel.sm }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack">
            <strong
              ><span style="font-family: 宋体"
                >家庭经济困难学生<br />
                提交资料表格</span
              ></strong
            >：
          </td>
          <td class="EdtBack">
            <a
              v-for="(item, index) in topfile"
              :key="index"
              target="_blank"
              :href="downurl + 'File/download?id=' + item.id"
              >{{ item.fileName }}</a
            >
          </td>
        </tr>
        <tr>
          <td colspan="2" class="EdtBack" style="text-align: center">
            <el-button type="primary" @click="showAdd">提交申请</el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 300"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="200"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="200"></el-table-column>
        <el-table-column prop="xb" label="性别" width="200">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="xn" label="学年" width="300"> </el-table-column>
        <el-table-column prop="zt" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.spzt === 1" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 2" type="danger"
              >辅导员审核拒绝</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleDetail(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--添加弹出框-->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <!-- <div class="form-item-group">
          <i class="fa fa-address-book-o"></i>
          <div>
            审批流程 当前状态：
            <el-tag v-if="forminfo.SPZT === 0">待辅导员审核</el-tag>
            <el-tag v-if="forminfo.SPZT === 1" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 2" type="danger"
              >辅导员审核拒绝</el-tag
            >
          </div>
        </div> -->
        <!-- <el-timeline>
          <el-timeline-item
            v-for="(item, index) in splist"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 1 || item.spzt == 2">
                辅导员审批{{ item.spzt == 1 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline> -->
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-card class="box-card">
            <span class="staffBmTitle">四川省家庭经济困难学生认定申请表</span>
            <el-row :gutter="0" class="inputnoborder">
              <el-col :span="5">
                <el-form-item label="学校：" label-width="55px">
                  <el-input value="成都艺术职业大学" readonly> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="院系：" label-width="55px">
                  <el-input v-model="forminfo.BMMC" readonly> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="专业：" label-width="55px">
                  <el-input v-model="forminfo.ZYMC" readonly> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="年级：" label-width="55px">
                  <el-input v-model="forminfo.NJ" readonly> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="班级：" label-width="55px">
                  <el-input v-model="forminfo.BJMC" readonly> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <table border="1px" class="staffBmTable">
              <tr>
                <td rowspan="5" style="width: 30px">学生基本情况</td>
                <td style="width: 100px">姓名</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.XM">
                    </el-input>
                  </el-form-item>
                </td>
                <td style="width: 100px">性别</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.XB">
                    </el-input>
                  </el-form-item>
                </td>
                <td style="width: 100px">出生年月</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.CSNY">
                    </el-input>
                  </el-form-item>
                </td>
                <td style="width: 100px">籍贯</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.JG">
                    </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>身份证号码</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.SFZH">
                    </el-input>
                  </el-form-item>
                </td>
                <td>家庭人口</td>
                <td>
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="JTRS"
                  >
                    <el-input size="small" v-model="forminfo.JTRS"> </el-input>
                  </el-form-item>
                </td>
                <td>手机号码</td>
                <td colspan="3">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="SJHM"
                  >
                    <el-input size="small" v-model="forminfo.SJHM"> </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>详细通讯地址</td>
                <td colspan="7">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="JTDZ"
                  >
                    <el-input size="small" v-model="forminfo.JTDZ"> </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>邮政编码</td>
                <td colspan="3">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="YZBM"
                  >
                    <el-input size="small" v-model="forminfo.YZBM"> </el-input>
                  </el-form-item>
                </td>
                <td>家长手机号码</td>
                <td colspan="3">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="JZSJHM"
                  >
                    <el-input size="small" v-model="forminfo.JZSJHM">
                    </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>户口性质</td>
                <td colspan="3">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="HKXZ"
                  >
                    <el-checkbox-group v-model="forminfo.HKXZ">
                      <el-checkbox
                        label="城镇"
                        :true-label="1"
                        name="HKXZ"
                      ></el-checkbox>
                      <el-checkbox
                        label="农村"
                        :true-label="2"
                        name="HKXZ"
                      ></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </td>
                <td>学费标准</td>
                <td>
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="XFBZ"
                  >
                    <el-input size="small" v-model="forminfo.XFBZ"> </el-input>
                  </el-form-item>
                </td>
                <td>住宿费标准</td>
                <td>
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="ZSFBZ"
                  >
                    <el-input size="small" v-model="forminfo.ZSFBZ"> </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td style="width: 30px">家庭成员情况</td>
                <td colspan="8" style="padding: 0px">
                  <el-table
                    :data="fromTable"
                    style="width: 100%"
                    border
                    :header-cell-style="{
                      borderColor: '#000',
                      color: '#303133',
                      fontSize: '12px',
                      fontWeight: 'normal',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }"
                    :cell-style="{
                      borderColor: '#000',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }"
                  >
                    <el-table-column
                      prop="XM"
                      label="姓名"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.XM" size="small">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="NL"
                      label="年龄"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.NL"
                          type="number"
                          size="small"
                        >
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="GX"
                      label="与学生关系"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.GX" size="small">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="GZDW"
                      label="工作（学习）单位"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.GZDW" size="small">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="ZY"
                      label="职业"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.ZY" size="small">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="NSR"
                      label="年收入（元）"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.NSR"
                          type="number"
                          size="small"
                        >
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="JKZK"
                      label="健康状况"
                      header-align="center"
                    >
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.JKZK" size="small">
                        </el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </td>
              </tr>
              <tr>
                <td style="width: 30px">特殊群体类型</td>
                <td colspan="8" style="text-align: left">
                  <el-checkbox-group v-model="forminfo.TSQTLXList">
                    <el-row :gutter="20">
                      <el-col style="margin: 5px 0" :span="6"
                        ><el-checkbox
                          label="1、建档立卡贫困家庭学生"
                        ></el-checkbox
                      ></el-col>
                      <el-col style="margin: 5px 0" :span="6"
                        ><el-checkbox
                          label="2、最低生活保障家庭学生"
                        ></el-checkbox
                      ></el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col style="margin: 5px 0" :span="6"
                        ><el-checkbox label="3、特困供养学生"></el-checkbox
                      ></el-col>
                      <el-col style="margin: 5px 0" :span="6"
                        ><el-checkbox label="4、孤残学生"></el-checkbox
                      ></el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col style="margin: 5px 0" :span="6"
                        ><el-checkbox label="5、烈士子女"></el-checkbox
                      ></el-col>
                      <el-col style="margin: 5px 0" :span="6"
                        ><el-checkbox
                          label="6、家庭经济困难残疾学生及残疾人子女"
                        ></el-checkbox
                      ></el-col>
                    </el-row>
                  </el-checkbox-group>
                  <span style="margin-top: 10px"
                    >（注：请按实际情况勾选，如不属于上述特殊困难群体，本栏可不填）</span
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 30px">影响家庭经济状况其他有关信息</td>
                <td colspan="8" style="text-align: left">
                  <el-row>
                    <el-col style="margin: 5px 0">
                      <el-checkbox
                        :true-label="1"
                        :false-label="0"
                        v-model="forminfo.JTRJNSRXZ"
                        >1、家庭人均年收入：<el-input
                          type="number"
                          :readonly="forminfo.JTRJNSRXZ == 0"
                          size="small"
                          v-model="forminfo.JTRJNSR"
                        >
                        </el-input
                        >元</el-checkbox
                      >
                    </el-col>
                    <el-col style="margin: 5px 0">
                      <el-checkbox
                        v-model="forminfo.ZRZHXZ"
                        :true-label="1"
                        :false-label="0"
                        >2、家庭遭受自然灾害情况：<el-input
                          :readonly="forminfo.ZRZHXZ == 0"
                          v-model="forminfo.ZRZH"
                          size="small"
                        >
                        </el-input
                      ></el-checkbox>
                    </el-col>
                    <el-col style="margin: 5px 0">
                      <el-checkbox
                        v-model="forminfo.TFYWXZ"
                        :true-label="1"
                        :false-label="0"
                        >3、家庭遭受突发意外事件：<el-input
                          :readonly="forminfo.TFYWXZ == 0"
                          v-model="forminfo.TFYW"
                          size="small"
                        >
                        </el-input
                      ></el-checkbox>
                    </el-col>
                    <el-col style="margin: 5px 0">
                      <el-checkbox
                        v-model="forminfo.NDLRXZ"
                        :true-label="1"
                        :false-label="0"
                        >4、家庭成员因残疾、年迈而劳动能力弱情况：<el-input
                          :readonly="forminfo.NDLRXZ == 0"
                          v-model="forminfo.NDLR"
                          size="small"
                        >
                        </el-input
                      ></el-checkbox>
                    </el-col>
                    <el-col style="margin: 5px 0">
                      <el-checkbox
                        v-model="forminfo.SYQKXZ"
                        :true-label="1"
                        :false-label="0"
                        >5、家庭成员失业情况：<el-input
                          :readonly="forminfo.SYQKXZ == 0"
                          v-model="forminfo.SYQK"
                          size="small"
                        >
                        </el-input
                      ></el-checkbox>
                    </el-col>
                    <el-col style="margin: 5px 0">
                      <el-checkbox
                        v-model="forminfo.QZQKXZ"
                        :true-label="1"
                        :false-label="0"
                        >6、家庭欠债情况：<el-input
                          :readonly="forminfo.QZQKXZ == 0"
                          v-model="forminfo.QZQK"
                          size="small"
                        >
                        </el-input
                      ></el-checkbox>
                    </el-col>
                    <el-col style="margin: 5px 0">
                      <el-checkbox
                        v-model="forminfo.QTQKXZ"
                        :true-label="1"
                        :false-label="0"
                        >7、其他情况：<el-input
                          :readonly="forminfo.QTQKXZ == 0"
                          v-model="forminfo.QTQK"
                          size="small"
                        >
                        </el-input
                      ></el-checkbox>
                    </el-col>
                  </el-row>
                  <span style="margin-top: 10px"
                    >（注：1、请按实际情况勾选，并注明相应情况；2、请尽可能提供相应佐证材料。）</span
                  >
                </td>
              </tr>
            </table>
          </el-card>
          <el-form-item label="附件材料" label-width="120px" class="mb10">
            <el-upload
              class="upload-demo"
              :headers="headers"
              :action="uploadurl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :before-remove="beforeRemove"
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                存在文件必须填写说明，附件类型包括：jpg，gif，bmp，doc，rar，zip，pdf，xls,swf
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer" v-if="forminfo.SPZT != 1">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
    <!--详情弹出框-->
    <el-dialog
      title="详情"
      :visible.sync="dialogDetailVisible"
      width="70%"
      :close-on-click-modal="false"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <div class="form-item-group">
          <i class="fa fa-address-book-o"></i>
          <div>
            审批流程 当前状态：
            <el-tag v-if="forminfo.SPZT === 0">待辅导员审核</el-tag>
            <el-tag v-if="forminfo.SPZT === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 110" type="success"
              >系主任审核通过</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 120" type="danger"
              >系主任审核拒绝</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 111" type="success"
              >系分管领导审核通过</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 112" type="danger"
              >系分管领导审核拒绝</el-tag
            >
          </div>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in splist"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 100 || item.spzt == 200">
                辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 110 || item.spzt == 120">
                系部领导审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 111 || item.spzt == 112">
                系部分管领导审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-form :model="forminfo" ref="formRef" :rules="forminfoRules">
          <div class="form-item-group">
            <i class="fa fa-calendar"></i>
            <div>学生家庭信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="人数" label-width="120px" prop="JTRS">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model="forminfo.JTRS"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="家庭人均年收入(元)"
                label-width="150px"
                prop="JTRJNSR"
              >
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model="forminfo.JTRJNSR"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item
                label="家庭通讯地址"
                label-width="120px"
                prop="JTDZ"
              >
                <el-input placeholder="请输入内容" v-model="forminfo.JTDZ">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="入学前户口" label-width="120px" prop="RXQHK">
                <el-radio v-model="forminfo.RXQHK" :label="1">城镇</el-radio>
                <el-radio v-model="forminfo.RXQHK" :label="2">农村</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮政编码" label-width="120px" prop="YZBM">
                <el-input placeholder="请输入内容" v-model="forminfo.YZBM">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系电话" label-width="120px" prop="LXDH">
                <el-input placeholder="请输入内容" v-model="forminfo.LXDH">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-table :data="fromTable" style="width: 100%">
            <el-table-column label="家庭成员情况" header-align="center">
              <el-table-column prop="XM" label="姓名" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.XM"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="NL" label="年龄" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NL" type="number"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GX"
                label="与学生关系"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GX"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GZDW"
                label="工作（学习）单位"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GZDW"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="ZY" label="职业" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.ZY"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="NSR"
                label="年收入（元）"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NSR" type="number"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="JKZK"
                label="健康状况"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.JKZK"> </el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

          <div class="form-item-group mt10">
            <i class="fa fa-address-book-o"></i>
            <div>家庭经济困难原因调查表</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="生源所在地" label-width="120px" prop="DQCB">
                <el-select v-model="forminfo.DQCB" style="width: 100%">
                  <el-option label="城市" :value="1"></el-option>
                  <el-option label="县城" :value="2"></el-option>
                  <el-option label="农村" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="多子女" label-width="120px" prop="DZN">
                <el-select v-model="forminfo.DZN" style="width: 100%">
                  <el-option
                    label="除本人外兄弟姐妹中有2人及以上需父母供养"
                    :value="1"
                  ></el-option>
                  <el-option
                    label="除本人外兄弟姐妹中有1人需父母供养"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="除本人外无兄弟姐妹需父母供养"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="家人健康状况"
                label-width="120px"
                prop="JRJKQK"
              >
                <el-select v-model="forminfo.JRJKQK" style="width: 100%">
                  <el-option label="家人身体状况良好" :value="1"></el-option>
                  <el-option
                    label="家人身体状况偏差（患有慢性疾病，家庭年医药费总支出占家庭总收入25%以下）"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="家人身体状况极差（失去大部分劳动力或患有慢性疾病，家庭年医药费总支出占家庭总收入25%及以上）"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="父母收入" label-width="120px" prop="FMSR">
                <el-select v-model="forminfo.FMSR" style="width: 100%">
                  <el-option label="父母一方有稳定收入" :value="1"></el-option>
                  <el-option label="父母双方有稳定收入" :value="2"></el-option>
                  <el-option
                    label="父母双方均没有稳定收入"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="孤儿及单亲" label-width="120px" prop="GEJDQ">
                <el-select v-model="forminfo.GEJDQ" style="width: 100%">
                  <el-option label="孤儿" :value="1"></el-option>
                  <el-option
                    label="单亲家庭（父母离异-监护人为父亲或母亲）"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="单亲家庭（一方亡故-监护人为父亲或母亲）"
                    :value="3"
                  ></el-option>
                  <el-option label="非单亲家庭或孤儿" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="自然灾害情况"
                label-width="120px"
                prop="ZRZH"
              >
                <el-select v-model="forminfo.ZRZH" style="width: 100%">
                  <el-option
                    label="遭受自然灾害或家庭成员突患重病（与选项4非同类情况的突发严重疾病）"
                    :value="1"
                  ></el-option>
                  <el-option
                    label="城乡享受政府低保的家庭"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="非低保家庭且未遭受重大变故"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="困难等级" label-width="120px" prop="KNDJ">
                <el-select v-model="forminfo.KNDJ" style="width: 100%">
                  <el-option label="一般困难" :value="1"></el-option>
                  <el-option label="困难" :value="2"></el-option>
                  <el-option label="特别困难" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="申请理由" label-width="120px" prop="RDLY">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="forminfo.RDLY"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="附件材料" label-width="120px" class="mb10">
            <el-upload
              class="upload-demo"
              :headers="headers"
              :action="uploadurl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :before-remove="beforeRemove"
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                存在文件必须填写说明，附件类型包括：jpg，gif，bmp，doc，rar，zip，pdf，xls,swf
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <div
        v-if="forminfo.SPZT == 0 || forminfo.SPZT == 2"
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogDetailVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDetail">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getMyAllDetailInfo } from '../../../api/jcxx'
import {
  stuGetPC,
  stuSave,
  getStuApplyPageList,
  getSQFormData,
  getListBySQID,
  getSPJL,
  GetListByPCID,
} from '../../../api/kns'
import { getAuthorizeButtonColumnList } from '../../../api/system'
import moment from 'moment'
import { baseUrl } from '../../../utils/http'
import store from '../../../store/index'
export default {
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          // 判断是否为数字
          value = value.toString() // 转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            // 判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      topfile: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      splist: [],
      pcmodel: {},
      showtop: false,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      detail: {},
      detailTable: [],
      operation: 1, // 当前操作1=添加，0=编辑
      fromTable: [
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
      ],
      forminfo: {
        BMMC: '',
        ZYMC: '',
        BJMC: '',
        NJ: '',
        ID: '',
        PCID: '',
        XH: '',
        XM: '',
        XB: '',
        CSNY: '',
        JG: '',
        SFZH: '',
        JTRS: '',
        SJHM: '',
        YZBM: '',
        JTDZ: '',
        JZSJHM: '',
        HKXZ: 1,
        XFBZ: 0,
        ZSFBZ: 0,
        TSQTLX: '',
        JTRJNSR: null,
        JTRJNSRXZ: 0,
        ZRZH: '',
        ZRZHXZ: 0,
        TFYW: '',
        TFYWXZ: 0,
        NDLR: '',
        NDLRXZ: 0,
        SYQK: '',
        SYQKXZ: 0,
        QZQK: '',
        QZQKXZ: 0,
        QTQK: '',
        QTQKXZ: 0,
        SPZT: 0,
        RDZT: 0,
        jtcy: [],
        fj: [],
        TSQTLXList: [],
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        JTRS: [
          { required: true, message: '请填写家庭人口数', trigger: 'blur' },
        ],
        SJHM: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: isMobileNumber, trigger: 'blur' },
        ],
        JTDZ: [
          { required: true, message: '请输入详细通讯地址', trigger: 'blur' },
        ],
        YZBM: [{ required: true, message: '请输入邮政编码', trigger: 'blur' }],
        JZSJHM: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: isMobileNumber, trigger: 'blur' },
        ],
        HKXZ: [{ required: true, message: '请选择户口性质', trigger: 'blur' }],
        XFBZ: [{ required: true, message: '请输入学费标准', trigger: 'blur' }],
        ZSFBZ: [
          { required: true, message: '请输入住宿费标准', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    stuGetPC()
      .then((res) => {
        if (res.code === 200) {
          this.showtop = true
          this.pcmodel = res.data
          GetListByPCID({ pcid: res.data.id }).then((res) => {
            if (res.code === 200) {
              this.topfile = res.data
            }
          })
        } else {
          this.showtop = false
        }
      })
      .catch((err) => {
        console.log(err)
        this.showtop = false
      })
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.fileList = []
      this.splist = []
      if (this.operation === 1) {
        getMyAllDetailInfo().then((res) => {
          if (res.code === 200) {
            this.forminfo.XH = res.data.xh
            this.forminfo.XM = res.data.xm
            this.forminfo.XB = res.data.xbm === '1' ? '男' : '女'
            this.forminfo.CSNY = this.dateFormat3(res.data.csrq)
            this.forminfo.JG = res.data.jg
            this.forminfo.SFZH = res.data.sfzh
            this.forminfo.SJHM = res.data.lxdh
            this.forminfo.JTDZ = res.data.jtdz
            this.forminfo.BMMC = res.data.bmmc
            this.forminfo.ZYMC = res.data.zymc
            this.forminfo.BJMC = res.data.bjmc
            this.forminfo.NJ = res.data.nj
            this.forminfo.JZSJHM = res.data.jzlxdh
          }
        })
      }
      this.dialogFormVisible = true
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.forminfo.ID = ''
      } else {
        this.forminfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.jtcy = this.fromTable
          this.forminfo.PCID = this.pcmodel.id
          this.forminfo.FJIDS = []
          for (var i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].response) {
              this.forminfo.FJIDS.push(this.fileList[i].response.data.id)
            } else {
              this.forminfo.FJIDS.push(this.fileList[i].id)
            }
          }
          this.forminfo.TSQTLX = this.forminfo.TSQTLXList.join(',')
          stuSave(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.dialogDetailVisible = false
              this.getDataPageList()
              stuGetPC()
                .then((res) => {
                  if (res.code === 200) {
                    this.showtop = true
                    this.pcmodel = res.data
                  } else {
                    this.showtop = false
                  }
                })
                .catch((err) => {
                  console.log(err)
                  this.showtop = false
                })
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 保存信息
    saveDetail() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.jtcy = this.fromTable
          this.forminfo.FJIDS = []
          for (var i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].response) {
              this.forminfo.FJIDS.push(this.fileList[i].response.data.id)
            } else {
              this.forminfo.FJIDS.push(this.fileList[i].id)
            }
          }
          this.forminfo.TSQTLX = this.forminfo.TSQTLXList.join(',')
          stuSave(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.dialogDetailVisible = false
              this.getDataPageList()
              stuGetPC()
                .then((res) => {
                  if (res.code === 200) {
                    this.showtop = true
                    this.pcmodel = res.data
                  } else {
                    this.showtop = false
                  }
                })
                .catch((err) => {
                  console.log(err)
                  this.showtop = false
                })
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getStuApplyPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat2(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
    },
    handleDetail(id) {
      this.operation = 2
      getMyAllDetailInfo().then((res) => {
        if (res.code === 200) {
          this.forminfo.BMMC = res.data.bjmc
          this.forminfo.ZYMC = res.data.zymc
          this.forminfo.BJMC = res.data.bjmc
          this.forminfo.NJ = res.data.nj
        }
      })
      getSQFormData({ id: id }).then((res) => {
        if (res.code === 200) {
          this.forminfo.ID = res.data.id
          this.forminfo.XH = res.data.xh
          this.forminfo.PCID = res.data.pcid
          this.forminfo.XM = res.data.xm
          this.forminfo.XB = res.data.xb
          this.forminfo.CSNY = this.dateFormat3(res.data.csny)
          this.forminfo.JG = res.data.jg
          this.forminfo.SFZH = res.data.sfzh
          this.forminfo.JTRS = res.data.jtrs
          this.forminfo.SJHM = res.data.sjhm
          this.forminfo.YZBM = res.data.yzbm
          this.forminfo.JTDZ = res.data.jtdz
          this.forminfo.JZSJHM = res.data.jzsjhm
          this.forminfo.HKXZ = res.data.hkxz
          this.forminfo.XFBZ = res.data.xfbz
          this.forminfo.ZSFBZ = res.data.zsfbz
          this.forminfo.JTRJNSR = res.data.jtrjnsr
          this.forminfo.JTRJNSRXZ = res.data.jtrjnsrxz
          this.forminfo.ZRZH = res.data.zrzh
          this.forminfo.ZRZHXZ = res.data.zrzhxz
          this.forminfo.TFYW = res.data.tfyw
          this.forminfo.TFYWXZ = res.data.tfywxz
          this.forminfo.NDLR = res.data.ndlr
          this.forminfo.NDLRXZ = res.data.ndlrxz
          this.forminfo.SYQK = res.data.syqk
          this.forminfo.SYQKXZ = res.data.syqkxz
          this.forminfo.QZQK = res.data.qzqk
          this.forminfo.QZQKXZ = res.data.qzqkxz
          this.forminfo.QTQK = res.data.qtqk
          this.forminfo.QTQKXZ = res.data.qtqkxz
          this.forminfo.SPZT = res.data.spzt
          this.forminfo.RDZT = res.data.rdzt
          this.forminfo.TSQTLXList = res.data.tsqtlx.split(',')
          this.fromTable = []
          for (var i = 0; i < res.data.jtcy.length; i++) {
            this.fromTable.push({
              XM: res.data.jtcy[i].xm,
              NL: res.data.jtcy[i].nl,
              GX: res.data.jtcy[i].gx,
              GZDW: res.data.jtcy[i].gzdw,
              ZY: res.data.jtcy[i].zy,
              NSR: res.data.jtcy[i].nsr,
              JKZK: res.data.jtcy[i].jkzk,
            })
          }
          if (this.fromTable.length < 4) {
            for (var j = this.fromTable.length - 1; j < 4; j++) {
              this.fromTable.push({
                XM: '',
                NL: null,
                GX: '',
                GZDW: '',
                ZY: '',
                NSR: null,
                JKZK: '',
              })
            }
          }
          var list = res.data.fj
          this.fileList = []
          for (var z = 0; z < list.length; z++) {
            this.fileList.push({ name: list[z].fileName, id: list[z].id })
          }
          this.dialogFormVisible = true
        } else {
          this.$message.error(res.message)
        }
      })
      getListBySQID({ sqid: id }).then((res) => {
        if (res.code === 200) {
          this.detailTable = res.data
        }
      })
      getSPJL({ sqid: id }).then((res) => {
        this.splist = res.data
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}

.c_table {
  height: 100%;
  overflow-y: auto;
  width: 99%;
  margin: 0 auto;
  margin-top: 20px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 12px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
