<!--学生请假申请-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">请假申请</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学期:</span>
            <sy-xueqi
              v-model="xq"
              :selectdqxq="true"
              style="display: inline"
            ></sy-xueqi>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">校区:</span>
            <el-select
              v-model="xiaoqu"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in xqlist"
                :key="index"
                :label="item.xqmc"
                :value="item.xqbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">请假类型:</span>
            <el-select
              v-model="qjlx"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in cxlx"
                :key="index"
                :label="item.zdz"
                :value="item.zddm"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_sp')"
          icon="el-icon-delete"
          @click="detailInfo"
          >审批</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 320"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="150"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bjmc" label="班级" width="200">
        </el-table-column>
        <el-table-column prop="kssj" label="请假时间" width="350">
          <template slot-scope="scop">
            {{
              dateFormat2(scop.row.kssj) + ' 至 ' + dateFormat2(scop.row.jssj)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="zdz" label="事由类型" width="150">
        </el-table-column>
        <el-table-column prop="wcdd" label="外出地点" width="300">
        </el-table-column>
        <el-table-column prop="zt" label="审批状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.zt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.zt === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.zt === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.zt === 110" type="success"
              >系主任审核通过</el-tag
            >
            <el-tag v-if="scope.row.zt === 120" type="danger"
              >系主任审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.zt === 111" type="success"
              >系分管领导审核通过</el-tag
            >
            <el-tag v-if="scope.row.zt === 112" type="danger"
              >系分管领导审核拒绝</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="rightsp(scope.row)" type="text" size="small"
              >审批</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="日常请假申请"
      :visible.sync="dialogDetailFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <div class="form-item-group">
          <i class="fa fa-address-book-o"></i>
          <div>审批流程</div>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in splist"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 100 || item.spzt == 200">
                辅导员【{{ item.spr }}】审批{{
                  item.spzt == 100 ? '通过' : '拒绝'
                }}
              </h4>
              <h4 v-if="item.spzt == 110 || item.spzt == 120">
                学院领导【{{ item.spr }}】审批{{
                  item.spzt == 110 ? '通过' : '拒绝'
                }}
              </h4>
              <h4 v-if="item.spzt == 111 || item.spzt == 112">
                学工处【{{ item.spr }}】审批{{
                  item.spzt == 111 ? '通过' : '拒绝'
                }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-form
          :model="spinfo"
          v-if="dialogDetailFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <sy-form
            ref="forminfo"
            v-if="dialogDetailFormVisible"
            :id="keyValue"
            :XH="xh"
          ></sy-form>
          <div class="form-item-group">
            <i class="fa fa-window-restore"></i>
            <div>审批</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批意见" label-width="120px" prop="SPYJ">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="spinfo.SPYJ"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批" label-width="120px" prop="SPZT">
                <el-radio v-model="spinfo.SPZT" :label="1">审批通过</el-radio>
                <el-radio v-model="spinfo.SPZT" :label="2">审批拒绝</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDetailFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetDSPPageList, saveSP, GetListBySQID } from '../../api/qjgl'
import {
  GetListByQX,
  GetZYListByQX,
  GetBJListByQX,
  getCode,
  getAllList,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getCurrentXQ } from '../../api/system'
import form from '../../components/qjgl/qjform.vue'
import moment from 'moment'
export default {
  components: {
    'sy-form': form, // 挂载组件
    'sy-xueqi': () => import('../../components/dropdown/xueqi_name'),
  },
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      ksxq: '',
      jsxq: '',
      keyword: '',
      qjlx: '',
      keyValue: '',
      cxlx: [],
      xq: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      xiaoqu: '',
      bmlist: [],
      zylist: [],
      bjlist: [],
      xqlist: [],
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      splist: [], // 审批记录
      xsinfo: {},
      xh: '',
      ZT: 0,
      spinfo: {
        SPYJ: '',
        SPZT: 100,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        SPYJ: [{ required: true, message: '请填写审批意见', trigger: 'blur' }],
        SPZT: [{ required: true, message: '请审批', trigger: 'blur' }],
      },
    }
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getAllList().then((res) => {
      if (res.code === 200) {
        this.xqlist = res.data
      }
    })
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getCurrentXQ().then((res) => {
      if (res.code === 200) {
        this.xq =
          res.data.ksnf + '-' + res.data.jsnf + '学年第' + res.data.xq + '学期'
        this.getDataPageList()
      }
    })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
      .then((res) => {
        if (res.code === 200) {
          this.cxlx = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          var files = this.$refs.forminfo.fileList
          var FJIDS = []
          for (var i = 0; i < files.length; i++) {
            if (files[i].response) {
              FJIDS.push(files[i].response.data.id)
            } else {
              FJIDS.push(files[i].id)
            }
          }
          saveSP({
            SQID: this.keyValue,
            SPZT: this.spinfo.SPZT,
            SPYJ: this.spinfo.SPYJ,
            FJIDS: FJIDS,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogDetailFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 显示编辑弹出框
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        GetListBySQID({ sqid: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.splist = res.data
          } else {
            this.splist = []
          }
        })
        this.operation = 0
        this.dialogDetailFormVisible = true
      }
    },
    rightsp(row) {
      this.keyValue = row.id
      this.ZT = row.zt
      this.xh = row.xh
      this.operation = 0
      this.dialogDetailFormVisible = true
      GetListBySQID({ sqid: this.keyValue }).then((res) => {
        if (res.code === 200) {
          this.splist = res.data
        } else {
          this.splist = []
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.ZT = 0
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.ZT = currentRow.zt
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetDSPPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          qjlx: this.qjlx,
          BJBH: this.bjbh,
          xq: this.xq,
          ZYBH: this.zybh,
          BMBH: this.bmbh,
          xiaoqu: this.xiaoqu,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
        .then((res) => {
          if (res.code === 200) {
            this.code = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 200px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
