<template>
  <div class="conten">
    <!--=====================================顶部标题======================================-->
    <div class="content_title">用户角色</div>
    <!--=====================================工具栏========================================-->
    <div class="c_tool">
      <div>
        <el-input
          v-model="keyword"
          placeholder="请输入内容"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>
      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('lr_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('lr_edit')"
          icon="el-icon-edit"
          @click="showEdit"
          >编辑</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('lr_delete')"
          icon="el-icon-delete"
          @click="delRole"
          >删除</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('lr-shouquan')"
          icon="el-icon-star-off"
          @click="moudleAuth"
          >功能授权</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="tableData"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
      >
        <el-table-column prop="id" label="角色ID" width="200">
        </el-table-column>
        <el-table-column prop="jsmc" label="角色名称" width="200">
        </el-table-column>
        <el-table-column
          prop="isSystem"
          label="系统内置"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isSystem"
              :active-value="1"
              :inactive-value="0"
              @change="issystemStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="bz" label="角色说明" width="300">
        </el-table-column>
        <el-table-column prop="cjsj" label="创建时间" :formatter="dateFormat">
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <!--========================================功能授权弹出框======================================-->
    <el-dialog
      title="功能授权"
      :visible.sync="dialogSQFormVisible"
      class="abow_dialog"
      width="30%"
      @close="closeAuthDialog"
    >
      <div slot="title" class="dialog-title">
        <div class="top_title">功能授权</div>
        <el-steps :active="active" simple>
          <el-step title="基础信息" icon="el-icon-info"></el-step>
          <el-step title="按钮信息" icon="el-icon-upload"></el-step>
        </el-steps>
      </div>
      <el-scrollbar style="height: 400px" class="myscrollbar">
        <el-tree
          v-show="active == 0"
          :data="modules"
          :props="moduleProps"
          :expand-on-click-node="false"
          node-key="id"
          ref="moduletree"
          @check-change="handleModuleClick"
          show-checkbox
          default-expand-all
          :check-strictly="moduleCheckStrictly"
          v-loading="loading"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <span> <i :class="data.icon"></i>{{ data.text }} </span>
          </span>
        </el-tree>
        <el-tree
          v-show="active == 1"
          :data="buttons"
          :props="buttonProps"
          :expand-on-click-node="false"
          node-key="id"
          ref="buttontree"
          @check-change="handleButtonClick"
          show-checkbox
          default-expand-all
          :filter-node-method="filterButtonNode"
          :check-strictly="buttonCheckStrictly"
          v-loading="loading"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <span> <i :class="data.icon"></i>{{ data.text }} </span>
          </span>
        </el-tree>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 12px" @click="back">上一步</el-button>
        <el-button style="margin-top: 12px" @click="next">下一步</el-button>
        <el-button @click="dialogSQFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAuth">确 定</el-button>
      </div>
    </el-dialog>
    <!--=====================================添加/修改弹出框======================================-->
    <el-dialog
      :title="this.operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogAddFormVisible"
      @close="closeRoleDialog"
    >
      <el-form :model="roleinfo" ref="addFormRef" :rules="roleinfoRules">
        <el-form-item label="角色名称" label-width="120px" prop="JSMC">
          <el-input v-model="roleinfo.JSMC" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="" label-width="120px">
          <el-checkbox
            v-model="roleinfo.IsSystem"
            :true-label="1"
            :false-label="0"
            >系统内置角色</el-checkbox
          >
        </el-form-item>
        <el-form-item label="角色说明" label-width="120px" prop="BZ">
          <el-input type="textarea" v-model="roleinfo.BZ" rows="4"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAddFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRole">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPageList,
  saveForm,
  getRoleInfo,
  deleteRole,
  getSQFormData,
  getCheckTree,
  authorizeSaveForm,
  getAuthorizeButtonColumnList,
  rolechangeState,
} from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyValue: '',
      keyword: '',
      active: 0,
      operation: 1, // 当前操作1=添加，0=编辑
      dialogAddFormVisible: false,
      dialogSQFormVisible: false,
      roleinfo: {
        JSMC: '',
        IsSystem: 1,
        BZ: '',
      },
      formdata: null,
      // 表单验证规则对象
      roleinfoRules: {
        JSMC: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        BZ: [{ required: true, message: '请对角色进行描述', trigger: 'blur' }],
      },
      moduleProps: {
        children: 'childNodes',
        label: 'text',
      },
      buttonProps: {
        children: 'childNodes',
        label: 'text',
      },
      // 表单验证规则对象
      FormRules: {
        JSMC: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        BZ: [{ required: true, message: '请输入角色说明', trigger: 'blur' }],
      },
      tableData: [],
      modules: [],
      buttons: [],
      checkModules: [],
      checkButtons: [],
      filterText: '',
      buttonCheckStrictly: false,
      moduleCheckStrictly: false,
      loading: true,
      tableloading: true,
      buttonauth: {}, // 按钮权限
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.roleinfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  mounted() {},
  watch: {
    filterText(val) {
      this.$refs.buttontree.filter(val)
    },
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存角色信息
    saveRole() {
      if (this.operation === 1) {
        this.keyValue = ''
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveForm({ id: this.keyValue, role: this.roleinfo }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogAddFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeRoleDialog() {
      this.active = 0
      this.$nextTick(() => {
        // this.roleinfo = {
        //   JSMC: '',
        //   IsSystem: 1,
        //   BZ: '',
        // }
        this.roleinfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.addFormRef.resetFields()
    },
    // 功能授权关闭时调用
    closeAuthDialog() {
      this.checkModules = []
      this.checkButtons = []
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.dialogAddFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getRoleInfo({ keyValue: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.roleinfo.JSMC = res.data.jsmc
            this.roleinfo.BZ = res.data.bz
            this.roleinfo.IsSystem = res.data.isSystem
            this.dialogAddFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 功能授权
    moudleAuth() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.dialogSQFormVisible = true
        const that = this
        getCheckTree().then((res) => {
          if (res.code === 200) {
            this.modules = res.data.moduleList
            this.buttons = res.data.buttonList
            getSQFormData({ objectId: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.checkModules = res.data.modules
                this.checkButtons = res.data.buttons
                that.moduleCheckStrictly = true // 重点：给数节点赋值之前 先设置为true
                this.$nextTick(() => {
                  that.$refs.moduletree.setCheckedKeys(res.data.modules) // 给树节点赋值
                  that.$refs.buttontree.setCheckedKeys(res.data.buttons) // 给树节点赋值
                  setTimeout(function () {
                    that.moduleCheckStrictly = false
                  }, 1000)
                })
                this.loading = false
              } else {
                this.checkModules = []
                this.checkButtons = []
                this.loading = false
              }
            })
          } else {
            this.modules = []
            this.buttons = []
          }
        })
      }
    },
    // 删除角色
    delRole() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRole({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    back() {
      if (this.active-- < 1) this.active = 0
    },
    next() {
      if (this.active++ > 0) this.active = 1
      this.$refs.buttontree.filter('')
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    getDataPageList() {
      this.tableloading = true
      getPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          this.tableloading = false
          if (res.code === 200) {
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.tableData = res.data.rows
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.tableloading = false
        })
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 点击功能授权节点
    handleModuleClick() {
      this.checkModules = this.$refs.moduletree
        .getCheckedKeys()
        .concat(this.$refs.moduletree.getHalfCheckedKeys())
    },
    // 点击按钮授权节点
    handleButtonClick() {
      this.checkButtons = this.$refs.buttontree
        .getCheckedKeys()
        .concat(this.$refs.buttontree.getHalfCheckedKeys())
    },
    // 过滤按钮信息
    filterButtonNode(value, data, node) {
      if ((data.id + '').indexOf('_learun_moduleId') === -1) {
        var tmp = data.parentId.split('_')[0]
        if (this.checkModules.find((val) => val === tmp)) {
          return true
        } else {
          return false
        }
        // this.checkModules.find(val=>val===node.parentId)
      } else {
        if (this.checkModules.indexOf(data.id.split('_')[0]) !== -1) {
          return true
        } else {
          return false
        }
      }
    },
    saveAuth() {
      var buttons = []
      for (var i = 0; i < this.checkButtons.length; i++) {
        if (this.checkButtons[i].indexOf('_learun_moduleId') === -1) {
          buttons.push(this.checkButtons[i])
        }
      }
      authorizeSaveForm({
        objectId: this.keyValue,
        strModuleId: this.checkModules,
        strModuleButtonId: buttons,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.dialogSQFormVisible = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    issystemStateChanged(row) {
      rolechangeState({ ID: row.id, IsSystem: row.isSystem })
        .then((res) => {
          if (res.code !== 200) {
            row.isSystem = row.isSystem === 1 ? 0 : 1
          }
        })
        .catch((err) => {
          console.log(err)
          row.isSystem = row.isSystem === 1 ? 0 : 1
        })
    },
  },
}
</script>

<style scoped>
.conten {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.content_title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.c_tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.c_tool .el-input {
  width: 300px;
}

.c_tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.abow_dialog >>> .el-dialog__header {
  padding-top: 10px;
}
.top_title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  margin-bottom: 10px;
}
/* 表单大小设置 */
.abow_dialog >>> .el-dialog {
  height: 600px;
  overflow: hidden;
}
/**表单 步骤条位置 */
.step_box {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.step_pos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
/**表单 确定和取消 按钮的位置 */
.abow_dialog >>> .el-dialog__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.custom-tree-node i {
  margin-right: 5px;
}
</style>
