import {
    get, post
} from '../utils/http';

// 获取请假管理流程
export function GetStep() {
    return get('xspy/SY_XSPY_LCPZ/GetStep/');
}

// 提交违纪流程配置
export function LCPZSave(data) {
  return post('xspy/SY_XSPY_LCPZ/LCPZSave/', data);
}

// 保存批次设置
export function savePCSZ(data) {
  return post('xspy/SY_XSPY_PCSZ/Save/', data);
}

// 获取批次
export function GetPCPageList(data) {
  return get('xspy/SY_XSPY_PCSZ/GetPageList/', data);
}

// 更新是否当前学年
export function changeState(data) {
  return get('xspy/SY_XSPY_PCSZ/SaveDQPC/', data);
}

// 根据ID获取
export function getFormData(data) {
  return get('xspy/SY_XSPY_PCSZ/GetFormData/', data);
}
