<!--学生请假申请-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">请假申请</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <span class="mr5 fcolor42">请假类型:</span>
        <el-select v-model="keyword" size="small" clearable>
          <el-option
            v-for="(item, index) in cxlx"
            :key="index"
            :label="item.zdz"
            :value="item.zddm"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_detail')"
          icon="el-icon-delete"
          @click="detailInfo"
          >查看</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delData"
          >撤销</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="kssj" label="请假时间" width="350">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.kssj) + '-' + dateFormat2(scop.row.jssj) }}
          </template>
        </el-table-column>
        <el-table-column prop="zdz" label="事由类型" width="150">
        </el-table-column>
        <el-table-column prop="wcdd" label="外出地点" width="300">
        </el-table-column>
        <el-table-column prop="zt" label="审批状态" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.zt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.zt === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.zt === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.zt === 110" type="success"
              >系主任审核通过</el-tag
            >
            <el-tag v-if="scope.row.zt === 120" type="danger"
              >系主任审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.zt === 111" type="success"
              >系分管领导审核通过</el-tag
            >
            <el-tag v-if="scope.row.zt === 112" type="danger"
              >系分管领导审核拒绝</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="sfdqxq" label="销假状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.xjzt == 2">已销假</el-tag>
            <el-tag type="danger" v-if="scope.row.xjzt == 1">未销假</el-tag>
            <el-tag v-if="scope.row.xjzt == 0">请假审批中</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="forminfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="forminfoRules"
      >
        <div class="form-item-group">
          <i class="fa fa-calendar"></i>
          <div>请假信息</div>
        </div>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="请假时间" label-width="120px" prop="QJSJ">
              <el-date-picker
                v-model="forminfo.QJSJ"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="请假类型" label-width="120px" prop="QJLX">
              <el-radio
                v-for="(item, index) in code"
                :key="index"
                v-model="forminfo.QJLX"
                :label="item.zddm"
                >{{ item.zdz }}</el-radio
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="请假事由" label-width="120px" prop="QJSY">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="forminfo.QJSY"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="外出地点" label-width="120px" prop="WCDD">
              <el-input
                v-model="forminfo.WCDD"
                placeholder="请输入外出地点"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="同行人数" label-width="120px" prop="TXRS">
              <el-input
                type="number"
                v-model="forminfo.TXRS"
                placeholder="请输入同行人数"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>

        <div class="form-item-group">
          <i class="fa fa-address-book-o"></i>
          <div>外出联系人信息</div>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="联系人姓名" label-width="120px" prop="LXRXM">
              <el-input
                v-model="forminfo.LXRXM"
                placeholder="请输入联系人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="移动电话" label-width="120px" prop="YDDH">
              <el-input
                v-model="forminfo.YDDH"
                placeholder="请输入移动电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="本人关系" label-width="120px" prop="BRGX">
              <el-input
                v-model="forminfo.BRGX"
                placeholder="请输入外出联系人与本人关系"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="本人电话" label-width="120px" prop="BRDH">
              <el-input
                v-model="forminfo.BRDH"
                placeholder="本人电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="附件材料" label-width="120px" class="mb10">
          <el-upload
            class="upload-demo"
            :headers="headers"
            :action="uploadurl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :before-remove="beforeRemove"
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              存在文件必须填写说明，附件类型包括：jpg，gif，bmp
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="日常请假申请"
      :visible.sync="dialogDetailFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <div class="form-item-group">
          <i class="fa fa-address-book-o"></i>
          <div>
            审批流程 当前状态：
            <el-tag v-if="forminfo.ZT === 0">待辅导员审核</el-tag>
            <el-tag v-if="forminfo.ZT === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="forminfo.ZT === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="forminfo.ZT === 110" type="success"
              >系主任审核通过</el-tag
            >
            <el-tag v-if="forminfo.ZT === 120" type="danger"
              >系主任审核拒绝</el-tag
            >
            <el-tag v-if="forminfo.ZT === 111" type="success"
              >系分管领导审核通过</el-tag
            >
            <el-tag v-if="forminfo.ZT === 112" type="danger"
              >系分管领导审核拒绝</el-tag
            >
          </div>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in splist"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 100 || item.spzt == 200">
                辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 110 || item.spzt == 120">
                系部领导审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 111 || item.spzt == 112">
                系部分管领导审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-form
          :model="forminfo"
          v-if="dialogDetailFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <div class="form-item-group">
            <i class="fa fa-calendar"></i>
            <div>
              请假信息 请假状态：
              <el-tag v-if="forminfo.XJZT == 0">审批中</el-tag>
              <el-tag v-if="forminfo.XJZT == 1" type="danger">未销假</el-tag>
              <el-tag v-if="forminfo.XJZT === 2" type="success">已销假</el-tag>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="请假时间" label-width="120px" prop="QJSJ">
                <el-date-picker
                  v-model="forminfo.QJSJ"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="请假类型" label-width="120px" prop="QJLX">
                <el-radio
                  v-for="(item, index) in code"
                  :key="index"
                  v-model="forminfo.QJLX"
                  :label="item.zddm"
                  >{{ item.zdz }}</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="请假事由" label-width="120px" prop="QJSY">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="forminfo.QJSY"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="外出地点" label-width="120px" prop="WCDD">
                <el-input
                  v-model="forminfo.WCDD"
                  placeholder="请输入外出地点"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="同行人数" label-width="120px" prop="TXRS">
                <el-input
                  type="number"
                  v-model="forminfo.TXRS"
                  placeholder="请输入同行人数"
                ></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>

          <div class="form-item-group">
            <i class="fa fa-address-book-o"></i>
            <div>外出联系人信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系人姓名" label-width="120px" prop="LXRXM">
                <el-input
                  v-model="forminfo.LXRXM"
                  placeholder="请输入联系人姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="移动电话" label-width="120px" prop="YDDH">
                <el-input
                  v-model="forminfo.YDDH"
                  placeholder="请输入移动电话"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="本人关系" label-width="120px" prop="BRGX">
                <el-input
                  v-model="forminfo.BRGX"
                  placeholder="请输入外出联系人与本人关系"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本人电话" label-width="120px" prop="BRDH">
                <el-input v-model="forminfo.BRDH"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="forminfo.fjlist.length > 0">
            <el-col :span="24">
              <el-form-item label="附件" label-width="120px">
                <div class="demo-image__preview">
                  <el-image
                    v-for="item in forminfo.fjlist"
                    :key="item.id"
                    style="width: 100px; height: 100px; margin-right: 10px"
                    :src="lookUrl + item.filePath"
                    :preview-src-list="new Array(lookUrl + item.filePath)"
                    fit="cover"
                  >
                  </el-image>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<script>
import {
  getQJSQPageList,
  saveQJSQInfo,
  getQJSQInfoThree,
  IsExitOld,
  GetListBySQID,
  deleteQJSQInfo,
} from '../../../api/qjgl'
import { getAuthorizeButtonColumnList } from '../../../api/system'
import { getCode, getMyAllDetailInfo } from '../../../api/jcxx'
import { baseUrl } from '../../../utils/http'
import store from '../../../store/index'
import moment from 'moment'
export default {
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          // 判断是否为数字
          value = value.toString() // 转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            // 判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      uploadurl: baseUrl + 'File/upload',
      lookUrl: baseUrl,
      headers: null,
      fileList: [],
      ksxq: '',
      jsxq: '',
      keyword: '',
      keyValue: '',
      cxlx: [],
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      splist: [], // 审批记录
      ZT: 0,
      forminfo: {
        ID: '',
        XH: '',
        KSSJ: '',
        JSSJ: '',
        Total: 0,
        QJLX: '',
        QJSY: '',
        WCDD: '',
        GDDH: '',
        YDDH: '',
        BRGX: '',
        LXRXM: '',
        ZT: 0,
        XJZT: 0,
        BRDH: '',
        FJIDS: [],
        fjlist: [],
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        QJSJ: [{ required: true, message: '请选择请假时间', trigger: 'blur' }],
        QJLX: [{ required: true, message: '请选择请假类型', trigger: 'blur' }],
        QJSY: [{ required: true, message: '请填写请假事由', trigger: 'blur' }],
        WCDD: [{ required: true, message: '请填写外出地点', trigger: 'blur' }],
        LXRXM: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' },
        ],
        YDDH: [
          { required: true, message: '请填写联系人电话', trigger: 'blur' },
          { validator: isMobileNumber, trigger: 'blur' },
        ],
        BRGX: [
          {
            required: true,
            message: '请填写联系人与本人关系',
            trigger: 'blur',
          },
        ],
        BRDH: [
          { required: true, message: '请填写本人电话', trigger: 'blur' },
          { validator: isMobileNumber, trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
      .then((res) => {
        if (res.code === 200) {
          this.cxlx = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示添加弹出框
    showAdd() {
      IsExitOld().then((res) => {
        if (res.code === 200) {
          getMyAllDetailInfo().then((res) => {
            if (res.code === 200) {
              this.forminfo.YDDH = res.data.jzlxdh
            }
          })
          this.getLXCode()
          this.operation = 1
          this.dialogFormVisible = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 显示编辑弹出框
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        this.getLXCode()
        GetListBySQID({ sqid: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.splist = res.data
          } else {
            this.splist = []
          }
        })
        getQJSQInfoThree({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.info.id
            this.forminfo.XH = res.data.info.xh
            this.forminfo.KSSJ = res.data.info.kssj
            this.forminfo.JSSJ = res.data.info.jssj
            this.forminfo.Total = res.data.info.total
            this.forminfo.QJLX = res.data.info.qjlx
            this.forminfo.QJSY = res.data.info.qjsy
            this.forminfo.WCDD = res.data.info.wcdd
            this.forminfo.YDDH = res.data.info.yddh
            this.forminfo.BRGX = res.data.info.brgx
            this.forminfo.LXRXM = res.data.info.lxrxm
            this.forminfo.ZT = res.data.info.zt
            this.forminfo.XJZT = res.data.info.xjzt
            this.forminfo.BRDH = res.data.info.brdh
            this.forminfo.QJSJ = [
              new Date(res.data.info.kssj),
              new Date(res.data.info.jssj),
            ]
            this.forminfo.fjlist = res.data.fj
            this.dialogDetailFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.forminfo.ID = ''
      } else {
        this.forminfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.KSSJ = moment(this.forminfo.QJSJ[0]).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
          this.forminfo.JSSJ = moment(this.forminfo.QJSJ[1]).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
          this.forminfo.FJIDS = []
          for (var i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].response) {
              this.forminfo.FJIDS.push(this.fileList[i].response.data.id)
            } else {
              this.forminfo.FJIDS.push(this.fileList[i].id)
            }
          }
          saveQJSQInfo({ qjsq: this.forminfo }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除数据
    delData() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        if (this.ZT !== 0) {
          this.$message.error('已审批，无法删除！')
          return
        }
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteQJSQInfo({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.ZT = 0
      } else {
        this.keyValue = currentRow.id
        this.ZT = currentRow.zt
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getQJSQPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
        .then((res) => {
          if (res.code === 200) {
            this.code = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
