import { post, get, excel } from '../utils/http'

// 保存批次设置
export function savePCSZ(data) {
  return post('zxj/SY_ZXJ_PCSZ/Save/', data)
}

// 获取批次
export function GetPCPageList(data) {
  return get('zxj/SY_ZXJ_PCSZ/GetPageList/', data)
}

// 更新是否当前学年
export function changeState(data) {
  return get('zxj/SY_ZXJ_PCSZ/SaveDQPC/', data)
}

// 根据ID获取
export function getFormData(data) {
  return get('zxj/SY_ZXJ_PCSZ/GetFormData/', data)
}

// 学生获取当前批次
export function stuGetPC(data) {
  return get('zxj/SY_ZXJ_XSSQ/StuGetPC/', data)
}

// 学生获取申请分页数据
export function getStuApplyPageList(data) {
  return get('zxj/SY_ZXJ_XSSQ/GetStuApplyPageList/', data)
}

// 学生提交申请
export function stuSave(data) {
  return post('zxj/SY_ZXJ_XSSQ/Save/', data)
}

// 学生获取申请详情
export function getSQFormData(data) {
  return get('zxj/SY_ZXJ_XSSQ/GetFormData/', data)
}

// 获取待审批列表
export function getSPPageList(data) {
  return get('zxj/SY_ZXJ_XSSQ/GetSPPageList/', data)
}

// 审批
export function sp(data) {
  return post('zxj/SY_ZXJ_XSSQ/shengpi/', data)
}

// 获取查询列表
export function getCXPageList(data) {
  return get('zxj/SY_ZXJ_XSSQ/GetCXPageList/', data)
}

// 获取批次
export function getListAndCurrent(data) {
  return get('zxj/SY_ZXJ_XSSQ/GetListAndCurrent/', data)
}

// 获取去向导出数据
export function exportSearchExcel(data) {
  return excel('zxj/SY_ZXJ_XSSQ/ExportExcel/', data)
}

// 获取临时补助信息
export function getLSBZPageList(data) {
  return get('zxj/SY_ZXJ_LSBZ/GetPageList/', data)
}

// 保存临时补助
export function saveLSBZ(data) {
  return post('zxj/SY_ZXJ_LSBZ/Save/', data)
}

// 获取临时补助详细信息
export function getLSBZFormData(data) {
  return get('zxj/SY_ZXJ_LSBZ/GetFormData/', data)
}

// 删除临时补助
export function deleteLSBZ(data) {
  return get('zxj/SY_ZXJ_LSBZ/Delete/', data)
}

// 获取代偿补助信息
export function getDCZZPageList(data) {
  return get('zxj/SY_ZXJ_DCZZ/GetPageList/', data)
}

// 保存代偿补助
export function saveDCZZ(data) {
  return post('zxj/SY_ZXJ_DCZZ/Save/', data)
}

// 获取代偿补助详细信息
export function getDCZZFormData(data) {
  return get('zxj/SY_ZXJ_DCZZ/GetFormData/', data)
}

// 删除代偿补助
export function deleteDCBZ(data) {
  return get('zxj/SY_ZXJ_DCZZ/Delete/', data)
}

// 学生获取申请分页数据
export function getZXJCXPageList(data) {
  return get('zxj/SY_ZXJ/GetPageList/', data)
}

// 获取去向导出数据
export function exportZXJ(data) {
  return excel('zxj/SY_ZXJ/ExportExcel/', data)
}

// 获取查询条件
export function getZXJSearchList(data) {
  return get('zxj/SY_ZXJ/GetSearchList/', data)
}

// 删除助学金
export function deleteZXJ(data) {
  return post('zxj/SY_ZXJ/Delete/', data)
}
