<!--
* 创建人：邓国伦
* 日 期：2021-06-04
* 描 述：代码生成器主页
-->
<!--view 模板-->
<template>
  <div>
    <el-row>
      <el-col :span="5">
        <el-card
          class="box-card"
          shadow="hover"
          :body-style="{ padding: '10px' }"
          @click.native="showdialog()"
        >
          <div class="cover-img">
            <img src="../../assets/img/singletable0.png" />
            <span>快速生成必要的类和页面</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!--弹出窗-->
    <el-dialog
      title="在线代码生成器并自动创建代码(快速开发模板)"
      :visible.sync="view.dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-steps
        :active="view.steps"
        process-status="finish"
        finish-status="success"
        simple
      >
        <el-step title="选择数据库 1"></el-step>
        <el-step title="信息配置 2"></el-step>
        <el-step title="查看代码 3"></el-step>
        <el-step title="发布功能 4"></el-step>
      </el-steps>
      <!--第一步选择数据库-->
      <div v-show="view.steps === 0" class="mt10">
        <el-table
          ref="table_db"
          :data="tableData"
          height="450"
          border
          style="width: 100%"
          highlight-current-row
          @current-change="currentRowChange"
          @expand-change="rolexpand"
        >
          <el-table-column type="expand">
            <template>
              <el-table :data="fileData" border  v-loading="filetableloading">
                <el-table-column prop="f_number" label="" width="50"></el-table-column>
                <el-table-column
                  prop="f_column"
                  label="列名"
                  width="220"
                ></el-table-column>
                <el-table-column
                  prop="f_datatype"
                  label="数据类型"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="f_length"
                  label="长度"
                  width="100"
                ></el-table-column>
                <el-table-column prop="f_isnullable" label="允许空" width="100">
                  <template slot-scope="scope">
                    <el-switch :value="scope.row.f_isnullable" :active-value="'1'">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="f_identity" label="标识" width="100">
                  <template slot-scope="scope">
                    <el-switch :value="scope.row.f_identity" :active-value="'1'">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="f_key" label="主键" width="100">
                  <template slot-scope="scope">
                    <el-switch :value="scope.row.f_key" :active-value="'1'"> </el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="f_remark" label="说明"></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="表名" width="220"> </el-table-column>
          <el-table-column prop="sumrows" label="记录数" width="120"> </el-table-column>
          <el-table-column prop="reserved" label="使用大小" width="120">
          </el-table-column>
          <el-table-column prop="index_size" label="使用索引大小" width="120">
          </el-table-column>
          <el-table-column prop="tdescription" label="说明"> </el-table-column>
        </el-table>
      </div>
      <!--第二步信息配置-->
      <div v-show="view.steps == 1" class="mt10">
        <el-form ref="form_config" :model="config" label-width="100px">
          <!--功能配置-->
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span><i class="fa fa-cog"></i> 功能信息配置</span>
            </div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="创建人">
                  <el-input v-model="config.name" placeholder="创建人姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="功能类名">
                  <el-input v-model="config.tableName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="输出目录">
                  <el-select v-model="config.classDirectory" placeholder="输出目录">
                    <el-option
                      v-for="(item, i) in config.classDirectorys"
                      :label="item"
                      :value="item"
                      :key="i"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="区域(Areas)">
                  <el-select v-model="config.area" placeholder="区域(Areas)里面的目录">
                    <el-option
                      v-for="(item, i) in config.areasDirectorys"
                      :label="item"
                      :value="item"
                      :key="i"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="功能描述">
                  <el-input
                    v-model="config.describe"
                    placeholder="请输入功能描述"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <!--输出目录-->
          <el-card class="box-card mt10">
            <div slot="header" class="clearfix">
              <span><i class="fa fa-sign-out"></i> 输出目录</span>
            </div>
            <el-form-item label="生成选项" label-width="140px">
              <el-checkbox
                label="实体类"
                v-model="config.IsModel"
                :true-label="1"
                :false-label="0"
              ></el-checkbox>
              <el-checkbox
                label="业务接口类"
                v-model="config.IsIServices"
                :true-label="1"
                :false-label="0"
              ></el-checkbox>
              <el-checkbox
                label="业务类"
                v-model="config.IsServices"
                :true-label="1"
                :false-label="0"
              ></el-checkbox>
              <el-checkbox
                label="API控制器"
                v-model="config.IsWeb"
                :true-label="1"
                :false-label="0"
              ></el-checkbox>
            </el-form-item>
            <el-form-item label="实体类输出目录" label-width="140px">
              <el-input v-model="config.modelDirectory" readonly></el-input>
            </el-form-item>
            <el-form-item label="业务接口类输出目录" label-width="140px">
              <el-input v-model="config.iServicesDirectory" readonly></el-input>
            </el-form-item>
            <el-form-item label="业务类输出目录" label-width="140px">
              <el-input v-model="config.servicesDirectory" readonly></el-input>
            </el-form-item>
            <el-form-item label="API控制器输出目录" label-width="140px">
              <el-input v-model="config.apiDirectory" readonly></el-input>
            </el-form-item>
          </el-card>
        </el-form>
      </div>
      <!--第三步查看代码-->
      <div v-show="view.steps == 2">
        <el-tabs type="card" v-model="view.activeName" @tab-click="handleClick">
          <el-tab-pane label="实体类" name="model"> </el-tab-pane>
          <el-tab-pane label="业务接口类" name="iservices"> </el-tab-pane>
          <el-tab-pane label="业务类" name="services"> </el-tab-pane>
          <el-tab-pane label="API控制器" name="conlltroller"> </el-tab-pane>
        </el-tabs>
        <codemirror v-model="code.val" :options="code.options"></codemirror>
      </div>
      <!--第四步发布功能-->
      <div v-show="view.steps == 3">
        <el-card class="box-card mt10">
          <div slot="header" class="clearfix">
            <span><i class="fa fa-send"></i> 发布功能</span>
          </div>
          <el-form
            :model="moduleForm"
            ref="addFormRef"
            :rules="FormRules"
            :label-position="'right'"
            label-width="100px"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="编号" prop="F_EnCode">
                  <el-input v-model="moduleForm.F_EnCode" placeholder="编号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="名称" prop="F_FullName">
                  <el-input
                    v-model="moduleForm.F_FullName"
                    placeholder="名称"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="上级">
                  <el-cascader
                    :options="parentModule"
                    :props="parentProps"
                    v-model="moduleForm.F_ParentId"
                    style="display: block"
                    clearable
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="图标">
                  <el-input
                    v-model="moduleForm.F_Icon"
                    placeholder="图标"
                    @focus="showIconDia()"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="目标" prop="F_Target">
                  <el-select
                    v-model="moduleForm.F_Target"
                    placeholder="目标"
                    style="display: block"
                    clearable
                  >
                    <el-option label="expand" value="expand"></el-option>
                    <el-option label="iframe" value="iframe"></el-option>
                    <el-option label="open" value="open"></el-option>
                  </el-select> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item label="排序" prop="F_SortCode">
                  <el-input
                    type="number"
                    v-model="moduleForm.F_SortCode"
                    placeholder="排序"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="地址" prop="F_UrlAddress">
                  <el-input
                    v-model="moduleForm.F_UrlAddress"
                    placeholder="地址"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="选项">
                  <el-checkbox
                    v-model="moduleForm.F_IsMenu"
                    :true-label="checkvalue.sel"
                    :false-label="checkvalue.nosel"
                    >菜单</el-checkbox
                  >
                  <el-checkbox
                    v-model="moduleForm.F_AllowExpand"
                    :true-label="checkvalue.sel"
                    :false-label="checkvalue.nosel"
                    >展开</el-checkbox
                  >
                  <el-checkbox
                    v-model="moduleForm.F_EnabledMark"
                    :true-label="checkvalue.sel"
                    :false-label="checkvalue.nosel"
                    >有效</el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="描述">
              <el-input type="textarea" v-model="moduleForm.F_Description"></el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer tright">
        <el-button v-if="view.steps > 0" @click="btnstep('pre')">上一步</el-button>
        <el-button v-if="view.steps < 3" @click="btnstep('next')">下一步</el-button>
        <el-button v-if="view.steps == 3" type="primary" @click="save(0)"
          >只生成文件</el-button
        >
        <el-button v-if="view.steps == 3" type="primary" @click="save(1)"
          >生成文件和菜单功能</el-button
        >
      </span>
    </el-dialog>
    <!--图标选择框-->
    <el-dialog title="选择图标" :visible.sync="dialogIconFormVisible">
      <el-scrollbar style="height: 500px" class="myscrollbar">
        <el-row :gutter="20">
          <el-col
            style="text-align: center; cursor: pointer"
            :span="3"
            v-for="(item, index) in Icons"
            :key="index"
            ><i class="iconstyle" :class="item" @click="selectIcon(item)"></i
          ></el-col>
        </el-row>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import store from "../../store/index";
import {
  getlist,
  getfields,
  getcodeconfig,
  getlookfastcode,
  CreateFastCode,
} from "../../api/code";
import { codemirror } from "vue-codemirror";
import icon from "../../utils/icons";
import { getExpendModuleTree, saveModule } from "../../api/system";
// 核心样式
import "codemirror/lib/codemirror.css";
// 引入主题后还需要在 options 中指定主题才会生效

export default {
  data() {
    return {
      tableData: [],
      fileData: [],
      config: {
        tableName: "",
        name: "",
        describe: "",
        area: "",
        classDirectory: "",
        classDirectorys: null,
        ModelDirectory: "",
        BLLDirectory: "",
        webDirectory: "",
        IsModel: 1,
        IsIServices: 1,
        IsServices: 1,
        IsWeb: 0,
      },
      code: {
        val: "",
        options: {
          mode: "text/x-csharp",
          // 默认配置
          tabSize: 2, // 缩进格式
          theme: "default", // 主题，对应主题库 JS 需要提前引入
          lineNumbers: true, // 显示行号
          line: true,
          styleActiveLine: true, // 高亮选中行
          hintOptions: {
            completeSingle: true, // 当匹配只有一项的时候是否自动补全
          },
          // 自动括号匹配功能
          matchBrackets: true,
        },
      },
      view: {
        dialogVisible: false,
        steps: 0,
        activeName: "model",
        viewcodeloading: true,
      },
      codeviews: {},
      moduleForm: {
        F_EnCode: "", // 编号
        F_FullName: "", // 名称
        F_ParentId: "", // 上级
        F_Icon: "", // 图标
        F_Target: "", // 目标
        F_SortCode: "", // 排序
        F_UrlAddress: "", // 地址
        F_CompentPath: "", // 组件
        F_IsMenu: "", // 菜单
        F_AllowExpand: "", // 展开
        F_EnabledMark: "", // 有效
        F_Description: "", // 描述
      },
      // 表单验证规则对象
      FormRules: {
        F_EnCode: [{ required: true, message: "请输入编号", trigger: "blur" }],
        F_FullName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        F_Target: [{ required: true, message: "请选择目标", trigger: "blur" }],
        F_SortCode: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      checkvalue: { sel: 1, nosel: 0 },
      Icons: icon.icons,
      parentModule: [],
      parentProps: {
        children: "childNodes",
        label: "text",
        value: "id",
        checkStrictly: true,
        emitPath: false,
      },
      dialogIconFormVisible: false,
      keyValue: "",
      filetableloading: false,
    };
  },
  methods: {
    btnstep(type) {
      if (type === "next") {
        this.view.steps = this.view.steps + 1;
      } else {
        this.view.steps = this.view.steps - 1;
      }
      switch (this.view.steps) {
        case 0: // 选择数据库表
          this.$refs.table_db.setCurrentRow(); // 取消选中行
          break;
        case 1: // 信息配置
          if (this.config.tableName === "") {
            this.$message.error("请选择数据表！");
            this.view.steps = this.view.steps - 1;
            return;
          }
          getcodeconfig().then((res) => {
            this.config = { ...this.config, ...res.data };
            this.config.name = store.state.userinfo.name;
          });
          break;
        case 2: // 预览代码
          getlookfastcode(this.config).then((res) => {
            this.view.viewcodeloading = false;
            this.codeviews = res.data;
            this.code.val = res.data.entityCode;
          });
          break;
        case 3:
          getExpendModuleTree()
            .then((res) => {
              this.parentModule = res.data;
              this.setEmptyNode(res.data);
              this.operation = 1;
            })
            .catch((err) => {
              console.log(err);
            });
          break;
      }
    },
    // 显示图标
    showIconDia() {
      this.dialogIconFormVisible = true;
    },
    // 选择图标
    selectIcon(icon) {
      this.moduleForm.F_Icon = icon;
      this.dialogIconFormVisible = false;
    },
    // 档子节点为空数组时设为null
    setEmptyNode(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].hasChildren) {
          this.setEmptyNode(data[i].childNodes);
        } else {
          data[i].childNodes = undefined;
        }
      }
    },
    showdialog() {
      this.view.dialogVisible = true;
      getlist().then((res) => {
        this.tableData = res.data;
      });
    },
    rolexpand(row, expandedRows) {
        var that = this;
      if (expandedRows.length > 0) {
          that.filetableloading = true;
        getfields({ tableName: row.name }).then((res) => {
          that.filetableloading = false;
          if (res.code === 200) {
            this.fileData = res.data;
          } else {
            this.fileData = [];
          }
          this.$forceUpdate();
        });
      }
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = "";
        this.config.tableName = "";
      } else {
        this.keyValue = currentRow.id;
        this.config.tableName = currentRow.name;
      }
    },
    handleClick(tab, event) {
      switch (this.view.activeName) {
        case "model":
          this.code.val = this.codeviews.entityCode;
          break;
        case "iservices":
          this.code.val = this.codeviews.iserviceCode;
          break;
        case "services":
          this.code.val = this.codeviews.serviceCode;
          break;
        case "conlltroller":
          this.code.val = this.codeviews.controllerCode;
          break;
      }
    },
    save(type) {
      var that = this;
      if (type === 1) {
        this.$refs.addFormRef.validate(async (valid) => {
          if (!valid) {
            return false;
          } else {
            // 第一步先生成文件
            CreateFastCode(this.config).then((res) => {
              if (res.code === 200) {
                // 第二步发布功能
                saveModule({
                  moduleEntityJson: JSON.stringify(that.moduleForm),
                }).then((rs) => {
                  if (rs.code === 200) {
                    that.view.dialogVisible = false;
                    that.$message.success("代码文件生成成功，功能发布成功！");
                  } else {
                    that.$message.error("代码文件生成成功，功能发布失败！");
                  }
                });
              } else {
                that.$message.error("操作失败！");
              }
            });
          }
        });
      } else {
        // 第一步先生成文件
        CreateFastCode(this.config).then((res) => {
          if (res.code === 200) {
            that.view.dialogVisible = false;
            that.$message.success("代码文件生成成功");
          } else {
            that.$message.error("操作失败！");
          }
        });
      }
    },
  },
  mounted() {},
  components: {
    codemirror,
  },
};
</script>
<!-- CSS样式 -->
<style>
.box-card:hover {
  cursor: pointer;
}

.cover-img {
  border-radius: 3px;
  background-color: #efefef;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.box-card:hover .cover-img {
  background-color: #2f94ee;
}
.cover-img img {
  border-radius: 2px;
}
.cover-img span {
  display: block;
  font-size: 14px;
  color: #393939;
  font-weight: 400;
  padding-bottom: 10px;
}
.box-card:hover .cover-img span {
  color: #fff;
}
.CodeMirror {
  height: 500px;
}
.el-tabs__header {
  margin: 0px;
}
.iconstyle {
  margin: 10px auto;
}
</style>
