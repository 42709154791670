<!--困难生申请-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">认定控制</div>
    <table
      id="Panel1"
      class="DivTable"
      cellpadding="3"
      cellspacing="0"
      v-if="showtop"
    >
      <tbody>
        <tr>
          <td width="15%" class="EdtTitleBack"><strong>当前学年</strong>：</td>
          <td class="EdtBack">
            <span id="TermYear" style="color: #ff0033; font-weight: bold">{{
              pcmodel.xn
            }}</span>
          </td>
        </tr>
        <tr>
          <td width="15%" class="EdtTitleBack">
            <strong>申请时间段</strong>：
          </td>
          <td class="EdtBack">
            <span id="BeginDate">{{ dateFormat2(pcmodel.kssj) }}</span
            >&nbsp;至&nbsp;<span id="EndDate">{{
              dateFormat2(pcmodel.jssj)
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack"><strong>重点提示</strong>：</td>
          <td class="EdtBack">
            <span id="MostSay" style="color: #ff0033; font-weight: bold">{{
              pcmodel.ts
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack">
            <strong><span style="font-family: 宋体">说明</span></strong
            >：
          </td>
          <td class="EdtBack">
            <span id="Memo">{{ pcmodel.sm }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack">
            <strong><span style="font-family: 宋体">统计信息</span></strong
            >：
          </td>
          <td class="EdtBack">
            <strong style="display: block; padding: 5px 0px"
              ><span>等待审核认定：{{ tjinfo.shz }}人</span></strong
            >
            <strong style="display: block; padding: 5px 0px"
              ><span>已审核认定，但未公示{{ tjinfo.yshwgs }}人</span
              ><el-button
                type="primary"
                size="mini"
                :disabled="tjinfo.yshwgs == 0"
                @click="tjrd(1, 2)"
                >将该数据进行公示</el-button
              >
              <el-button
                type="primary"
                size="mini"
                :disabled="tjinfo.yshwgs == 0"
                @click="tjrd(1, 3)"
                >不公示，直接转入家庭经济困难认定库</el-button
              ></strong
            >
            <strong style="display: block; padding: 5px 0px"
              ><span
                >已公示，但未提交进入家庭经济困难认定库{{ tjinfo.zzgs }}人</span
              ><el-button
                type="primary"
                size="mini"
                :disabled="tjinfo.zzgs == 0"
                @click="tjrd(2, 3)"
                >提交进入家庭经济困难认定库</el-button
              ></strong
            >
            <strong style="display: block; padding: 5px 0px"
              ><span>已进入家庭经济困难认定库{{ tjinfo.yrk }}人</span></strong
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 500"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
        ><el-table-column label="当前学年公示信息" align="center">
          <el-table-column prop="xh" label="学号" width="200">
          </el-table-column>
          <el-table-column prop="xm" label="姓名" width="200"></el-table-column>
          <el-table-column prop="xb" label="性别" width="200">
            <template slot-scope="scope">
              {{ scope.row.xbm == 1 ? '男' : '女' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="bmmc"
            label="学院"
            width="200"
          ></el-table-column>
          <el-table-column prop="kndj" label="最终认定结果" width="300">
            <template slot-scope="scope">
              {{
                scope.row.kndj == 1
                  ? '家庭经济一般困难'
                  : scope.row.kndj == 2
                  ? '家庭经济困难'
                  : '家庭经济特别困难'
              }}
            </template>
          </el-table-column>
          <el-table-column prop="xn" label="学年"> </el-table-column>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getCurrentPC, tongji, getGSPageList, rending } from '../../../api/kns'
import { getAuthorizeButtonColumnList } from '../../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      tjinfo: {},
      pcmodel: {},
      showtop: false,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
    }
  },
  created() {
    getCurrentPC()
      .then((res) => {
        if (res.code === 200) {
          this.showtop = true
          this.pcmodel = res.data
          tongji({ pcid: res.data.id }).then((re) => {
            if (re.code === 200) {
              this.tjinfo = re.data
            }
          })
          this.getDataPageList()
        } else {
          this.showtop = false
        }
      })
      .catch((err) => {
        console.log(err)
        this.showtop = false
      })
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 获取分页信息
    getDataPageList() {
      if (this.showtop) {
        this.tableloading = true
        getGSPageList({
          pcid: this.pcmodel.id,
          pagination: JSON.stringify(this.pagination),
        })
          .then((res) => {
            if (res.code === 200) {
              this.tableloading = false
              this.pagination.page = res.data.page
              this.pagination.total = res.data.total
              this.pagination.records = res.data.records
              this.dataPageList = res.data.rows
            } else {
              this.tableloading = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.tableloading = false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    tjrd(ordzt, rdzt) {
      rending({ ORDZT: ordzt, RDZT: rdzt, PCID: this.pcmodel.id })
        .then((res) => {
          if (res.code === 200) {
            this.getDataPageList()
            tongji({ pcid: this.pcmodel.id }).then((re) => {
              if (re.code === 200) {
                this.tjinfo = re.data
              }
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat2(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}

.c_table {
  height: 100%;
  overflow-y: auto;
  width: 99%;
  margin: 0 auto;
  margin-top: 20px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
