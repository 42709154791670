<!--
* 创建人：罗兴
* 日 期：
* 描 述：学生可登记去向节假日
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">可登记去向节假日</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <sy-xueqi
          v-model="xqid"
          :selectdqxq="true"
          style="display: inline"
        ></sy-xueqi>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >登记</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xueqi" label="学期" width="200">
        </el-table-column>
        <el-table-column prop="jqmc" label="假期名称" width="250">
        </el-table-column>
        <el-table-column prop="jqkssj" label="假期开始时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.jqkssj) }}
          </template>
        </el-table-column>
        <el-table-column prop="jqjssj" label="假期结束时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.jqjssj) }}
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!------------------添加 修改 -------------->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="info"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="infoRules"
      >
        <el-form-item label="离校时间" label-width="120px" prop="LXSJ">
          <el-date-picker
            v-model="info.LXSJ"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="返校时间" label-width="120px" prop="FXSJ">
          <el-date-picker
            v-model="info.FXSJ"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="假期去向" label-width="120px" prop="JQQX">
          <el-input v-model="info.JQQX" placeholder="请输入假期去向"></el-input>
        </el-form-item>
        <el-form-item label="本人联系电话" label-width="120px" prop="BRLXDH">
          <el-input
            v-model="info.BRLXDH"
            autocomplete="off"
            placeholder="本人联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="家长联系电话" label-width="120px" prop="JZLXDH">
          <el-input
            v-model="info.JZLXDH"
            autocomplete="off"
            placeholder="家长联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="BZ">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="info.BZ"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import api from '../../api/jqgl'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xqid: null,
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      info: {
        ID: '',
        JRID: '',
        XH: '',
        LXSJ: '',
        FXSJ: '',
        JQQX: '',
        BRLXDH: '',
        JZLXDH: '',
        BZ: '',
      },
      infoRules: {
        LXSJ: [{ required: true, message: '请选择离校时间', trigger: 'blur' }],
        FXSJ: [{ required: true, message: '请选择返校时间', trigger: 'blur' }],
        JQQX: [{ required: true, message: '请填写假期去向', trigger: 'blur' }],
        BRLXDH: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入电话号码'))
              } else {
                const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
                const isPhone = reg.test(value)
                value = Number(value) // 转换为数字
                if (typeof value === 'number' && !isNaN(value)) {
                  // 判断是否为数字
                  value = value.toString() // 转换成字符串
                  if (value.length < 0 || value.length > 12 || !isPhone) {
                    // 判断是否为11位手机号
                    callback(new Error('手机号码格式如:138xxxx8754'))
                  } else {
                    callback()
                  }
                } else {
                  callback(new Error('请输入电话号码'))
                }
              }
            },
          },
        ],
        JZLXDH: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入电话号码'))
              } else {
                const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
                const isPhone = reg.test(value)
                value = Number(value) // 转换为数字
                if (typeof value === 'number' && !isNaN(value)) {
                  // 判断是否为数字
                  value = value.toString() // 转换成字符串
                  if (value.length < 0 || value.length > 12 || !isPhone) {
                    // 判断是否为11位手机号
                    callback(new Error('手机号码格式如:138xxxx8754'))
                  } else {
                    callback()
                  }
                } else {
                  callback(new Error('请输入电话号码'))
                }
              }
            },
          },
        ],
      },
    }
  },
  watch: {},
  components: {
    'sy-xueqi': () => import('../../components/dropdown/dropdown_xueqi'),
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      api
        .getkdjpagelist({
          queryJson: JSON.stringify({ xqid: this.xqid }),
          pagination: JSON.stringify(this.pagination),
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      if (!this.keyValue) {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.dialogFormVisible = true
      }
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.info.JRID = this.keyValue
          this.info.LXSJ = moment(this.info.LXSJ).format('YYYY-MM-DDTHH:mm:ss')
          this.info.FXSJ = moment(this.info.FXSJ).format('YYYY-MM-DDTHH:mm:ss')
          api.savejrqxdj(this.info).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
