<template>
  <el-form :model="forminfo" ref="formRef">
    <div class="form-item-group">
      <i class="fa fa-drivers-license-o"></i>
      <div>学生基础信息：</div>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="学号" label-width="120px">
          <el-input v-model="xsinfo.xh" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="姓名" label-width="120px">
          <el-input v-model="xsinfo.xm" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="性别" label-width="120px">
          <el-radio v-model="xsinfo.xbm" label="1">男</el-radio>
          <el-radio v-model="xsinfo.xbm" label="2">女</el-radio>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="院系" label-width="120px">
          <el-input v-model="xsinfo.bmmc" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="专业" label-width="120px">
          <el-input v-model="xsinfo.zymc" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="班级" label-width="120px">
          <el-input v-model="xsinfo.bjmc" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="form-item-group">
      <i class="fa fa-calendar"></i>
      <div>学生违纪信息</div>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="处分类型" label-width="120px" prop="CFLX">
          <el-select
            v-model="forminfo.CFLX"
            size="small"
            clearable
            style="width: 100%"
          >
            <el-option label="教学处分" :value="1"></el-option>
            <el-option label="学生行为习惯违纪处分" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="学期" label-width="120px" prop="XQ">
          <el-select
            v-model="forminfo.XQ"
            size="small"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in xqlist"
              :key="index"
              :label="item.ksnf + '-' + item.jsnf + '学年第' + item.xq + '学期'"
              :value="item.ksnf + '-' + item.jsnf + '学年第' + item.xq + '学期'"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="处分发文编号" label-width="120px" prop="CFFWBH">
          <el-input
            v-model="forminfo.CFFWBH"
            placeholder="请输入处分发文编号"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="违纪情况" label-width="120px" prop="WJQK">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="forminfo.WJQK"
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="认定结果" label-width="120px" prop="RDJG">
          <el-select
            v-model="forminfo.RDJG"
            size="small"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in rdjglist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="违纪时间" label-width="120px" prop="WJSJ">
          <el-date-picker
            v-model="forminfo.WJSJ"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <div class="form-item-group">
      <i class="fa fa-address-book-o"></i>
      <div>处理情况</div>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="处分结果" label-width="120px" prop="CFJG">
          <el-select
            v-model="forminfo.CFJG"
            size="small"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in cfjglist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="处分时间" label-width="120px" prop="CFSJ">
          <el-date-picker
            v-model="forminfo.CFSJ"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="备注" label-width="120px" prop="BZ">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="forminfo.BZ"
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="issp == 1">
      <el-col :span="8">
        <el-form-item label="是否解除处分" label-width="120px">
          <el-radio v-model="forminfo.SFJC" :label="1">是</el-radio>
          <el-radio v-model="forminfo.SFJC" :label="0">否</el-radio>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="解除处分文号" label-width="120px">
          <el-input v-model="forminfo.JCCFWH"> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="解除日期" label-width="120px">
          <el-input v-model="forminfo.JCSJ"> </el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { GetPostDetail } from '../../api/wjcf.js'
import { getCode } from '../../api/jcxx'
import moment from 'moment'
export default {
  name: 'diaform',
  props: {
    id: {
      type: String,
    },
    issp: {
      type: String,
    },
  },
  data() {
    return {
      keyValue: this.id,
      xsinfo: {},
      xqlist: [], // 学期
      rdjglist: [], // 认定结果
      cfjglist: [], // 处分结果
      forminfo: {
        ID: '',
        XH: '',
        CFLX: '',
        XQ: '',
        CFFWBH: '',
        WJQK: '',
        RDJG: '',
        WJSJ: '',
        CFJG: '',
        CFSJ: '',
        BZ: '',
        SPZT: 0,
        SFSX: 0,
        SXSJ: null,
        SFJC: 0,
        JCCFWH: '',
        JCSJ: null,
      },
    }
  },
  created() {
    this.getLXCode()
    GetPostDetail({ id: this.keyValue }).then((res) => {
      if (res.code === 200 && res.data) {
        this.forminfo.ID = res.data.id
        this.forminfo.XH = res.data.xh
        this.forminfo.CFLX = res.data.cflx
        this.forminfo.XQ = res.data.xq
        this.forminfo.CFFWBH = res.data.cffwbh
        this.forminfo.WJQK = res.data.wjqk
        this.forminfo.RDJG = res.data.rdjg
        this.forminfo.WJSJ = res.data.wjsj
        this.forminfo.CFJG = res.data.cfjg
        this.forminfo.CFSJ = res.data.cfsj
        this.forminfo.BZ = res.data.bz
        this.forminfo.SPZT = res.data.spzt
        this.forminfo.SFSX = res.data.sfsx
        this.forminfo.SXSJ = res.data.sxsj
        this.forminfo.SFJC = res.data.sfjc
        this.forminfo.JCCFWH = res.data.jccfwh
        this.forminfo.JCSJ = this.dateFormat2(res.data.jcsj)
        this.xsinfo.xh = res.data.xh
        this.xsinfo.xm = res.data.xm
        this.xsinfo.xbm = res.data.xbm
        this.xsinfo.bmmc = res.data.bmmc
        this.xsinfo.zymc = res.data.zymc
        this.xsinfo.bjmc = res.data.bjmc
      } else {
        this.$message.error(res.message)
      }
    })
  },
  methods: {
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'WJRDJGDM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.rdjglist = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'CFJGDM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.cfjglist = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>
<style scoped>
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
