<!--校区管理-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">教职工基础信息管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <el-input
          v-model="keyword"
          placeholder="请输入姓名/教工号"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
        >搜索</el-button>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
        >新增</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
        >编辑</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delLs"
        >删除</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_sjtb')"
          icon="el-icon-d-arrow-right"
          @click="sjtb"
        >数据同步到用户表</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_czmm')"
          icon="el-icon-d-arrow-right"
          @click="czmm"
        >集体重置密码</el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="jgh"
          label="工号"
        >
        </el-table-column>
        <el-table-column
          prop="xm"
          label="姓名"
        > </el-table-column>
        <el-table-column
          prop="xb"
          label="性别"
        >
          <template slot-scope="scope">
            {{ scope.row.xb == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="bmmc"
          label="部门名称"
        >
        </el-table-column>
        <el-table-column
          prop="sfzh"
          label="身份证号"
        >
        </el-table-column>
        <el-table-column
          prop="lxdh"
          label="电话号码"
        >
        </el-table-column>
        <el-table-column
          prop="jjlxdh"
          label="紧急联系方式"
        >
        </el-table-column>
        <el-table-column
          prop="zzzt"
          label="在职状态"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.zzzt == 1"
              type="success"
              effect="dark"
            >
              在职
            </el-tag>
            <el-tag
              v-if="scope.row.zzzt == 0"
              type="danger"
              effect="dark"
            >
              离职
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="lsinfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="lsinfoRules"
      >
        <el-form-item
          label="部门"
          label-width="120px"
          prop="BMBH"
        >
          <el-select
            v-model="lsinfo.BMBH"
            style="display: block"
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="教工号"
          label-width="120px"
          prop="JGH"
        >
          <el-input
            v-model="lsinfo.JGH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="姓名"
          label-width="120px"
          prop="XM"
        >
          <el-input
            v-model="lsinfo.XM"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别"
          label-width="120px"
          prop="XB"
        >
          <el-select
            v-model="lsinfo.XB"
            style="display: block"
          >
            <el-option
              label="男"
              value="1"
            ></el-option>
            <el-option
              label="女"
              value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="身份证号"
          label-width="120px"
          prop="SFZH"
        >
          <el-input
            v-model="lsinfo.SFZH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          label-width="120px"
          prop="LXDH"
        >
          <el-input
            v-model="lsinfo.LXDH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="紧急联系电话"
          label-width="120px"
          prop="JJLXDH"
        >
          <el-input
            v-model="lsinfo.JJLXDH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="在职状态"
          label-width="120px"
          prop="ZZZT"
        >
          <el-select
            v-model="lsinfo.ZZZT"
            style="display: block"
          >
            <el-option
              label="在职"
              :value="1"
            ></el-option>
            <el-option
              label="离职"
              :value="0"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getLSPageList,
  saveLSInfo,
  getLSInfo,
  deleteLsInfo,
  getAllBM,
  tbsj,
  czmmls,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      bmlist: [],
      lsinfo: {
        ID: '',
        JGH: '',
        XM: '',
        BMBH: '',
        XB: '',
        SFZH: '',
        LXDH: '',
        JJLXDH: '',
        ZZZT: 1,
      },
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      lsinfoRules: {
        BMBH: [{ required: true, message: '请输入部门编号', trigger: 'blur' }],
        JGH: [{ required: true, message: '请输入教工号', trigger: 'blur' }],
        XM: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        XB: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        SFZH: [{ required: true, message: '请填写身份证号', trigger: 'blur' }],
        LXDH: [{ required: true, message: '请填写联系电话', trigger: 'blur' }],
        ZZZT: [{ required: true, message: '请选择在职状态', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.lsinfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示添加弹出框
    showAdd() {
      getAllBM()
        .then((res) => {
          if (res.code === 200) {
            this.bmlist = res.data
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
      this.operation = 1
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        getAllBM()
          .then((res) => {
            if (res.code === 200) {
              this.bmlist = res.data
            } else {
              this.$message.error(res.message)
            }
          })
          .catch((err) => {
            this.$message.error(err)
          })
        this.operation = 0
        getLSInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.lsinfo.ID = res.data.id
            this.lsinfo.BMBH = res.data.bmbh
            this.lsinfo.JGH = res.data.jgh
            this.lsinfo.XM = res.data.xm
            this.lsinfo.XB = res.data.xb
            this.lsinfo.SFZH = res.data.sfzh
            this.lsinfo.LXDH = res.data.lxdh
            this.lsinfo.JJLXDH = res.data.jjlxdh
            this.lsinfo.ZZZT = res.data.zzzt
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.lsinfo.ID = ''
      } else {
        this.lsinfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveLSInfo({ ls: this.lsinfo }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除部门
    delLs() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteLsInfo({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 数据同步
    sjtb() {
      this.$confirm('是否确认同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          tbsj().then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {})
    },
    // 重置密码
    czmm() {
      this.$confirm('是否确认重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          czmmls().then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {})
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.lsinfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getLSPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
