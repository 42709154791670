<!--学生请假申请-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">日常请假查询</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学期:</span>
            <sy-xueqi
              v-model="xq"
              :selectdqxq="true"
              style="display: inline"
            ></sy-xueqi>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">请假类型:</span>
            <el-select
              v-model="qjlx"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in cxlx"
                :key="index"
                :label="item.zdz"
                :value="item.zddm"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">销假状态:</span>
            <el-select v-model="zt" size="small" clearable style="width: 200px">
              <el-option label="已销假" :value="2"></el-option>
              <el-option label="未销假" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col :span="16" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">日期:</span>
            <el-date-picker
              v-model="qjrq"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
            >
            </el-date-picker>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_detail')"
          icon="el-icon-delete"
          @click="detailInfo"
          >查看</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_export')"
          icon="el-icon-download"
          @click="showExport"
          >导出</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 360"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="150"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bmmc" label="系部" width="200">
        </el-table-column>
        <el-table-column prop="kssj" label="请假时间" width="350">
          <template slot-scope="scop">
            {{
              dateFormat2(scop.row.kssj) + ' 至 ' + dateFormat2(scop.row.jssj)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="zdz" label="事由类型" width="150">
        </el-table-column>
        <el-table-column prop="wcdd" label="外出地点" width="300">
        </el-table-column>
        <el-table-column prop="xjzt" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.xjzt === 2" type="success">已销假</el-tag>
            <el-tag v-if="scope.row.xjzt === 1" type="danger">未销假</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="日常请假申请"
      :visible.sync="dialogDetailFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <sy-form
          ref="forminfo"
          v-if="dialogDetailFormVisible"
          :id="keyValue"
          :XH="xh"
          :ishowupload="false"
          :ishowtimeline="true"
        ></sy-form>
      </el-scrollbar>
    </el-dialog>
    <!--导出日志弹出框-->
    <el-dialog title="导出Excel数据" :visible.sync="dialogExcelVisible">
      <div>
        <el-checkbox
          v-model="excelForm.XQ"
          label="学期"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XH"
          label="学号"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XM"
          label="姓名"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BMMC"
          label="院系"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.ZYMC"
          label="专业"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BJMC"
          label="班级"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.QJLX"
          label="请假类型"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.KSSJ"
          label="开始时间"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.JSSJ"
          label="结束时间"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.QJSY"
          label="请假事由"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.WCDD"
          label="外出地点"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcelVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportToExcel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetCXPageList, exportSearchExcel } from '../../../api/qjgl'
import {
  getCode,
  GetListByQX,
  GetZYListByQX,
  GetBJListByQX,
} from '../../../api/jcxx'
import { getAuthorizeButtonColumnList, getCurrentXQ } from '../../../api/system'
import form from '../../../components/qjgl/qjform.vue'
import moment from 'moment'
export default {
  components: {
    'sy-form': form, // 挂载组件
    'sy-xueqi': () => import('../../../components/dropdown/xueqi_name'),
  },
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      ksxq: '',
      jsxq: '',
      keyword: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      bmlist: [],
      zylist: [],
      bjlist: [],
      xq: '',
      zt: '',
      qjrq: '',
      qjlx: '',
      keyValue: '',
      cxlx: [],
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      splist: [], // 审批记录
      xsinfo: {},
      xh: '',
      ZT: 0,
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      dialogExcelVisible: false,
      excelForm: {
        XQ: true,
        XH: true,
        XM: true,
        BMMC: true,
        ZYMC: true,
        BJMC: true,
        QJLX: true,
        KSSJ: true,
        JSSJ: true,
        QJSY: true,
        WCDD: true,
      },
    }
  },
  created() {
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getCurrentXQ().then((res) => {
      if (res.code === 200) {
        this.xq =
          res.data.ksnf + '-' + res.data.jsnf + '学年第' + res.data.xq + '学期'
        this.getDataPageList()
      }
    })
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
      .then((res) => {
        if (res.code === 200) {
          this.cxlx = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    // 显示编辑弹出框
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        this.dialogDetailFormVisible = true
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.ZT = 0
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.ZT = currentRow.zt
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetCXPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          qjlx: this.qjlx,
          ZYBH: this.zybh,
          BMBH: this.bmbh,
          xq: this.xq,
          zt: this.zt,
          kssj: this.qjrq ? this.dateFormat3(this.qjrq[0]) : '',
          jssj: this.qjrq ? this.dateFormat3(this.qjrq[1]) : '',
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    dateFormat3: function (value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
        .then((res) => {
          if (res.code === 200) {
            this.code = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    showExport() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.XQ) {
        exportFiles.push({ name: 'XQ', label: '学期' })
      }
      if (this.excelForm.XH) {
        exportFiles.push({ name: 'XH', label: '学号' })
      }
      if (this.excelForm.XM) {
        exportFiles.push({ name: 'XM', label: '姓名' })
      }
      if (this.excelForm.BMMC) {
        exportFiles.push({ name: 'BMMC', label: '院系' })
      }
      if (this.excelForm.ZYMC) {
        exportFiles.push({ name: 'ZYMC', label: '专业' })
      }
      if (this.excelForm.BJMC) {
        exportFiles.push({ name: 'BJMC', label: '班级' })
      }
      if (this.excelForm.QJLX) {
        exportFiles.push({ name: 'QJLX', label: '请假类型' })
      }
      if (this.excelForm.KSSJ) {
        exportFiles.push({ name: 'KSSJ', label: '开始时间' })
      }
      if (this.excelForm.JSSJ) {
        exportFiles.push({ name: 'JSSJ', label: '结束时间' })
      }
      if (this.excelForm.QJSY) {
        exportFiles.push({ name: 'QJSY', label: '请假事由' })
      }
      if (this.excelForm.WCDD) {
        exportFiles.push({ name: 'WCDD', label: '外出地点' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }

      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportSearchExcel({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          xq: this.xq,
          qjlx: this.qjlx,
          ZYBH: this.zybh,
          BMBH: this.bmbh,
          zt: this.zt,
          kssj: this.qjrq ? this.dateFormat3(this.qjrq[0]) : '',
          jssj: this.qjrq ? this.dateFormat3(this.qjrq[1]) : '',
        }),
        fileName: '请假信息',
        exportField: JSON.stringify(exportFiles),
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 160px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
