<template>
  <div class="module">
    <div class="m_left">
      <div
        @click="changeoption(1)"
        :class="categoryId == 1 ? 'menu_active' : 'menu_normal'"
      >
        登录日志
      </div>
      <div
        @click="changeoption(2)"
        :class="categoryId == 2 ? 'menu_active' : 'menu_normal'"
      >
        访问日志
      </div>
      <div
        @click="changeoption(3)"
        :class="categoryId == 3 ? 'menu_active' : 'menu_normal'"
      >
        操作日志
      </div>
      <div
        @click="changeoption(4)"
        :class="categoryId == 4 ? 'menu_active' : 'menu_normal'"
      >
        异常日志
      </div>
    </div>
    <div class="m_right">
      <div class="m_tool">
        <div>
          <el-date-picker
            v-model="searchtime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-input v-model="keyword" placeholder="请输入内容"></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getDataPageList"
            >搜索</el-button
          >
        </div>

        <el-button-group>
          <el-button icon="el-icon-refresh" @click="reload"></el-button>
          <el-button
            icon="el-icon-delete"
            @click="dialogLogVisible = true"
            v-if="hasPerms('lr_removelog')"
            >清空</el-button
          >
          <el-button
            icon="el-icon-download"
            @click="showExcelDia"
            v-if="hasPerms('lr_export')"
            >导出</el-button
          >
        </el-button-group>
      </div>
      <div class="m_table">
        <el-table
          v-loading="tableloading"
          :data="tableData"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          :height="windowHeight - 270"
          highlight-current-row
        >
          <el-table-column
            prop="f_OperateTime"
            label="操作时间"
            width="200"
            :formatter="dateFormat"
          >
          </el-table-column>
          <el-table-column prop="f_OperateAccount" label="操作用户" width="200">
          </el-table-column>
          <el-table-column prop="f_IPAddress" label="IP地址" width="200">
          </el-table-column>
          <el-table-column prop="f_Module" label="系统功能" width="200">
          </el-table-column>
          <el-table-column
            prop="f_OperateType"
            label="操作类型"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="f_ExecuteResult"
            align="center"
            label="执行结果"
            width="100"
          >
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.f_ExecuteResult === 1 ? 'success' : 'danger'"
                effect="dark"
              >
                {{ scope.row.f_ExecuteResult === 1 ? '成功' : '失败' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="f_ExecuteResultJson" label="执行结果描述">
          </el-table-column>
        </el-table>
        <!--=======================================分页================================================-->
        <div class="pagebox" v-if="pagination.total != 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination.records"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--清空日志弹出框-->
    <el-dialog title="清空" :visible.sync="dialogLogVisible" width="500px">
      <el-form>
        <el-form-item label="日志保留时间" label-width="120px">
          <el-select v-model="dellogoption" placeholder="请选择日志保留时间">
            <el-option label="保留最近一周" value="7"></el-option>
            <el-option label="保留最近一个月" value="1"></el-option>
            <el-option label="保留最近三个月" value="3"></el-option>
            <el-option label="不保留，全部删除" value="all"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogLogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delLog">确 定</el-button>
      </div>
    </el-dialog>
    <!--导出日志弹出框-->
    <el-dialog title="导出Excel数据" :visible.sync="dialogExcelVisible">
      <div>
        <el-checkbox
          v-model="excelForm.F_OperateTime"
          label="操作时间"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.F_OperateAccount"
          label="操作用户"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.F_IPAddress"
          label="IP地址"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.F_Module"
          label="系统功能"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.F_OperateType"
          label="操作类型"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.F_ExecuteResult"
          label="执行结果"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.F_ExecuteResultJson"
          label="执行结果描述"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcelVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportToExcel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLogPageList,
  getAuthorizeButtonColumnList,
  saveRemoveLog,
  exportExcel,
} from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      categoryId: 1, // 日志类别
      tableloading: true,
      buttonauth: {}, // 按钮授权
      keyword: '',
      searchtime: '',
      dellogoption: '7',
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      tableData: [],
      dialogLogVisible: false,
      dialogExcelVisible: false,
      excelForm: {
        F_OperateTime: true, // 操作时间
        F_OperateAccount: true, // 操作用户
        F_IPAddress: true, // IP地址
        F_Module: true, // 系统功能
        F_OperateType: true, // 操作类型
        F_ExecuteResult: true, // 执行结果
        F_ExecuteResultJson: true, // 执行结果描述
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  created() {
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    reload() {
      this.$router.go(0)
    },
    // 改变当前日志类型
    changeoption(option) {
      this.categoryId = option
      this.getDataPageList()
    },
    // 跳转页
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getDataPageList()
    },
    // 获取分页数据
    getDataPageList() {
      this.tableloading = true
      getLogPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          CategoryId: this.categoryId,
          StartTime: this.searchtime ? this.searchtime[0] : '',
          EndTime: this.searchtime ? this.searchtime[1] : '',
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          this.tableloading = false
          if (res.code === 200) {
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.tableData = res.data.rows
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.tableloading = false
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 删除日志
    delLog() {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          saveRemoveLog({
            categoryId: this.categoryId,
            keepTime: this.dellogoption,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.getDataPageList()
              this.dialogLogVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {})
    },
    // 日志导出弹出框
    showExcelDia() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    // 导出数据
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.F_OperateTime) {
        exportFiles.push({ name: 'F_OperateTime', label: '操作时间' })
      }
      if (this.excelForm.F_OperateAccount) {
        exportFiles.push({ name: 'F_OperateAccount', label: '操作用户' })
      }
      if (this.excelForm.F_IPAddress) {
        exportFiles.push({ name: 'F_IPAddress', label: 'IP地址' })
      }
      if (this.excelForm.F_Module) {
        exportFiles.push({ name: 'F_Module', label: '系统功能' })
      }
      if (this.excelForm.F_OperateType) {
        exportFiles.push({ name: 'F_OperateType', label: '操作类型' })
      }
      if (this.excelForm.F_ExecuteResult) {
        exportFiles.push({ name: 'F_ExecuteResult', label: '执行结果' })
      }
      if (this.excelForm.F_ExecuteResultJson) {
        exportFiles.push({ name: 'F_ExecuteResultJson', label: '执行结果描述' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }
      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportExcel({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          CategoryId: this.categoryId,
          StartTime: this.searchtime ? this.searchtime[0] : '',
          EndTime: this.searchtime ? this.searchtime[1] : '',
        }),
        fileName: '日志',
        exportField: JSON.stringify(exportFiles),
      }).then((res) => {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
  },
}
</script>

<style  scoped>
.module {
  position: relative;
  padding-left: 210px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.m_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
}
.m_right {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}
.m_title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
}
.m_table {
  width: 100%;
  padding: 1px;
  box-sizing: border-box;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.m_tool {
  padding: 20px;
  width: 100%;
  height: 85px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.m_tool .el-input {
  margin-left: 20px;
  width: 200px;
}

.m_tool .el-button {
  padding: 0px 15px;
  height: 40px;
  margin-left: 10px;
}
.el-table .cell {
  font-size: 14px;
}
.menu_normal {
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
}
.menu_normal:hover {
  background-color: #ecf8ff;
}
.menu_active {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-left: 5px solid #50bfff;
  margin-top: 10px;
  cursor: pointer;
}
</style>
