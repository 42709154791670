<template>
  <div>
    <el-form>
      <div class="form-item-group" v-if="showline">
        <i class="fa fa-address-book-o"></i>
        <div>
          审批流程 当前状态：
          <el-tag v-if="forminfo.ZT === 0">待辅导员审核</el-tag>
          <el-tag v-if="forminfo.ZT === 100" type="success"
            >辅导员审核通过</el-tag
          >
          <el-tag v-if="forminfo.ZT === 200" type="danger"
            >辅导员审核拒绝</el-tag
          >
          <el-tag v-if="forminfo.ZT === 110" type="success"
            >系主任审核通过</el-tag
          >
          <el-tag v-if="forminfo.ZT === 120" type="danger"
            >系主任审核拒绝</el-tag
          >
          <el-tag v-if="forminfo.ZT === 111" type="success"
            >系分管领导审核通过</el-tag
          >
          <el-tag v-if="forminfo.ZT === 112" type="danger"
            >系分管领导审核拒绝</el-tag
          >
        </div>
      </div>
      <el-timeline v-if="showline">
        <el-timeline-item
          v-for="(item, index) in splist"
          :key="index"
          :timestamp="dateFormat2(item.cjsj)"
          placement="top"
        >
          <el-card>
            <h4 v-if="item.spzt == 100 || item.spzt == 200">
              辅导员【{{ item.spr }}】审批{{
                item.spzt == 100 ? '通过' : '拒绝'
              }}
            </h4>
            <h4 v-if="item.spzt == 110 || item.spzt == 120">
              学院领导【{{ item.spr }}】审批{{
                item.spzt == 110 || item.spzt == 120 ? '通过' : '拒绝'
              }}
            </h4>
            <h4 v-if="item.spzt == 111 || item.spzt == 112">
              学工【{{ item.spr }}】审批{{ item.spzt == 1 ? '通过' : '拒绝' }}
            </h4>
            <p>审批意见：{{ item.spyj }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <div class="form-item-group">
        <i class="fa fa-drivers-license-o"></i>
        <div>学生基础信息：</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="学号" label-width="120px">
            <el-input v-model="xsinfo.xh"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名" label-width="120px">
            <el-input v-model="xsinfo.xm"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="性别" label-width="120px">
            <el-radio v-model="xsinfo.xbm" label="1">男</el-radio>
            <el-radio v-model="xsinfo.xbm" label="2">女</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="院系" label-width="120px">
            <el-input v-model="xsinfo.bmmc"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="专业" label-width="120px">
            <el-input v-model="xsinfo.zymc"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="班级" label-width="120px">
            <el-input v-model="xsinfo.bjmc"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="form-item-group">
        <i class="fa fa-calendar"></i>
        <div>
          请假信息 请假状态：
          <el-tag v-if="forminfo.XJZT == 0">审批中</el-tag>
          <el-tag v-if="forminfo.XJZT == 1" type="danger">未销假</el-tag>
          <el-tag v-if="forminfo.XJZT === 2" type="success">已销假</el-tag>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="请假时间" label-width="120px" prop="QJSJ">
            <el-date-picker
              v-model="forminfo.QJSJ"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="请假类型" label-width="120px" prop="QJLX">
            <el-radio
              v-for="(item, index) in code"
              :key="index"
              v-model="forminfo.QJLX"
              :label="item.zddm"
              >{{ item.zdz }}</el-radio
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="请假事由" label-width="120px" prop="QJSY">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
              v-model="forminfo.QJSY"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="外出地点" label-width="120px" prop="WCDD">
            <el-input
              v-model="forminfo.WCDD"
              placeholder="请输入外出地点"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="form-item-group">
        <i class="fa fa-address-book-o"></i>
        <div>外出联系人信息</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="联系人姓名" label-width="120px" prop="LXRXM">
            <el-input
              v-model="forminfo.LXRXM"
              placeholder="请输入联系人姓名"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="移动电话" label-width="120px" prop="YDDH">
            <el-input
              v-model="forminfo.YDDH"
              placeholder="请输入移动电话"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="本人关系" label-width="120px" prop="BRGX">
            <el-input
              v-model="forminfo.BRGX"
              placeholder="请输入外出联系人与本人关系"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="本人电话" label-width="120px" prop="BRDH">
            <el-input
              v-model="forminfo.BRDH"
              placeholder="请输入本人电话"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="forminfo.fjlist.length > 0">
        <el-col :span="24">
          <el-form-item label="附件" label-width="120px">
            <div class="demo-image__preview">
              <el-image
                v-for="item in forminfo.fjlist"
                :key="item.id"
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="lookUrl + item.filePath"
                :preview-src-list="new Array(lookUrl + item.filePath)"
                fit="cover"
              >
              </el-image>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        label="附件材料"
        label-width="120px"
        class="mb10"
        v-if="showupload"
      >
        <el-upload
          class="upload-demo"
          :headers="headers"
          :action="uploadurl"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :before-remove="beforeRemove"
          :limit="3"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button size="small" type="primary" v-if="forminfo.DQBZ == 1"
            >点击上传</el-button
          >
          <div slot="tip" class="el-upload__tip" v-if="forminfo.DQBZ == 1">
            存在文件必须填写说明，附件类型包括：jpg，gif，bmp
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getQJSQInfoTwo, GetListBySQID } from '../../api/qjgl'
import { getStuAllDetailInfo, getCode } from '../../api/jcxx'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
import moment from 'moment'
export default {
  props: {
    id: {
      // 显示隐藏
      type: String,
    },
    XH: {
      type: String,
    },
    ishowtimeline: {
      type: Boolean,
      default: false,
    },
    ishowupload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uploadurl: baseUrl + 'File/upload',
      lookUrl: baseUrl,
      headers: null,
      fileList: [],
      code: [],
      showline: this.ishowtimeline,
      showupload: this.ishowupload,
      keyValue: this.id,
      xh: this.XH,
      xsinfo: {},
      forminfo: {
        ID: '',
        XH: '',
        KSSJ: '',
        JSSJ: '',
        Total: 0,
        QJLX: '',
        QJSY: '',
        WCDD: '',
        TXRS: '',
        GDDH: '',
        YDDH: '',
        BRGX: '',
        LXRXM: '',
        ZT: 0,
        XJZT: 0,
        DQBZ: 0,
        BRDH: '',
        fjlist: [],
      },
      splist: [], // 审批记录
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.getLXCode()
    GetListBySQID({ sqid: this.keyValue }).then((res) => {
      if (res.code === 200) {
        this.splist = res.data
      } else {
        this.splist = []
      }
    })
    getStuAllDetailInfo({ XH: this.xh }).then((res) => {
      if (res.code === 200) {
        this.xsinfo = res.data
      }
    })
    getQJSQInfoTwo({ id: this.keyValue }).then((res) => {
      if (res.code === 200) {
        this.forminfo.ID = res.data.info.id
        this.forminfo.XH = res.data.info.xh
        this.forminfo.KSSJ = res.data.info.kssj
        this.forminfo.JSSJ = res.data.info.jssj
        this.forminfo.Total = res.data.info.total
        this.forminfo.QJLX = res.data.info.qjlx
        this.forminfo.QJSY = res.data.info.qjsy
        this.forminfo.WCDD = res.data.info.wcdd
        this.forminfo.TXRS = res.data.info.txrs
        this.forminfo.YDDH = res.data.info.yddh
        this.forminfo.BRGX = res.data.info.brgx
        this.forminfo.LXRXM = res.data.info.lxrxm
        this.forminfo.ZT = res.data.info.zt
        this.forminfo.XJZT = res.data.info.xjzt
        this.forminfo.DQBZ = res.data.info.dqbz
        this.forminfo.BRDH = res.data.info.brdh
        this.forminfo.QJSJ = [
          new Date(res.data.info.kssj),
          new Date(res.data.info.jssj),
        ]
        this.forminfo.fjlist = res.data.sqfj
        this.fileList = []
        for (var i = 0; i < res.data.fj.length; i++) {
          this.fileList.push({
            name: res.data.fj[i].fileName,
            id: res.data.fj[i].id,
          })
        }
        this.dialogDetailFormVisible = true
      } else {
        this.$message.error(res.message)
      }
    })
  },
  methods: {
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
        .then((res) => {
          if (res.code === 200) {
            this.code = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
