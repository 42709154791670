import { post, get, download, excel } from '../utils/http'

// 保存批次设置
export function savePCSZ(data) {
  return post('jxj/SY_JXJ_GJJXJ_XSSQ/SavePC/', data)
}

// 获取批次
export function GetPCPageList(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/GetPCPageList/', data)
}

// 更新是否当前学年
export function changeState(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/SaveDQPC/', data)
}

// 根据ID获取
export function getFormData(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/GetPCFormData/', data)
}

// 学生获取当前批次
export function stuGetPC(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/StuGetPC/', data)
}

// 学生获取申请分页数据
export function getStuApplyPageList(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/GetStuApplyPageList/', data)
}

// 学生提交申请
export function stuSave(data) {
  return post('jxj/SY_JXJ_GJJXJ_XSSQ/Save/', data)
}

// 学生获取申请详情
export function getSQFormData(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/GetFormData/', data)
}

// 获取待审批列表
export function getSPPageList(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/GetSPPageList/', data)
}

// 审批
export function sp(data) {
  return post('jxj/SY_JXJ_GJJXJ_XSSQ/shengpi/', data)
}

// 获取查询列表
export function getCXPageList(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/GetCXPageList/', data)
}

// 获取批次
export function getListAndCurrent(data) {
  return get('jxj/SY_JXJ_GJJXJ_XSSQ/GetListAndCurrent/', data)
}

// 保存批次设置
export function saveDJPCSZ(data) {
  return post('jxj/SY_JXJ_DJJXJ_PCSZ/Save/', data)
}

// 获取批次
export function GetDJPCPageList(data) {
  return get('jxj/SY_JXJ_DJJXJ_PCSZ/GetPageList/', data)
}

// 根据ID获取
export function getDJFormData(data) {
  return get('jxj/SY_JXJ_DJJXJ_PCSZ/GetFormData/', data)
}

// 更新是否当前学年
export function changeDJState(data) {
  return post('jxj/SY_JXJ_DJJXJ_PCSZ/SaveDQPC/', data)
}

// 获取等级奖学金管理流程
export function GetDJStep() {
  return get('jxj/SY_JXJ_DJJXJ_LCPZ/GetStep/')
}

// 提交等级奖学金流程配置
export function DJLCPZSave(data) {
  return post('jxj/SY_JXJ_DJJXJ_LCPZ/LCPZSave/', data)
}

// 学生获取等级奖学金当前批次
export function stuDJGetPC(data) {
  return get('jxj/SY_JXJ_DJJXJ/StuGetPC/', data)
}

// 学生获取申请分页数据
export function getDJStuApplyPageList(data) {
  return get('jxj/SY_JXJ_DJJXJ/GetStuApplyPageList/', data)
}

// 提交等级奖学金流程配置
export function DJSave(data) {
  return post('jxj/SY_JXJ_DJJXJ/Save/', data)
}

// 学生获取申请详情
export function getDJSQFormData(data) {
  return get('jxj/SY_JXJ_DJJXJ/GetFormData/', data)
}

// 获取等级奖学金待审批列表
export function getDJDSPPageList(data) {
  return get('jxj/SY_JXJ_DJJXJ/GetDSPPageList/', data)
}

// 获取等级奖学金待审批列表
export function getDJDXYSPPageList(data) {
  return get('jxj/SY_JXJ_DJJXJ/GetDXYSPPageList/', data)
}

// 获取等级奖学金待审批列表
export function getDJDXGSPPageList(data) {
  return get('jxj/SY_JXJ_DJJXJ/GetDXGSPPageList/', data)
}

// 获取等级奖学金待审批列表
export function saveDJSP(data) {
  return post('jxj/SY_JXJ_DJJXJ/saveSP/', data)
}

// 学院审批
export function saveDJXYSP(data) {
  return post('jxj/SY_JXJ_DJJXJ/saveXYSP/', data)
}

// 学工审批
export function saveDJXGSP(data) {
  return post('jxj/SY_JXJ_DJJXJ/saveXGSP/', data)
}

// 获取等级奖学金PDF
export function getSQBPdf(data, filename) {
  return download('jxj/SY_JXJ_DJJXJ/GetSQBPdf/', data, filename)
}

// 获取等级奖学金PDF
export function getJXJPdf(data, filename) {
  return download('jxj/SY_JXJ_DJJXJ/GetPdf/', data, filename)
}

// 获取等级奖学金PDF
export function getJXJPdfUrl(data) {
  return post('jxj/SY_JXJ_DJJXJ/GetPdfUrl/', data)
}

// 获取查询列表
export function getDJCXPageList(data) {
  return get('jxj/SY_JXJ_DJJXJ/GetCXPageList/', data)
}

// 获取国奖出数据
export function exportGJSearchExcel(data) {
  return excel('jxj/SY_JXJ_GJJXJ_XSSQ/ExportExcel/', data)
}

// 获取等级奖学金导出数据
export function exportDJSearchExcel(data) {
  return excel('jxj/SY_JXJ_DJJXJ/ExportExcel/', data)
}

// 获取等级奖学金导出数据
export function exportDJXYSearchExcel(data) {
  return excel('jxj/SY_JXJ_DJJXJ/ExportXYExcel/', data)
}

// 获取等级奖学金导出数据
export function exportDJXGSearchExcel(data) {
  return excel('jxj/SY_JXJ_DJJXJ/ExportXGExcel/', data)
}

// 获取等级奖学金申请配置
export function getDJJXJSQPZInfo(data) {
  return get('jxj/SY_JXJ_DJJXJ_SQPZ/GetFormDataBYLX/', data)
}

// 提交等级奖学金申请配置
export function DJJXJSQPZSave(data) {
  return post('jxj/SY_JXJ_DJJXJ_SQPZ/Save/', data)
}

// 获取等级奖学金名单
export function getDJJXJMDPageList(data) {
  return get('jxj/SY_JXJ_DJJXJ/GetMDCXPageList/', data)
}

// 删除等级奖学金名单
export function deleteDJJXJMD(data) {
  return post('jxj/SY_JXJ_DJJXJ/deleteMD/', data)
}

// 获取国家奖学金查询列表
export function getGJJXJSearchList(data) {
  return get('jxj/SY_JXJ_GJJXJ/GetSearchList/', data)
}

// 获取查询列表
export function getGJJXJCXPageList(data) {
  return get('jxj/SY_JXJ_GJJXJ/GetPageList/', data)
}

// 删除国家奖学金
export function deleteGJJXJ(data) {
  return post('jxj/SY_JXJ_GJJXJ/Delete/', data)
}

// 获取国奖出数据
export function exportGJJXJ(data) {
  return excel('jxj/SY_JXJ_GJJXJ/ExportExcel/', data)
}
