<template>
  <el-form :model="forminfo" ref="formRef">
    <el-card class="box-card">
      <span class="staffBmTitle">四川省家庭经济困难学生认定申请表</span>
      <el-row :gutter="0" class="inputnoborder">
        <el-col :span="5">
          <el-form-item label="学校：" label-width="55px">
            <el-input value="" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="院系：" label-width="55px">
            <el-input v-model="forminfo.BMMC" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="专业：" label-width="55px">
            <el-input v-model="forminfo.ZYMC" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年级：" label-width="55px">
            <el-input v-model="forminfo.NJ" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="班级：" label-width="55px">
            <el-input v-model="forminfo.BJMC" readonly> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <table border="1px" class="staffBmTable">
        <tr>
          <td rowspan="5" style="width: 30px">学生基本情况</td>
          <td style="width: 100px">姓名</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.XM"> </el-input>
            </el-form-item>
          </td>
          <td style="width: 100px">性别</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.XB"> </el-input>
            </el-form-item>
          </td>
          <td style="width: 100px">出生年月</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.CSNY">
              </el-input>
            </el-form-item>
          </td>
          <td style="width: 100px">籍贯</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.JG"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>身份证号码</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.SFZH">
              </el-input>
            </el-form-item>
          </td>
          <td>家庭人口</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="JTRS"
            >
              <el-input size="small" v-model="forminfo.JTRS"> </el-input>
            </el-form-item>
          </td>
          <td>手机号码</td>
          <td colspan="3">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="SJHM"
            >
              <el-input size="small" v-model="forminfo.SJHM"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>详细通讯地址</td>
          <td colspan="7">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="JTDZ"
            >
              <el-input size="small" v-model="forminfo.JTDZ"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>邮政编码</td>
          <td colspan="3">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="YZBM"
            >
              <el-input size="small" v-model="forminfo.YZBM"> </el-input>
            </el-form-item>
          </td>
          <td>家长手机号码</td>
          <td colspan="3">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="JZSJHM"
            >
              <el-input size="small" v-model="forminfo.JZSJHM"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>户口性质</td>
          <td colspan="3">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="HKXZ"
            >
              <el-checkbox-group v-model="forminfo.HKXZ">
                <el-checkbox
                  label="城镇"
                  :true-label="1"
                  name="HKXZ"
                ></el-checkbox>
                <el-checkbox
                  label="农村"
                  :true-label="2"
                  name="HKXZ"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </td>
          <td>学费标准</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="XFBZ"
            >
              <el-input size="small" v-model="forminfo.XFBZ"> </el-input>
            </el-form-item>
          </td>
          <td>住宿费标准</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="ZSFBZ"
            >
              <el-input size="small" v-model="forminfo.ZSFBZ"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td style="width: 30px">家庭成员情况</td>
          <td colspan="8" style="padding: 0px">
            <el-table
              :data="fromTable"
              style="width: 100%"
              border
              :header-cell-style="{
                borderColor: '#000',
                color: '#303133',
                fontSize: '12px',
                fontWeight: 'normal',
                paddingTop: '5px',
                paddingBottom: '5px',
              }"
              :cell-style="{
                borderColor: '#000',
                paddingTop: '5px',
                paddingBottom: '5px',
              }"
            >
              <el-table-column prop="XM" label="姓名" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.XM" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="NL" label="年龄" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NL" type="number" size="small">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GX"
                label="与学生关系"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GX" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GZDW"
                label="工作（学习）单位"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GZDW" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="ZY" label="职业" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.ZY" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="NSR"
                label="年收入（元）"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NSR" type="number" size="small">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="JKZK"
                label="健康状况"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.JKZK" size="small"> </el-input>
                </template>
              </el-table-column>
            </el-table>
          </td>
        </tr>
        <tr>
          <td style="width: 30px">特殊群体类型</td>
          <td colspan="8" style="text-align: left">
            <el-checkbox-group v-model="forminfo.TSQTLXList">
              <el-row :gutter="20">
                <el-col style="margin: 5px 0" :span="6"
                  ><el-checkbox label="1、建档立卡贫困家庭学生"></el-checkbox
                ></el-col>
                <el-col style="margin: 5px 0" :span="6"
                  ><el-checkbox label="2、最低生活保障家庭学生"></el-checkbox
                ></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col style="margin: 5px 0" :span="6"
                  ><el-checkbox label="3、特困供养学生"></el-checkbox
                ></el-col>
                <el-col style="margin: 5px 0" :span="6"
                  ><el-checkbox label="4、孤残学生"></el-checkbox
                ></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col style="margin: 5px 0" :span="6"
                  ><el-checkbox label="5、烈士子女"></el-checkbox
                ></el-col>
                <el-col style="margin: 5px 0" :span="6"
                  ><el-checkbox
                    label="6、家庭经济困难残疾学生及残疾人子女"
                  ></el-checkbox
                ></el-col>
              </el-row>
            </el-checkbox-group>
            <span style="margin-top: 10px"
              >（注：请按实际情况勾选，如不属于上述特殊困难群体，本栏可不填）</span
            >
          </td>
        </tr>
        <tr>
          <td style="width: 30px">影响家庭经济状况其他有关信息</td>
          <td colspan="8" style="text-align: left">
            <el-row>
              <el-col style="margin: 5px 0">
                <el-checkbox
                  :true-label="1"
                  :false-label="0"
                  v-model="forminfo.JTRJNSRXZ"
                  >1、家庭人均年收入：<el-input
                    :readonly="forminfo.JTRJNSRXZ == 0"
                    size="small"
                    v-model="forminfo.JTRJNSR"
                  >
                  </el-input
                ></el-checkbox>
              </el-col>
              <el-col style="margin: 5px 0">
                <el-checkbox
                  v-model="forminfo.ZRZHXZ"
                  :true-label="1"
                  :false-label="0"
                  >2、家庭遭受自然灾害情况：<el-input
                    :readonly="forminfo.ZRZHXZ == 0"
                    v-model="forminfo.ZRZH"
                    size="small"
                  >
                  </el-input
                ></el-checkbox>
              </el-col>
              <el-col style="margin: 5px 0">
                <el-checkbox
                  v-model="forminfo.TFYWXZ"
                  :true-label="1"
                  :false-label="0"
                  >3、家庭遭受突发意外事件：<el-input
                    :readonly="forminfo.TFYWXZ == 0"
                    v-model="forminfo.TFYW"
                    size="small"
                  >
                  </el-input
                ></el-checkbox>
              </el-col>
              <el-col style="margin: 5px 0">
                <el-checkbox
                  v-model="forminfo.NDLRXZ"
                  :true-label="1"
                  :false-label="0"
                  >4、家庭成员因残疾、年迈而劳动能力弱情况：<el-input
                    :readonly="forminfo.NDLRXZ == 0"
                    v-model="forminfo.NDLR"
                    size="small"
                  >
                  </el-input
                ></el-checkbox>
              </el-col>
              <el-col style="margin: 5px 0">
                <el-checkbox
                  v-model="forminfo.SYQKXZ"
                  :true-label="1"
                  :false-label="0"
                  >5、家庭成员失业情况：<el-input
                    :readonly="forminfo.SYQKXZ == 0"
                    v-model="forminfo.SYQK"
                    size="small"
                  >
                  </el-input
                ></el-checkbox>
              </el-col>
              <el-col style="margin: 5px 0">
                <el-checkbox
                  v-model="forminfo.QZQKXZ"
                  :true-label="1"
                  :false-label="0"
                  >6、家庭欠债情况：<el-input
                    :readonly="forminfo.QZQKXZ == 0"
                    v-model="forminfo.QZQK"
                    size="small"
                  >
                  </el-input
                ></el-checkbox>
              </el-col>
              <el-col style="margin: 5px 0">
                <el-checkbox
                  v-model="forminfo.QTQKXZ"
                  :true-label="1"
                  :false-label="0"
                  >7、其他情况：<el-input
                    :readonly="forminfo.QTQKXZ == 0"
                    v-model="forminfo.QTQK"
                    size="small"
                  >
                  </el-input
                ></el-checkbox>
              </el-col>
            </el-row>
            <span style="margin-top: 10px"
              >（注：1、请按实际情况勾选，并注明相应情况；2、请尽可能提供相应佐证材料。）</span
            >
          </td>
        </tr>
      </table>
    </el-card>
    <el-form-item label="附件" label-width="120px">
      <a
        v-for="(item, index) in fileList"
        :key="index"
        target="_blank"
        :href="downurl + 'File/download?id=' + item.id"
        >{{ item.name }}</a
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { getSQFormData } from '../../api/kns'
import { baseUrl } from '../../utils/http'
import { getStuAllDetailInfo } from '../../api/jcxx'
import moment from 'moment'
export default {
  props: {
    id: {
      // 显示隐藏
      type: String,
    },
    XH: {
      type: String,
    },
  },
  data() {
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      splist: [],
      keyword: '',
      keyValue: this.id,
      xh: this.XH,
      detail: {},
      detailTable: [],
      fromTable: [
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
      ],
      forminfo: {
        BMMC: '',
        ZYMC: '',
        BJMC: '',
        NJ: '',
        ID: '',
        PCID: '',
        XH: '',
        XM: '',
        XB: '',
        CSNY: '',
        JG: '',
        SFZH: '',
        JTRS: '',
        SJHM: '',
        YZBM: '',
        JTDZ: '',
        JZSJHM: '',
        HKXZ: 1,
        XFBZ: 0,
        ZSFBZ: 0,
        TSQTLX: '',
        JTRJNSR: null,
        JTRJNSRXZ: 0,
        ZRZH: '',
        ZRZHXZ: 0,
        TFYW: '',
        TFYWXZ: 0,
        NDLR: '',
        NDLRXZ: 0,
        SYQK: '',
        SYQKXZ: 0,
        QZQK: '',
        QZQKXZ: 0,
        QTQK: '',
        QTQKXZ: 0,
        SPZT: 0,
        RDZT: 0,
        jtcy: [],
        fj: [],
        TSQTLXList: [],
      },
    }
  },
  created() {
    this.handleDetail(this.keyValue)
  },
  methods: {
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat2(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleDetail() {
      getStuAllDetailInfo({ XH: this.xh }).then((res) => {
        if (res.code === 200) {
          this.forminfo.BMMC = res.data.bjmc
          this.forminfo.ZYMC = res.data.zymc
          this.forminfo.BJMC = res.data.bjmc
          this.forminfo.NJ = res.data.nj
        }
      })
      getSQFormData({ id: this.keyValue }).then((res) => {
        if (res.code === 200) {
          this.forminfo.ID = res.data.id
          this.forminfo.XH = res.data.xh
          this.forminfo.PCID = res.data.pcid
          this.forminfo.XM = res.data.xm
          this.forminfo.XB = res.data.xb
          this.forminfo.CSNY = this.dateFormat3(res.data.csny)
          this.forminfo.JG = res.data.jg
          this.forminfo.SFZH = res.data.sfzh
          this.forminfo.JTRS = res.data.jtrs
          this.forminfo.SJHM = res.data.sjhm
          this.forminfo.YZBM = res.data.yzbm
          this.forminfo.JTDZ = res.data.jtdz
          this.forminfo.JZSJHM = res.data.jzsjhm
          this.forminfo.HKXZ = res.data.hkxz
          this.forminfo.XFBZ = res.data.xfbz
          this.forminfo.ZSFBZ = res.data.zsfbz
          this.forminfo.JTRJNSR = res.data.jtrjnsr
          this.forminfo.JTRJNSRXZ = res.data.jtrjnsrxz
          this.forminfo.ZRZH = res.data.zrzh
          this.forminfo.ZRZHXZ = res.data.zrzhxz
          this.forminfo.TFYW = res.data.tfyw
          this.forminfo.TFYWXZ = res.data.tfywxz
          this.forminfo.NDLR = res.data.ndlr
          this.forminfo.NDLRXZ = res.data.ndlrxz
          this.forminfo.SYQK = res.data.syqk
          this.forminfo.SYQKXZ = res.data.syqkxz
          this.forminfo.QZQK = res.data.qzqk
          this.forminfo.QZQKXZ = res.data.qzqkxz
          this.forminfo.QTQK = res.data.qtqk
          this.forminfo.QTQKXZ = res.data.qtqkxz
          this.forminfo.SPZT = res.data.spzt
          this.forminfo.RDZT = res.data.rdzt
          this.forminfo.TSQTLXList = res.data.tsqtlx.split(',')
          this.fromTable = []
          for (var i = 0; i < res.data.jtcy.length; i++) {
            this.fromTable.push({
              XM: res.data.jtcy[i].xm,
              NL: res.data.jtcy[i].nl,
              GX: res.data.jtcy[i].gx,
              GZDW: res.data.jtcy[i].gzdw,
              ZY: res.data.jtcy[i].zy,
              NSR: res.data.jtcy[i].nsr,
              JKZK: res.data.jtcy[i].jkzk,
            })
          }
          if (this.fromTable.length < 4) {
            for (var j = this.fromTable.length - 1; j < 4; j++) {
              this.fromTable.push({
                XM: '',
                NL: null,
                GX: '',
                GZDW: '',
                ZY: '',
                NSR: null,
                JKZK: '',
              })
            }
          }
          var list = res.data.fj
          this.fileList = []
          for (var z = 0; z < list.length; z++) {
            this.fileList.push({ name: list[z].fileName, id: list[z].id })
          }
          this.splist = res.data.spjl
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
<style scoped>
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  font-variant: normal;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 12px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
