import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    routes: [],
  },
  mutations: {
    setroutes(state, routes) {
      state.routes = routes;
    }
  },
  getters: {
    routes: state => state.routes
  },
  actions: {
    setroutes({
      commit
    }, routes) {
      // state.routes = routes;
      commit("setroutes", routes);
    }
  },
  modules: {}
})
