import { get, post, upload, excel } from '../utils/http'
import store from '../store'
// 获取树形菜单
export function getModuleTree() {
  return get('system/module/GetModuleTree') // post('api/Authroize/GetToken', data);
}

// 根据父ID获取菜单
export function getModuleListByParentId(parentId, keyword) {
  return get('system/module/GetModuleListByParentId', {
    parentId: parentId,
    keyword: keyword
  }) // post('api/Authroize/GetToken', data);
}

// 根据父ID获取菜单
export function getFormData(keyValue) {
  return get('system/module/GetFormData', {
    keyValue: keyValue
  })
}

// 获取菜单栏树形下拉
export function getExpendModuleTree(keyValue) {
  return get('system/module/getExpendModuleTree', {})
}

// 获取菜单栏
export function getModuleList() {
  return get('system/module/GetModuleList', {})
}

// 获取菜单栏
export function saveModule(data) {
  return post('system/module/SaveForm', data)
}

// 删除菜单d
export function deleteForm(id) {
  return post('system/module/DeleteForm', {
    keyValue: id
  })
}

// 获取角色列表
export function getPageList(data) {
  return post('system/SY_Base_Role/GetPageList', data)
}

// 保存角色信息
export function saveForm(data) {
  return post('system/SY_Base_Role/saveForm', data)
}

// 根据ID获取角色信息
export function getRoleInfo(data) {
  return get('system/SY_Base_Role/GetRoleInfo', data)
}

// 删除角色信息
export function deleteRole(data) {
  return post('system/SY_Base_Role/DeleteForm', data)
}

// 获取角色授权信息
export function getSQFormData(data) {
  return post('system/SY_Base_Role/GetFormData', data)
}

// 获取角色树形选择授权项
export function getCheckTree(data) {
  return get('system/Module/GetCheckTree', data)
}

// 保存授权信息
export function authorizeSaveForm(data) {
  return post('system/SY_Base_Role/AuthorizeSaveForm', data)
}

// 获取用户列表
export function getUserPageList(data) {
  return post('system/sy_base_user/GetPageList', data)
}

// 修改密码
export function submitResetPassword(data) {
  return post('system/sy_base_user/SubmitResetPassword', data)
}

// 强制修改密码
export function qzsubmitResetPassword(data) {
  return post('system/sy_base_user/QZSubmitResetPassword', data)
}

// 获取用户信息
export function getUserInfo(data) {
  return get('system/sy_base_user/GetUserInfo', data)
}

// 获取角色列表
export function getRoleList(data) {
  return post('system/sy_base_role/GetList', data)
}

// 保存用户信息
export function saveUserInfo(data) {
  return post('system/sy_base_user/SaveForm', data)
}

// 获取用户信息
export function geteUserInfo(data) {
  return get('system/sy_base_user/GetFormData', data)
}

// 删除用户信息
export function deleteUserInfo(data) {
  return get('system/sy_base_user/DeleteForm', data)
}

// 获取按钮授权信息
export function getAuthorizeButtonColumnList(data) {
  return get('system/module/GetAuthorizeButtonColumnList', data)
}

// 上传头像
export function insertPicture(data) {
  return upload('system/sy_base_user/InsertPicture', data)
}

// 改变状态
export function changeState(data) {
  return post('system/module/ChangeState', data)
}

// 改变角色是否内置状态
export function rolechangeState(data) {
  return post('system/sy_base_role/ChangeState', data)
}

// 改变用户是否内置状态
export function userchangeState(data) {
  return post('system/sy_base_user/ChangeState', data)
}

// 获取日志列表
export function getLogPageList(data) {
  return post('system/log/GetPageList', data)
}

// 删除日志
export function saveRemoveLog(data) {
  return post('system/log/SaveRemoveLog', data)
}

// 导出数据
export function exportExcel(data) {
  return excel('system/log/ExportExcel', data)
}

// 获取学期列表
export function getTermPageList(data) {
  return get('system/SY_System_Term/GetPageList/', data)
}

// 保存学期信息
export function saveTermInfo(data) {
  return post('system/SY_System_Term/Save/', data)
}

// 根据ID获取学期信息
export function getTermInfo(data) {
  return get('system/SY_System_Term/GetFormData/', data)
}

// 删除学期信息
export function deleteTermInfo(data) {
  return get('system/SY_System_Term/Delete/', data)
}

// 获取当前学期
export function getDQXQ(data) {
  return get('/system/SY_System_Term/GetDQXQ', data)
}

// 清空缓存
export function qkhc(data) {
  return post('/Authroize/ClearCache', data)
}

// 获取学期
export function GetList() {
  return get('system/SY_System_Term/GetList/')
}
// 用户数据权限列表
export function getsjqxlist(data) {
  return get('/system/SY_Base_User_SJQX/GetQXList', data)
}
// 保存权限
export function saveqx(uid, data) {
  return post('/system/SY_Base_User_SJQX/SaveQX?uid=' + uid, data)
}
// 清空权限
export function clearqx(data) {
  return post('/system/SY_Base_User_SJQX/ClearQX', data)
}

// 获取角色树形
export function GetRoleTree(data) {
  return get('system/sy_base_role/GetRoleTree', data)
}
// 获取系统配置信息
export function getConfig() {
  var config = store.state.sysconfig
  if (config != null) {
    return new Promise(function(resolve, reject) {
      resolve({
        code: 200,
        data: config
      })
    })
  } else {
    return new Promise(function(resolve, reject) {
      get('system/sy_system_config/GetConfig').then(res => {
        config = res.data
        store.commit('setsysconfig', config)
        resolve({
          code: 200,
          data: config
        })
      })
    })
  }
}

// 获取当前学期
export function getXNList(data) {
  return get('/system/SY_System_Term/GetXNList', data)
}

// 获取当前学期
export function getCurrentXQ(data) {
  return get('/system/SY_System_Term/GetCurrentXQ', data)
}

// 获取学期
export function getXQList(data) {
  return get('/system/SY_System_Term/GetXQList', data)
}

// 获取年份
export function getNFList(data) {
  return get('/system/SY_System_Term/GetNFList/', data)
}

// 获取用户信息
export function getIndexData(data) {
  return get('/system/sy_base_user/GetIndexData/', data)
}
