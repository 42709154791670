import { get, post, excel } from '../utils/http'

// 获取校区列表
export function getXQList(data) {
  return get('jcxx/SY_JCXX_XQ/GetList/', data)
}

// 获取校区列表
export function getXQPageList(data) {
  return get('jcxx/SY_JCXX_XQ/GetPageList/', data)
}

// 保存校区信息
export function saveXQInfo(data) {
  return post('jcxx/SY_JCXX_XQ/Save/', data)
}

// 根据ID获取校区信息
export function getXQInfo(data) {
  return get('jcxx/SY_JCXX_XQ/GetFormData/', data)
}

// 删除校区信息
export function deleteXqInfo(data) {
  return get('jcxx/SY_JCXX_XQ/Delete/', data)
}

// 获取部门列表
export function getBMPageList(data) {
  return get('jcxx/SY_JCXX_BM/GetPageList/', data)
}

// 保存部门信息
export function saveBMInfo(data) {
  return post('jcxx/SY_JCXX_BM/Save/', data)
}

// 根据ID获取部门信息
export function getBMInfo(data) {
  return get('jcxx/SY_JCXX_BM/GetFormData/', data)
}

// 删除部门信息
export function deleteBmInfo(data) {
  return get('jcxx/SY_JCXX_BM/Delete/', data)
}

// 改变部门类别
export function bmchangeState(data) {
  return post('jcxx/SY_JCXX_BM/ChangeState', data)
}

// 获取专业列表
export function getZYList(data) {
  return get('jcxx/SY_JCXX_ZY/GetList/', data)
}

// 获取专业列表
export function getZYPageList(data) {
  return get('jcxx/SY_JCXX_ZY/GetPageList/', data)
}

// 保存专业信息
export function saveZYInfo(data) {
  return post('jcxx/SY_JCXX_ZY/Save/', data)
}

// 根据ID获取专业信息
export function getZYInfo(data) {
  return get('jcxx/SY_JCXX_ZY/GetFormData/', data)
}

// 删除专业信息
export function deleteZyInfo(data) {
  return get('jcxx/SY_JCXX_ZY/Delete/', data)
}

// 获取代码列表
export function getCodePageList(data) {
  return get('jcxx/SY_JCXX_CodeTable/GetPageList/', data)
}

// 保存代码信息
export function saveCodeInfo(data) {
  return post('jcxx/SY_JCXX_CodeTable/Save/', data)
}

// 根据ID获取代码信息
export function getCodeInfo(data) {
  return get('jcxx/SY_JCXX_CodeTable/GetFormData/', data)
}

// 删除代码信息
export function deleteCodeInfo(data) {
  return get('jcxx/SY_JCXX_CodeTable/Delete/', data)
}

// 获取所有部门列表
export function getAllBM(data) {
  return get('jcxx/SY_JCXX_BM/GetList/', data)
}

// 获取教学部门列表
export function getStuBM(data) {
  return get('jcxx/SY_JCXX_BM/GetStuBM/', data)
}

// 获取代码
export function getCode(data) {
  return get('jcxx/SY_JCXX_CodeTable/GetList/', data)
}

// 获取班级列表
export function getBJList(data) {
  return get('jcxx/SY_JCXX_BJ/GetList/', data)
}

// 获取班级列表
export function getBJPageList(data) {
  return get('jcxx/SY_JCXX_BJ/GetPageList/', data)
}

// 保存班级信息
export function saveBJInfo(data) {
  return post('jcxx/SY_JCXX_BJ/Save/', data)
}

// 根据ID获取班级信息
export function getBjInfo(data) {
  return get('jcxx/SY_JCXX_BJ/GetFormData/', data)
}

// 删除班级信息
export function deleteBjInfo(data) {
  return get('jcxx/SY_JCXX_BJ/Delete/', data)
}

// 获取教职工列表
export function getLSPageList(data) {
  return get('jcxx/SY_JCXX_LS/GetPageList/', data)
}

// 保存教职工信息
export function saveLSInfo(data) {
  return post('jcxx/SY_JCXX_LS/Save/', data)
}

// 根据ID获取教职工信息
export function getLSInfo(data) {
  return get('jcxx/SY_JCXX_LS/GetFormData/', data)
}

// 删除教职工信息
export function deleteLsInfo(data) {
  return post('jcxx/SY_JCXX_LS/Delete/', data)
}

// 获取学生列表
export function getXSPageList(data) {
  return get('jcxx/SY_JCXX_XS/GetPageList/', data)
}

// 保存学生信息
export function saveXSInfo(data) {
  return post('jcxx/SY_JCXX_XS/Save/', data)
}

// 根据ID获取学生信息
export function getXSInfo(data) {
  return get('jcxx/SY_JCXX_XS/GetFormData/', data)
}

// 删除学生信息
export function deleteXsInfo(data) {
  return get('jcxx/SY_JCXX_XS/Delete/', data)
}

// 根据XH获取学生信息
export function getStuAllDetailInfo(data) {
  return get('jcxx/SY_JCXX_XS/getStuAllDetailInfo/', data)
}

// 根据XH获取学生信息
export function getMyAllDetailInfo(data) {
  return get('jcxx/SY_JCXX_XS/getMyAllDetailInfo/', data)
}

// 获取辅导员班级信息
export function GetFDYBJList(data) {
  return get('jcxx/SY_JCXX_FDY_BJ/GetList/', data)
}

// 系部领导获取班级
export function GetBJListByXY(data) {
  return get('jcxx/SY_JCXX_BJ/GetBJListByXY/', data)
}

// 辅导员获取学生列表
export function getFDYCXXSPageList(data) {
  return get('jcxx/SY_JCXX_XS/GetFDYCXPageList/', data)
}

// 学院获取学生列表
export function getXYCXXSPageList(data) {
  return get('jcxx/SY_JCXX_XS/GetXYCXPageList/', data)
}

// 获取部门人员树形结构
export function GetTree(data) {
  return get('jcxx/SY_JCXX_BM/GetTree/', data)
}

// 获取部门树形结构
export function GetBMTree(data) {
  return get('jcxx/SY_JCXX_BM/GetBMTree/', data)
}

// 获取班级树形结构
export function GetBJTree(data) {
  return get('jcxx/SY_JCXX_BJ/GetBJTree/', data)
}
// 获取学生树形结构
export function GetXSTree(data) {
  return get('jcxx/SY_JCXX_XS/GetXSTree/', data)
}

// 获取人员树形结构
export function GetRYTree(data) {
  return get('jcxx/SY_JCXX_LS/GetRYTree/', data)
}

// 获取权限部门
export function GetListByQX(data) {
  return get('jcxx/SY_JCXX_BM/GetListByQX/', data)
}

// 获取权限专业
export function GetZYListByQX(data) {
  return get('jcxx/SY_JCXX_ZY/GetListByQX/', data)
}

// 获取权限班级
export function GetBJListByQX(data) {
  return get('jcxx/SY_JCXX_BJ/GetListByQX/', data)
}
// 获取部门》专业》班级 用于 tree
export function getdeptchildlist(data) {
  return get('jcxx/SY_JCXX_BM/GetDeptChildList/', data)
}
// 获取学生个人信息
export function getPersonInfo(data) {
  return get('jcxx/SY_JCXX_XS/GetPersonInfo/', data)
}

// 获取学生个人信息
export function getAllPersonInfo() {
  return get('jcxx/SY_JCXX_XS/GetAllPersonInfo/')
}

// 获取学生个人信息
export function getAllStuInfo(data) {
  return get('jcxx/SY_JCXX_XS/GetAllStuInfo/', data)
}

// 获取学生年级
export function getNJ(data) {
  return get('jcxx/SY_JCXX_XS/GetNJ/', data)
}

// 学院获取学生列表
export function getFDYPageList(data) {
  return get('jcxx/SY_JCXX_FDY/GetPageList/', data)
}

export function saveFDYBJ(data) {
  return post('jcxx/SY_JCXX_FDY/Save/', data)
}

// 保存辅导员信息
export function saveFDYInfo(data) {
  return post('jcxx/SY_JCXX_FDY/SaveFDY/', data)
}

// 根据ID获取学生信息
export function getFDYBJInfo(data) {
  return get('jcxx/SY_JCXX_FDY/GetFormData/', data)
}

// 删除学生信息
export function deleteFDYInfo(data) {
  return get('jcxx/SY_JCXX_FDY/Delete/', data)
}

// 获取省份
export function GetSF(data) {
  return get('jcxx/SY_SSX/GetSF/', data)
}

// 获取市
export function GetSZS(data) {
  return get('jcxx/SY_SSX/GetSZS/', data)
}

// 获取县
export function GetSZX(data) {
  return get('jcxx/SY_SSX/GetSZX/', data)
}

// 获取校区信息
export function getAllList(data) {
  return get('jcxx/SY_JCXX_XQ/GetAllList/', data)
}

// 获取在读年级
export function getZDNJ(data) {
  return get('jcxx/SY_JCXX_XS/GetZDNJ/', data)
}

// 获取学生信息首页初始数据
export function getXSIndexData(data) {
  return get('jcxx/SY_JCXX_XS/GetIndexData/', data)
}

// 根据学生信息
export function getXSInfoPageList(data) {
  return get('jcxx/SY_JCXX_XS/GetXSInfoPageList/', data)
}

// 导出学生信息
export function exportXSExcel(data) {
  return excel('jcxx/SY_JCXX_XS/ExportExcel/', data)
}

// 导出学生信息
export function exportWSExcel(data) {
  return excel('jcxx/SY_JCXX_XS/ExportWSExcel/', data)
}

// 获取学生详细信息
export function getXSDetailView(data) {
  return get('jcxx/SY_JCXX_XS/GetDetailView/', data)
}

// 批量保存学生
export function savePLXSInfo(data) {
  return post('jcxx/SY_JCXX_XS/PLSave/', data)
}

// 学生获取个人详细信息
export function getStuDetailView(data) {
  return get('jcxx/SY_JCXX_XS/GetStuDetailView/', data)
}

// 获取辅导员个人信息
export function getFDYPersonInfo(data) {
  return get('jcxx/SY_JCXX_FDY/GetMyInfo/', data)
}

// 生成地区代码
export function saveArea(data) {
  return post('jcxx/SY_JCXX_CodeTable/SaveArea/', data)
}

// 重置密码
export function czmm(data) {
  return post('jcxx/SY_JCXX_XS/CZMM/', data)
}

// 获取省市区县
export function getZXQH(data) {
  return get('https://restapi.amap.com/v3/config/district', data)
}

// 是否完善信息
export function SFWSXX(data) {
  return get('jcxx/SY_JCXX_XS/SFWSXX/', data)
}

// 审核
export function saveSH(data) {
  return post('jcxx/SY_JCXX_XS/saveSH/', data)
}

// 同步数据
export function tbsj() {
  return post('jcxx/SY_JCXX_lS/SJTB/')
}

// 重置密码
export function czmmls() {
  return post('jcxx/SY_JCXX_lS/CZMM/')
}
