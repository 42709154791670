<template>
  <div class="container">
    <el-result v-if="errmsg" icon="error" title="错误提示" :subTitle="errmsg">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="reset"
          >重新认证</el-button
        >
      </template>
    </el-result>
  </div>
</template>
<script>
import store from '../../store/index'
import storex from '../../store/vuex'
import { getModuleList } from '../../api/system'
import { router, routepage } from '../../router'
import { GetRHCAS, RHCASCallBack } from '../../api/login'
export default {
  data() {
    return {
      ticket: '',
      errmsg: null,
    }
  },
  created() {
    this.errmsg = null
    const loading = this.$loading({
      lock: true,
      text: '正在登录...',
      spinner: 'el-icon-loading',
    })
    var query = this.$route.query
    this.ticket = query.ticket
    if (this.ticket) {
      RHCASCallBack({ ticket: this.ticket }).then((res) => {
        loading.close()
        if (res.code === 200) {
          console.log(res)

          // 保存Token
          store.commit('setToken', res.data ? res.data : res)
          getModuleList()
            .then((res) => {
              var data = res.data
              var items = ['/index', '/profile']
              data.forEach((item) => {
                if (
                  item.f_UrlAddress.trim() !== '' &&
                  item.f_UrlAddress.trim() !== '&nbsp;'
                ) {
                  items.push(item.f_UrlAddress)
                }
              })
              var newroutes = []
              var rt = this.$router.options.routes
              routepage.forEach((item) => {
                items.forEach((n) => {
                  if (n === item.path) {
                    newroutes.push(item)
                  }
                })
              })
              rt[2].children = newroutes
              store.commit('setroutes', items)
              storex.commit('setroutes', items)
              router.addRoutes(rt)
              this.$message.success('登录成功')
              loading.close()
              this.$router.push('/home')
            })
            .catch((err) => {
              console.log(err)
              this.errmsg = '认证出现异常，请与管理员联系！'
            })
        } else {
          loading.close()
          // this.$router.push('/login')
          this.$message.error(res.message)
          this.errmsg = res.message
        }
      })
    } else {
      loading.close()
      // 参数错误
      this.$message.error('错误，缺少Ticket参数')
      this.errmsg = '缺少Ticket参数'
    }
  },
  methods: {
    reset() {
      var loading = this.$loading({
        lock: true,
        text: '正在进行认证...',
        spinner: 'el-icon-loading',
      })
      GetRHCAS()
        .then((res) => {
          loading.close()
          if (res.code === 200) {
            if (res.data.casurl) {
              location.href = res.data.casurl
            } else {
              this.errmsg = 'CAS认证地址不正确，请与管理员联系！'
            }
          } else {
            this.errmsg = res.message
          }
        })
        .catch(() => {
          loading.close()
          this.errmsg = 'CAS认证出现异常，请与管理员联系！'
        })
    },
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>
