import {
      excel,
    get, post
} from '../utils/http';
// 获取学生请假申请列表
export function getQJSQPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetPageList/', data);
}

// 保存学生请假申请信息
export function saveQJSQInfo(data) {
    return post('qjgl/SY_QJGL_QJSQ/Save/', data);
}

// 根据ID获取学生请假申请信息
export function getQJSQInfo(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetFormData/', data);
}

// 根据ID获取学生请假申请信息
export function getQJSQInfoTwo(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetFormDataTwo/', data);
}

// 根据ID获取学生请假申请信息
export function getQJSQInfoThree(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetFormDataThree/', data);
}

// 删除学生请假申请信息
export function deleteQJSQInfo(data) {
    return get('qjgl/SY_QJGL_QJSQ/Delete/', data);
}

// 判断学生请假申请信息
export function IsExitOld(data) {
    return get('qjgl/SY_QJGL_QJSQ/IsExitOld/', data);
}

// 根据申请ID获取审批记录
export function GetListBySQID(data) {
    return get('qjgl/SY_QJGL_QJSQ_SPJL/GetListBySQID/', data);
}

// 获取待辅导员审批学生请假申请列表
export function GetFDYSPPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetFDYSPPageList/', data);
}

// 保存学生请假申请信息
export function saveFDYSP(data) {
    return post('qjgl/SY_QJGL_QJSQ_SPJL/Save/', data);
}

// 获取待学院审批学生请假申请列表
export function GetXYSPPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetXYSPPageList/', data);
}

// 保存学生请假申请信息
export function saveXYSP(data) {
    return post('qjgl/SY_QJGL_QJSQ_SPJL/XYSave/', data);
}

// 获取待学工审批学生请假申请列表
export function GetXGSPPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetXGSPPageList/', data);
}

// 保存学生请假申请信息
export function saveXGSP(data) {
    return post('qjgl/SY_QJGL_QJSQ_SPJL/XGSave/', data);
}

// 销假
export function saveFDYXJ(data) {
    return get('qjgl/SY_QJGL_QJSQ/saveFDYXJ/', data);
}

// 销假
export function saveFDYXJ2(data) {
    return post('qjgl/SY_QJGL_QJSQ/saveFDYXJ2/', data);
}

// 获取待辅导员销假学生请假申请列表
export function GetFDYXJPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetFDYXJPageList/', data);
}

// 获取待辅导员销假学生请假申请列表
export function GetXJPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetXJPageList/', data);
}

// 获取辅导员查询学生请假申请列表
export function GetFDYCXPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetFDYCXPageList/', data);
}

// 获取学院查询学生请假申请列表
export function GetXYCXPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetXYCXPageList/', data);
}

// 获取学工查询学生请假申请列表
export function GetXGCXPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetXGCXPageList/', data);
}

// 获取学生请假申请列表
export function GetCXPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetCXPageList/', data);
}

// 获取请假管理流程
export function GetStep() {
    return get('qjgl/SY_QJGL_QJSQ/GetStep/');
}

// 提交违纪流程配置
export function LCPZSave(data) {
  return post('qjgl/SY_QJGL_QJSQ/LCPZSave/', data);
}

// 获取待审批列表
export function GetDSPPageList(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetDSPPageList/', data);
}

// 提交审批
export function saveSP(data) {
  return post('qjgl/SY_QJGL_QJSQ/saveSP/', data);
}

// 获取去向导出数据
export function exportSearchExcel(data) {
  return excel('/qjgl/SY_QJGL_QJSQ/ExportExcel/', data);
}

// 或取销假信息
export function getXJSQInfo(data) {
    return get('qjgl/SY_QJGL_QJSQ/GetXJSQFormData/', data);
}

// 获取学生请假延长申请列表
export function getYCPageList(data) {
    return get('qjgl/SY_QJGL_QJYC/GetPageList/', data);
}

// 提交请假延长审批
export function saveYCSP(data) {
  return post('qjgl/SY_QJGL_QJYC/SaveSP/', data);
}

// 获取学生请假延长申请详细信息
export function getYCInfo(data) {
    return get('qjgl/SY_QJGL_QJYC/GetFormData/', data);
}
