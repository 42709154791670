<!--辅导员销假-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">日常请假查询</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <span class="mr5 fcolor42">班级:</span>
        <el-select v-model="bjbh" size="small" clearable>
          <el-option
            v-for="(item, index) in bjlist"
            :key="index"
            :label="item.bjmc"
            :value="item.bjbh"
          ></el-option>
        </el-select>
        <span class="mr5 ml20 fcolor42">请假类型:</span>
        <el-select v-model="qjlx" size="small" clearable>
          <el-option
            v-for="(item, index) in cxlx"
            :key="index"
            :label="item.zdz"
            :value="item.zddm"
          ></el-option>
        </el-select>
        <span class="mr5 ml20 fcolor42">学号/姓名:</span>
        <el-input
          v-model="keyword"
          placeholder="请输入学号/姓名"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_detail')"
          icon="el-icon-delete"
          @click="detailInfo"
          >查看</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="150"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bjmc" label="班级" width="200">
        </el-table-column>
        <el-table-column prop="kssj" label="请假时间" width="350">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.kssj) + '至' + dateFormat2(scop.row.jssj) }}
          </template>
        </el-table-column>
        <el-table-column prop="zdz" label="事由类型" width="150">
        </el-table-column>
        <el-table-column prop="wcdd" label="外出地点"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="日常请假申请"
      :visible.sync="dialogDetailFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <sy-form
          ref="forminfo"
          v-if="dialogDetailFormVisible"
          :id="keyValue"
          :XH="xh"
          :ishowupload="false"
        ></sy-form>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<script>
import { GetFDYCXPageList, getQJSQInfo } from '../../../api/qjgl'
import { getStuAllDetailInfo, getCode, GetFDYBJList } from '../../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../../api/system'
import form from '../../../components/qjgl/qjform.vue'
import moment from 'moment'
export default {
  components: {
    'sy-form': form, // 挂载组件
  },
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      ksxq: '',
      jsxq: '',
      keyword: '',
      bjbh: '',
      qjlx: '',
      keyValue: '',
      cxlx: [],
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      xsinfo: {},
      bjlist: [], // 班级列表
      xh: '',
      ZT: 0,
      forminfo: {
        ID: '',
        XH: '',
        KSSJ: '',
        JSSJ: '',
        Total: 0,
        QJLX: '',
        QJSY: '',
        WCDD: '',
        TXRS: '',
        GDDH: '',
        YDDH: '',
        BRGX: '',
        LXRXM: '',
        ZT: 0,
        XJZT: 0,
      },
      spinfo: {
        SPYJ: '',
        SPZT: 1,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        SPYJ: [{ required: true, message: '请填写审批意见', trigger: 'blur' }],
        SPZT: [{ required: true, message: '请审批', trigger: 'blur' }],
      },
    }
  },
  created() {
    GetFDYBJList().then((res) => {
      if (res.code === 200) {
        this.bjlist = res.data
      }
    })
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
      .then((res) => {
        if (res.code === 200) {
          this.cxlx = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示编辑弹出框
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        this.getLXCode()
        getStuAllDetailInfo({ XH: this.xh }).then((res) => {
          if (res.code === 200) {
            this.xsinfo = res.data
          }
        })
        getQJSQInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XH = res.data.xh
            this.forminfo.KSSJ = res.data.kssj
            this.forminfo.JSSJ = res.data.jssj
            this.forminfo.Total = res.data.total
            this.forminfo.QJLX = res.data.qjlx
            this.forminfo.QJSY = res.data.qjsy
            this.forminfo.WCDD = res.data.wcdd
            this.forminfo.TXRS = res.data.txrs
            this.forminfo.YDDH = res.data.yddh
            this.forminfo.BRGX = res.data.brgx
            this.forminfo.LXRXM = res.data.lxrxm
            this.forminfo.ZT = res.data.zt
            this.forminfo.XJZT = res.data.xjzt
            this.forminfo.QJSJ = [
              new Date(res.data.kssj),
              new Date(res.data.jssj),
            ]
            this.dialogDetailFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.ZT = 0
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.ZT = currentRow.zt
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetFDYCXPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          qjlx: this.qjlx,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
        .then((res) => {
          if (res.code === 200) {
            this.code = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
