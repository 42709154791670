<!--
* 创建人：邓国伦
* 日 期：
* 描 述：用户选择
-->
<!--view 模板-->
<template>
  <div>
    <el-dialog
      title="选择人员"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-scrollbar style="height: 400px" class="myscrollbar">
        <sy-ryselect
          v-if="visible"
          ref="ryselect"
          :checkNodes="checkNodes"
          :checkKeys="checkKeys"
        ></sy-ryselect>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import ryselect from "../../components/common/ryselect.vue";
export default {
  props: {
    visible: {
      // 显示隐藏
      type: Boolean,
      default: false,
    },
    checkNodes: {
      type: Array,
    },
    checkKeys: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  components: {
    "sy-ryselect": ryselect,
  },
  methods: {
    ok() {
      this.$emit("ok", this.$refs.ryselect.mycheckNodes, this.$refs.ryselect.mycheckKeys);
     // this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<!-- CSS样式 -->
<style></style>
