import { post, get } from '../utils/http';

// 用户登陆
export function login(data) {
    // return post('connect/token', data);
    // var dat = { userna: data.username, mm: data.password }
    return post('Authroize/GetToken', JSON.stringify(data));
}

// 测试
export function test() {
    return get('Authroize/GetToken'); // post('api/Authroize/GetToken', data);
}

// 测试
export function getTokenByAuthCode(data) {
    return get('Authroize/GetTokenByAuthCode', data);
}
// 明厚天统一身份认证接口
export function GetMHTOAuth() {
  return get('Authroize/GetMHTOAuth');
}
export function MHTCallBack(data) {
  return get('Authroize/MHTCallBack', data);
}
// 仁华CAS认证接口
export function GetRHCAS() {
   return get('Authroize/GetRHCAS');
}
// 仁华CAS认证回调地址
export function RHCASCallBack(data) {
  return get('Authroize/RH_CAS_CallBack', data);
}
// 仁华CAS认证注销登录
export function RHCASLogOut(data) {
  return get('Authroize/RH_CAS_LogOut', data);
}
