import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex)
const vuexlocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'SY_STORE'
});

export default new Vuex.Store({
  state: {
    userinfo: '',
    token: '',
    routes: [],
    menuexpend: [], // 默认展开菜单项
    sysconfig: null, // 系统配置信息
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, userinfo) {
      state.userinfo = userinfo;
    },
    setroutes(state, routes) {
      state.routes = routes;
    },
    setmenuexpend(state, expend) {
      state.menuexpend = expend;
    },
    setsysconfig(state, expend) {
      state.sysconfig = expend;
    }
  },
  getters: {
    token: state => state.token,
    userinfo: state => state.userinfo,
    routes: state => state.routes,
    menuexpend: state => state.menuexpend,
    sysconfig: state => state.sysconfig

  },
  actions: {},
  modules: {},
  plugins: [vuexlocal.plugin]
})
