<!--
* 创建人：罗兴
* 日 期：
* 描 述：学生去向登记
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">节假日去向登记</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <sy-xueqi
          v-model="xqid"
          :selectdqxq="true"
          style="display: inline"
        ></sy-xueqi>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xueqi" label="学期" width="200">
        </el-table-column>
        <el-table-column prop="jqmc" label="假期名称" width="250">
        </el-table-column>
        <el-table-column prop="lxsj" label="离校时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.lxsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="fxsj" label="返校时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.fxsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="jqqx" label="假期去向"> </el-table-column>
        <el-table-column prop="brlxdh" label="本人联系电话"> </el-table-column>
        <el-table-column prop="jzlxdh" label="家长联系电话"> </el-table-column>
        <el-table-column prop="bz" label="备注"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import api from '../../api/jqgl'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xqid: null,
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
    }
  },
  watch: {},
  components: {
    'sy-xueqi': () => import('../../components/dropdown/dropdown_xueqi'),
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      api
        .getstuqxdjpagelist({
          queryJson: JSON.stringify({ xqid: this.xqid }),
          pagination: JSON.stringify(this.pagination),
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
