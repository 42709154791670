<template>
  <el-form :model="forminfo" ref="formRef">
    <el-card class="box-card">
      <span class="staffBmTitle"
        >普通本科高校、高等职业学校国家助学金申请表</span
      >
      <table border="1px" class="staffBmTable">
        <tr>
          <td rowspan="4" style="width: 30px">本人情况</td>
          <td style="width: 100px">姓名</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.XM"> </el-input>
            </el-form-item>
          </td>
          <td style="width: 100px">性别</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.XB"> </el-input>
            </el-form-item>
          </td>
          <td style="width: 100px">出生年月</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.CSNY">
              </el-input>
            </el-form-item>
          </td>
          <td rowspan="4" style="width: 150px">照片</td>
        </tr>
        <tr>
          <td>民族</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.MZ"> </el-input>
            </el-form-item>
          </td>
          <td>政治面貌</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="ZZMM"
            >
              <el-select v-model="forminfo.ZZMM" placeholder="请选择政治面貌">
                <el-option
                  v-for="(item, index) in zzmm"
                  :key="index"
                  :label="item.zdz"
                  :value="item.zdz"
                ></el-option>
              </el-select>
            </el-form-item>
          </td>
          <td>入学时间</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.RXSJ">
              </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>身份证号码</td>
          <td colspan="3">
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.SFZH">
              </el-input>
            </el-form-item>
          </td>
          <td>联系电话</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="LXDH"
            >
              <el-input size="small" v-model="forminfo.LXDH"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            {{ this.forminfo.XJXX }}
          </td>
        </tr>
        <tr>
          <td rowspan="3" style="width: 30px">家庭经济情况</td>
          <td>家庭户口</td>
          <td colspan="4">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="HKXZ"
            >
              <el-checkbox-group v-model="forminfo.HKXZ">
                <el-checkbox
                  label="城镇"
                  :true-label="1"
                  name="HKXZ"
                ></el-checkbox>
                <el-checkbox
                  label="农村"
                  :true-label="2"
                  name="HKXZ"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </td>
          <td>家庭人口总数</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="JTRS"
            >
              <el-input size="small" type="number" v-model="forminfo.JTRS">
              </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>家庭月总收入</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="JTYZSR"
            >
              <el-input size="small" type="number" v-model="forminfo.JTYZSR">
              </el-input>
            </el-form-item>
          </td>
          <td>人均月收入</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="RJYSR"
            >
              <el-input
                size="small"
                type="number"
                readonly
                v-model="forminfo.RJYSR"
              >
              </el-input>
            </el-form-item>
          </td>
          <td>收入来源</td>
          <td colspan="2">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="SRLY"
            >
              <el-input size="small" v-model="forminfo.SRLY"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>家庭住址</td>
          <td colspan="4">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="JTDZ"
            >
              <el-input size="small" v-model="forminfo.JTDZ"> </el-input>
            </el-form-item>
          </td>
          <td>邮政编码</td>
          <td colspan="2">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="YZBM"
            >
              <el-input size="small" v-model="forminfo.YZBM"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td style="width: 30px">家庭成员情况</td>
          <td colspan="8" style="padding: 0px">
            <el-table
              :data="fromTable"
              style="width: 100%"
              border
              :header-cell-style="{
                borderColor: '#000',
                color: '#303133',
                fontSize: '14px',
                fontWeight: 'normal',
                paddingTop: '5px',
                paddingBottom: '5px',
              }"
              :cell-style="{
                borderColor: '#000',
                paddingTop: '5px',
                paddingBottom: '5px',
              }"
            >
              <el-table-column prop="XM" label="姓名" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.XM" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="NL" label="年龄" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NL" type="number" size="small">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GX"
                label="与学生关系"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GX" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GZDW"
                label="工作（学习）单位"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GZDW" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="ZY" label="职业" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.ZY" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="NSR"
                label="年收入（元）"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NSR" type="number" size="small">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="JKZK"
                label="健康状况"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.JKZK" size="small"> </el-input>
                </template>
              </el-table-column>
            </el-table>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="SQLY"
            >
              <el-input
                size="small"
                type="textarea"
                v-model="forminfo.SQLY"
                placeholder="申请理由"
                :autosize="{ minRows: 8, maxRows: 12 }"
              >
              </el-input>
            </el-form-item>
          </td>
        </tr>
      </table>
    </el-card>
    <el-form-item label="附件" label-width="120px">
      <a
        v-for="(item, index) in fileList"
        :key="index"
        target="_blank"
        :href="downurl + 'File/download?id=' + item.id"
        >{{ item.name }}</a
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { getSQFormData } from '../../api/zxj'
import { baseUrl } from '../../utils/http'
import moment from 'moment'
export default {
  props: {
    id: {
      // 显示隐藏
      type: String,
    },
    XH: {
      type: String,
    },
  },
  data() {
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      splist: [],
      keyword: '',
      keyValue: this.id,
      xh: this.XH,
      detail: {},
      detailTable: [],
      fromTable: [
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
        { XM: '', NL: null, GX: '', GZDW: '', ZY: '', NSR: null, JKZK: '' },
      ],
      zzmm: [],
      forminfo: {
        BMMC: '',
        ZYMC: '',
        BJMC: '',
        NJ: '',
        ID: '',
        PCID: '',
        XH: '',
        XM: '',
        XB: '',
        CSNY: '',
        MZ: '',
        ZZMM: '',
        RXSJ: null,
        SFZH: '',
        LXDH: '',
        XJXX: '',
        HKXZ: '',
        JTRS: '',
        JTYZSR: '',
        RJYSR: '',
        SRLY: '',
        JTDZ: '',
        YZBM: '',
        SQLY: '',
        SPZT: 0,
        jtcy: [],
        fj: [],
      },
    }
  },
  created() {
    this.handleDetail(this.keyValue)
  },
  methods: {
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat2(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    // 时间格式化
    dateFormat4(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleDetail(id) {
      this.operation = 2
      getSQFormData({ id: id }).then((res) => {
        if (res.code === 200) {
          this.forminfo.ID = res.data.id
          this.forminfo.XH = res.data.xh
          this.forminfo.PCID = res.data.pcid
          this.forminfo.XM = res.data.xm
          this.forminfo.XB = res.data.xb
          this.forminfo.CSNY = this.dateFormat3(res.data.csny)
          this.forminfo.MZ = res.data.mz
          this.forminfo.ZZMM = res.data.zzmm
          this.forminfo.RXSJ = this.dateFormat4(res.data.rxsj)
          this.forminfo.SFZH = res.data.sfzh
          this.forminfo.LXDH = res.data.lxdh
          this.forminfo.XJXX = res.data.xjxx
          this.forminfo.HKXZ = res.data.hkxz
          this.forminfo.JTRS = res.data.jtrs
          this.forminfo.JTYZSR = res.data.jtyzsr
          this.forminfo.RJYSR = res.data.rjysr
          this.forminfo.SRLY = res.data.srly
          this.forminfo.JTDZ = res.data.jtdz
          this.forminfo.YZBM = res.data.yzbm
          this.forminfo.SQLY = res.data.sqly
          this.forminfo.SPZT = res.data.spzt
          this.fromTable = []
          for (var i = 0; i < res.data.jtcy.length; i++) {
            this.fromTable.push({
              XM: res.data.jtcy[i].xm,
              NL: res.data.jtcy[i].nl,
              GX: res.data.jtcy[i].gx,
              GZDW: res.data.jtcy[i].gzdw,
              ZY: res.data.jtcy[i].zy,
              NSR: res.data.jtcy[i].nsr,
              JKZK: res.data.jtcy[i].jkzk,
            })
          }
          if (this.fromTable.length < 4) {
            for (var j = this.fromTable.length - 1; j < 4; j++) {
              this.fromTable.push({
                XM: '',
                NL: null,
                GX: '',
                GZDW: '',
                ZY: '',
                NSR: null,
                JKZK: '',
              })
            }
          }
          var list = res.data.fj
          this.fileList = []
          for (var z = 0; z < list.length; z++) {
            this.fileList.push({ name: list[z].fileName, id: list[z].id })
          }
          this.splist = res.data.spjl
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
<style scoped>
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  font-variant: normal;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
