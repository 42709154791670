<template>
  <div class="stepcontainer">
    <div style="width: 100%; text-align: center; margin-bottom: 30px">
      <div class="step" v-for="(item, index) in steplist" :key="index">
        <div class="stepline" v-if="index > 0"></div>
        <div
          class="stepindex"
          :class="index == step ? 'active' : ''"
          @click="btnchangestep(index)"
        >
          <span>{{ index + 1 }}</span>
        </div>
      </div>
    </div>

    <el-carousel
      arrow="always"
      :autoplay="false"
      :loop="false"
      indicator-position="none"
      height="400px"
      ref="carousel"
      @change="changestep"
    >
      <el-carousel-item v-for="(item, index) in steplist" :key="index">
        <div class="title">第{{ index + 1 }}步</div>
        <div class="bzsm">步骤说明：{{ item.bzmc }}</div>
        <el-form label-position="top" label-width="80px">
          <el-row :gutter="40">
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">部门设置</span>
                <el-button type="text" @click="showBMDia">设置</el-button>
              </div>
              <div class="tag-warp">
                <el-tag
                  type="warning"
                  closable
                  @close="delbm(index)"
                  v-for="(item, index) in steplist[step].sqbm"
                  :key="index"
                  ><i class="fa fa-group">{{ item.bmmc }}</i></el-tag
                >
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">人员设置</span>
                <el-button type="text" @click="showRYDia">设置</el-button>
              </div>
              <div class="tag-warp">
                <el-tag
                  closable
                  @close="delry(index)"
                  v-for="(item, index) in steplist[step].sqry"
                  :key="index"
                  ><i class="fa fa-user">{{ item.xm }}</i></el-tag
                >
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">角色设置</span>
                <el-button type="text" @click="showRoleDia">设置</el-button>
              </div>
              <div class="tag-warp">
                <el-tag
                  type="success"
                  closable
                  @close="delrole(index)"
                  v-for="(item, index) in steplist[step].sqjs"
                  :key="index"
                  ><i class="fa fa-user-circle-o">{{ item.jsmc }}</i></el-tag
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-carousel-item>
    </el-carousel>
    <div class="btngroup">
      <el-button type="primary" @click="Save">提交保存</el-button>
    </div>
    <!-- <el-dialog
      title="选择机构系部/人员"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="false"
      width="50%"
      center
    >
      <el-scrollbar style="height: 500px" class="myscrollbar">
        <el-row :gutter="40">
          <el-col :span="12">
            <div class="diatitle">系部/人员</div>
            <el-tree
              :data="tree"
              :props="moduleProps"
              :expand-on-click-node="false"
              node-key="id"
              ref="tree"
              show-checkbox
              :check-strictly="true"
            >
              <span class="custom-tree-node" slot-scope="{ data }">
                <span>
                  <i :class="data.icon" style="margin-right: 5px"></i
                  >{{ data.text }}
                </span>
              </span>
            </el-tree>
          </el-col>
          <el-col :span="12">
            <div class="diatitle">已选择</div>
            <div class="yxzbox">
              <div
                class="yxzitem"
                v-for="(item, index) in stepdata[step].RYSZ"
                :key="index"
              >
                <div>
                  <i class="fa fa-group dia-i1" v-if="item.parentId == ''"></i>
                  <i class="fa fa-user dia-i2" v-if="item.parentId != ''"></i
                  >{{ item.text }}
                </div>
                <i class="el-icon-circle-close del-i" @click="delry(index)"></i>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <el-dialog
      title="选择机构系部"
      :visible.sync="bmDialogVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-scrollbar style="height: 500px" class="myscrollbar">
        <sy-bmselect
          v-if="bmDialogVisible"
          ref="bmselect"
          :checkNodes="steplist[step].sqbm"
          :checkKeys="steplist[step].sqbmStr"
        ></sy-bmselect>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bmDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bmDiaConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="选择角色"
      :visible.sync="roleDialogVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-scrollbar style="height: 500px" class="myscrollbar">
        <sy-roleselect
          v-if="roleDialogVisible"
          ref="roleselect"
          :checkNodes="steplist[step].sqjs"
          :checkKeys="steplist[step].sqjsStr"
        ></sy-roleselect>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="roleDiaConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="选择人员"
      :visible.sync="ryDialogVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-scrollbar style="height: 500px" class="myscrollbar">
        <sy-ryselect
          v-if="ryDialogVisible"
          ref="ryselect"
          :checkNodes="steplist[step].sqry"
          :checkKeys="steplist[step].sqryStr"
        ></sy-ryselect>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ryDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ryDiaConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bmselect from '../../components/common/bmselect.vue'
import roleselect from '../../components/common/roleselect.vue'
import ryselect from '../../components/common/ryselect.vue'
import { GetLCPZ, LCPZSave } from '../../api/wjcf'
export default {
  components: {
    'sy-bmselect': bmselect, // 挂载组件
    'sy-roleselect': roleselect,
    'sy-ryselect': ryselect,
  },
  props: {
    LX: {
      type: Number,
    },
  },
  data() {
    return {
      steplist: [{ sqbm: [], sqbmStr: [] }],
      centerDialogVisible: false,
      bmDialogVisible: false,
      roleDialogVisible: false,
      ryDialogVisible: false,
      tree: [],
      moduleProps: {
        children: 'childNodes',
        label: 'text',
      },
      step: 0,
    }
  },
  created() {
    GetLCPZ({ LX: this.LX }).then((res) => {
      if (res.code === 200) {
        this.steplist = res.data
      }
    })
  },
  methods: {
    changestep(curr, old) {
      this.step = curr
    },
    showBMDia() {
      this.bmDialogVisible = true
    },
    bmDiaConfirm() {
      const sqbm = this.$refs.bmselect.mycheckNodes
      this.steplist[this.step].sqbm = []
      for (var i = 0; i < sqbm.length; i++) {
        this.steplist[this.step].sqbm.push({
          bmbh: sqbm[i].id,
          bmmc: sqbm[i].text,
        })
      }
      this.steplist[this.step].sqbmStr = this.$refs.bmselect.mycheckKeys
      this.bmDialogVisible = false
    },
    delbm(index) {
      this.steplist[this.step].sqbmStr.splice(index, 1)
      this.steplist[this.step].sqbm.splice(index, 1)
    },
    showRoleDia() {
      this.roleDialogVisible = true
    },
    roleDiaConfirm() {
      const sqjs = this.$refs.roleselect.mycheckNodes
      this.steplist[this.step].sqjs = []
      for (var i = 0; i < sqjs.length; i++) {
        this.steplist[this.step].sqjs.push({
          id: sqjs[i].id,
          jsmc: sqjs[i].text,
        })
      }
      this.steplist[this.step].sqjsStr = this.$refs.roleselect.mycheckKeys
      this.roleDialogVisible = false
    },
    delrole(index) {
      this.steplist[this.step].sqjsStr.splice(index, 1)
      this.steplist[this.step].sqjs.splice(index, 1)
    },
    showRYDia() {
      this.ryDialogVisible = true
    },
    ryDiaConfirm() {
      const sqry = this.$refs.ryselect.mycheckNodes
      this.steplist[this.step].sqry = []
      for (var i = 0; i < sqry.length; i++) {
        this.steplist[this.step].sqry.push({
          jgh: sqry[i].id,
          xm: sqry[i].text,
        })
      }
      this.steplist[this.step].sqryStr = this.$refs.ryselect.mycheckKeys
      this.ryDialogVisible = false
    },
    delry(index) {
      this.steplist[this.step].sqryStr.splice(index, 1)
      this.steplist[this.step].sqry.splice(index, 1)
    },
    Save() {
      var data = []
      for (var i = 0; i < this.steplist.length; i++) {
        data.push({
          ID: this.steplist[i].id,
          BMBH: this.steplist[i].sqbmStr
            ? this.steplist[i].sqbmStr.toString()
            : '',
          JGH: this.steplist[i].sqryStr
            ? this.steplist[i].sqryStr.toString()
            : '',
          RoleID: this.steplist[i].sqjsStr
            ? this.steplist[i].sqjsStr.toString()
            : '',
          STEP: this.steplist[i].step,
          LX: this.steplist[i].lx,
          BZMC: this.steplist[i].bzmc,
        })
      }
      LCPZSave({ pcsz: data }).then((res) => {
        if (res.code === 200) {
          GetLCPZ({ LX: this.LX }).then((res) => {
            if (res.code === 200) {
              this.steplist = res.data
            }
            this.$message.success(res.message)
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    btnchangestep(index) {
      this.$refs.carousel.setActiveItem(index)
    },
  },
}
</script>

<style>
.stepcontainer {
  width: 800px;
  margin: 50px auto;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item {
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 60px;
}
.title {
  display: flex;
  justify-content: space-around;
  background: rgb(87, 97, 255);
  color: rgb(255, 255, 255);
  width: 211px;
  height: 40px;
  line-height: 40px;
  border-radius: 0px 0px 8px 8px;
  margin: 0 auto;
}
.ry-top-left {
  font-size: 14px;
  color: #606266;
}
.tag-warp {
  background: rgb(249, 249, 249);
  border-radius: 5px;
  padding: 10px;
  min-height: 50px;
}
.el-tag {
  margin-right: 10px;
  margin-top: 5px;
}
.diatitle {
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 20px;
}
.yxzbox {
  width: 100%;
}
.yxzitem {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dia-i1 {
  margin-right: 5px;
  color: #e6a23c;
}
.dia-i2 {
  margin-right: 8px;
  color: #409eff;
}
.del-i {
  color: red;
  font-size: 18px;
  cursor: pointer;
}
.btngroup {
  text-align: center;
  margin-top: 20px;
}
.bzsm {
  color: orange;
  margin: 10px 0;
}
.step {
  width: auto;
  display: inline-block;
}
.stepindex {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #909399;
  border-color: #909399;
  text-align: center;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
}
.stepindex:hover {
  background: #abadb1;
}
.active {
  background: #66b1ff;
  border: #66b1ff;
}
.active:hover {
  background: #91c3fd;
}
.stepline {
  width: 100px;

  display: inline-block;
  position: relative;
  top: -5px;
  border-top: 2px dashed #909399;
}
</style>
