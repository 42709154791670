<!--困难生认定-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">困难生认定库</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <span class="mr5 fcolor42">年份:</span>
        <el-select v-model="pcid" size="small" clearable>
          <el-option
            v-for="(item, index) in pclist"
            :key="index"
            :label="item.xn"
            :value="item.id"
          ></el-option>
        </el-select>
        <span class="mr5 ml20 fcolor42">班级:</span>
        <el-select v-model="bjbh" size="small" clearable>
          <el-option
            v-for="(item, index) in bjlist"
            :key="index"
            :label="item.bjmc"
            :value="item.bjbh"
          ></el-option>
        </el-select>
        <span class="mr5 ml20 fcolor42">学号/姓名:</span>
        <el-input
          v-model="keyword"
          placeholder="请输入学号/姓名"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_detail')"
          icon="el-icon-delete"
          @click="detailInfo"
          >查看</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号"> </el-table-column>
        <el-table-column prop="xm" label="姓名"> </el-table-column>
        <el-table-column prop="xbm" label="性别">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bjmc" label="班级"> </el-table-column>
        <el-table-column prop="kndj" label="认定等级">
          <template slot-scope="scope">
            {{
              scope.row.kndj == 1
                ? '一般困难'
                : scope.row.kndj == 2
                ? '困难'
                : '特别困难'
            }}
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="困难生认定"
      :visible.sync="dialogDetailFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <el-form
          :model="spinfo"
          v-if="dialogDetailFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <div class="form-item-group">
            <i class="fa fa-drivers-license-o"></i>
            <div>学生基础信息：</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学号" label-width="120px">
                <el-input v-model="xsinfo.xh"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" label-width="120px">
                <el-input v-model="xsinfo.xm"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别" label-width="120px">
                <el-radio v-model="xsinfo.xbm" label="1">男</el-radio>
                <el-radio v-model="xsinfo.xbm" label="2">女</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="院系" label-width="120px">
                <el-input v-model="xsinfo.bmmc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="专业" label-width="120px">
                <el-input v-model="xsinfo.zymc"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班级" label-width="120px">
                <el-input v-model="xsinfo.bjmc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="form-item-group">
            <i class="fa fa-calendar"></i>
            <div>学生家庭信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="人数" label-width="120px" prop="JTRS">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model="forminfo.JTRS"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="家庭人均年收入(元)"
                label-width="150px"
                prop="JTRJNSR"
              >
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model="forminfo.JTRJNSR"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item
                label="家庭通讯地址"
                label-width="120px"
                prop="JTDZ"
              >
                <el-input placeholder="请输入内容" v-model="forminfo.JTDZ">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="入学前户口" label-width="120px" prop="RXQHK">
                <el-radio v-model="forminfo.RXQHK" :label="1">城镇</el-radio>
                <el-radio v-model="forminfo.RXQHK" :label="2">农村</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮政编码" label-width="120px" prop="YZBM">
                <el-input placeholder="请输入内容" v-model="forminfo.YZBM">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系电话" label-width="120px" prop="LXDH">
                <el-input placeholder="请输入内容" v-model="forminfo.LXDH">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-table :data="fromTable" style="width: 100%">
            <el-table-column label="家庭成员情况" header-align="center">
              <el-table-column prop="XM" label="姓名" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.XM"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="NL" label="年龄" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NL" type="number"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GX"
                label="与学生关系"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GX"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="GZDW"
                label="工作（学习）单位"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.GZDW"> </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="ZY" label="职业" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.ZY"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="NSR"
                label="年收入（元）"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.NSR" type="number"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="JKZK"
                label="健康状况"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.JKZK"> </el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>

          <div class="form-item-group mt10">
            <i class="fa fa-address-book-o"></i>
            <div>家庭经济困难原因调查表</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="生源所在地" label-width="120px">
                <el-select v-model="forminfo.DQCB" style="width: 100%">
                  <el-option label="城市" :value="1"></el-option>
                  <el-option label="县城" :value="2"></el-option>
                  <el-option label="农村" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="多子女" label-width="120px">
                <el-select v-model="forminfo.DZN" style="width: 100%">
                  <el-option
                    label="除本人外兄弟姐妹中有2人及以上需父母供养"
                    :value="1"
                  ></el-option>
                  <el-option
                    label="除本人外兄弟姐妹中有1人需父母供养"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="除本人外无兄弟姐妹需父母供养"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="家人健康状况"
                label-width="120px"
                prop="JRJKQK"
              >
                <el-select v-model="forminfo.JRJKQK" style="width: 100%">
                  <el-option label="家人身体状况良好" :value="1"></el-option>
                  <el-option
                    label="家人身体状况偏差（患有慢性疾病，家庭年医药费总支出占家庭总收入25%以下）"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="家人身体状况极差（失去大部分劳动力或患有慢性疾病，家庭年医药费总支出占家庭总收入25%及以上）"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="父母收入" label-width="120px">
                <el-select v-model="forminfo.FMSR" style="width: 100%">
                  <el-option label="父母一方有稳定收入" :value="1"></el-option>
                  <el-option label="父母双方有稳定收入" :value="2"></el-option>
                  <el-option
                    label="父母双方均没有稳定收入"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="孤儿及单亲" label-width="120px">
                <el-select v-model="forminfo.GEJDQ" style="width: 100%">
                  <el-option label="孤儿" :value="1"></el-option>
                  <el-option
                    label="单亲家庭（父母离异-监护人为父亲或母亲）"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="单亲家庭（一方亡故-监护人为父亲或母亲）"
                    :value="3"
                  ></el-option>
                  <el-option
                    label="非单亲家庭或孤儿%及以上）"
                    :value="4"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="自然灾害情况"
                label-width="120px"
                prop="ZRZH"
              >
                <el-select v-model="forminfo.ZRZH" style="width: 100%">
                  <el-option
                    label="遭受自然灾害或家庭成员突患重病（与选项4非同类情况的突发严重疾病）"
                    :value="1"
                  ></el-option>
                  <el-option
                    label="城乡享受政府低保的家庭"
                    :value="2"
                  ></el-option>
                  <el-option
                    label="非低保家庭且未遭受重大变故"
                    :value="3"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="困难等级" label-width="120px" prop="KNDJ">
                <el-select v-model="forminfo.KNDJ" style="width: 100%">
                  <el-option label="一般困难" :value="1"></el-option>
                  <el-option label="困难" :value="2"></el-option>
                  <el-option label="特别困难" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="申请理由" label-width="120px" prop="RDLY">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="forminfo.RDLY"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="附件" label-width="120px">
            <a
              v-for="(item, index) in fileList"
              :key="index"
              target="_blank"
              :href="downurl + 'File/download?id=' + item.id"
              >{{ item.name }}</a
            >
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<script>
import {
  getFDYCXPageList,
  getSQFormData,
  getListAndCurrent,
} from '../../../api/kns'
import { getStuAllDetailInfo, GetFDYBJList } from '../../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../../api/system'
import { baseUrl } from '../../../utils/http'
import moment from 'moment'
export default {
  data() {
    return {
      downurl: baseUrl,
      fileList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      pcid: '',
      pclist: [],
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      bjbh: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      splist: [], // 审批记录
      xsinfo: {},
      bjlist: [], // 班级列表
      xh: '',
      fromTable: [],
      forminfo: {
        ID: '',
        XH: '',
        PCID: '',
        JTRS: null,
        JTRJNSR: null,
        JTDZ: '',
        RXQHK: 1,
        YZBM: '',
        LXDH: '',
        DQCB: null,
        DZN: null,
        JRJKQK: null,
        FMSR: null,
        GEJDQ: null,
        ZRZH: null,
        RDLY: '',
        SPZT: null,
        RDZT: null,
        KNDJ: null,
      },
      spinfo: {
        SPYJ: '',
        SPZT: 1,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
    }
  },
  created() {
    getListAndCurrent().then((res) => {
      if (res.code === 200) {
        this.pcid = res.data.pcid
        this.pclist = res.data.pclist
        this.getDataPageList()
      }
    })
    GetFDYBJList().then((res) => {
      if (res.code === 200) {
        this.bjlist = res.data
      }
    })
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示编辑弹出框
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getStuAllDetailInfo({ xh: this.xh }).then((res) => {
          if (res.code === 200) {
            this.xsinfo = res.data
          }
        })
        getSQFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XH = res.data.xh
            this.forminfo.PCID = res.data.pcid
            this.forminfo.JTRS = res.data.jtrs
            this.forminfo.JTRJNSR = res.data.jtrjnsr
            this.forminfo.JTDZ = res.data.jtdz
            this.forminfo.RXQHK = res.data.rxqhk
            this.forminfo.YZBM = res.data.yzbm
            this.forminfo.LXDH = res.data.lxdh
            this.forminfo.DQCB = res.data.dqcb
            this.forminfo.DZN = res.data.dzn
            this.forminfo.JRJKQK = res.data.jrjkqk
            this.forminfo.FMSR = res.data.fmsr
            this.forminfo.GEJDQ = res.data.gejdq
            this.forminfo.ZRZH = res.data.zrzh
            this.forminfo.RDLY = res.data.rdly
            this.forminfo.SPZT = res.data.spzt
            this.forminfo.RDZT = res.data.rdzt
            this.forminfo.KNDJ = res.data.kndj
            this.fromTable = []
            for (var i = 0; i < res.data.jtcy.length; i++) {
              this.fromTable.push({
                XM: res.data.jtcy[i].xm,
                NL: res.data.jtcy[i].nl,
                GX: res.data.jtcy[i].gx,
                GZDW: res.data.jtcy[i].gzdw,
                ZY: res.data.jtcy[i].zy,
                NSR: res.data.jtcy[i].nsr,
                JKZK: res.data.jtcy[i].jkzk,
              })
            }
            var list = res.data.fj
            this.fileList = []
            for (var z = 0; z < list.length; z++) {
              this.fileList.push({ name: list[z].fileName, id: list[z].id })
            }
            this.splist = res.data.spjl
            this.dialogDetailFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
        this.dialogDetailFormVisible = true
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getFDYCXPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          PCID: this.pcid,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
