<!--学生违纪处分申报-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生违纪管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学期:</span>
            <sy-xueqi
              v-model="xq"
              :selectdqxq="true"
              style="display: inline"
            ></sy-xueqi>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">处分类型:</span>
            <el-select v-model="CFLX" size="small" style="width: 200px">
              <el-option label="教学处分" :value="3"></el-option>
              <el-option label="行为习惯处分" :value="4"></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_sp')"
          icon="el-icon-edit"
          @click="showEdit"
          >审批/查看</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="150"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="cffwbh" label="发文编号" width="300">
        </el-table-column>
        <el-table-column prop="rdjgmc" label="认定结果" width="150">
        </el-table-column>
        <el-table-column prop="wjsj" label="违纪时间" width="150">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.wjsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="xq" label="学期" width="250"> </el-table-column>
        <el-table-column prop="spzt" label="审批状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.spzt === 1000" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 2000" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 1100" type="success"
              >系主任审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 1200" type="danger"
              >系主任审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 1110" type="success">{{
              scope.row.cflx == 1 ? '教学部部长审核通过' : '学工部部长审核通过'
            }}</el-tag>
            <el-tag v-if="scope.row.spzt === 1120" type="danger">{{
              scope.row.cflx == 1 ? '教学部部长审核拒绝' : '学工部部长审核拒绝'
            }}</el-tag>
            <el-tag v-if="scope.row.spzt === 1111" type="success"
              >分管副院长审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 1112" type="danger"
              >分管副院长审核拒绝</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="学生违纪处分"
      :visible.sync="dialogFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 600px" class="myscrollbar">
        <div class="form-item-group">
          <i class="fa fa-address-book-o"></i>
          <div>审批流程</div>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in splist"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 1000 || item.spzt == 2000">
                辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 1100 || item.spzt == 1200">
                系主任审批{{ item.spzt == 1100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 1110 || item.spzt == 1120">
                学工/教学部长审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 1111 || item.spzt == 1112">
                分管副院长审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <sy-form v-if="dialogFormVisible" :id="keyValue"></sy-form>
        <div class="form-item-group">
          <i class="fa fa-edit"></i>
          <div>申诉信息</div>
        </div>
        <el-form>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="申诉理由" label-width="120px">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="forminfo.SSLY"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form :model="spinfo" ref="formRef" :rules="forminfoRules">
          <div class="form-item-group">
            <i class="fa fa-window-restore"></i>
            <div>审批</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批意见" label-width="120px" prop="SPYJ">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="spinfo.SPYJ"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批" label-width="120px" prop="SPZT">
                <el-radio v-model="spinfo.SPZT" :label="1">审批通过</el-radio>
                <el-radio v-model="spinfo.SPZT" :label="0">审批拒绝</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetSSApprovalPendingPageList,
  saveWJSSSPInfo,
  GetWJSSSPJL,
  GetSSDetail,
} from '../../api/wjcf'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getCurrentXQ } from '../../api/system'
import moment from 'moment'
import form from '../../components/wjcf/diacxform.vue'
export default {
  components: {
    'sy-form': form, // 挂载组件
    'sy-xueqi': () => import('../../components/dropdown/xueqi_name'),
  },
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      CFLX: 3,
      xq: '',
      keyword: '',
      keyValue: '',
      ssid: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      xsinfo: {},
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      bmbh: '',
      zybh: '',
      bjbh: '',
      rdjglist: [], // 认定结果
      cfjglist: [], // 处分结果
      splist: [], // 审批列表
      xh: '',
      forminfo: {
        ID: '',
        CFID: '',
        SSLY: '',
        SPZT: 0,
      },
      spinfo: {
        SSID: '',
        SPYJ: '',
        SPZT: 1,
      },
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      forminfoRules: {
        SPYJ: [{ required: true, message: '请填写审批意见', trigger: 'blur' }],
        SPZT: [{ required: true, message: '请审批', trigger: 'blur' }],
      },
    }
  },
  created() {
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getCurrentXQ().then((res) => {
      if (res.code === 200) {
        this.xq =
          res.data.ksnf + '-' + res.data.jsnf + '学年第' + res.data.xq + '学期'
        this.getDataPageList()
      }
    })

    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存信息
    saveForm() {
      this.spinfo.SSID = this.ssid
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveWJSSSPInfo(this.spinfo).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功！')
              this.getDataPageList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        GetWJSSSPJL({ ssid: this.ssid }).then((res) => {
          if (res.code === 200) {
            this.splist = res.data
          }
        })
        GetSSDetail({ id: this.ssid }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.CFID = res.data.cfid
            this.forminfo.SSLY = res.data.ssly
            this.forminfo.SPZT = res.data.spzt
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
        this.dialogFormVisible = true
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.ssid = ''
        this.xh = ''
      } else {
        this.keyValue = currentRow.cfid
        this.ssid = currentRow.id
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetSSApprovalPendingPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          XQ: this.xq,
          CFLX: this.CFLX,
          ZYBH: this.zybh,
          BMBH: this.bmbh,
        }),
        pagination: JSON.stringify(this.pagination),
        LX: this.CFLX,
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 200px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
