<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div>
    <div class="content">
      <div class="ctitle">
        您的密码不符合系统安全要求，请修改密码后重新登陆<span style="color: red"
          >&nbsp;&nbsp;密码要求：数字+大小写字母+特殊字符（特殊字符：~、!、@、#、$、%、^）</span
        >
      </div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="原密码" prop="oldpass">
          <el-input
            type="password"
            v-model="ruleForm.oldpass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { qzsubmitResetPassword } from '../api/system'
import md5 from 'js-md5'
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          if (
            this.ruleForm.checkPass.length >= 8 &&
            this.ruleForm.checkPass.length <= 16
          ) {
            let a, b, c, d
            a = b = c = d = 0
            for (let i = 0; i < this.ruleForm.checkPass.length; i++) {
              if (
                this.ruleForm.checkPass[i] >= '0' &&
                this.ruleForm.checkPass[i] <= '9'
              ) {
                a = 1
              }
              if (
                this.ruleForm.checkPass[i] >= 'a' &&
                this.ruleForm.checkPass[i] <= 'z'
              ) {
                b = 1
              }
              if (
                this.ruleForm.checkPass[i] >= 'A' &&
                this.ruleForm.checkPass[i] <= 'Z'
              ) {
                c = 1
              }
              if (
                this.ruleForm.checkPass[i] === '~' ||
                this.ruleForm.checkPass[i] === '!' ||
                this.ruleForm.checkPass[i] === '@' ||
                this.ruleForm.checkPass[i] === '#' ||
                this.ruleForm.checkPass[i] === '$' ||
                this.ruleForm.checkPass[i] === '%' ||
                this.ruleForm.checkPass[i] === '^'
              ) {
                d = 1
              }
            }
            if (a + b + c + d < 4) {
              callback(
                new Error('密码长度必须为8-16位，并且数字+大小写字母+特殊字符')
              )
            }
          } else {
            callback(
              new Error('密码长度必须为8-16位，并且数字+大小写字母+特殊字符')
            )
          }
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        oldpass: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        oldpass: [{ validator: validatePass, trigger: 'blur' }],
        pass: [{ validator: validatePass1, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      },
    }
  },
  created() {},
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          qzsubmitResetPassword({
            zh: this.$route.query.zh,
            password: md5(this.ruleForm.pass),
            oldPassword: md5(this.ruleForm.oldpass),
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              localStorage.removeItem('SY_STORE')
              this.$router.push('/login')
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.content {
  width: 400px;
  margin: 100px auto;
}
.ctitle {
  margin: 20px auto;
  font-size: 20px;
}
</style>
