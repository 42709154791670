<!--学生请假申请-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">请假延长申请</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <span class="mr5 fcolor42">班级:</span>
        <el-select v-model="bjbh" size="small" clearable>
          <el-option
            v-for="(item, index) in bjlist"
            :key="index"
            :label="item.bjmc"
            :value="item.bjbh"
          ></el-option>
        </el-select>
        <span class="mr5 ml20 fcolor42">学号/姓名:</span>
        <el-input
          v-model="keyword"
          placeholder="请输入学号/姓名"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_sp')"
          icon="el-icon-delete"
          @click="detailInfo"
          >审批</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="150"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bjmc" label="班级" width="200">
        </el-table-column>
        <el-table-column prop="yjzsj" label="原截止时间">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.yjzsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="xjzsj" label="现截止时间">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.xjzsj) }}
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="日常请假延长申请"
      :visible.sync="dialogDetailFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <el-form
          :model="spinfo"
          v-if="dialogDetailFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <div class="form-item-group">
            <i class="fa fa-drivers-license-o"></i>
            <div>学生基础信息：</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学号" label-width="120px">
                <el-input v-model="xsinfo.xh"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" label-width="120px">
                <el-input v-model="xsinfo.xm"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别" label-width="120px">
                <el-radio v-model="xsinfo.xbm" label="1">男</el-radio>
                <el-radio v-model="xsinfo.xbm" label="2">女</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="院系" label-width="120px">
                <el-input v-model="xsinfo.bmmc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="专业" label-width="120px">
                <el-input v-model="xsinfo.zymc"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班级" label-width="120px">
                <el-input v-model="xsinfo.bjmc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-item-group">
            <i class="fa fa-calendar"></i>
            <div>请假信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="请假时间" label-width="120px">
                <el-date-picker
                  v-model="forminfo.QJSJ"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="请假类型" label-width="120px">
                <el-radio
                  v-for="(item, index) in code"
                  :key="index"
                  v-model="forminfo.QJLX"
                  :label="item.zddm"
                  >{{ item.zdz }}</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="请假事由" label-width="120px">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="forminfo.QJSY"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="外出地点" label-width="120px">
                <el-input
                  v-model="forminfo.WCDD"
                  placeholder="请输入外出地点"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="form-item-group">
            <i class="fa fa-address-book-o"></i>
            <div>外出联系人信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系人姓名" label-width="120px">
                <el-input
                  v-model="forminfo.LXRXM"
                  placeholder="请输入联系人姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="移动电话" label-width="120px">
                <el-input
                  v-model="forminfo.YDDH"
                  placeholder="请输入移动电话"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="本人关系" label-width="120px" prop="BRGX">
                <el-input
                  v-model="forminfo.BRGX"
                  placeholder="请输入外出联系人与本人关系"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本人电话" label-width="120px" prop="BRDH">
                <el-input
                  v-model="forminfo.BRDH"
                  placeholder="请输入本人电话"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="forminfo.fjlist.length > 0">
            <el-col :span="24">
              <el-form-item label="附件" label-width="120px">
                <div class="demo-image__preview">
                  <el-image
                    v-for="item in forminfo.fjlist"
                    :key="item.id"
                    style="width: 100px; height: 100px; margin-right: 10px"
                    :src="lookUrl + item.filePath"
                    :preview-src-list="new Array(lookUrl + item.filePath)"
                    fit="cover"
                  >
                  </el-image>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-item-group">
            <i class="fa fa-address-book-o"></i>
            <div>延长信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="现截止时间" label-width="120px">
                <el-input v-model="ycinfo.XJZSJ"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="申请理由" label-width="120px">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="ycinfo.YCLY"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-item-group">
            <i class="fa fa-window-restore"></i>
            <div>审批</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批意见" label-width="120px" prop="SPYJ">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="spinfo.SPYJ"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批" label-width="120px" prop="SPZT">
                <el-radio v-model="spinfo.SPZT" :label="1">审批通过</el-radio>
                <el-radio v-model="spinfo.SPZT" :label="0">审批拒绝</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDetailFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getQJSQInfoThree,
  GetListBySQID,
  saveYCSP,
  getYCPageList,
  getYCInfo,
} from '../../api/qjgl'
import { getStuAllDetailInfo, getCode, GetFDYBJList } from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import { baseUrl } from '../../utils/http'
import moment from 'moment'
export default {
  data() {
    return {
      lookUrl: baseUrl,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      ksxq: '',
      jsxq: '',
      keyword: '',
      bjbh: '',
      qjlx: '',
      keyValue: '',
      qjid: '',
      cxlx: [],
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      code: [],
      splist: [], // 审批记录
      xsinfo: {},
      bjlist: [], // 班级列表
      xh: '',
      ZT: 0,
      forminfo: {
        ID: '',
        XH: '',
        KSSJ: '',
        JSSJ: '',
        Total: 0,
        QJLX: '',
        QJSY: '',
        WCDD: '',
        TXRS: '',
        GDDH: '',
        YDDH: '',
        BRGX: '',
        LXRXM: '',
        BRDH: '',
        ZT: 0,
        XJZT: 0,
        fjlist: [],
      },
      ycinfo: {
        XJZSJ: '',
        YCLY: '',
      },
      spinfo: {
        ID: '',
        SPYJ: '',
        SPZT: 1,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        SPYJ: [{ required: true, message: '请填写审批意见', trigger: 'blur' }],
        SPZT: [{ required: true, message: '请审批', trigger: 'blur' }],
      },
    }
  },
  created() {
    GetFDYBJList().then((res) => {
      if (res.code === 200) {
        this.bjlist = res.data
      }
    })
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
      .then((res) => {
        if (res.code === 200) {
          this.cxlx = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存信息
    saveForm() {
      this.forminfo.ID = this.keyValue
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveYCSP({
            ID: this.keyValue,
            SPZT: this.spinfo.SPZT,
            SPYJ: this.spinfo.SPYJ,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogDetailFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 显示编辑弹出框
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        this.getLXCode()
        GetListBySQID().then((res) => {
          if (res.code === 200) {
            this.splist = res.data
          } else {
            this.splist = []
          }
        })
        getStuAllDetailInfo({ XH: this.xh }).then((res) => {
          if (res.code === 200) {
            this.xsinfo = res.data
          }
        })
        getQJSQInfoThree({ id: this.qjid }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.info.id
            this.forminfo.XH = res.data.info.xh
            this.forminfo.KSSJ = res.data.info.kssj
            this.forminfo.JSSJ = res.data.info.jssj
            this.forminfo.Total = res.data.info.total
            this.forminfo.QJLX = res.data.info.qjlx
            this.forminfo.QJSY = res.data.info.qjsy
            this.forminfo.WCDD = res.data.info.wcdd
            this.forminfo.TXRS = res.data.info.txrs
            this.forminfo.YDDH = res.data.info.yddh
            this.forminfo.BRGX = res.data.info.brgx
            this.forminfo.LXRXM = res.data.info.lxrxm
            this.forminfo.ZT = res.data.info.zt
            this.forminfo.XJZT = res.data.info.xjzt
            this.forminfo.BRDH = res.data.info.brdh
            this.forminfo.QJSJ = [
              new Date(res.data.info.kssj),
              new Date(res.data.info.jssj),
            ]
            this.forminfo.fjlist = res.data.fj
            this.dialogDetailFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
        getYCInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.spinfo.ID = res.data.id
            this.ycinfo.XJZSJ = moment(res.data.xjzsj).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            this.ycinfo.YCLY = res.data.ycly
          }
        })
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.qjid = ''
      } else {
        this.keyValue = currentRow.id
        this.ZT = currentRow.zt
        this.xh = currentRow.xh
        this.qjid = currentRow.qjid
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getYCPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          BJBH: this.bjbh,
          qjlx: this.qjlx,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    getLXCode() {
      getCode({ queryJson: JSON.stringify({ LXDM: 'XSQJLX' }) })
        .then((res) => {
          if (res.code === 200) {
            this.code = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
