<!--
* 创建人：罗兴
* 日 期：
* 描 述：添加学生
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="tool">
      <el-button
        v-if="cz==1&&xsinfo.SHZT==1"
        type="success"
        size="mini"
        @click="sh(2)"
      >审核通过</el-button>
      <el-button
        v-if="cz==1&&xsinfo.SHZT==1"
        type="danger"
        size="mini"
        @click="sh(3)"
      >审核不通过</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="back"
      >返回</el-button>
    </div>
    <el-scrollbar
      style="height: 90%; width: 100%"
      class="myscrollbar"
    >
      <div class="form">
        <el-form
          :model="xsinfo"
          ref="formRef"
          :rules="xsinfoRules"
        >
          <div class="bt">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="16">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="姓名"
                    label-width="120px"
                    prop="XM"
                  >
                    <el-input
                      v-model="xsinfo.XM"
                      autocomplete="off"
                      size="small"
                      readonly
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="性别"
                    label-width="120px"
                    prop="XB"
                  >
                    <el-input
                      v-model="xsinfo.XB"
                      autocomplete="off"
                      size="small"
                      readonly
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="身份证号"
                    label-width="120px"
                    prop="SFZH"
                  >
                    <el-input
                      v-model="xsinfo.SFZH"
                      autocomplete="off"
                      maxlength="20"
                      size="small"
                      readonly
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="出生日期"
                    label-width="120px"
                    prop="CSRQ"
                  >
                    <el-input
                      v-model="xsinfo.CSRQ"
                      autocomplete="off"
                      maxlength="20"
                      size="small"
                      readonly
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="籍贯"
                    label-width="120px"
                    prop="JG"
                  >
                    <el-input
                      v-model="xsinfo.JG"
                      autocomplete="off"
                      maxlength="20"
                      size="small"
                      readonly
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="民族"
                    label-width="120px"
                    prop="MZ"
                  >
                    <el-input
                      v-model="xsinfo.MZ"
                      autocomplete="off"
                      maxlength="20"
                      size="small"
                      readonly
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="政治面貌"
                    label-width="120px"
                    prop="ZZMM"
                  >
                    <el-input
                      v-model="xsinfo.ZZMM"
                      autocomplete="off"
                      maxlength="20"
                      size="small"
                      readonly
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row><el-row>
              </el-row>
            </el-col>
            <el-col :span="8">
              <el-upload
                class="avatar-uploader"
                disabled
                :show-file-list="false"
                :headers="headers"
                :action="uploadurl"
              >
                <img
                  v-if="xsinfo.imgurl"
                  :src="xsinfo.imgurl"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
              </el-upload>
            </el-col>
          </el-row>
          <div class="bt">在校信息</div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="考生号"
                label-width="120px"
                prop="KSH"
              >
                <el-input
                  v-model="xsinfo.KSH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="学号"
                label-width="120px"
                prop="XH"
              >
                <el-input
                  v-model="xsinfo.XH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="年级"
                label-width="120px"
                prop="NJ"
              >
                <el-input
                  v-model="xsinfo.NJ"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="入学年级"
                label-width="120px"
                prop="RXNJ"
              >
                <el-select
                  v-model="xsinfo.RXNJ"
                  style="display: block"
                  size="small"
                  readonly
                >
                  <el-option
                    v-for="(item, index) in nflist"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="学院"
                label-width="120px"
                prop="BMBH"
              >
                <el-input
                  v-model="xsinfo.BMMC"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="专业"
                label-width="120px"
                prop="ZYBH"
              >
                <el-input
                  v-model="xsinfo.ZYMC"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="班级"
                label-width="120px"
                prop="BJBH"
              >
                <el-input
                  v-model="xsinfo.BJMC"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="学籍状态"
                label-width="120px"
                prop="ZDZTM"
              >
                <el-input
                  v-model="xsinfo.ZDZT"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="房间号"
                label-width="120px"
                prop="FJBH"
              >
                <el-input
                  v-model="xsinfo.FJBH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bt">联系方式</div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="联系电话"
                label-width="120px"
                prop="LXDH"
              >
                <el-input
                  v-model="xsinfo.LXDH"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="QQ号"
                label-width="120px"
                prop="QQ"
              >
                <el-input
                  v-model="xsinfo.QQ"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="电子邮箱"
                label-width="120px"
                prop="DZYX"
              >
                <el-input
                  v-model="xsinfo.DZYX"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bt">家庭信息</div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="父亲姓名"
                label-width="120px"
                prop="FQXM"
              >
                <el-input
                  v-model="xsinfo.FQXM"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="父亲联系方式"
                label-width="120px"
                prop="FQLXFS"
              >
                <el-input
                  v-model="xsinfo.FQLXFS"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="母亲姓名"
                label-width="120px"
                prop="MQXM"
              >
                <el-input
                  v-model="xsinfo.MQXM"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col> </el-row><el-row>
            <el-col :span="8">
              <el-form-item
                label="母亲联系方式"
                label-width="120px"
                prop="MQLXFS"
              >
                <el-input
                  v-model="xsinfo.MQLXFS"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item
                label="家庭地址"
                label-width="120px"
                prop="JTDZ"
              >
                <el-input
                  v-model="xsinfo.JTDZ"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-scrollbar>
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getCode,
  getStuBM,
  getZYList,
  getBJList,
  getXSDetailView,
  saveSH,
} from '../../api/jcxx'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
import { getNFList } from '../../utils/nllist.js'
export default {
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        const isPhone = reg.test(value)
        value = Number(value) // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          // 判断是否为数字
          value = value.toString() // 转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            // 判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'))
          } else {
            callback()
          }
        } else {
          callback(new Error('请输入电话号码'))
        }
      }
    }
    return {
      cz: 0,
      flag: 0,
      xsinfo: {
        XM: '',
        XBM: '',
        SFZH: '',
        CSRQ: '',
        JG: '',
        MZM: '',
        ZZMMM: '',
        LXDH: '',
        XX: '',
        imgurl: '',
        XH: '',
        NJ: '',
        BMBH: '',
        ZYBH: '',
        BJBH: '',
        ZDZTM: '',
        SFJBX: null,
        SFZX: null,
        FJBH: '',
        QQ: '',
        DZYX: '',
        FQXM: '',
        FQLXFS: '',
        MQXM: '',
        MQLXFS: '',
        JTDZ: '',
        HJDZ: '',
        RXNJ: '',
        KSH: '',
        RXCJ: null,
        YXX: '',
        BYRQ: null,
        GHYHKH: '',
        JHYHKH: '',
        SFWCBYDJ: null,
        BYDJH: '',
        SFTYJR: null,
      },
      mzm: [],
      zzmm: [],
      zdzt: [],
      nflist: [],
      bmlist: [],
      zylist: [],
      bjlist: [],
      // 表单验证规则对象
      xsinfoRules: {
        KSH: [{ required: true, message: '请输入考生号', trigger: 'blur' }],
        XH: [{ required: true, message: '请输入学号', trigger: 'blur' }],
        XM: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        ZYBH: [{ required: true, message: '请选择专业', trigger: 'blur' }],
        BJBH: [{ required: true, message: '请选择班级', trigger: 'blur' }],
        NJ: [{ required: true, message: '请选择年级', trigger: 'blur' }],
        RXNJ: [{ required: true, message: '请选择入学年级', trigger: 'blur' }],
        BYNF: [{ required: true, message: '请选择毕业年份', trigger: 'blur' }],
        ZDZTM: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        LXDH: [{ validator: isMobileNumber, trigger: 'blur' }],
        JZLXDH: [{ validator: isMobileNumber, trigger: 'blur' }],
        SFZH: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
        DZYX: [
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
      },
      headers: null,
      uploadurl: baseUrl + 'File/uploadImg',
      baseurl: baseUrl,
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    if (this.$route.query.cz === 'sh') {
      this.cz = 1
    }
    getCode({ queryJson: JSON.stringify({ LXDM: 'MZM' }) })
      .then((res) => {
        if (res.code === 200) {
          this.mzm = res.data
        } else {
          this.mzm = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.mzm = []
      })
    getCode({ queryJson: JSON.stringify({ LXDM: 'ZZMMM' }) })
      .then((res) => {
        if (res.code === 200) {
          this.zzmm = res.data
        } else {
          this.zzmm = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.zzmm = []
      })
    getCode({ queryJson: JSON.stringify({ LXDM: 'XJZTM' }) })
      .then((res) => {
        if (res.code === 200) {
          this.zdzt = res.data
        } else {
          this.zdzt = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.zdzt = []
      })
    this.nflist = getNFList()
    getStuBM()
      .then((res) => {
        if (res.code === 200) {
          this.bmlist = res.data
        } else {
          this.bmlist = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.bmlist = []
      })
    getXSDetailView({ xh: this.$route.query.xh }).then((res) => {
      if (res.code === 200) {
        this.xsinfo.ID = res.data.id
        this.xsinfo.XM = res.data.xm
        this.xsinfo.XB = res.data.xbm === '1' ? '男' : '女'
        this.xsinfo.SFZH = res.data.sfzh
        this.xsinfo.CSRQ = res.data.csrq ? res.data.csrq.substring(0, 10) : ''
        this.xsinfo.JG = res.data.jg
        this.xsinfo.MZ = res.data.mz
        this.xsinfo.ZZMM = res.data.zzmm
        this.xsinfo.LXDH = res.data.lxdh
        this.xsinfo.XX = res.data.xx
        this.xsinfo.imgurl =
          this.baseurl.substring(0, this.baseurl.length - 1) + res.data.imgurl
        this.xsinfo.XH = res.data.xh
        this.xsinfo.NJ = res.data.nj
        this.xsinfo.BMMC = res.data.bmmc
        this.xsinfo.ZYMC = res.data.zymc
        this.xsinfo.BJMC = res.data.bjmc
        this.xsinfo.ZDZT = res.data.zdzt
        this.xsinfo.SFJBX = res.data.sfjbx
        this.xsinfo.SFZX = res.data.sfzx
        this.xsinfo.FJBH = res.data.fjbh
        this.xsinfo.QQ = res.data.qq
        this.xsinfo.DZYX = res.data.dzyx
        this.xsinfo.FQXM = res.data.fqxm
        this.xsinfo.FQLXFS = res.data.fqlxfs
        this.xsinfo.MQXM = res.data.mqxm
        this.xsinfo.MQLXFS = res.data.mqlxfs
        this.xsinfo.JTDZ = res.data.jtdz
        this.xsinfo.HJDZ = res.data.hjdz
        this.xsinfo.RXNJ = res.data.rxnj
        this.xsinfo.KSH = res.data.ksh
        this.xsinfo.RXCJ = res.data.rxcj
        this.xsinfo.YXX = res.data.yxx
        this.xsinfo.BYRQ = res.data.byrq
        this.xsinfo.GHYHKH = res.data.ghyhkh
        this.xsinfo.JHYHKH = res.data.jhyhkh
        this.xsinfo.SFWCBYDJ = res.data.sfwcbydj
        this.xsinfo.BYDJH = res.data.bydjh
        this.xsinfo.SFTYJR = res.data.sftyjr
        this.xsinfo.SHZT = res.data.shzt
        this.flag = 1
      }
    })
  },
  methods: {
    back() {
      // this.$router.push({
      //   path: '/jcxx/sy_xsinfo/index',
      // })
      this.$router.go(-1)
    },
    sh(shzt) {
      saveSH({ ID: this.xsinfo.ID, SHZT: shzt }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    bmchange() {
      getZYList({ queryJson: JSON.stringify({ BMBH: this.xsinfo.BMBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.zylist = res.data
          } else {
            this.zylist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zylist = []
        })
    },
    zychange() {
      getBJList({ queryJson: JSON.stringify({ ZYBH: this.xsinfo.ZYBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.bjlist = res.data
          } else {
            this.bjlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bjlist = []
        })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}
.tool {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  background: white;
  min-width: 600px;
}
.form {
  width: 1240px;
  margin: 0 auto;
  border: 1px solid #e2e4e5;
  padding: 16px;
  border-radius: 6px;
  background: white;
  min-height: 20px;
}
.bt {
  background-color: #eeeeee;
  padding: 5px;
  font-weight: 700;
}
>>> .el-upload {
  /* border: 1px dashed #d9d9d9; */
  margin: 20px 0 0 120px;
  border: 1px solid #8c939d !important;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 126px;
  height: 180px;
  line-height: 180px;
  text-align: center;
}
.avatar {
  width: 126px;
  height: 180px;
  display: block;
}
</style>
