<template>
  <el-tree
    :data="tree"
    :props="moduleProps"
    :expand-on-click-node="false"
    node-key="id"
    ref="tree"
    @check-change="handleClick"
    show-checkbox
    :check-strictly="true"
  >
    <span class="custom-tree-node" slot-scope="{ data }">
      <span>
        <i :class="data.icon" style="margin-right: 5px"></i>{{ data.text }}
      </span>
    </span>
  </el-tree>
</template>
<script>
import { GetRoleTree } from '../../api/system'
export default {
  props: {
    checkNodes: {
      type: Array,
    },
    checkKeys: {
      type: Array,
    },
  },
  data() {
    return {
      moduleProps: {
        children: 'childNodes',
        label: 'text',
      },
      tree: [],
      mycheckNodes: this.checkNodes,
      mycheckKeys: this.checkKeys,
    }
  },
  created() {
    GetRoleTree().then((res) => {
      if (res.code === 200) {
        this.tree = res.data
        if (this.mycheckKeys) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(this.mycheckKeys) // 给树节点赋值
          })
        }
      }
    })
  },
  methods: {
    // 点击节点
    handleClick() {
      this.mycheckNodes = this.$refs.tree.getCheckedNodes()
      this.mycheckKeys = this.$refs.tree.getCheckedKeys()
    },
  },
}
</script>
<style scoped>
</style>
