import {
  excel,
  post, get,
} from '../utils/http';

// 获取已提交列表
export function getPostPageList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetPageList/', data);
}

// 提交违纪申请
export function saveWJSBInfo(data) {
  return post('wjcf/SY_WJCF_WJCFSB/Save/', data);
}

// 获取信息
export function GetPostDetail(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetDetail/', data);
}

// 获取待审批信息
export function GetApprovalPendingPageList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetApprovalPendingPageList/', data);
}

// 获取待审批信息
export function GetSSApprovalPendingPageList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetSSApprovalPendingPageList/', data);
}

// 获取待审批信息
export function GetJCApprovalPendingPageList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetJCApprovalPendingPageList/', data);
}

// 获取处分数据
export function GetManagePageList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetManagePageList/', data);
}

// 获取处分数据
export function GetSearchList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetSearchList/', data);
}

// 提交违纪
export function ManageSave(data) {
  return post('wjcf/SY_WJCF_WJCFSB/ManageSave/', data);
}

// 获取流程配置
export function GetLCPZ(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetLCPZ/', data);
}

// 提交违纪流程配置
export function LCPZSave(data) {
  return post('wjcf/SY_WJCF_WJCFSB/LCPZSave/', data);
}

// 提交违纪申报
export function saveWJSBSPInfo(data) {
  return post('wjcf/SY_WJCF_WJCFSB/saveWJSBSPInfo/', data);
}

// 提交违纪申诉审批
export function saveWJSSSPInfo(data) {
  return post('wjcf/SY_WJCF_WJCFSB/saveWJSSSPInfo/', data);
}

// 提交违纪解除审批
export function saveWJJCSPInfo(data) {
  return post('wjcf/SY_WJCF_WJCFSB/saveWJJCSPInfo/', data);
}

// 获取违纪申报审批记录
export function GetWJSBSPJL(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetWJSBSPJL', data);
}

// 获取违纪申诉审批记录
export function GetWJSSSPJL(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetWJSSSPJL', data);
}

// 获取违纪处分可申诉列表
export function GetAllowApplyList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetAllowApplyList', data);
}

// 获取违纪处分已申诉列表
export function GetApplayList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetApplayList', data);
}

// 提交违纪申诉
export function SaveSS(data) {
  return post('wjcf/SY_WJCF_WJCFSB/SaveSS/', data);
}

// 获取违纪处分申诉详情
export function GetSSDetail(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetSSDetail', data);
}

// 获取违纪处分可申请解除列表
export function GetApplyDeleteList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetApplyDeleteList', data);
}

// 获取违纪处分已申请解除列表
export function GetJCApplayList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetJCApplayList', data);
}

// 提交违纪解除申请
export function SaveJC(data) {
  return post('wjcf/SY_WJCF_WJCFSB/SaveJC/', data);
}

// 获取违纪处分解除申请详情
export function GetJCDetail(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetJCDetail', data);
}

// 获取违纪处分解除申请审批记录
export function GetWJJCSPJL(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetWJJCSPJL', data);
}

// 获取违纪处分列表信息
export function GetStuSearchList(data) {
  return get('wjcf/SY_WJCF_WJCFSB/GetStuSearchList', data);
}

// 获取去向导出数据
export function exportSearchExcel(data) {
  return excel('/wjcf/SY_WJCF_WJCFSB/ExportExcel/', data);
}
