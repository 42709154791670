<!--
* 创建人：罗兴
* 日 期：
* 描 述：学生信息管理
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生管理</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button
        type="primary"
        size="mini"
        @click="toadd"
        v-if="hasPerms('sy_add')"
      >新增</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="toedit"
        v-if="hasPerms('sy_edit')"
      >修改</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="showdia"
        v-if="hasPerms('sy_pledit')"
      >批量修改</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="tocx"
      >查看详情</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="exportToExcel"
        v-if="hasPerms('sy_export')"
      >导出Excel</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="czmm"
        v-if="hasPerms('sy_czmm')"
      >重置密码</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="search"
      >查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">学号</div>
          <el-input
            size="mini"
            v-model="xh"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">姓名</div>
          <el-input
            size="mini"
            v-model="xm"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">身份证号</div>
          <el-input
            size="mini"
            v-model="sfzh"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">年级</div>
          <el-select
            v-model="nj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in njlist"
              :key="index"
              :label="item.nj"
              :value="item.nj"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">二级学院</div>
          <el-select
            v-model="bm"
            style="display: block; width: 178px"
            size="mini"
            @change="bmchange"
            clearable
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业名称</div>
          <el-select
            v-model="zy"
            style="display: block; width: 178px"
            size="mini"
            @change="zychange"
            clearable
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级名称</div>
          <el-select
            v-model="bj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">学籍状态</div>
          <el-select
            v-model="xjzt"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in xjztlist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">政治面貌</div>
          <el-select
            v-model="zzmm"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in zzmmlist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">民族</div>
          <el-select
            v-model="mz"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in mzlist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">联系电话</div>
          <el-input
            size="mini"
            v-model="lxdh"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">辅导员</div>
          <el-input
            size="mini"
            v-model="fdy"
          ></el-input>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">籍贯</div>
          <el-cascader
            :props="{ checkStrictly: true }"
            size="mini"
            v-model="JGFORM"
            :options="xzqhList"
            @change="xzqhhandleChange"
            style="display: block; width: 430px"
            clearable
          ></el-cascader>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">家庭地址</div>
          <el-cascader
            :props="{ checkStrictly: true }"
            size="mini"
            v-model="JTDZFORM"
            :options="xzqhList"
            @change="jtdzhandleChange"
            style="display: block; width: 430px"
            clearable
          ></el-cascader>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 300"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
        @selection-change="plselchange"
      >
        <el-table-column
          type="selection"
          width="55"
        > </el-table-column>
        <el-table-column
          prop="xjzt"
          label="学籍状态"
          width="100"
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          prop="ksh"
          label="考生号"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="xh"
          label="学号"
          width="150"
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          prop="xm"
          label="姓名"
          width="100"
          fixed="left"
        >
        </el-table-column><el-table-column
          prop="xbm"
          label="性别"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.xbm == '1' ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="nj"
          label="年级"
          width="100"
        > </el-table-column>

        <el-table-column
          prop="lxdh"
          label="联系电话"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="csrq"
          label="出生日期"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="sfzh"
          label="身份证号"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="mz"
          label="民族"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="zzmm"
          label="政治面貌"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="bmmc"
          label="二级学院"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="zymc"
          label="专业名称"
          width="350"
        >
        </el-table-column>
        <el-table-column
          prop="bjmc"
          label="班级名称"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="xz"
          label="学制"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="fdy"
          label="辅导员"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="sfzx"
          label="是否住校"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.sfzx == 1"
              effect="dark"
            > 是 </el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.sfzx == 0"
              effect="dark"
            >
              否
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="fqxm"
          label="父亲姓名"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="fqlxfs"
          label="父亲联系方式"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="mqxm"
          label="母亲姓名"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="mqlxfs"
          label="母亲联系方式"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="rxcj"
          label="入学成绩"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="xx"
          label="血型"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="rxnj"
          label="入学年份"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="byrq"
          label="毕业日期"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="yxx"
          label="原学校"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="dzyx"
          label="电子邮箱"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="csd"
          label="出生地"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="jg"
          label="籍贯"
          width="200"
        > </el-table-column>
        <el-table-column
          prop="dzzd"
          label="乘车区间"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="jtdz"
          label="家庭地址"
          width="500"
        >
        </el-table-column>
        <el-table-column
          prop="hjdz"
          label="户籍地址"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="sfwcbydj"
          label="是否完成兵役登记"
          width="150"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.sfwcbydj == 1"
              effect="dark"
            > 是 </el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.sfwcbydj == 0"
              effect="dark"
            >
              否
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="bydjh"
          label="兵役登记号"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="ghyhkh"
          label="工行银行卡号"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="jhyhkh"
          label="建行银行卡号"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="sfjbx"
          label="是否交保险"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.sfjbx == 1"
              effect="dark"
            > 是 </el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.sfjbx == 0"
              effect="dark"
            >
              否
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="qq"
          label="QQ"
        > </el-table-column>
        <el-table-column
          prop="sftyjr"
          label="是否退役军人"
          width="150"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.sftyjr == 1"
              effect="dark"
            > 是 </el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.sftyjr == 0"
              effect="dark"
            >
              否
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="fjbh"
          label="宿舍号"
          width="200"
        >
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="批量修改"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="plform"
        v-if="dialogFormVisible"
        ref="formRef"
      >
        <el-form-item
          label="学籍状态"
          label-width="120px"
        >
          <el-select
            v-model="plform.xjzt"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in xjztlist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="年级"
          label-width="120px"
        >
          <el-select
            v-model="plform.nj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in njlist"
              :key="index"
              :label="item.nj"
              :value="item.nj"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getXSIndexData,
  GetZYListByQX,
  GetBJListByQX,
  getXSInfoPageList,
  exportXSExcel,
  savePLXSInfo,
  czmm,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import { arealsit } from '../../utils/area'
export default {
  data() {
    return {
      dialogFormVisible: false,
      selxh: '',
      keyValue: '',
      xh: '',
      xm: '',
      sfzh: '',
      nj: '',
      njlist: [],
      bm: '',
      bmlist: [],
      zy: '',
      zylist: [],
      bj: '',
      bjlist: [],
      xjzt: '',
      xjztlist: [],
      zzmm: '',
      zzmmlist: [],
      mz: '',
      mzlist: [],
      lxdh: '',
      fdy: '',
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      plform: {
        xhs: [],
        xjzt: '',
        nj: '',
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      buttonauth: {},
      JG_SHENG: '',
      JG_SHI: '',
      JG_QU: '',
      JTDZ_SHENG: '',
      JTDZ_SHI: '',
      JTDZ_QU: '',
      areaLsit: arealsit,
      xzqhList: [],
      JGFORM: [],
      JTDZFORM: [],
    }
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getXSIndexData().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data.njlist
        this.bmlist = res.data.bmlist
        this.xjztlist = res.data.xjztlist
        this.zzmmlist = res.data.zzmmlist
        this.mzlist = res.data.mzlist
      }
    })
    this.getDataPageList()
    this.getZXQH()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bm }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zy }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zy = ''
      this.bj = ''
      this.getZY()
    },
    zychange() {
      this.bj = ''
      this.getBJ()
    },
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getXSInfoPageList({
        queryJson: JSON.stringify({
          xh: this.xh,
          xm: this.xm,
          sfzh: this.sfzh,
          nj: this.nj,
          bm: this.bm,
          zy: this.zy,
          bj: this.bj,
          xjzt: this.xjzt,
          zzmm: this.zzmm,
          mz: this.mz,
          lxdh: this.lxdh,
          fdy: this.fdy,
          jg_sheng: this.JGFORM[0],
          jg_shi: this.JGFORM[1],
          jg_qu: this.JGFORM[2],
          jtdz_sheng: this.JTDZFORM[0],
          jtdz_shi: this.JTDZFORM[1],
          jtdz_qu: this.JTDZFORM[2],
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.selxh = ''
      } else {
        this.keyValue = currentRow.id
        this.selxh = currentRow.xh
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    toadd() {
      this.$router.push({
        path: '/jcxx/sy_addxs/index',
      })
    },
    toedit() {
      if (this.selxh === '') {
        this.$message.error('未选中需要修改的数据！')
        return
      }
      this.$router.push({
        path: '/jcxx/sy_addxs/index',
        query: { xh: this.selxh },
      })
    },
    tocx() {
      if (this.selxh === '') {
        this.$message.error('未选中需要修改的数据！')
        return
      }
      this.$router.push({
        path: '/jcxx/sy_cxxs/index',
        query: { xh: this.selxh },
      })
    },
    exportToExcel() {
      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportXSExcel({
        queryJson: JSON.stringify({
          xh: this.xh,
          xm: this.xm,
          sfzh: this.sfzh,
          nj: this.nj,
          bm: this.bm,
          zy: this.zy,
          bj: this.bj,
          xjzt: this.xjzt,
          zzmm: this.zzmm,
          mz: this.mz,
          lxdh: this.lxdh,
          fdy: this.fdy,
          jg_sheng: this.JGFORM[0],
          jg_shi: this.JGFORM[1],
          jg_qu: this.JGFORM[2],
          jtdz_sheng: this.JTDZFORM[0],
          jtdz_shi: this.JTDZFORM[1],
          jtdz_qu: this.JTDZFORM[2],
        }),
        fileName: '学生信息',
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$refs.formRef.resetFields()
    },
    saveForm() {
      savePLXSInfo(this.plform).then((res) => {
        if (res.code === 200) {
          this.$message.success('保存成功！')
          this.dialogFormVisible = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    showdia() {
      if (this.plform.xhs.length === 0) {
        this.$message.error('未选中任何数据！')
        return
      }
      this.dialogFormVisible = true
    },
    plselchange(e) {
      this.plform.xhs = []
      for (let i = 0; i < e.length; i++) {
        this.plform.xhs.push(e[i].xh)
      }
    },
    czmm() {
      if (this.selxh === '') {
        this.$message.error('未选中数据！')
        return
      }
      czmm({ XH: this.selxh }).then((res) => {
        this.$alert(res.message, {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
      })
    },
    getZXQH() {
      var ccc = this.areaLsit.districts[0]
      var tmp = []
      for (let i = 0; i < ccc.districts.length; i++) {
        tmp.push({
          value: ccc.districts[i].name,
          label: ccc.districts[i].name,
          children: [],
        })
        for (let j = 0; j < ccc.districts[i].districts.length; j++) {
          tmp[i].children.push({
            value: ccc.districts[i].districts[j].name,
            label: ccc.districts[i].districts[j].name,
            children: [],
          })
          for (
            let k = 0;
            k < ccc.districts[i].districts[j].districts.length;
            k++
          ) {
            tmp[i].children[j].children.push({
              value: ccc.districts[i].districts[j].districts[k].name,
              label: ccc.districts[i].districts[j].districts[k].name,
            })
          }
        }
      }
      this.xzqhList = tmp
    },
    xzqhhandleChange(value) {
      this.JGFORM = value
    },
    jtdzhandleChange(value) {
      this.JTDZFORM = value
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
