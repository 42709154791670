<template>
  <el-form :model="forminfo" ref="formRef">
    <el-card class="box-card">
      <span class="staffBmTitle">（{{ pcmodel.xn }}）国家奖学金申请审批表</span>
      <el-row :gutter="0" class="inputnoborder">
        <el-col :span="8">
          <el-form-item label="学校：" label-width="55px">
            <el-input value="" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="院系：" label-width="55px">
            <el-input v-model="forminfo.BMMC" readonly> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学号" label-width="55px">
            <el-input v-model="forminfo.XH" readonly> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <table border="1px" class="staffBmTable">
        <tr>
          <td rowspan="4" style="width: 30px">基本情况</td>
          <td style="width: 100px">姓名</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.XM"> </el-input>
            </el-form-item>
          </td>
          <td style="width: 100px">性别</td>
          <td style="width: 100px">
            <el-form-item style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.XB"> </el-input>
            </el-form-item>
          </td>
          <td style="width: 100px">出生年月</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.CSNY">
              </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>政治面貌</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="ZZMM"
            >
              <el-input size="small" readonly v-model="forminfo.ZZMM">
              </el-input>
            </el-form-item>
          </td>
          <td>民族</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.MZ"> </el-input>
            </el-form-item>
          </td>
          <td>入学时间</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="RXSJ"
            >
              <el-input size="small" readonly v-model="forminfo.RXSJ">
              </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>专业</td>
          <td>
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.ZYMC">
              </el-input>
            </el-form-item>
          </td>
          <td>学制</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="XZ"
            >
              <el-input size="small" readonly v-model="forminfo.XZ"> </el-input>
            </el-form-item>
          </td>
          <td>联系电话</td>
          <td>
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="LXDH"
            >
              <el-input size="small" v-model="forminfo.LXDH"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td>身份证号</td>
          <td colspan="5">
            <el-form-item label-width="0px" style="margin-bottom: 0px">
              <el-input size="small" readonly v-model="forminfo.SFZH">
              </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td rowspan="2" style="width: 30px">学习情况</td>
          <td colspan="3">
            <el-form-item
              label-width="150px"
              label="成绩排名："
              style="margin-bottom: 0px"
              prop="CJPMMC"
            >
              <el-input size="small" v-model="forminfo.CJPMMC"> </el-input>
            </el-form-item>
            <el-form-item
              label-width="150px"
              label="总人数："
              style="margin-bottom: 0px"
              prop="CJPMZRS"
            >
              <el-input size="small" v-model="forminfo.CJPMZRS"> </el-input>
            </el-form-item>
          </td>
          <td colspan="3">
            <el-form-item
              label-width="150px"
              style="margin-bottom: 0px"
              prop="ZHKHPM"
              label="实行综合考评排名："
            >
              <el-radio-group v-model="forminfo.ZHKHPM">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <el-form-item
              label-width="150px"
              label="必修课（门）："
              style="margin-bottom: 0px"
              prop="BXK"
            >
              <el-input size="small" v-model="forminfo.BXK"> </el-input>
            </el-form-item>
            <el-form-item
              label-width="150px"
              label="及格以上（门）："
              style="margin-bottom: 0px"
              prop="BXKJG"
            >
              <el-input size="small" v-model="forminfo.BXKJG"> </el-input>
            </el-form-item>
          </td>
          <td colspan="3">
            <el-form-item
              label-width="150px"
              label="如是，排名："
              style="margin-bottom: 0px"
            >
              <el-input size="small" v-model="forminfo.ZHPM"> </el-input>
            </el-form-item>
            <el-form-item
              label-width="150px"
              label="总人数："
              style="margin-bottom: 0px"
            >
              <el-input size="small" v-model="forminfo.ZHPMRS"> </el-input>
            </el-form-item>
          </td>
        </tr>
        <tr>
          <td style="width: 30px">大学期间主要获奖情况</td>
          <td colspan="8" style="padding: 0px">
            <el-table
              :data="fromTable"
              style="width: 100%"
              border
              :header-cell-style="{
                borderColor: '#000',
                color: '#303133',
                fontSize: '14px',
                fontWeight: 'normal',
                paddingTop: '5px',
                paddingBottom: '5px',
              }"
              :cell-style="{
                borderColor: '#000',
                paddingTop: '5px',
                paddingBottom: '5px',
              }"
            >
              <el-table-column prop="HJRQ" label="日期" header-align="center">
                <template slot-scope="scope">
                  <el-date-picker
                    v-model="scope.row.HJRQ"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    size="small"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </template>
              </el-table-column>
              <el-table-column
                prop="JXMC"
                label="奖项名称"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.JXMC" size="small"> </el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="BJDW"
                label="颁奖单位"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.BJDW" size="small"> </el-input>
                </template>
              </el-table-column>
            </el-table>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <el-form-item
              label-width="0px"
              style="margin-bottom: 0px"
              prop="SQLY"
            >
              <el-input
                size="small"
                type="textarea"
                v-model="forminfo.SQLY"
                placeholder="申请理由"
                :autosize="{ minRows: 8, maxRows: 12 }"
              >
              </el-input>
            </el-form-item>
          </td>
        </tr>
      </table>
    </el-card>
    <el-form-item label="附件" label-width="120px">
      <a
        v-for="(item, index) in fileList"
        :key="index"
        target="_blank"
        :href="downurl + 'File/download?id=' + item.id"
        >{{ item.name }}</a
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { getSQFormData, getFormData } from '../../api/jxj'
import { baseUrl } from '../../utils/http'
import moment from 'moment'
export default {
  props: {
    id: {
      // 显示隐藏
      type: String,
    },
    XH: {
      type: String,
    },
  },
  data() {
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      splist: [],
      keyword: '',
      keyValue: this.id,
      xh: this.XH,
      detail: {},
      pcmodel: {},
      detailTable: [],
      fromTable: [
        { HJRQ: null, JXMC: null, BJDW: '' },
        { HJRQ: null, JXMC: null, BJDW: '' },
        { HJRQ: null, JXMC: null, BJDW: '' },
        { HJRQ: null, JXMC: null, BJDW: '' },
      ],
      forminfo: {
        BMMC: '',
        ZYMC: '',
        BJMC: '',
        NJ: '',
        ID: '',
        PCID: '',
        XH: '',
        XM: '',
        XB: '',
        CSNY: '',
        MZ: '',
        ZZMM: '',
        RXSJ: null,
        XZ: '',
        SFZH: '',
        LXDH: '',
        CJPMMC: null,
        CJPMZRS: null,
        ZHKHPM: null,
        BXK: null,
        BXKJG: null,
        ZHPM: null,
        ZHPMRS: null,
        SQLY: '',
        SPZT: 0,
        hj: [],
        fj: [],
      },
    }
  },
  created() {
    this.handleDetail(this.keyValue)
  },
  methods: {
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat2(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    // 时间格式化
    dateFormat4(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleDetail(id) {
      this.operation = 2
      getSQFormData({ id: id }).then((res) => {
        if (res.code === 200) {
          getFormData({ id: res.data.pcid }).then((re) => {
            if (re.code === 200) {
              this.pcmodel = re.data
            }
          })
          this.pcmodel.xn = res.data.xn
          this.forminfo.ID = res.data.id
          this.forminfo.XH = res.data.xh
          this.forminfo.PCID = res.data.pcid
          this.forminfo.XM = res.data.xm
          this.forminfo.XB = res.data.xb
          this.forminfo.BMMC = res.data.bmmc
          this.forminfo.ZYMC = res.data.zymc
          this.forminfo.CSNY = this.dateFormat3(res.data.csny)
          this.forminfo.MZ = res.data.mz
          this.forminfo.ZZMM = res.data.zzmm
          this.forminfo.RXSJ = this.dateFormat4(res.data.rxsj)
          this.forminfo.XZ = res.data.xz
          this.forminfo.SFZH = res.data.sfzh
          this.forminfo.LXDH = res.data.lxdh
          this.forminfo.CJPMMC = res.data.cjpmmc
          this.forminfo.CJPMZRS = res.data.cjpmzrs
          this.forminfo.ZHKHPM = res.data.zhkhpm
          this.forminfo.BXK = res.data.bxk
          this.forminfo.BXKJG = res.data.bxkjg
          this.forminfo.ZHPM = res.data.zhpm
          this.forminfo.ZHPMRS = res.data.zhpmrs
          this.forminfo.SQLY = res.data.sqly
          this.forminfo.SPZT = res.data.spzt
          this.fromTable = []
          for (var i = 0; i < res.data.hj.length; i++) {
            this.fromTable.push({
              HJRQ: res.data.hj[i].hjrq,
              JXMC: res.data.hj[i].jxmc,
              BJDW: res.data.hj[i].bjdw,
            })
          }
          if (this.fromTable.length < 4) {
            for (var j = this.fromTable.length - 1; j < 4; j++) {
              this.fromTable.push({
                HJRQ: null,
                JXMC: '',
                BJDW: '',
              })
            }
          }
          var list = res.data.fj
          this.fileList = []
          for (var z = 0; z < list.length; z++) {
            this.fileList.push({ name: list[z].fileName, id: list[z].id })
          }
          this.splist = res.data.spjl
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
<style scoped>
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  font-variant: normal;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
