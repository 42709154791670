<!--
* 创建人：邓国伦
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div v-loading="loading">
    <el-tree
      v-if="!loading"
      :default-expanded-keys="default_expanded_keys"
      :props="props"
      ref="tree"
      node-key="id"
      node-text="mc"
      :load="loadNode"
      :highlight-current="highlightcurrent"
      lazy
      @node-click="nodeclick"
    ></el-tree>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getdeptchildlist } from '../../api/jcxx'
export default {
  props: {
    layer: {
      type: Number,
      default: null,
    },
    highlightcurrent: {
      // 是否高亮当前选中节点
      type: Boolean,
      default: true,
    },
    hasstu: {
      // 过滤是否有学生的部门 null 查询全部0=无学生 1=有学生
      type: Number,
      default: null,
    },
    iszd: {
      // 是否过滤在读班级
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      props: { label: 'mc' },
      loading: false,
      default_expanded_keys: [],
    }
  },
  created() {},
  methods: {
    reload() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 10)
    },
    loadNode(node, resolve) {
      if (this.layer != null && node.data && node.data.layer === this.layer) {
        return resolve([])
      }
      getdeptchildlist({
        bh: node.data ? node.data.bh : null,
        lx: node.data ? node.data.lx : 0,
        hasstu: this.hasstu,
        iszd: this.iszd,
      })
        .then((res) => {
          if (res.code === 200) {
            if (node.data !== undefined && node.data.length === 0) {
              this.default_expanded_keys = []
              this.default_expanded_keys.push(res.data[0].id)
            }
            resolve(res.data)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 当节点被点击的时候触发
    nodeclick(node) {
      this.$emit('node-click', node)
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style></style>
