<!--困难生批次设置-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">助学金批次设置</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <el-input v-model="keyword" placeholder="学年" size="small"></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
          >编辑</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xn" label="学年" width="200"> </el-table-column>
        <el-table-column
          prop="kssj"
          label="开始时间"
          width="200"
          :formatter="dateFormat"
        ></el-table-column>
        <el-table-column
          prop="jssj"
          label="结束时间"
          width="200"
          :formatter="dateFormat"
        ></el-table-column>
        <el-table-column prop="ts" label="提示" width="300"> </el-table-column>
        <el-table-column prop="sm" label="说明"> </el-table-column>
        <el-table-column prop="sm" label="是否当前学年">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.sfdqxn"
              :active-value="1"
              :inactive-value="0"
              @change="ztStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="forminfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="forminfoRules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="学年" label-width="120px" prop="XN">
              <el-select
                v-model="forminfo.XN"
                placeholder="请选择学年"
                style="display: block"
              >
                <el-option
                  v-for="(item, index) in xnList"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="是否当前学年"
              label-width="120px"
              prop="SFDQXN"
            >
              <el-radio v-model="forminfo.SFDQXN" :label="1">是</el-radio>
              <el-radio v-model="forminfo.SFDQXN" :label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="时间" label-width="120px" prop="SJ">
              <el-date-picker
                v-model="forminfo.SJ"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="提示" label-width="120px" prop="TS">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="forminfo.TS"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="说明" label-width="120px" prop="SM">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="forminfo.SM"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getAuthorizeButtonColumnList, getDQXQ } from '../../api/system'
import {
  savePCSZ,
  GetPCPageList,
  changeState,
  getFormData,
} from '../../api/zxj'
import moment from 'moment'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      fileList: [],
      xnList: [],
      forminfo: {
        ID: '',
        XN: '',
        TS: '',
        SM: '',
        KSSJ: '',
        JSSJ: '',
        SFDQXN: 0,
        SJ: '',
      },
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      forminfoRules: {
        XN: [{ required: true, message: '选择学年', trigger: 'blur' }],
        TS: [{ required: true, message: '请输入提示', trigger: 'blur' }],
        SM: [{ required: true, message: '请输入说明', trigger: 'blur' }],
        SJ: [{ required: true, message: '请选择时间', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示添加弹出框
    showAdd() {
      getDQXQ()
        .then((res) => {
          if (res.code === 200) {
            this.xnList = []
            this.xnList.push(res.data.xnmc)
            this.operation = 1
            this.dialogFormVisible = true
          } else {
            this.xnList = []
            this.operation = 1
            this.dialogFormVisible = true
          }
        })
        .catch((err) => {
          console.log(err)
          this.xnList = []
          this.operation = 1
          this.dialogFormVisible = true
        })
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XN = res.data.xn
            this.forminfo.TS = res.data.ts
            this.forminfo.SM = res.data.sm
            this.forminfo.KSSJ = res.data.kssj
            this.forminfo.JSSJ = res.data.jssj
            this.forminfo.SFDQXN = res.data.sfdqxn
            this.forminfo.SJ = [
              new Date(res.data.kssj),
              new Date(res.data.jssj),
            ]
            this.xnList = []
            this.xnList.push(res.data.xn)
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.forminfo.ID = ''
      } else {
        this.forminfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.KSSJ = moment(this.forminfo.SJ[0]).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
          this.forminfo.JSSJ = moment(this.forminfo.SJ[1]).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
          savePCSZ(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetPCPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 是否当前学年
    ztStateChanged(row) {
      changeState({ ID: row.id, SFDQXN: row.sfdqxn })
        .then((res) => {
          if (res.code !== 200) {
            row.sfdqxn = row.sfdqxn === 1 ? 0 : 1
          }
        })
        .catch((err) => {
          console.log(err)
          row.sfdqxn = row.sfdqxn === 1 ? 0 : 1
        })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
