import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import storex from '../store/vuex' // 为什么要用单独引用 因为 这个持久化 存入localstroge
import Login from '../components/Login.vue'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Profile from '../views/profile/Profile.vue'
import Module from '../views/system/Module.vue'
import Log from '../views/system/Log.vue'
import Role from '../views/system/Role.vue'
import User from '../views/system/User.vue'
import TemplatePC from '../views/code/Index.vue'
import NProgress from 'nprogress'
import xqgl from '../views/jcxx/xq.vue'
import bmgl from '../views/jcxx/bm.vue'
import code from '../views/jcxx/code.vue'
import zygl from '../views/jcxx/zy.vue'
import bjgl from '../views/jcxx/bj.vue'
import lsgl from '../views/jcxx/ls.vue'
import xsgl from '../views/jcxx/xs.vue'
import xsxxwcqk from '../views/jcxx/xsxxwcqk.vue'
import xsdetailgl from '../views/jcxx/newxsinfo.vue'
import addxsform from '../views/jcxx/addxsform.vue'
import cxxsform from '../views/jcxx/cxxsdetail.vue'
import term from '../views/system/Term.vue'
import xsqjsq from '../views/qjgl/stupage/qjsq.vue'
import qjsqfdysp from '../views/qjgl/fdypage/fdysp.vue'
import qjsqxysp from '../views/qjgl/xypage/xysp.vue'
import qjsqxgsp from '../views/qjgl/xgpage/xgsp.vue' // 学工平台学生请假申请
import qjsqfdyxj from '../views/qjgl/fdypage/fdyxj.vue' // 辅导员销假
import qjsqfdycx from '../views/qjgl/fdypage/fdyqjcx.vue' // 辅导员查询请假
import qjsqxycx from '../views/qjgl/xypage/xycx.vue' // 学院查询请假
import qjsqxgcx from '../views/qjgl/xgpage/xgcx.vue' // 学工查询请假
import qjycsp from '../views/qjgl/qjycsp.vue' // 请假延长审批
import knspcsz from '../views/kns/xgpage/pcsz.vue' // 困难生批次设置
import knssq from '../views/kns/stupage/knssq.vue' // 困难生申请
import knsfdysp from '../views/kns/fdypage/fdysp.vue' // 困难生申请辅导员审批
import knsxysp from '../views/kns/xypage/xysp.vue' // 困难生申请学院审批
import knsxgsp from '../views/kns/xgpage/xgsp.vue' // 困难生申请学工审批
import knsgsrk from '../views/kns/xgpage/gsrk.vue' // 困难生公示入库
import knsgs from '../views/kns/common/gsmd.vue' // 困难生公示名单
import knsfdycx from '../views/kns/fdypage/fdycx.vue' // 困难生辅导员查询
import knsxycx from '../views/kns/xypage/xycx.vue' // 困难生学院查询
import knsxgcx from '../views/kns/xgpage/xgcx.vue' // 困难生学工查询
import callback from '../views/callback.vue' // 困难生学工查询
import fdyxs from '../views/jcxx/fdyxs.vue' // 辅导员查询学生
import xyxs from '../views/jcxx/xyxs.vue' // 学院查询学生
import wjcfsb from '../views/wjcf/fdypage/wjcfsb.vue' // 违纪处分申报
import wjcfspjl from '../views/wjcf/spjl.vue' // 违纪处分申报审批
import wjcfcx from '../views/wjcf/search.vue' // 违纪处分查询
import wjcfgl from '../views/wjcf/manage.vue' // 违纪处分管理
import wjcfstep from '../views/wjcf/step.vue' // 违纪处分审批流程设置
import wjcfss from '../views/wjcf/stupage/wjcfss.vue' // 违纪处分审批流程设置
import wjcfssspjl from '../views/wjcf/ssspjl.vue' // 违纪处分申诉审批
import wjcfjc from '../views/wjcf/stupage/wjcfjc.vue' // 违纪解除申请
import wjcfjcsp from '../views/wjcf/jcspjl.vue' // 违纪解除申请审批
import wjcfxscx from '../views/wjcf/stupage/grwjcfcx.vue' // 个人违纪处分查询
import qjsqstep from '../views/qjgl/step.vue' // 请假流程设置
import qjsqspjl from '../views/qjgl/qjsp.vue' // 请假流程设置
import zxjpcsz from '../views/zxj/zxjpcsz.vue' // 助学金批次设置
import zxjxssq from '../views/zxj/xssq.vue' // 助学金批次设置
import zxjsqsp from '../views/zxj/sqsp.vue' // 助学金审批
import zxjcx from '../views/zxj/zxjcx.vue' // 助学金审批
import zxj from '../views/zxj/zxj.vue' // 助学金审批
import jxjgjjxjpcsz from '../views/jxj/gjjxjpcsz.vue' // 国家奖学金申请批次设置
import jxjgjjxjxssq from '../views/jxj/gjjxjxssq.vue' // 国家奖学金申请
import jxjgjjxjsp from '../views/jxj/gjjxjsp.vue' // 国家奖学金审批
import jxjgjjxjcx from '../views/jxj/gjjxjcx.vue' // 国家奖学金审批
import jxjgjjxj from '../views/jxj/gjjxj.vue' // 国家奖学金
import xspylcpz from '../views/xspy/lcpz.vue' // 学生评优流程配置
import xspypcsz from '../views/xspy/pcsz.vue' // 学生评优批次设置
import jqgljjr from '../views/jqgl/jjr.vue' // 节假日设置
import jqglkdj from '../views/jqgl/kdj.vue' // 可登记去向节假日
import jqglstuqxdj from '../views/jqgl/qxdj.vue' // 去向登记
import jqglqxdj from '../views/jqgl/qxdjcx.vue' // 去向登记
import mhtcallback from '../views/oauth2/mht_callback.vue' // 明厚天 统一身份认证
import rhcascallback from '../views/oauth2/rh_cas_callback.vue' // 仁华 CAS统一身份认证
import qzxgmm from '../views/qzxgmm.vue' // 强制修改密码
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: Index
      },
      {
        path: '/profile',
        component: Profile
      },
      {
        name: 'studetail',
        path: '/jcxx/studetail/index',
        component: () => import('../views/jcxx/studetail.vue')
      },
      {
        name: 'appmenucreate',
        path: '/mobile/sy_mobile_menu_create/index', // 创建应用
        component: () => import('../views/mobile/create.vue')
      },
      {
        name: 'mobileconfig',
        path: '/mobile/sy_mobile_config/index', // 创建应用
        component: () => import('../views/mobile/config.vue')
      },
      {
        path: '/jcxx/sy_addxs/index',
        component: addxsform
      },
      {
        path: '/jcxx/sy_cxxs/index',
        component: cxxsform
      }
    ]
  },
  {
    path: '/callback',
    component: callback
  },
  {
    path: '/oauth2/mhtcallback',
    component: mhtcallback
  },
  {
    path: '/oauth2/rhcascallback',
    component: rhcascallback
  },
  {
    path: '/qzxgmm',
    component: qzxgmm
  }
]

// 动态路由部分
const routepage = [
  {
    path: '/system/Module/Index',
    component: Module
  },
  {
    path: '/system/log/index',
    component: Log
  },
  {
    path: '/system/SY_Base_Role/Index',
    component: Role
  },
  {
    path: '/system/SY_Base_User/index',
    component: User
  },
  {
    path: '/code/TemplatePC/Index',
    component: TemplatePC
  },
  {
    path: '/jcxx/sy_jcxx_xq/index',
    component: xqgl
  },
  {
    path: '/jcxx/sy_jcxx_bm/index',
    component: bmgl
  },
  {
    path: '/jcxx/sy_jcxx_code/index',
    component: code
  },
  {
    path: '/jcxx/sy_jcxx_zy/index',
    component: zygl
  },
  {
    path: '/jcxx/sy_jcxx_bj/index',
    component: bjgl
  },
  {
    path: '/jcxx/sy_jcxx_ls/index',
    component: lsgl
  },
  {
    path: '/jcxx/sy_jcxx_xs/index',
    component: xsgl
  },
  {
    path: '/jcxx/sy_jcxx_xsxxwsqk/index',
    component: xsxxwcqk
  },
  {
    path: '/jcxx/sy_xsinfo/index',
    component: xsdetailgl
  },
  {
    path: '/jcxx/sy_jcxx_fdy/index',
    component: () => import('../views/jcxx/fdy.vue')
  },
  {
    path: '/system/sy_system_term/index',
    component: term
  },
  {
    path: '/qjgl/sy_qjgl_qjsq/index',
    component: xsqjsq
  },
  {
    path: '/qjgl/sy_qjgl_fdysp/index',
    component: qjsqfdysp
  },
  {
    path: '/qjgl/sy_qjgl_xysp/index',
    component: qjsqxysp
  },
  {
    path: '/qjgl/sy_qjgl_xgsp/index',
    component: qjsqxgsp
  },
  {
    path: '/qjgl/sy_qjgl_fdyxj/index',
    component: qjsqfdyxj
  },
  {
    path: '/qjgl/sy_qjgl_fdycx/index',
    component: qjsqfdycx
  },
  {
    path: '/qjgl/sy_qjgl_xycx/index',
    component: qjsqxycx
  },
  {
    path: '/qjgl/sy_qjgl_xgcx/index',
    component: qjsqxgcx
  },
  {
    path: '/qjgl/sy_qjgl_qjycsp/index',
    component: qjycsp
  },
  {
    path: '/kns/sy_kns_pcsz/index',
    component: knspcsz
  },
  {
    path: '/kns/sy_kns_xssq/index',
    component: knssq
  },
  {
    path: '/kns/sy_kns_fdysp/index',
    component: knsfdysp
  },
  {
    path: '/kns/sy_kns_xysp/index',
    component: knsxysp
  },
  {
    path: '/kns/sy_kns_xgsp/index',
    component: knsxgsp
  },
  {
    path: '/kns/sy_kns_gsrk/index',
    component: knsgsrk
  },
  {
    path: '/kns/sy_kns_gsmd/index',
    component: knsgs
  },
  {
    path: '/kns/sy_kns_fdycx/index',
    component: knsfdycx
  },
  {
    path: '/kns/sy_kns_xycx/index',
    component: knsxycx
  },
  {
    path: '/kns/sy_kns_xgcx/index',
    component: knsxgcx
  },
  {
    path: '/jcxx/sy_jcxx_fdyxs/index',
    component: fdyxs
  },
  {
    path: '/jcxx/sy_jcxx_xyxs/index',
    component: xyxs
  },
  {
    path: '/wjcf/sy_wjcf_wjcfsb/index',
    component: wjcfsb
  },
  {
    path: '/wjcf/sy_wjcf_wjcfsp/index',
    component: wjcfspjl
  },
  {
    path: '/wjcf/sy_wjcf_search/index',
    component: wjcfcx
  },
  {
    path: '/wjcf/sy_wjcf_manage/index',
    component: wjcfgl
  },
  {
    path: '/wjcf/sy_wjcf_step/index',
    component: wjcfstep
  },
  {
    path: '/wjcf/sy_wjcf_xswjss/index',
    component: wjcfss
  },
  {
    path: '/wjcf/sy_wjcf_wjcfsssp/index',
    component: wjcfssspjl
  },
  {
    path: '/wjcf/sy_wjcf_wjcfjc/index',
    component: wjcfjc
  },
  {
    path: '/wjcf/sy_wjcf_wjcfjcsp/index',
    component: wjcfjcsp
  },
  {
    path: '/wjcf/sy_wjcf_xscx/index',
    component: wjcfxscx
  },
  {
    path: '/qjgl/sy_qjgl_step/index',
    component: qjsqstep
  },
  {
    path: '/qjgl/sy_qjgl_qjsp/index',
    component: qjsqspjl
  },
  // 公寓管理
  {
    path: '/gygl/sy_sg_gyqy/index',
    component: () => import('../views/gygl/gyqy.vue')
  },
  {
    path: '/gygl/sy_sg_gyl/index',
    component: () => import('../views/gygl/gyl.vue')
  },
  {
    path: '/gygl/sy_sg_fjgl/index',
    // component: () => import('../views/gygl/fjgl.vue') // 旧界面
    component: () => import('../views/gygl/fjgl_new.vue')
  },
  {
    path: '/gygl/sy_sg_fjyfp/index',
    component: () => import('../views/gygl/fjyfp.vue')
  },
  {
    path: '/gygl/sy_sg_xsrz/index',
    component: () => import('../views/gygl/xsrz.vue')
  },
  {
    path: '/gygl/sy_sg_ghfjlog/index',
    component: () => import('../views/gygl/hflog.vue')
  },
  // 助学金
  {
    path: '/zxj/sy_zxj_pcsz/index',
    component: zxjpcsz
  },
  {
    path: '/zxj/sy_zxj_xssq/index',
    component: zxjxssq
  },
  {
    path: '/zxj/sy_zxj_sqsp/index',
    component: zxjsqsp
  },
  {
    path: '/zxj/sy_zxj_cx/index',
    component: zxjcx
  },
  {
    path: '/zxj/sy_zxj/index',
    component: zxj
  },
  // 国家奖学金
  {
    path: '/jxj/sy_gjjxj_pcsz/index',
    component: jxjgjjxjpcsz
  },
  {
    path: '/jxj/sy_gjjxj_xssq/index',
    component: jxjgjjxjxssq
  },
  {
    path: '/jxj/sy_gjjxj_sp/index',
    component: jxjgjjxjsp
  },
  {
    path: '/jxj/sy_gjjxj_cx/index',
    component: jxjgjjxjcx
  },
  {
    path: '/jxj/sy_gjjxj/index',
    component: jxjgjjxj
  },
  // 等级奖学金
  {
    path: '/jxj/sy_djjxj/index',
    component: () => import('../views/jxj/djjxjpcsz.vue')
  },
  {
    path: '/jxj/sy_djjxj_lcpz/index',
    component: () => import('../views/jxj/djjxjlcpz.vue')
  },
  {
    path: '/jxj/sy_djjxj_xssq/index',
    component: () => import('../views/jxj/djjxjsq.vue')
  },
  {
    path: '/jxj/sy_djjxj_sp/index',
    component: () => import('../views/jxj/djjxjsp.vue')
  },
  {
    path: '/jxj/sy_djjxj_xysp/index',
    component: () => import('../views/jxj/djjxjspxy.vue')
  },
  {
    path: '/jxj/sy_djjxj_xgsp/index',
    component: () => import('../views/jxj/djjxjspxg.vue')
  },
  {
    path: '/jxj/sy_djjxj_cx/index',
    component: () => import('../views/jxj/djjxjcx.vue')
  },
  {
    path: '/jxj/sy_djjxj_sqpz/index', // 等级奖学金申请配置
    component: () => import('../views/jxj/djjxjsqpz.vue')
  },
  {
    path: '/jxj/sy_djjxj_md/index',
    component: () => import('../views/jxj/djjxjmd.vue')
  },
  // 学生评优
  {
    path: '/xspy/sy_xspy_lcpz/index',
    component: xspylcpz
  },
  {
    path: '/xspy/sy_xspy_pcsz/index',
    component: xspypcsz
  },
  // 假期管理
  {
    path: '/jqgl/sy_jqgl_jjr/index',
    component: jqgljjr
  },
  {
    path: '/jqgl/sy_jqgl_kdj/index',
    component: jqglkdj
  },
  {
    path: '/jqgl/sy_jqgl_qxdj/index',
    component: jqglstuqxdj
  },
  {
    path: '/jqgl/sy_jqgl_qxdjcx/index',
    component: jqglqxdj
  },
  {
    path: '/jqgl/sy_jqgl_lcpz/index',
    component: () => import('../views/jqgl/lcpz.vue')
  },
  {
    path: '/jqgl/sy_jqgl_ksq/index',
    component: () => import('../views/jqgl/ksq.vue')
  },
  {
    path: '/jqgl/sy_jqgl_lxsq/index',
    component: () => import('../views/jqgl/lsxq.vue')
  },
  {
    path: '/jqgl/sy_jqgl_lxsp/index',
    component: () => import('../views/jqgl/lxsp.vue')
  },
  {
    path: '/jqgl/sy_jqgl_lxcx/index',
    component: () => import('../views/jqgl/lxcx.vue')
  },
  // 辅导员工作
  {
    path: '/fdy/sy_fdy_gzjh/index', // 工作计划
    component: () => import('../views/fdy/gzjh.vue')
  },
  {
    path: '/fdy/sy_fdy_xsssgzjl/index', // 宿舍工作记录
    component: () => import('../views/fdy/ssgzjl.vue')
  },
  {
    path: '/fdy/sy_fdy_xlfd/index', // 学生心理辅导
    component: () => import('../views/fdy/xlfd.vue')
  },
  {
    path: '/fdy/sy_fdy_bfjl/index', //  学生帮扶记录
    component: () => import('../views/fdy/bfjl.vue')
  },
  {
    path: '/fdy/sy_fdy_zhcpjfdj/index', //   减分登记
    component: () => import('../views/fdy/jfdj.vue')
  },
  {
    path: '/fdy/sy_fdy_jzlx/index', //  家长联系记录
    component: () => import('../views/fdy/jzlx.vue')
  },
  // 迎新管理
  {
    path: '/yxgl/sy_yx_xxcx/index', // 新生信息查询
    component: () => import('../views/yxgl/xxcx.vue')
  },
  {
    path: '/yxgl/sy_yx_jtxx/index', // 新生家庭信息
    component: () => import('../views/yxgl/jtxx.vue')
  },
  {
    path: '/yxgl/sy_yx_jfxx/index', // 信息缴费系信息
    component: () => import('../views/yxgl/jfxx.vue')
  },
  {
    path: '/yxgl/sy_yx_dkxx/index', // 新生贷款信息
    component: () => import('../views/yxgl/dkxx.vue')
  },
  {
    path: '/yxgl/sy_yx_dxjh/index', // 新生到校计划
    component: () => import('../views/yxgl/dxjh.vue')
  },
  // 岗位管理
  {
    path: '/qgzx/sy_qgzx_gwgl/index', // 岗位管理
    component: () => import('../views/qgzx/gwgl.vue')
  },
  {
    path: '/qgzx/sy_qgzx_lcpz/index', // 流程配置
    component: () => import('../views/qgzx/lcpz.vue')
  },
  {
    path: '/qgzx/sy_qgzx_xsgw/index', // 学生岗位
    component: () => import('../views/qgzx/xsgwpage.vue')
  },
  {
    path: '/qgzx/sy_qgzx_sqsp/index', // 学生岗位申请审批
    component: () => import('../views/qgzx/xssqsp.vue')
  },
  {
    path: '/qgzx/sy_qgzx_gwsq/index', // 岗位申请
    component: () => import('../views/qgzx/gwsq.vue')
  },
  {
    path: '/qgzx/sy_qgzx_gwsqsp/index', // 岗位申请审批
    component: () => import('../views/qgzx/gwsqsp.vue')
  },
  // 手机端应用配置
  {
    path: '/mobile/sy_mobile_menu/index', // 应用设置
    component: () => import('../views/mobile/menu.vue')
  },
  {
    path: '/mobile/sy_mobile_group/index', // 应用设置
    component: () => import('../views/mobile/groupmenu.vue')
  },
  {
    path: '/zxdk/sy_zxdk/index', // 老生贷款信息
    component: () => import('../views/yxgl/lsdkxx.vue')
  },
  // 学生详细信息
  {
    path: '/stuinfo/myinfo/index', // 老生贷款信息
    component: () => import('../views/stupersoninfo/myinfo.vue')
  },
  // 学生证
  {
    path: '/xsz/ccqj/index', // 学生证
    component: () => import('../views/xsz/ccqj.vue')
  },
  {
    path: '/xsz/print/index', // 学生证打印
    component: () => import('../views/xsz/template.vue')
  },
  {
    path: '/xsz/xszbbsq/index', // 学生证补办申请
    component: () => import('../views/xsz/xszbb.vue')
  },
  {
    path: '/xsz/xszbbsp/index', // 学生证补办申请审批
    component: () => import('../views/xsz/xszbbsp.vue')
  },
  {
    path: '/xsz/ccqjcxdc/index', // 未填写查询
    component: () => import('../views/xsz/wtxdc.vue')
  },
  // 评优，单项积极分子
  {
    path: '/py/pydxlcpz/index', // 单项积极分子流程配置
    component: () => import('../views/py/dx_lcpz.vue')
  },
  {
    path: '/py/pydxpcsz/index', // 单项积极分子批次设置
    component: () => import('../views/py/dx_pcsz.vue')
  },
  {
    path: '/py/pydxxssq/index', // 单项积极分子学生申请
    component: () => import('../views/py/dx_xssq.vue')
  },
  {
    path: '/py/pydxsp/index', // 单项积极分子审批
    component: () => import('../views/py/dx_sp.vue')
  },
  {
    path: '/py/pydxcx/index', // 单项积极分子学生查询
    component: () => import('../views/py/dx_cx.vue')
  },
  {
    path: '/py/dxsqpz/index', // 单项积极分子申请配置
    component: () => import('../views/py/dxsqpz.vue')
  },
  {
    path: '/py/dxxysp/index', // 单项积极分子学院批量审批
    component: () => import('../views/py/dx_xysp.vue')
  },
  {
    path: '/py/dxxgsp/index', // 单项积极分子学工批量审批
    component: () => import('../views/py/dx_xgsp.vue')
  },
  {
    path: '/py/dxmd/index', // 单项积极分子名单
    component: () => import('../views/py/dx_md.vue')
  },
  // 评优，三好学生
  {
    path: '/py/shxslcpz/index', // 三好学生流程配置
    component: () => import('../views/py/shxs_lcpz.vue')
  },
  {
    path: '/py/shxspcsz/index', // 三好学生批次设置
    component: () => import('../views/py/shxs_pcsz.vue')
  },
  {
    path: '/py/shxsxssq/index', // 三好学生学生申请
    component: () => import('../views/py/shxs_xssq.vue')
  },
  {
    path: '/py/shxssp/index', // 三好学生审批
    component: () => import('../views/py/shxs_sp.vue')
  },
  {
    path: '/py/shxsxysp/index', // 三好学生学院审批
    component: () => import('../views/py/shxs_spxy.vue')
  },
  {
    path: '/py/shxsxgsp/index', // 三好学生学工审批
    component: () => import('../views/py/shxs_spxg.vue')
  },
  {
    path: '/py/shxscx/index', // 三好学生学生查询
    component: () => import('../views/py/shxs_cx.vue')
  },
  {
    path: '/py/shxssqpz/index', // 三好学生学生申请配置
    component: () => import('../views/py/shxssqpz.vue')
  },
  {
    path: '/py/shxsmd/index', // 三好学生学生名单配置
    component: () => import('../views/py/shxs_md.vue')
  },
  // 评优 优秀学生干部
  {
    path: '/py/xsgblcpz/index', // 优秀学生干部流程配置
    component: () => import('../views/py/xsgb_lcpz.vue')
  },
  {
    path: '/py/xsgbpcsz/index', // 优秀学生干部流程配置
    component: () => import('../views/py/xsgb_pcsz.vue')
  },
  {
    path: '/py/xsgbxssq/index', // 优秀学生干部流程配置
    component: () => import('../views/py/xsgb_xssq.vue')
  },
  {
    path: '/py/xsgbsp/index', // 优秀学生干部审批
    component: () => import('../views/py/xsgb_sp.vue')
  },
  {
    path: '/py/xsgbxysp/index', // 优秀学生干部学院审批
    component: () => import('../views/py/xsgb_spxy.vue')
  },
  {
    path: '/py/xsgbxgsp/index', // 优秀学生干部学工审批
    component: () => import('../views/py/xsgb_spxg.vue')
  },
  {
    path: '/py/xsgbcx/index', // 优秀学生干部查询
    component: () => import('../views/py/xsgb_cx.vue')
  },
  {
    path: '/py/xsgbsqpz/index', // 优秀学生干部申请配置
    component: () => import('../views/py/xsgbsqpz.vue')
  },
  {
    path: '/py/xsgbmd/index', // 优秀学生干部申请配置
    component: () => import('../views/py/xsgb_md.vue')
  },
  // 校优秀毕业生
  {
    path: '/py/xyxxslcpz/index', // 校优秀毕业生流程配置
    component: () => import('../views/py/xyxxs_lcpz.vue')
  },
  {
    path: '/py/xyxxspcsz/index', // 校优秀毕业生流程配置
    component: () => import('../views/py/xyxxs_pcsz.vue')
  },
  {
    path: '/py/xyxxsxssq/index', // 校优秀毕业生申请
    component: () => import('../views/py/xyxxs_xssq.vue')
  },
  {
    path: '/py/xyxxssp/index', // 优秀毕业生审批
    component: () => import('../views/py/xyxxs_sp.vue')
  },
  {
    path: '/py/xyxxscx/index', // 优秀毕业生查询
    component: () => import('../views/py/xyxxs_cx.vue')
  },
  {
    path: '/py/xyxxssqpz/index', // 优秀毕业生申请配置
    component: () => import('../views/py/xyxxssqpz.vue')
  },
  {
    path: '/py/xyxxsxysp/index', // 优秀毕业生学院审批
    component: () => import('../views/py/xyxxs_xysp.vue')
  },
  {
    path: '/py/xyxxsxgsp/index', // 优秀毕业生学工审批
    component: () => import('../views/py/xyxxs_xgsp.vue')
  },
  {
    path: '/py/xyxxsmd/index', // 优秀毕业生名单
    component: () => import('../views/py/xyxxs_md.vue')
  },
  {
    path: '/py/pyxxsmd/index', // 优秀毕业生名单
    component: () => import('../views/py/pyxxs_md.vue')
  },
  // 省优秀毕业生
  {
    path: '/py/pyxxslcpz/index', // 省优秀毕业生流程配置
    component: () => import('../views/py/pyxxs_lcpz.vue')
  },
  {
    path: '/py/pyxxspcsz/index', // 省优秀毕业生流程配置
    component: () => import('../views/py/pyxxs_pcsz.vue')
  },
  {
    path: '/py/pyxxsxssq/index', // 省优秀毕业生申请
    component: () => import('../views/py/pyxxs_xssq.vue')
  },
  {
    path: '/py/pyxxssp/index', // 省优秀毕业生审批
    component: () => import('../views/py/pyxxs_sp.vue')
  },
  {
    path: '/py/pyxxscx/index', // 省优秀毕业生查询
    component: () => import('../views/py/pyxxs_cx.vue')
  },
  {
    path: '/py/pyxxssqpz/index', // 省优秀毕业生申请配置
    component: () => import('../views/py/pyxxssqpz.vue')
  },
  {
    path: '/py/pyxxsxysp/index', // 省优秀毕业生审批
    component: () => import('../views/py/pyxxs_xysp.vue')
  },
  {
    path: '/py/pyxxsxgsp/index', // 省优秀毕业生审批
    component: () => import('../views/py/pyxxs_xgsp.vue')
  },
  // 综合测评
  {
    path: '/zhcp/zhcpxm/index', // 综合测评项目
    component: () => import('../views/zhcp/zhcpxm.vue')
  },
  {
    path: '/zhcp/zhcpxssq/index', // 综合测评学生申请
    component: () => import('../views/zhcp/xssq.vue')
  },
  {
    path: '/zhcp/zhcpxsjjf/index', // 综合测评学生加减分
    component: () => import('../views/zhcp/wdjf.vue')
  },
  {
    path: '/zhcp/zhcpxsjf/index', // 综合测评学生加分
    component: () => import('../views/zhcp/wdjfsq.vue')
  },
  {
    path: '/zhcp/zhcpdsp/index', // 综合测评学生加分
    component: () => import('../views/zhcp/zhcpsp.vue')
  },
  {
    path: '/zhcp/zhcpjfdj/index', // 综合测评减分登记
    component: () => import('../views/zhcp/xsjsdj.vue')
  },
  {
    path: '/zhcp/fdyjf/index', // 综合测评辅导员加分登记
    component: () => import('../views/zhcp/fdyjf.vue')
  },
  {
    path: '/zhcp/zhcpjgl/index', // 综合测评减分管理
    component: () => import('../views/zhcp/jfgl.vue')
  },
  {
    path: '/zhcp/zhcpcx/index', // 综合测评查询
    component: () => import('../views/zhcp/cpcx.vue')
  },
  {
    path: '/zhcp/zhcpjs/index', // 综合测评得分计算
    component: () => import('../views/zhcp/zhcpjs.vue')
  },
  {
    path: '/zhcp/zhcpdfcx/index', // 综合测评得分查询
    component: () => import('../views/zhcp/zhcpdf.vue')
  },
  {
    path: '/zhcp/zhcpdfxscx/index', // 综合测评得分查询
    component: () => import('../views/zhcp/xscxcpcj.vue')
  },
  // 新闻
  {
    path: '/news/category/index', // 新闻栏目
    component: () => import('../views/news/category.vue')
  },
  {
    path: '/news/article/index', // 新闻后台
    component: () => import('../views/news/article.vue')
  },
  // 辅导员管理
  {
    path: '/jcxx/fdygl/index', // 新闻后台
    component: () => import('../views/jcxx/fdyxg.vue')
  },
  // 核酸检测
  {
    path: '/hsjc/index', // 核酸检测
    component: () => import('../views/hsjc/hsjc.vue')
  },
  // 医疗保险
  {
    path: '/ylbx/gmjl/index', // 保险购买记录
    component: () => import('../views/ylbx/gmjl.vue')
  },
  {
    path: '/ylbx/lpjl/index', // 保险理赔记录
    component: () => import('../views/ylbx/lpdj.vue')
  },
  // 学籍异动
  {
    path: '/xjyd/bjyd/index', // 班级异动
    component: () => import('../views/xjyd/bjyd.vue')
  },
  {
    path: '/xjyd/ztyd/index', // 学籍状态异动
    component: () => import('../views/xjyd/xjzt.vue')
  },
  // 辅导员考核
  {
    path: '/fdykh/gzsz/index', // 规则设置
    component: () => import('../views/fdykh/gzsz.vue')
  },
  {
    path: '/fdykh/pcsz/index', // 批次设置
    component: () => import('../views/fdykh/pcsz.vue')
  },
  {
    path: '/fdykh/fdyfs/index', // 辅导员分数
    component: () => import('../views/fdykh/fdyfs.vue')
  },
  {
    path: '/fdykh/xypf/index', // 学院评分
    component: () => import('../views/fdykh/xypf.vue')
  },
  {
    path: '/fdykh/bmpf/index', // 部门评分
    component: () => import('../views/fdykh/bmpf.vue')
  },
  // 助学金
  {
    path: '/zxj/lsbz/index', // 临时补助
    component: () => import('../views/zxj/lsbz.vue')
  },
  {
    path: '/zxj/dczz/index', // 代偿资助
    component: () => import('../views/zxj/dczz.vue')
  },
  // 工作提醒
  {
    path: '/fdy/gztx/index', // 工作提醒
    component: () => import('../views/fdy/gztx.vue')
  },
  // 学生编辑个人信息
  {
    path: '/jcxx/newstudetail/index',
    component: () => import('../views/stupersoninfo/stuedit.vue')
  },
  // 辅导员编辑个人信息
  {
    path: '/fdy/grxx/index',
    component: () => import('../views/jcxx/fdygrxx.vue')
  },
  // 晚点名
  {
    path: '/wdm/wdmdetail/index',
    component: () => import('../views/wdmsj/wdm.vue')
  },
  {
    path: '/wdm/wdmtj/index',
    component: () => import('../views/wdmsj/wdmtj.vue')
  },
  // 返离校报备
  {
    path: '/bbgl/lxbb/index',
    component: () => import('../views/bbgl/lxbb.vue')
  },
  {
    path: '/bbgl/fxbb/index',
    component: () => import('../views/bbgl/fxbb.vue')
  },
  // 地区代码生成
  {
    path: '/area/area/index',
    component: () => import('../views/areautil/area.vue')
  }
]
const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  NProgress.start()
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  // 每次切换页面时，调用进度条
  if (
    (storex.state.routes.length < 3) &
    (to.path !== '/login') &
    (to.path !== '/callback') &
    !to.path.toLocaleLowerCase().startsWith('/oauth2')
  ) {
    // storex 判断内存 vuex 是否有值 来识别页面是否刷新
    var route = store.state.routes
    var newroutes = []
    routepage.forEach(item => {
      route.forEach(n => {
        if (n.toLowerCase() === item.path.toLowerCase()) {
          newroutes.push(item)
        }
      })
    })
    routes[2].children = newroutes
    router.addRoutes(routes)

    storex.commit('setroutes', [...newroutes, ...routes]) // 提交更新vuex store 内存数据
    next({
      ...to,
      replace: true
    })
  }
  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  if (to.path === '/login') return next()
  if (to.path === '/callback') return next()
  if (to.path.toLocaleLowerCase().startsWith('/oauth2') > -1) return next()
  // 获取token
  const tokenStr = store.state.token
  if (!tokenStr) {
    return next('/login')
  }
  next()
})

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
export { router, routes, routepage }
