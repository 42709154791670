<!--校区管理-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">班级管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col
            :span="8"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">院系:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmselchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmsellist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="8"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zysellist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="8"
            style="display: flex; align-items: center"
          >
            <span class="fcolor42 mr5 rspan">是否配置辅导员:</span>
            <el-select
              v-model="sfpzfdy"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                label="已配置"
                value="1"
              ></el-option>
              <el-option
                label="未配置"
                value="2"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top:10px">
          <el-col :span="8">
            <span class="fcolor42 mr5 rspan">班级:</span>
            <el-input
              v-model="keyword"
              placeholder="请输入班级编号/名称"
              size="small"
              style="width: 200px"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
        >新增</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
        >编辑</el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delBj"
        >删除</el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 350"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="xqmc"
          label="校区"
        >
        </el-table-column>
        <af-table-column
          prop="bmmc"
          label="院系"
        >
        </af-table-column>
        <af-table-column
          prop="zymc"
          label="专业"
        >
        </af-table-column>
        <el-table-column
          prop="bjbh"
          label="班级编号"
        >
        </el-table-column>
        <af-table-column
          prop="bjmc"
          label="班级名称"
        >
        </af-table-column>
        <el-table-column
          prop="nj"
          label="年级"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="bynf"
          label="毕业年份"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="xm"
          label="辅导员"
        > </el-table-column>
        <el-table-column
          prop="zdz"
          label="班级状态"
        > </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="bjinfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="bjinfoRules"
      >
        <el-form-item
          label="校区"
          label-width="120px"
          prop="XQBH"
        >
          <el-select
            v-model="bjinfo.XQBH"
            style="display: block"
          >
            <el-option
              v-for="(item, index) in xqlist"
              :key="index"
              :label="item.xqmc"
              :value="item.xqbh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="学院"
          label-width="120px"
          prop="BMBH"
        >
          <el-select
            v-model="bjinfo.BMBH"
            style="display: block"
            @change="bmchange"
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="专业"
          label-width="120px"
          prop="ZYBH"
        >
          <el-select
            v-model="bjinfo.ZYBH"
            style="display: block"
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="班级编号"
          label-width="120px"
          prop="BJBH"
        >
          <el-input
            v-model="bjinfo.BJBH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="班级名称"
          label-width="120px"
          prop="BJMC"
        >
          <el-input
            v-model="bjinfo.BJMC"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="班级简称"
          label-width="120px"
          prop="BJJC"
        >
          <el-input
            v-model="bjinfo.BJJC"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="年级"
          label-width="120px"
          prop="NJ"
        >
          <el-date-picker
            v-model="bjinfo.NJ"
            type="year"
            style="width: 100%"
            value-format="yyyy"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="毕业年份"
          label-width="120px"
          prop="BYNF"
        >
          <el-date-picker
            v-model="bjinfo.BYNF"
            type="year"
            style="width: 100%"
            value-format="yyyy"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="班级状态"
          label-width="120px"
          prop="BJZTM"
        >
          <el-select
            v-model="bjinfo.BJZTM"
            style="display: block"
          >
            <el-option
              v-for="(item, index) in codelist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveForm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getBJPageList,
  saveBJInfo,
  getBjInfo,
  deleteBjInfo,
  getCode,
  getXQList,
  getStuBM,
  getZYList,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      bmbh: '',
      zybh: '',
      sfpzfdy: '',
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      codelist: [],
      xqlist: [],
      zylist: [],
      bmlist: [],
      bmsellist: [],
      zysellist: [],
      BMBH: '',
      bjinfo: {
        ID: '',
        XQBH: '',
        ZYBH: '',
        BJBH: '',
        BJMC: '',
        BJJC: '',
        NJ: '',
        BYNF: '',
        BJZTM: '',
        BMBH: '',
      },
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      bjinfoRules: {
        XQBH: [{ required: true, message: '请选择校区', trigger: 'blur' }],
        BMBH: [{ required: true, message: '请选择院系', trigger: 'blur' }],
        ZYBH: [{ required: true, message: '请选择专业', trigger: 'blur' }],
        BJBH: [{ required: true, message: '请输入班级编号', trigger: 'blur' }],
        BJMC: [{ required: true, message: '请输入班级名称', trigger: 'blur' }],
        BJJC: [{ required: true, message: '请输入班级简称', trigger: 'blur' }],
        NJ: [{ required: true, message: '请选择年级', trigger: 'blur' }],
        BYNF: [{ required: true, message: '请选择毕业年份', trigger: 'blur' }],
        BJZTM: [{ required: true, message: '请选择班级状态', trigger: 'blur' }],
      },
      defaultProps: {
        children: 'childNodes',
        label: 'text',
      },
      parentProps: {
        children: 'childNodes',
        label: 'text',
        value: 'id',
        checkStrictly: true,
        emitPath: false,
      },
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.bjinfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getStuBM()
      .then((res) => {
        if (res.code === 200) {
          this.bmsellist = res.data
        } else {
          this.bmsellist = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.bmsellist = []
      })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    getFormInitData() {
      getXQList()
        .then((res) => {
          if (res.code === 200) {
            this.xqlist = res.data
          } else {
            this.xqlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.xqlist = []
        })
      getStuBM()
        .then((res) => {
          if (res.code === 200) {
            this.bmlist = res.data
          } else {
            this.bmlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bmlist = []
        })
      getZYList({ queryJson: JSON.stringify({ BMBH: this.BMBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.zylist = res.data
          } else {
            this.zylist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.ZYlist = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'BJZTM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.codelist = res.data
          } else {
            this.codelist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.codelist = []
        })
    },
    bmchange() {
      this.bjinfo.ZYBH = ''
      getZYList({ queryJson: JSON.stringify({ BMBH: this.bjinfo.BMBH }) })
        .then((res) => {
          if (res.code === 200) {
            this.zylist = res.data
          } else {
            this.zylist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.ZYlist = []
        })
    },
    bmselchange() {
      this.zybh = ''
      getZYList({ queryJson: JSON.stringify({ BMBH: this.bmbh }) })
        .then((res) => {
          if (res.code === 200) {
            this.zysellist = res.data
          } else {
            this.zysellist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.zysellist = []
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.getFormInitData()
      this.operation = 1
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.getFormInitData()
        this.operation = 0
        getBjInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.bjinfo.ID = res.data.id
            this.bjinfo.XQBH = res.data.xqbh
            this.bjinfo.ZYBH = res.data.zybh
            this.bjinfo.BJBH = res.data.bjbh
            this.bjinfo.BJMC = res.data.bjmc
            this.bjinfo.BJJC = res.data.bjjc
            this.bjinfo.NJ = res.data.nj
            this.bjinfo.BYNF = res.data.bynf
            this.bjinfo.BJZTM = res.data.bjztm
            this.dialogFormVisible = true
            this.bjinfo.BMBH = this.BMBH
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.bjinfo.ID = ''
      } else {
        this.bjinfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveBJInfo({ bj: this.bjinfo }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除班级
    delBj() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteBjInfo({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.bjinfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.bjinfo.BMBH = ''
      } else {
        this.keyValue = currentRow.id
        this.bjinfo.BMBH = currentRow.bmbh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getBJPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          bmbh: this.bmbh,
          zybh: this.zybh,
          sfpzfdy: this.sfpzfdy,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
