// 用于获取年级列表
export function getNFList() {
    const date = new Date();
    const currentYear = date.getFullYear();
    var list = [];
    for (var i = 4; i >= 0; i--) {
        list.push(currentYear + i + '');
    }
    for (var j = 1; j <= 10; j++) {
        list.push(currentYear - j + '')
    }
    return list
}
