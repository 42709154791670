import { get, post } from '../utils/http'

function getgyqlist(data) {
  return get('/gygl/SY_SG_GY0101/GetList', data)
}
/**
 * 获取分页数据
 * @param {*} data
 * @returns
 */
function getqypagelist(data) {
  return get('/gygl/SY_SG_GY0101/GetPageList', data)
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function getqyformdata(data) {
  return get('/gygl/SY_SG_GY0101/GetFormData', data)
}
/**
 * 删除公寓区数据
 * @param {*} data id
 * @returns
 */
function delqy(data) {
  return post('/gygl/SY_SG_GY0101/Delete', data)
}
/**
 * 保存公寓区
 * @param {object} data 表单数据
 * @returns
 */
function saveqy(data) {
  return post('/gygl/SY_SG_GY0101/Save', data)
}
/**
 * 验证区域编号是否重复
 * @param {json} data {id，qybh}
 * @returns
 */
function exqybh(data) {
  return get('/gygl/SY_SG_GY0101/GetExtBH', data)
}
/** ***************公寓楼接口**********************/
/**
 * 获取公寓楼树形数据
 * @param {*} data
 * @returns
 */
function getgyltree(data) {
  return get('/gygl/SY_SG_GY0102/GetTreeList', data)
}
/**
 * 获取分页数据
 * @param {*} data
 * @returns
 */
function getgylpagelist(data) {
  return get('/gygl/SY_SG_GY0102/GetPageList', data)
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function getgylformdata(data) {
  return get('/gygl/SY_SG_GY0102/GetFormData', data)
}
/**
 * 删除公寓区数据
 * @param {*} data id
 * @returns
 */
function delgyl(data) {
  return post('/gygl/SY_SG_GY0102/Delete', data)
}
/**
 * 保存公寓区
 * @param {object} data 表单数据
 * @returns
 */
function savegyl(data) {
  return post('/gygl/SY_SG_GY0102/Save', data)
}
/**
 * 验证区域编号是否重复
 * @param {json} data {id，qybh}
 * @returns
 */
function exgylbh(data) {
  return get('/gygl/SY_SG_GY0102/GetExtBH', data)
}
/** ***************房间调整日志接口**********************/

/**
 * 房间调整获取分页数据
 * @param {*} data
 * @returns
 */
function getfjtzjlpagelist(data) {
  return get('/gygl/SY_SG_GY0105/GetPageList', data)
}
/** ***************房间接口**********************/
/**
 * 房间列表数据
 * @param {*} data
 * @returns
 */
function getfjlist(data) {
  return get('/gygl/SY_SG_GY0104/GetList', data)
}
/**
 * 获取房间视图列表
 * @param {*} data
 * @returns
 */
function getfjviewlist(data) {
  return get('/gygl/SY_SG_GY0104/GetListView', data)
}
/**
 * 获取房间视图分页列表
 * @param {*} data
 * @returns
 */
function getfjviewpagelist(data) {
  return get('/gygl/SY_SG_GY0104/GetViewPageList', data)
}

/**
 * 获取房间视图列表
 * @param {*} data
 * @returns
 */
function getfjbaselist(data) {
  return get('/gygl/SY_SG_GY0104/GetListBase', data)
}
/**
 * 删除房间
 * @param {*} data
 * @returns
 */
function deletefj(data) {
  return post('/gygl/SY_SG_GY0104/Delete', data)
}
/**
 * 学生入住
 * @param {*} data
 * @returns
 */
function xsrz(data) {
  return post('/gygl/SY_SG_GY0104/XSRZ', data)
}
/**
 * 学生移除房间
 * @param {*} data
 * @returns
 */
function delrz(data) {
  return post('/gygl/SY_SG_GY0104/DelRZ', data)
}
/**
 * 清空房间
 * @param {*} data
 * @returns
 */
function clearfj(data) {
  return post('/gygl/SY_SG_GY0104/ClearFJ', data)
}

/**
 * 预分配房间
 * @param {*} data
 * @returns
 */
function yfpfj(data) {
  return post('/gygl/SY_SG_GY0104/YFPRoom', data)
}

/**
 * 释放房间
 * @param {*} data
 * @returns
 */
function sffj(data) {
  return post('/gygl/SY_SG_GY0104/SFRoom', data)
}
/**
 * 预约房间
 * @param {*} data
 * @returns
 */
function yyfj(data) {
  return post('/gygl/SY_SG_GY0104/YYRoom', data)
}
/**
 * 验证房间编号是否重复
 * @param {json} data {id，qybh}
 * @returns
 */
function exfjbh(data) {
  return get('/gygl/SY_SG_GY0104/GetExtBH', data)
}
/**
 * 保存房间信息
 * @param {*} data
 */
function savefj(data) {
  return post('/gygl/SY_SG_GY0104/Save', data)
}

// ====================新生入住============================
/**
 * 获取公寓楼列表
 */
function getOtherList(data) {
  return get('/gygl/SY_SG_GY0102/GetOtherList')
}

/**
 * 获取公寓楼列表
 */
function getKXZList(data) {
  return get('/gygl/SY_SG_GY0104/GetKXZList', data)
}

/**
 * 获取学生信息
 */
function getStuInfoToXSRZ(kshxh) {
  return get('/jcxx/SY_JCXX_XS/GetStuInfoToXSRZ?kshxh=' + kshxh)
}

/**
 * 获取学生信息
 */
function getKRZList(kshxh) {
  return get('/gygl/SY_SG_GY0104/GetKRZList?kshxh=' + kshxh)
}

/**
 * 新生入住
 * @param {*} data
 */
function newxsrz(data) {
  return post('/gygl/SY_SG_GY0104/NEWXSRZ', data)
}

export default {
  // 公寓区域管理
  getgyqlist,
  getqypagelist,
  getqyformdata,
  delqy,
  saveqy,
  exqybh,
  // 公寓楼管理
  getgyltree,
  getgylpagelist,
  getgylformdata,
  delgyl,
  savegyl,
  exgylbh,
  // 房间调整
  getfjtzjlpagelist,
  // 房间数据
  getfjlist,
  getfjviewlist,
  getfjviewpagelist,
  xsrz,
  delrz,
  clearfj,
  deletefj,
  yfpfj,
  sffj,
  yyfj,
  getfjbaselist,
  exfjbh,
  savefj,
  // 新生入住
  getOtherList,
  getKXZList,
  getStuInfoToXSRZ,
  getKRZList,
  newxsrz
}
