<!--校区管理-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">专业管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <el-input
          v-model="keyword"
          placeholder="请输入专业编号/名称"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
          >编辑</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delBm"
          >删除</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="zybh" label="专业编号" width="200">
        </el-table-column>
        <el-table-column prop="zygbm" label="专业国标码" width="200">
        </el-table-column>
        <el-table-column prop="zymc" label="专业名称" width="200">
        </el-table-column>
        <el-table-column prop="zyjc" label="专业简称" width="200">
        </el-table-column>
        <el-table-column prop="zyqc" label="专业全称" width="200">
        </el-table-column>
        <el-table-column prop="zyfx" label="专业方向" width="200">
        </el-table-column>
        <el-table-column prop="zdz" label="专业层次" width="200">
        </el-table-column>
        <el-table-column prop="bmmc" label="所属院系"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="zyinfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="zyinfoRules"
      >
        <el-form-item label="所属院系" label-width="120px" prop="BMBH">
          <el-select v-model="zyinfo.BMBH" style="display: block">
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业编号" label-width="120px" prop="ZYBH">
          <el-input
            v-model="zyinfo.ZYBH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业国标码" label-width="120px" prop="ZYGBM">
          <el-input
            v-model="zyinfo.ZYGBM"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业名称" label-width="120px" prop="ZYMC">
          <el-input
            v-model="zyinfo.ZYMC"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业简称" label-width="120px" prop="ZYJC">
          <el-input
            v-model="zyinfo.ZYJC"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业全称" label-width="120px" prop="ZYQC">
          <el-input
            v-model="zyinfo.ZYQC"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业方向" label-width="120px">
          <el-input
            v-model="zyinfo.ZYFX"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业层次" label-width="120px" prop="CCDM">
          <el-select v-model="zyinfo.CCDM" style="display: block">
            <el-option
              v-for="(item, index) in codelist"
              :key="index"
              :label="item.zdz"
              :value="item.zddm"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getZYPageList,
  saveZYInfo,
  getZYInfo,
  deleteZyInfo,
  getStuBM,
  getCode,
} from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      bmlist: [],
      codelist: [],
      zyinfo: {
        ID: '',
        ZYBH: '',
        ZYGBM: '',
        ZYMC: '',
        ZYJC: '',
        ZYQC: '',
        ZYFX: '',
        BMBH: '',
        CCDM: '',
      },
      dialogFormVisible: false, // 是否显示弹出框
      // 表单验证规则对象
      zyinfoRules: {
        ZYBH: [{ required: true, message: '请输入专业编号', trigger: 'blur' }],
        ZYGBM: [
          { required: true, message: '请输入专业国标码', trigger: 'blur' },
        ],
        ZYMC: [{ required: true, message: '请输入专业名称', trigger: 'blur' }],
        ZYJC: [{ required: true, message: '请输入专业简称', trigger: 'blur' }],
        ZYQC: [{ required: true, message: '请输入专业全称', trigger: 'blur' }],
        BMBH: [{ required: true, message: '请选择部门编号', trigger: 'blur' }],
        CCDM: [{ required: true, message: '请选择层次代码', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.zyinfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    getFormInitData() {
      getStuBM()
        .then((res) => {
          if (res.code === 200) {
            this.bmlist = res.data
          } else {
            this.bmlist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.bmlist = []
        })
      getCode({ queryJson: JSON.stringify({ LXDM: 'ZYCCDM' }) })
        .then((res) => {
          if (res.code === 200) {
            this.codelist = res.data
          } else {
            this.codelist = []
          }
        })
        .catch((err) => {
          this.$message.error(err)
          this.codelist = []
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.getFormInitData()
      this.operation = 1
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.getFormInitData()
        this.operation = 0
        getZYInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.zyinfo.ID = res.data.id
            this.zyinfo.ZYBH = res.data.zybh
            this.zyinfo.ZYGBM = res.data.zygbm
            this.zyinfo.ZYMC = res.data.zymc
            this.zyinfo.ZYJC = res.data.zyjc
            this.zyinfo.ZYQC = res.data.zyqc
            this.zyinfo.ZYFX = res.data.zyfx
            this.zyinfo.BMBH = res.data.bmbh
            this.zyinfo.CCDM = res.data.ccdm
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.zyinfo.ID = ''
      } else {
        this.zyinfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          saveZYInfo({ zy: this.zyinfo }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除专业
    delBm() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteZyInfo({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.zyinfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getZYPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
