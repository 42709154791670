<template>
  <div class="login_global">
    <img class="bgone" src="../assets/img/index_bg.jpg" />
    <img class="pic" src="../assets/img/index_login.png" />
    <div class="table">
      <div class="wel">
        <img v-if="view.logoPath != ''" :src="baseUrl + view.logoPath" />{{
          view.softName
        }}
      </div>
      <!-- <div class="wel1">{{this.view.companyName}}</div> -->
      <div class="login_box">
        <el-form
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginFormRules"
          label-width="0px"
          class="login_form"
        >
          <el-form-item prop="zh">
            <el-input
              v-model="loginForm.zh"
              prefix-icon="iconfont icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="mm">
            <el-input
              v-model="loginForm.mm"
              prefix-icon="iconfont icon-3702mima"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button
              type="primary btn"
              @click="loginAction"
              @keyup.enter="enterSearch"
              >登录</el-button
            >
            <el-button type="info btn" @click="resetLoginForm">重置</el-button>
          </el-form-item>
        </el-form>
        <template
          v-if="view.mhtcodeurl != null && this.view.mhtcodeurl.length > 0"
        >
          <el-divider>其它登录方式</el-divider>
          <div class="tcenter">
            <el-button type="primary" plain round @click="mhtlogin"
              >统一身份认证登录</el-button
            >
          </div>
        </template>
      </div>
    </div>
    <div class="copyright">
      {{ view.copyright }} 当前版本：{{ view.version }}
    </div>
  </div>
</template>

<script>
import store from '../store/index'
import storex from '../store/vuex'
// import md5 from 'js-md5'
import { login, GetMHTOAuth, GetRHCAS } from '../api/login'
import { getModuleList, getConfig } from '../api/system'
import { router, routepage } from '../router'
import { baseUrl } from '../utils/http'
// import HomeVue from '../views/Home.vue'
// import Index from '../views/Index.vue'
export default {
  data() {
    return {
      baseUrl: baseUrl,
      // 显示信息
      view: {
        companyName: '',
        softName: '',
        logoPath: '',
        copyright: '',
        version: '',
        mhtcodeurl: null,
        rhcasurl: null,
      },
      // 登录表单数据
      loginForm: {
        zh: '',
        mm: '',
      },
      // 表单验证规则对象
      loginFormRules: {
        zh: [
          { required: true, message: '请输入登陆名称', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到20 个字符',
            trigger: 'blur',
          },
        ],
        mm: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            max: 16,
            message: '长度在 8 到 16 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    getConfig().then((res) => {
      if (res.data.siteconfig) {
        this.view = { ...this.view, ...res.data.siteconfig }
      }
    })
    GetMHTOAuth().then((res) => {
      if (res.code === 200) {
        this.view.mhtcodeurl = res.data.codeurl
      }
    })
    var query = this.$route.query
    if (query.type === 'system') {
    } else {
      GetRHCAS().then((res) => {
        if (res.code === 200) {
          this.view.rhcasurl = res.data.casurl
          if (this.view.rhcasurl) {
            location.href = this.view.rhcasurl
          }
        }
      })
    }

    // 一定要在create里面执行一下写的回车事件enterSearch(),否则会执行不了
    this.enterSearch()
  },
  methods: {
    // 回车搜索
    enterSearch() {
      document.onkeydown = (e) => {
        // 13表示回车键，baseURI是当前页面的地址，为了更严谨，也可以加别的，可以打印e看一下
        if (e.keyCode === 13) {
          // 回车后执行搜索方法
          this.loginAction()
        }
      }
    },
    // 重置表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
    },
    loginAction() {
      const that = this
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          login({
            username: that.loginForm.zh,
            password: that.loginForm.mm, // md5(that.loginForm.mm),
            client_id: 'syapi',
            client_secret: '123456',
            grant_type: 'password',
          })
            .then((res) => {
              if (res.code === 200) {
                // 保存Token
                store.commit('setToken', res.data ? res.data : res)
                getModuleList()
                  .then((res) => {
                    var data = res.data
                    var items = ['/index', '/profile', '/code/TemplatePC/Index']
                    data.forEach((item) => {
                      if (
                        item.f_UrlAddress.trim() !== '' &&
                        item.f_UrlAddress.trim() !== '&nbsp;'
                      ) {
                        items.push(item.f_UrlAddress)
                      }
                    })
                    var newroutes = []
                    var rt = this.$router.options.routes
                    routepage.forEach((item) => {
                      items.forEach((n) => {
                        if (n === item.path) {
                          newroutes.push(item)
                        }
                      })
                    })
                    rt[2].children = newroutes
                    store.commit('setroutes', items)
                    storex.commit('setroutes', items)
                    router.addRoutes(rt)
                    that.$message.success('登录成功')
                    this.$router.push('/home')
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              } else {
                if (res.message === '密码不符合要求') {
                  this.$router.push({
                    path: '/qzxgmm',
                    query: { zh: this.loginForm.zh },
                  })
                } else {
                  that.$message.error(res.message)
                }
              }
            })
            .catch((err) => {
              // that.$message.error(err.title)
              console.log(err)
            })
        }
      })
    }, // 明后天统一身份认证登录
    mhtlogin() {
      location.href = this.view.mhtcodeurl
    },
  },
}
</script>

<style  scoped>
.login_global {
  height: 100%;
}

.bgone {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.pic {
  width: 492px;
  height: auto;
  position: absolute;
  top: 173px;
  right: 453px;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 99999;
}

.table {
  background-color: #ffffff;
  width: 960px;
  height: 465px;
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.wel {
  width: 960px;
  height: 35px;
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: -7rem;
  text-align: center;
}
.wel img {
  height: 50px;
  vertical-align: middle;
  margin-right: 10px;
}
.wel1 {
  width: 960px;
  height: 35px;
  color: #fff;
  font-size: 12.38px;
  position: absolute;
  top: -4rem;
  text-align: center;
}

.password {
  position: absolute;
  top: 12.8rem;
  right: 4rem;
  display: flex;
}
.user {
  position: absolute;
  top: 7.8rem;
  right: 4rem;
  display: flex;
}
.login_box {
  position: absolute;
  width: 400px;
  right: 4rem;
  top: 7.8rem;
}
.btns {
  display: flex;
  justify-content: center;
}
.btn {
  width: 195px;
}
.copyright {
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 12px;
  bottom: 0px;
  padding: 10px 0px;
  color: #909399;
}
.el-card >>> .el-card__header {
  padding: 10px 10px;
}
.card-header {
  text-align: center;
  font-size: 12px;
}
.el-divider__text {
  color: #909399;
}
</style>
