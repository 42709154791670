<!--学生违纪处分查询-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">我的违纪信息</div>
    <!--=====================================工具栏========================================-->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="150"> </el-table-column>
        <el-table-column prop="xbm" label="性别" width="100">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="cffwbh" label="发文编号" width="300">
        </el-table-column>
        <el-table-column prop="rdjgmc" label="认定结果" width="150">
        </el-table-column>
        <el-table-column prop="wjsj" label="违纪时间" width="150">
          <template slot-scope="scop">
            {{ dateFormat2(scop.row.wjsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="cfjgmc" label="处分结果" width="150">
        </el-table-column>
        <el-table-column prop="xq" label="学期" width="250"> </el-table-column>
        <el-table-column prop="sfjc" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sfjc === 1" type="success">已解除</el-tag>
            <el-tag v-if="scope.row.sfjc === 0" type="danger"
              >处分执行中</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleSS(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
    </div>

    <el-dialog
      title="学生违纪处分"
      :visible.sync="dialogFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 650px" class="myscrollbar">
        <sy-form v-if="dialogFormVisible" :id="keyValue" :issp="'1'"></sy-form>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<script>
import { GetStuSearchList } from '../../../api/wjcf'
import { getAuthorizeButtonColumnList } from '../../../api/system'
import moment from 'moment'
import form from '../../../components/wjcf/diacxform.vue'
export default {
  components: {
    'sy-form': form, // 挂载组件
  },
  data() {
    return {
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      xqlist: [], // 学期
      dialogFormVisible: false, // 是否显示弹出框
    }
  },
  created() {
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        this.dialogFormVisible = true
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {},
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    handleSS(row) {
      this.keyValue = row.id
      this.dialogFormVisible = true
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetStuSearchList()
        .then((res) => {
          if (res.code === 200) {
            this.dataPageList = res.data
            this.tableloading = false
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 200px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
