var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"home-header"},[_c('div',{staticClass:"home-logo"},[(_vm.logourl != null)?_c('img',{attrs:{"src":_vm.logourl}}):_vm._e(),(!_vm.isCollapse)?_c('span',[_vm._v("综合学工")]):_vm._e()]),_c('div',{staticClass:"home-top-right"},[_c('div',{staticClass:"top-icons-btn"},[_c('el-badge',{staticClass:"item",attrs:{"is-dot":""}},[_c('i',{staticClass:"fa fa-bell"})])],1),_c('el-avatar',{attrs:{"src":_vm.userinfo.imgurl}}),_c('el-dropdown',{staticClass:"ml5",on:{"command":_vm.tophandleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.userinfo.name)),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-user","command":"profile"}},[_vm._v("个人信息")]),(_vm.userinfo.roleId == 1)?_c('el-dropdown-item',{attrs:{"icon":"el-icon-switch-button","command":"qkhc"}},[_vm._v("清空缓存")]):_vm._e(),_c('el-dropdown-item',{attrs:{"icon":"el-icon-switch-button","command":"out"}},[_vm._v("安全退出")])],1)],1)],1)]),_c('el-container',[_c('el-aside',{attrs:{"width":_vm.isCollapse ? '64px' : '220px'}},[_c('el-scrollbar',{style:({ height: _vm.scrollbarheight + 'px' })},[_c('el-menu',{attrs:{"unique-opened":true,"collapse":_vm.isCollapse,"default-openeds":_vm.opends,"default-active":_vm.$route.path,"collapse-transition":true,"router":""},on:{"open":_vm.open}},[_vm._l((_vm.menulist),function(item){return [(item.f_IsMenu == 1 && item.f_EnabledMark == 1)?_c('el-submenu',{key:item.id,attrs:{"index":item.id + '',"popper-class":'subcss'}},[_c('template',{slot:"title"},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.text))])]),_vm._l((item.childNodes),function(subItem){return [(
                    subItem.hasChildren &&
                    subItem.f_IsMenu == 1 &&
                    subItem.f_EnabledMark == 1
                  )?_c('el-submenu',{key:subItem.id,attrs:{"index":subItem.id}},[_c('template',{slot:"title"},[_c('i',{class:subItem.icon}),_c('span',[_vm._v(_vm._s(subItem.text))])]),_vm._l((subItem.childNodes),function(menuItem){return [(
                        menuItem.f_IsMenu == 1 && menuItem.f_EnabledMark == 1
                      )?_c('el-menu-item',{key:menuItem.id,attrs:{"index":menuItem.urladdress}},[_c('template',{slot:"title"},[_c('i',{class:menuItem.icon}),_c('span',[_vm._v(_vm._s(menuItem.text))])])],2):_vm._e()]})],2):_c('el-menu-item',{key:subItem.id,attrs:{"index":subItem.urladdress}},[_c('template',{slot:"title"},[_c('i',{class:subItem.icon}),_c('span',[_vm._v(_vm._s(subItem.text))])])],2)]})],2):_vm._e()]})],2)],1)],1),_c('el-container',[_c('el-header',[_c('div',{staticClass:"header_menu"},[_c('div',{staticClass:"home-top-left"},[_c('i',{class:_vm.isCollapse
                  ? 'el-icon-s-unfold home-top-fold'
                  : 'el-icon-s-fold home-top-fold',on:{"click":_vm.toggleCollapse}}),_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},_vm._l((_vm.headerrouter),function(item){return _c('el-breadcrumb-item',{key:item.router,attrs:{"to":{ path: item.router }},nativeOn:{"click":function($event){return _vm.toIndex(item.text)}}},[_vm._v(_vm._s(item.text))])}),1)],1)])]),_c('el-main',{style:({ height: _vm.scrollbarheight - 30 + 'px' })},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }