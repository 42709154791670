import Vue from 'vue'
import VueUeditorWrap from 'vue-ueditor-wrap'
import App from './App.vue'
import { router } from './router'
import './plugins/element.js'
import Print from 'vue-print-nb'
// 导入全局样式表
import './assets/css/common.css'
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'

import 'font-awesome/css/font-awesome.min.css'
import * as echarts from 'echarts'
import axios from 'axios'
import store from './store'
import moment from 'moment' // 日期格式化插件
// 页面线性进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import AFTableColumn from 'af-table-column'

NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 600, // 递增进度条的速度
  showSpinner: true, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})
Vue.use(Print)
Vue.use(VueUeditorWrap)
Vue.use(AFTableColumn)
// axios.defaults.baseURL = 'http://localhost:8080/'
Vue.prototype.moment = moment
Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
// console.log(store.state.routes);
// const fmtroute = (data) => {
//     data.forEach(item => {
//         console.log(item.comp);
//         item.component = () => import(item.comp);
//         if (item.children && item.children.length > 0) {
//             fmtroute(item.children)
//         }
//     });
// }
// var rt = store.state.routes;
// fmtroute(rt);
// router.addRoutes(rt);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
