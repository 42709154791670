<template>
  <div
    class="conten"
    ref="content"
  >
    <!--=====================================顶部标题======================================-->
    <div class="content_title">
      <template v-if="viewtype == 0">用户管理</template>
      <template v-else>用户数据权限</template>
    </div>
    <!--=====================================用户管理========================================-->
    <template v-if="viewtype == 0">
      <!--=====================================工具栏========================================-->
      <div class="c_tool">
        <div>
          <el-input
            v-model="keyword"
            placeholder="请输入内容"
            size="small"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="getPageInfoList"
          >搜索</el-button>
        </div>

        <el-button-group>
          <el-button
            size="small"
            icon="el-icon-refresh"
            @click="reload()"
          ></el-button>
          <el-button
            size="small"
            v-if="hasPerms('lr_add')"
            icon="el-icon-plus"
            @click="showAdd"
          >新增</el-button>
          <el-button
            size="small"
            v-if="hasPerms('lr_edit')"
            icon="el-icon-edit"
            @click="showEdit"
          >编辑</el-button>
          <el-button
            size="small"
            v-if="hasPerms('lr_delete')"
            icon="el-icon-delete"
            @click="delUser"
          >删除</el-button>
          <el-button
            size="small"
            v-if="hasPerms('sy_sjqx')"
            icon="fa fa-handshake-o"
            @click="loadsjqx()"
          >
            分配数据权限</el-button>
        </el-button-group>
      </div>
      <!--=============================表格========================================-->
      <div class="c_table">
        <el-table
          v-loading="tableloading"
          :data="userList"
          :height="windowHeight - 270"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          highlight-current-row
          @current-change="currentRowChange"
        >
          <el-table-column
            prop="zh"
            label="用户名"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="xm"
            label="姓名"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="xb"
            label="性别"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="lxdh"
            label="手机号"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="jsmc"
            label="用户角色"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="zt"
            label="用户状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.zt"
                :active-value="1"
                :inactive-value="0"
                @change="ztStateChanged(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="isSystem"
            label="系统内置用户"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isSystem"
                :active-value="1"
                :inactive-value="0"
                @change="issystemStateChanged(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="cjsj"
            label="创建时间"
            :formatter="dateFormat"
          >
          </el-table-column>
        </el-table>
        <!--=======================================分页================================================-->
        <div class="pagebox">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.rows"
            layout="total, prev, pager, next, jumper"
            :total="pagination.records"
          >
          </el-pagination>
        </div>
      </div>
      <!--=============================新增 修改========================================-->
      <el-dialog
        title="新增"
        :visible.sync="dialogAddFormVisible"
        @close="closeRoleDialog"
      >
        <el-form
          :model="userinfo"
          v-if="dialogAddFormVisible"
          ref="addFormRef"
          :rules="userinfoRules"
        >
          <el-form-item
            label="账号"
            label-width="120px"
            prop="ZH"
          >
            <el-input
              v-model="userinfo.ZH"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="密码"
            label-width="120px"
            prop="MM"
          >
            <el-input
              v-model="userinfo.MM"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="姓名"
            label-width="120px"
            prop="XM"
          >
            <el-input
              v-model="userinfo.XM"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="性别"
            label-width="120px"
            prop="XB"
          >
            <el-select
              v-model="userinfo.XB"
              placeholder="性别"
              style="display: block"
            >
              <el-option
                label="男"
                value="男"
              ></el-option>
              <el-option
                label="女"
                value="女"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="昵称"
            label-width="120px"
            prop="NickName"
          >
            <el-input
              v-model="userinfo.NickName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号"
            label-width="120px"
            prop="LXDH"
          >
            <el-input
              v-model="userinfo.LXDH"
              autocomplete="off"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="用户角色"
            prop="RoleID"
            label-width="120px"
          >
            <el-select
              v-model="userinfo.RoleID"
              placeholder="用户角色"
              style="display: block"
            >
              <el-option
                v-for="(item, index) in rolelist"
                :label="item.jsmc"
                :value="item.id"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="用户状态"
            prop="ZT"
            label-width="120px"
          >
            <el-select
              v-model="userinfo.ZT"
              placeholder="用户状态"
              style="display: block"
            >
              <el-option
                label="启用"
                :value="1"
              ></el-option>
              <el-option
                label="禁用"
                :value="0"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="dialogAddFormVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveUser"
          >确 定</el-button>
        </div>
      </el-dialog>
    </template>
    <!--=====================================权限分配========================================-->
    <template v-else>
      <div class="pd10 pl20">
        <el-radio-group
          v-model="qx.radio_sel"
          size="small"
        >
          <el-radio-button label="1">全部</el-radio-button>
          <el-radio-button label="2">教学部门</el-radio-button>
          <el-radio-button label="3">行政部门</el-radio-button>
        </el-radio-group>
      </div>
      <div
        v-loading="qx.loading"
        class="pd10"
        :style="{ height: qx.qxheight }"
        style="overflow-y: auto"
      >
        <template v-for="(bm, i) in qx.list">
          <el-card
            v-if="bm.bmlx == 1"
            v-show="(qx.radio_sel == 1 || qx.radio_sel == 2) && bm.bmlx == 1"
            class="box-card mt5"
            shadow="hover"
            :key="i"
          >
            <template
              v-if="bm.expend == 1"
              #header
            >
              <el-checkbox
                v-model="bm.authorized"
                :true-label="1"
                :false-label="0"
                @change="checkchange"
              >{{ bm.bmmc }}</el-checkbox>
              <el-tag
                v-if="bm.zy.length > 0"
                class="ml10"
                size="mini"
                effect="dark"
                @click="expend(bm)"
              >
                <i
                  v-if="bm.expend == 1"
                  class="el-icon-minus"
                ></i>
                <i
                  v-else
                  class="el-icon-plus"
                ></i>
                专业({{ bm.sumZY }})班级({{ bm.sumBJ }})</el-tag>
            </template>
            <template v-else>
              <el-checkbox
                v-model="bm.authorized"
                :true-label="1"
                :false-label="0"
                @change="checkchange"
              >{{ bm.bmmc }}</el-checkbox>
              <el-tag
                v-if="bm.zy.length > 0"
                class="ml10"
                size="mini"
                effect="dark"
                @click="expend(bm)"
              >
                <i
                  v-if="bm.expend == 1"
                  class="el-icon-minus"
                ></i>
                <i
                  v-else
                  class="el-icon-plus"
                ></i>
                专业({{ bm.sumZY }})班级({{ bm.sumBJ }})</el-tag>
            </template>
            <div v-show="bm.expend == 1">
              <el-card
                v-for="(zy, n) in bm.zy"
                class="box-card mt10"
                :key="n"
              >
                <template #header>
                  <div class="card-header">
                    <el-checkbox
                      v-model="zy.authorized"
                      :true-label="1"
                      :false-label="0"
                    >{{ zy.zymc }}(专业)</el-checkbox>
                  </div>
                </template>
                <el-checkbox
                  v-for="(bj, b) in zy.bj"
                  :key="b"
                  v-model="bj.authorized"
                  :true-label="1"
                  :false-label="0"
                >{{ bj.bjmc }}</el-checkbox>
              </el-card>
            </div>
          </el-card>

          <el-card
            v-else
            v-show="(qx.radio_sel == 0 || qx.radio_sel == 3) && bm.bmlx == 2"
            class="box-card mt5"
            shadow="hover"
            :key="i"
          >
            <el-checkbox
              v-model="bm.authorized"
              :true-label="1"
              :false-label="0"
              @change="checkchange"
            >{{ bm.bmmc }}</el-checkbox>
          </el-card>
        </template>
      </div>
      <div class="bottom">
        <el-checkbox
          :indeterminate="qx.isIndeterminate"
          v-model="qx.checkAll"
          @change="CheckAllChange"
        >全选</el-checkbox>&nbsp;
        <el-button
          type="primary"
          :loading="qx.loading"
          size="small"
          @click="saveuserqx"
        >保存权限</el-button>
        <el-button
          type="danger"
          size="small"
          @click="clearqx"
        >清除所有权限</el-button>
        <el-button
          type="warning"
          size="small"
          @click="viewtype = 0"
        >返回列表</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import {
  getUserPageList,
  getRoleList,
  saveUserInfo,
  geteUserInfo,
  deleteUserInfo,
  getAuthorizeButtonColumnList,
  userchangeState,
  getsjqxlist,
  saveqx,
  clearqx,
} from '../../api/system'
import moment from 'moment'
import md5 from 'js-md5'
export default {
  data() {
    return {
      viewtype: 0, // 0 为用户管理 1为数据权限页面
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      dialogAddFormVisible: false, // 是否显示添加用户弹出框
      keyword: '',
      keyValue: '',
      operation: 1, // 当前操作1=添加，0=编辑
      userinfo: {
        ID: '',
        ZH: '',
        MM: '',
        XM: '',
        XB: '',
        NickName: '',
        LXDH: '',
        RoleID: '',
        ZT: '',
      },
      formdata: null,
      // 表单验证规则对象
      userinfoRules: {
        ZH: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        RoleID: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        ZT: [{ required: true, message: '请设置状态', trigger: 'blur' }],
      },
      userList: [],
      rolelist: [],
      tableloading: true,
      buttonauth: {},
      qx: {
        loading: false,
        qxheight: 'auto',
        radio_sel: '1',
        list: [],
        isIndeterminate: true,
        checkAll: false,
      },
    }
  },
  created() {
    this.formdata = JSON.parse(JSON.stringify(this.userinfo))
    this.getPageInfoList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.dialogAddFormVisible = true
      this.getRole()
    },
    // 显示编辑弹出框
    showEdit() {
      this.getRole()
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        geteUserInfo({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.userinfo.ZH = res.data.zh
            this.userinfo.MM = ''
            this.userinfo.XM = res.data.xm
            this.userinfo.XB = res.data.xb
            this.userinfo.NickName = res.data.nickName
            this.userinfo.RoleID = res.data.roleID
            this.userinfo.ZT = res.data.zt
            this.userinfo.LXDH = res.data.lxdh

            this.dialogAddFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveUser() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.userinfo.ID = ''
      } else {
        this.userinfo.ID = this.keyValue
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          if (this.userinfo.MM.trim() !== '') {
            this.userinfo.MM = md5(this.userinfo.MM.trim())
          }
          saveUserInfo({ id: this.keyValue, user: this.userinfo }).then(
            (res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.dialogAddFormVisible = false
                this.getPageInfoList()
              } else {
                this.$message.error(res.message)
              }
            }
          )
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeRoleDialog() {
      this.active = 0
      this.$nextTick(() => {
        //   this.userinfo = {
        //     ZH: '',
        //     MM: '',
        //     XM: '',
        //     XB: '',
        //     NickName: '',
        //     RoleID: '',
        //     ZT: '',
        //   }
        this.userinfo = JSON.parse(JSON.stringify(this.formdata))
      })
      //   this.$nextTick(() => {
      //     this.$refs.addFormRef.resetFields()
      //   })
      this.$refs.addFormRef.resetFields()
    },
    // 获取角色列表
    getRole() {
      getRoleList().then((res) => {
        if (res.code === 200) {
          this.rolelist = res.data
        }
      })
    },
    // 删除角色
    delUser() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteUserInfo({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getPageInfoList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getPageInfoList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getPageInfoList() {
      this.tableloading = true
      getUserPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      }).then((res) => {
        if (res.code === 200) {
          this.tableloading = false
          this.pagination.page = res.data.page
          this.pagination.total = res.data.total
          this.pagination.records = res.data.records
          this.userList = res.data.rows
        } else {
          this.tableloading = false
        }
      })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 是否系统用户switch监听
    issystemStateChanged(row) {
      userchangeState({ ID: row.id, IsSystem: row.isSystem })
        .then((res) => {
          if (res.code !== 200) {
            row.isSystem = row.isSystem === 1 ? 0 : 1
          }
        })
        .catch((err) => {
          console.log(err)
          row.isSystem = row.isSystem === 1 ? 0 : 1
        })
    },
    // 用户状态switch监听
    ztStateChanged(row) {
      userchangeState({ ID: row.id, ZT: row.zt })
        .then((res) => {
          if (res.code !== 200) {
            row.zt = row.zt === 1 ? 0 : 1
          }
        })
        .catch((err) => {
          console.log(err)
          row.zt = row.zt === 1 ? 0 : 1
        })
    },
    // 用户数据权限
    loadsjqx() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return
      }
      this.viewtype = 1
      this.qx.loading = true
      getsjqxlist({ uid: this.keyValue }).then((res) => {
        this.qx.loading = false
        if (res.code === 200) {
          this.qx.list = res.data
        } else {
          this.viewtype = 0
          this.$message.error(res.message)
        }
      })
    },
    // 展开/折叠
    expend(obj) {
      if (obj.expend === 1) {
        obj.expend = 0
      } else {
        obj.expend = 1
      }
    },
    // 保存数据权限
    saveuserqx() {
      this.qx.loading = true
      var list = []
      this.qx.list.forEach((v) => {
        if (v.authorized === 1) {
          list.push({ BMBH: v.bmbh, ZYBH: null, BJBH: null })
        } else {
          if (v.zy) {
            v.zy.forEach((z) => {
              if (z.authorized === 1) {
                list.push({ BMBH: null, ZYBH: z.zybh, BJBH: null })
              } else {
                if (z.bj) {
                  z.bj.forEach((b) => {
                    if (b.authorized === 1) {
                      list.push({
                        BMBH: null,
                        ZYBH: null,
                        BJBH: b.bjbh,
                      })
                    }
                  })
                }
              }
            })
          }
        }
      })
      saveqx(this.keyValue, list).then((res) => {
        this.qx.loading = false
        if (res.code === 200) {
          this.$message.success(res.message)
          this.viewtype = 0
        } else {
          this.$message.error(res.message)
        }
      })
    },
    clearqx() {
      this.qx.loading = true
      clearqx({ uid: this.keyValue }).then((res) => {
        this.qx.loading = false
        if (res.code === 200) {
          this.$message.success(res.message)
          this.viewtype = 0
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 全选
    CheckAllChange() {
      this.qx.isIndeterminate = false
      if (this.qx.checkAll) {
        this.qx.list.forEach((v) => {
          v.authorized = 1
        })
      } else {
        this.qx.list.forEach((v) => {
          v.authorized = 0
        })
      }
    },
    checkchange(val) {
      var cklist = this.qx.list.filter((v) => {
        return v.authorized > 0
      })
      if (cklist.length > 0) {
        if (this.qx.list.length === cklist.length) {
          this.qx.isIndeterminate = false
          this.qx.checkAll = true
        } else {
          this.qx.isIndeterminate = true
        }
      } else {
        this.qx.checkAll = false
      }
    },
  },
  mounted() {
    this.qx.qxheight = this.$refs.content.offsetHeight - 155 + 'px'
    console.log(this.qx.qxheight)
  },
}
</script>

<style scoped>
.conten {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.content_title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.c_tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.c_tool .el-input {
  width: 300px;
}

.c_tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.bottom {
  width: 100%;
  background: #eee;
  height: 50px;
  padding: 10px;
  padding-left: 50px;
}
</style>
