<template>
  <el-tabs type="border-card" @tab-click="tabchange">
    <el-tab-pane label="违纪申报（教学）">
      <!-- <sy-stepset :list="[{ sqbm: [], sqbmStr: [] }]"></sy-stepset> -->
      <sy-form :LX="1"></sy-form>
    </el-tab-pane>
    <el-tab-pane label="违纪申报（行为习惯）"
      ><sy-form :LX="2"></sy-form
    ></el-tab-pane>
    <el-tab-pane label="违纪申诉（教学）"
      ><sy-form :LX="3"></sy-form
    ></el-tab-pane>
    <el-tab-pane label="违纪申诉（行为习惯）"
      ><sy-form :LX="4"></sy-form
    ></el-tab-pane>
    <el-tab-pane label="违纪解除（教学）"
      ><sy-form :LX="5"></sy-form
    ></el-tab-pane>
    <el-tab-pane label="违纪解除（行为习惯）"
      ><sy-form :LX="6"></sy-form
    ></el-tab-pane>
  </el-tabs>
</template>

<script>
import stepform from '../../components/wjcf/stepform.vue'
// import stepset from '../../components/common/setpset.vue'
export default {
  components: {
    'sy-form': stepform, // 挂载组件
    // 'sy-stepset': stepset
  },
  data() {
    return {
      LX: 1,
    }
  },
  created() {},
  methods: {
    tabchange(e) {
      this.LX = parseInt(e.index) + 1
    },
  },
}
</script>

<style>
.stepcontainer {
  width: 800px;
  /* margin: 100px auto; */
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item {
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 60px;
}
.title {
  display: flex;
  justify-content: space-around;
  background: rgb(87, 97, 255);
  color: rgb(255, 255, 255);
  width: 211px;
  height: 40px;
  line-height: 40px;
  border-radius: 0px 0px 8px 8px;
  margin: 0 auto;
}
.ry-top-left {
  font-size: 14px;
  color: #606266;
}
.tag-warp {
  background: rgb(249, 249, 249);
  border-radius: 5px;
  padding: 10px;
}
.el-tag {
  margin-right: 10px;
  margin-top: 5px;
}
.diatitle {
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 20px;
}
.yxzbox {
  width: 100%;
}
.yxzitem {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dia-i1 {
  margin-right: 5px;
  color: #e6a23c;
}
.dia-i2 {
  margin-right: 8px;
  color: #409eff;
}
.del-i {
  color: red;
  font-size: 18px;
  cursor: pointer;
}
.btngroup {
  text-align: center;
  margin-top: 20px;
}
</style>
