<template>
  <!-- <el-tabs tab-position="left">
    <el-tab-pane label="基本信息">
      <div class="basetitle">
        <div>基本信息</div>
      </div>
      <el-form
        label-position="right"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-upload
          class="avatar-uploader myupload"
          :action="baseurl + 'system/sy_base_user/InsertPicture'"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :headers="header"
        >
          <img
            v-if="formLabelAlign.imgurl"
            :src="formLabelAlign.imgurl"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-form-item label="账号">
          <el-input v-model="formLabelAlign.zh" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="formLabelAlign.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-model="formLabelAlign.sex" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-input v-model="formLabelAlign.role" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="修改密码">
      <div class="basetitle">
        <div>修改密码</div>
      </div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="原密码" prop="oldpass">
          <el-input
            type="password"
            v-model="ruleForm.oldpass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="我的日志">
      <div class="basetitle">
        <div>我的日志</div>
      </div></el-tab-pane
    >
  </el-tabs> -->
  <div class="personmain">
    <div class="container">
      <div class="left">
        <div class="card">
          <el-card class="box-card is-always-shadow">
            <div
              slot="header"
              class="clearfix"
            >
              <span>身份切换</span>
            </div>
          </el-card>
        </div>
        <div class="card">
          <el-card class="box-card is-always-shadow">
            <div
              slot="header"
              class="clearfix"
            >
              <span>修改密码</span>
            </div>
            <div>
              <div class="app-change-pwd">
                <el-form
                  :model="ruleForm"
                  status-icon
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item
                    label="原始密码："
                    prop="oldpass"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.oldpass"
                      autocomplete="off"
                      size="small"
                      placeholder="请输入原始密码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="新密码："
                    prop="pass"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.pass"
                      autocomplete="off"
                      size="small"
                      placeholder="请输入新的密码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="确认密码："
                    prop="checkPass"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.checkPass"
                      autocomplete="off"
                      size="small"
                      placeholder="请确认密码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="submitForm('ruleForm')"
                      size="small"
                    >确定修改</el-button>
                    <el-button
                      @click="resetForm('ruleForm')"
                      size="small"
                    >重置表单</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div class="right">
        <el-card class="box-card is-always-shadow">
          <div
            slot="header"
            class="clearfix"
          >
            <span>个人资料</span>
          </div>
          <div>
            <div class="personal-wrapper">
              <el-form label-position="top">
                <el-row :gutter="20">
                  <el-col
                    :span="24"
                    class="img-item"
                  >
                    <img
                      class="user-img"
                      v-if="userinfo.sex == '男'"
                      src="~@/assets/img/tea-boy.png"
                    />
                    <img
                      class="user-img"
                      v-if="userinfo.sex == '女'"
                      src="~@/assets/img/tea-girl.png"
                    />
                    <div class="send-name">超级管理员</div>
                  </el-col>
                  <div class="first-row">
                    <el-col :span="12">
                      <el-form-item label="账号">
                        <el-input
                          v-model="userinfo.zh"
                          readonly
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="姓名">
                        <el-input
                          v-model="userinfo.name"
                          readonly
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="性别">
                        <el-input
                          v-model="userinfo.sex"
                          readonly
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="角色">
                        <el-input
                          v-model="userinfo.role"
                          readonly
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </div>
                </el-row>
              </el-form>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { submitResetPassword, getUserInfo } from '../../api/system'
import md5 from 'js-md5'
import store from '../../store/index'
import http from '../../utils/http'
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userinfo: {},
      baseurl: http.baseUrl,
      header: {
        Authorization: 'Bearer ' + store.state.token.access_token,
      },
      formLabelAlign: {
        zh: '',
        name: '',
        sex: '',
        role: '',
        imgurl: '',
      },
      imageUrl: '',
      ruleForm: {
        oldpass: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        oldpass: [{ validator: validatePass, trigger: 'blur' }],
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      },
    }
  },
  created() {
    this.userinfo = store.state.userinfo
    if (this.userinfo.imgurl) {
      this.userinfo.imgurl = this.baseurl + this.userinfo.imgurl
    }
    getUserInfo().then((res) => {
      if (res.code === 200) {
        this.formLabelAlign = res.data
        this.formLabelAlign.imgurl = this.baseurl + this.formLabelAlign.imgurl
      } else {
        this.formLabelAlign = { zh: '', name: '', se: '', role: '' }
      }
    })
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.formLabelAlign.imgurl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 1024 < 1

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG或PNG 格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传头像图片大小不能超过 1MB!')
      }
      //   insertPicture(file).then((res) => {
      //     if (res.code === 200) {
      //       this.$message.success(res.message)
      //     } else {
      //       this.$message.error(res.message)
      //     }
      //   })
      return isJPG && isLt1M
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          submitResetPassword({
            password: md5(this.ruleForm.pass),
            oldPassword: md5(this.ruleForm.oldpass),
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              localStorage.removeItem('SY_STORE')
              this.$router.push('/login')
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style  scoped>
.el-tabs {
  background-color: #fff;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
}
.basetitle {
  width: 100%;
  padding: 0px 20px 0 10px;
}
.basetitle > div {
  width: 100%;
  border-bottom: 1px solid #ccc;
  line-height: 34px;
  font-size: 14px;
  font-weight: bold;
  color: #409eff;
}
.el-form {
  margin-top: 20px;
  width: 500px;
}
.avatar-uploader {
  margin-top: 20px;
  margin-left: 200px;
  margin-bottom: 20px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/** */
.personmain {
  height: 100%;
  width: 100%;
}
.container {
  display: flex;
  justify-content: center;
}
.container .card {
  width: 620px;
  margin: 30px;
  margin-left: 0;
}
.container .card .el-card {
  width: 620px;
}
.box-card {
  margin-bottom: 30px;
  margin-right: 30px;
  border-radius: 8px;
  background: #fff;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.box-card >>> .el-card__header {
  border: 0;
}
.el-card__header .clearfix {
  height: 24px;
  border-left: 4px solid #0184ff;
  padding-left: 12px;
  font-size: 20px;
  line-height: 24px;
  color: #333 !important;
}
.app-change-pwd {
  background: #fff;
  border-radius: 3px;
  padding: 50px 75px;
  font-size: 14px;
}
.personal-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #fff;
  border-radius: 3px;
  padding: 0 80px;
}
.personal-wrapper .img-item {
  margin-left: 53px;
  width: 690px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 38px;
  margin-bottom: 50px;
  border-bottom: 1px solid #ccc;
}
.container .right .el-card {
  width: 892px;
}
.personal-wrapper .img-item img {
  width: 110px;
  height: 110px;
  border-radius: 10px;
}
.personal-wrapper .img-item .send-name {
  font-family: SourceHanSansCN-Medium;
  font-size: 18px;
  line-height: 38px;
}
.personal-wrapper .first-row {
  width: 690px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
</style>
