<!--
* 创建人：罗兴
* 日 期：
* 描 述：学生去向登记
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">节假日去向登记查询</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学期:</span>
            <sy-xueqi
              v-model="xqid"
              :selectdqxq="true"
              style="display: inline"
            ></sy-xueqi>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">节假日:</span>
            <el-select
              style="width: 200px"
              v-model="jrid"
              placeholder="请选择假期"
              :clearable="true"
              size="small"
            >
              <el-option
                v-for="item in jrlist"
                :key="item.id"
                :label="item.jqmc"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_export')"
          icon="el-icon-download"
          @click="showExport"
          >导出</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xueqi" label="学期" width="200">
        </el-table-column>
        <el-table-column prop="jqmc" label="假期名称" width="250">
        </el-table-column>
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="bmmc" label="院系" width="200">
        </el-table-column>
        <el-table-column prop="lxsj" label="离校时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.lxsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="fxsj" label="返校时间">
          <template slot-scope="scope">
            {{ dateFormat2(scope.row.fxsj) }}
          </template>
        </el-table-column>
        <el-table-column prop="jqqx" label="假期去向"> </el-table-column>
        <el-table-column prop="brlxdh" label="本人联系电话"> </el-table-column>
        <el-table-column prop="jzlxdh" label="家长联系电话"> </el-table-column>
        <el-table-column prop="bz" label="备注"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!--导出日志弹出框-->
    <el-dialog title="导出Excel数据" :visible.sync="dialogExcelVisible">
      <div>
        <el-checkbox
          v-model="excelForm.XUEQI"
          label="学期"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.JQMC"
          label="假期名称"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XH"
          label="学号"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XM"
          label="姓名"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BMMC"
          label="院系"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.ZYMC"
          label="专业"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BJMC"
          label="班级"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.LXSJ"
          label="离校时间"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.FXSJ"
          label="返校时间"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.JQQX"
          label="假期去向"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BRLXDH"
          label="本人联系电话"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.JZLXDH"
          label="家长联系电话"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcelVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportToExcel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import api from '../../api/jqgl'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getCurrentXQ } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xqid: null,
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      jrlist: [],
      jrid: '',
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      bmbh: '',
      zybh: '',
      bjbh: '',
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      dialogExcelVisible: false,
      excelForm: {
        XUEQI: true,
        JQMC: true,
        XH: true,
        XM: true,
        BMMC: true,
        ZYMC: true,
        BJMC: true,
        LXSJ: true,
        FXSJ: true,
        JQQX: true,
        BRLXDH: true,
        JZLXDH: true,
      },
    }
  },
  watch: {
    xqid() {
      const that = this
      api
        .getjjrlist({ queryJson: JSON.stringify({ xqid: that.xqid }) })
        .then(function (res) {
          if (res.code === 200) {
            that.jrid = ''
            that.jrlist = res.data
          }
        })
    },
  },
  components: {
    'sy-xueqi': () => import('../../components/dropdown/dropdown_xueqi'),
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getCurrentXQ().then((res) => {
      if (res.code === 200) {
        this.xqid = res.data.id
        this.getDataPageList()
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      api
        .getqxdjpagelist({
          queryJson: JSON.stringify({
            xqid: this.xqid,
            jrid: this.jrid,
            keyword: this.keyword,
            bmbh: this.bmbh,
            zybh: this.zybh,
            bjbh: this.bjbh,
          }),
          pagination: JSON.stringify(this.pagination),
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    showExport() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.XUEQI) {
        exportFiles.push({ name: 'XUEQI', label: '学期' })
      }
      if (this.excelForm.JQMC) {
        exportFiles.push({ name: 'JQMC', label: '假期名称' })
      }
      if (this.excelForm.XH) {
        exportFiles.push({ name: 'XH', label: '学号' })
      }
      if (this.excelForm.XM) {
        exportFiles.push({ name: 'XM', label: '姓名' })
      }
      if (this.excelForm.BMMC) {
        exportFiles.push({ name: 'BMMC', label: '院系' })
      }
      if (this.excelForm.ZYMC) {
        exportFiles.push({ name: 'ZYMC', label: '专业' })
      }
      if (this.excelForm.BJMC) {
        exportFiles.push({ name: 'BJMC', label: '班级' })
      }
      if (this.excelForm.LXSJ) {
        exportFiles.push({ name: 'LXSJ', label: '离校时间' })
      }
      if (this.excelForm.FXSJ) {
        exportFiles.push({ name: 'FXSJ', label: '返校时间' })
      }
      if (this.excelForm.JQQX) {
        exportFiles.push({ name: 'JQQX', label: '假期去向' })
      }
      if (this.excelForm.BRLXDH) {
        exportFiles.push({ name: 'BRLXDH', label: '本人联系电话' })
      }
      if (this.excelForm.JZLXDH) {
        exportFiles.push({ name: 'JZLXDH', label: '家长联系电话' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }

      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      api
        .exportQXExcel({
          queryJson: JSON.stringify({
            keyword: this.keyword,
            bmbh: this.bmbh,
            zybh: this.zybh,
          }),
          fileName: '新生到校信息',
          exportField: JSON.stringify(exportFiles),
        })
        .then((res) => {
          console.log(res)
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8',
          })
          // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, res.fileName)
          } else {
            var downloadElement = document.createElement('a')
            var href = window.URL.createObjectURL(blob) // 创建下载的链接
            downloadElement.href = href
            downloadElement.download = res.fileName // 下载后文件名
            document.body.appendChild(downloadElement)
            downloadElement.click() // 点击下载
            document.body.removeChild(downloadElement) // 下载完成移除元素
            window.URL.revokeObjectURL(href) // 释放掉blob对象
          }
        })
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
