<template>
  <div style="width:100%;">
    <el-row :gutter="24">
      <el-col :span="18">
        <el-row><el-col :span="24"><el-card>
              <el-row>
                <el-col :span="8">
                  <div class="avatar">
                    <div class="img">
                      <el-avatar
                        v-if="userinfo.headIcon"
                        :src="systemurl+userinfo.headIcon"
                      ></el-avatar>
                      <el-avatar
                        v-else
                        icon="el-icon-user-solid"
                      ></el-avatar>
                    </div>
                    <span>{{ userinfo.xm }}</span>
                  </div>
                  <el-row class="mt10"><el-col :span="4">身 份:</el-col><el-col :span="20">{{ userinfo.js }}</el-col></el-row>
                  <el-row class="mt5"><el-col :span="4">部 门:</el-col><el-col :span="20">{{ userinfo.bmmc }}</el-col></el-row>
                  <el-row class="mt5"><el-col :span="4">教工号:</el-col><el-col :span="20">{{ userinfo.zh }}</el-col></el-row>
                </el-col>
                <el-col :span="16">
                  <div class="tongji">
                    <el-row>
                      <el-col :span="6">
                        <div class="item">
                          <img
                            class="icon"
                            src="~@/assets/img/qingjia.png"
                          />
                          <div class="text ml5">
                            <span class="tip">今日请假</span>
                            <span class="number">
                              <span class="num">{{ indexdata.qjrs }}</span>人</span>
                          </div>
                        </div>
                      </el-col>
                      <el-col
                        :span="6"
                        class="item"
                      >
                        <img
                          class="icon"
                          src="~@/assets/img/xiaojia.png"
                        />
                        <div class="text ml5">
                          <span class="tip">未销假</span>
                          <span class="number">
                            <span class="num">{{ indexdata.wxj }}</span>人</span>
                        </div>
                      </el-col>
                      <el-col
                        :span="6"
                        class="item"
                      >
                        <img
                          class="icon"
                          src="~@/assets/img/kunnansheng.png"
                        />
                        <div class="text ml5">
                          <span class="tip">困难生</span>
                          <span class="number">
                            <span class="num">{{ indexdata.kns }}</span>人</span>
                        </div>
                      </el-col>
                      <el-col
                        :span="6"
                        class="item"
                      >
                        <img
                          class="icon"
                          src="~@/assets/img/weiji.png"
                        />
                        <div class="text ml5">
                          <span class="tip">违纪人数</span>
                          <span class="number">
                            <span class="num">{{ indexdata.wjcf }}</span>人</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </el-card></el-col></el-row>
        <el-row
          class="mt20"
          :gutter="24"
        ><el-col :span="12">
            <!--通知公告-->
            <el-card class="tongzhi">
              <div class="head">
                <div class="tit">通知公告</div>
                <div class="more">
                  <el-link
                    type="info"
                    :underline="false"
                    :href="indexdata.newlisturl"
                  >查看更多 ></el-link>
                </div>
              </div>
              <div class="list">
                <ul>
                  <li
                    v-for="(item,index) in indexdata.newslist"
                    :key="index"
                  >
                    <div class="tit fleft">
                      <a
                        class="fcolor24"
                        :href="item.url"
                        target="_blank"
                      >{{ item.title }}</a>
                    </div>
                    <div class="date fright">
                      <el-link
                        type="info"
                        :underline="false"
                        :href="item.url"
                      >{{ item.updateTime }}</el-link>
                    </div>
                  </li>
                </ul>
              </div>
            </el-card></el-col><el-col :span="12">
            <!--人员统计-->
            <el-card class="renyuan">
              <div class="tit">学生人数</div>
              <div
                id="sex"
                class="sexechart"
              ></div>
            </el-card></el-col></el-row>
        <el-row class="mt10">
          <el-col :span="24">
            <el-card class="yingyong">
              <div class="tit">常用功能</div>
              <div class="list">
                <el-empty
                  style="padding: 30px"
                  description="还没有添加功能"
                ></el-empty>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div class="calendar curriculum-box">
            <el-calendar v-model="rlvalue"> </el-calendar>
          </div>
          <div class="daiban">
            <div class="tit">待办事项</div>
            <div class="list">
              <div
                class="item"
                v-for="(item,index) in indexdata.qjlist"
                :key="index"
              >
                <div class="tip">{{item.xm}}请假申请</div>
                <div class="desc">请假事由：{{ item.qjsy }}</div>
                <div class="user">
                  <div class="avatar">
                    <el-avatar
                      :size="20"
                      icon="el-icon-user-solid"
                    ></el-avatar>
                  </div>
                  <span class="txt">{{ item.xm }}</span><span class="txt ml20">|</span>
                  <span class="txt ml20">{{ item.cjsj }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
  <script>
import { getIndexData } from '../../api/system'
import store from '../../store/index.js'
import { baseUrl } from '../../utils/http'
export default {
  name: 'lsindex',
  data() {
    return {
      rlvalue: new Date(),
      userinfo: [],
      systemurl: baseUrl,
      indexdata: {},
    }
  },
  created() {
    // this.userinfo = store.state.userinfo
  },
  mounted() {
    getIndexData().then((res) => {
      if (res.code === 200) {
        this.userinfo = res.data.userinfo
        this.userinfo.js = store.state.userinfo.role
        this.indexdata = res.data
        this.sexecharts()
      }
    })
  },
  methods: {
    sexecharts() {
      var nan = this.indexdata.nan
      var nv = this.indexdata.nv
      var chartDom = document.getElementById('sex')
      var myChart = this.$echarts.init(chartDom)
      var echartop = {
        title: {
          text: '{b|' + (nan + nv) + '}',
          subtext: '{a|学生人数}',
          subtextStyle: {
            rich: {
              a: {
                fontSize: '20',
              },
            },
          },
          textStyle: {
            rich: {
              b: {
                fontSize: '20',
              },
            },
          },
          left: 'center',
          top: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        label: {
          alignTo: 'edge',
          formatter: '{name|{b}}\n{time|{c} 人}',
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            time: {
              fontSize: 10,
              color: '#999',
            },
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['男生', '女生'],
        },
        color: ['#0184ff', '#fa4f27'],
        series: [
          {
            name: '人数统计',
            type: 'pie',
            radius: ['80%', '100%'],
            center: ['50%', '50%'],
            data: [
              { value: nan, name: '男生' },
              { value: nv, name: '女生' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      myChart.setOption(echartop)
    },
  },
}
</script>
<style scoped >
.avatar {
  height: 44px;
}
.avatar .img {
  float: left !important;
  width: 44px;
  display: inline-block;
}
.avatar span {
  line-height: 44px;
  float: left !important;
  font-weight: 600;
}
.tongji {
  background: url('~@/assets/img/indexbg.png');
  height: 160px;
  border-radius: 5px;
}
.tongji .item {
  text-align: center;
  margin-top: 50px;
}
.tongji .icon,
.tongji .text {
  float: left;
  color: #fff;
}
.tongji .icon {
  margin-left: 20%;
  width: 50px;
  height: 50px;
}
.tongji .text {
  text-align: left;
}
.tongji .text span {
}
.tongji .text .number {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 800;
}
.tongji .text .tip {
  font-size: 12px;
  font-weight: 800;
}
.tongji .text .number .num {
  font-weight: bold;
  font-family: 微软正黑体;
  font-size: 20px;
}
.tongzhi .head .tit {
  font-weight: bold;
  width: 50%;
  float: left;
}
.tongzhi .head .more {
  width: 40%;
  float: right;
  text-align: right;
}
.tongzhi .list {
  clear: both;
  margin-top: 30px;
}
.tongji .list ul {
}
.tongzhi .list ul li {
  list-style: none;
  height: 30px;
  line-height: 26px;
}
.tongzhi .list .tit {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tongzhi .list .tit a {
  text-decoration: none;
}
.tongzhi .list .date {
  text-align: right;
  width: 30%;
}
.renyuan .tit {
  font-weight: bold;
  width: 50%;
}
.renyuan .sexechart {
  width: 100%;
  height: 178px;
}
.yingyong {
  min-height: 300px;
}
.yingyong .tit {
  font-weight: bold;
  width: 50%;
}
.calendar {
  height: 300px;
}
/**日历 */
.curriculum-box >>> .el-calendar {
  border: 1px solid #e7e7e7;
  margin: 0 5px;
}
.curriculum-box >>> .el-calendar__title {
  font-weight: 700;
  font-size: 18px;
  color: #595959;
}
.curriculum-box >>> .el-calendar__body {
  padding: 0 20px 8px;
}
.curriculum-box >>> .el-calendar-table thead th {
  font-weight: 800;
  color: #b3b3b3;
  font-size: 14px;
}
.curriculum-box >>> .el-calendar-table:not(.is-range) td.next,
.curriculum-box >>> .el-calendar-table:not(.is-range) td.prev {
  color: #c0c4cc;
}
.curriculum-box >>> .el-calendar-table td.is-selected {
  background: #fff;
  font-weight: 800;
}
.curriculum-box >>> .el-calendar-table td {
  border: none;
}
.curriculum-box >>> .el-calendar-table td.is-selected .el-calendar-day {
  color: #0184ff;
}
.curriculum-box >>> .el-calendar-table .el-calendar-day {
  height: 32px;
  font-size: 14px;
  padding: 5px 0;
  text-align: center;
}
.curriculum-box >>> .el-calendar-table .el-calendar-day span {
  width: 22px;
  height: 22px;
  display: inline-block;
  border-radius: 100%;
  line-height: 22px;
}
.curriculum-box >>> .el-calendar-table td.is-today {
  background: #fff;
  font-weight: 800;
}
.curriculum-box >>> .el-calendar-table td.is-today .el-calendar-day span {
  color: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0184ff),
    to(#0459f0)
  );
  background: linear-gradient(90deg, #0184ff, #0459f0);
}
/**待办事项 */
.daiban {
  margin-top: 10px;
}
.daiban .tit {
  font-weight: bold;
  width: 50%;
}
.daiban .list {
  min-height: 425px;
}
.daiban .list .item {
  font-size: 12px;
  margin-top: 5px;
  border-radius: 5px;
  border-left: 5px solid #c7d9f0;
  background: #f7fafd;
  height: 70px;
  padding: 5px;
}
.daiban .list .item .tip {
  font-weight: bold;
}
.daiban .list .item .desc {
  color: #a2a3a3;
  padding: 10px 0;
}
.daiban .list .item .user {
  color: #a2a3a3;
}
.daiban .list .item .user .avatar {
  float: left;
  width: 25px;
}
.daiban .list .item .user .txt {
  line-height: 20px;
}
.top-ard {
  width: 100%;
  margin-bottom: 20px;
}
.top-arc-box {
  display: flex;
}
.top-ard-left {
  width: 300px;
}
.top-ard-tx {
  display: flex;
  align-items: center;
}
.top-user-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.top-username {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
}
.top-detail {
  margin-top: 10px;
  padding-left: 10px;
}
.top-item {
  display: flex;
  font-size: 20px;
  margin-bottom: 10px;
}
.top-item-left {
  width: 60px;
  text-align-last: justify;
  color: #797b86;
  font-size: 18px;
}
.top-item-right {
  margin-left: 10px;
}
</style>
