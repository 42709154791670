import {
  post, get
} from '../utils/http';

// 保存批次设置
export function savePCSZ(data) {
  return post('kns/SY_KNS_PCSZ/Save/', data);
}

// 获取批次
export function GetPCPageList(data) {
  return get('kns/SY_KNS_PCSZ/GetPageList/', data);
}

// 更新是否当前学年
export function changeState(data) {
  return post('kns/SY_KNS_PCSZ/SaveDQPC/', data);
}

// 根据ID获取
export function getFormData(data) {
  return get('kns/SY_KNS_PCSZ/GetFormData/', data);
}

// 学生获取当前批次
export function stuGetPC(data) {
  return get('kns/SY_KNS_PCSZ/StuGetPC/', data);
}

// 学生提交申请
export function stuSave(data) {
  return post('kns/SY_KNS_XSSQ/Save/', data);
}

// 学生获取申请分页数据
export function getStuApplyPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetStuApplyPageList/', data);
}

// 学生获取申请详情
export function getSQFormData(data) {
  return get('kns/SY_KNS_XSSQ/GetFormData/', data);
}

// 学生获取家庭成员列表
export function getListBySQID(data) {
  return get('kns/SY_KNS_XSSQ_JTCY/GetListBySQID/', data);
}

// 获取审批记录
export function getSPJL(data) {
  return get('kns/SY_KNS_XSSQ_SPJL/GetListBySQID/', data);
}

// 获取批次附件
export function GetListByPCID(data) {
  return get('kns/SY_KNS_PCSZ_FJ/GetListByPCID/', data);
}

// 获取待辅导员审批列表
export function getFDYSPPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetFDYSPPageList/', data);
}

// 获取待学工审批列表
export function getXYSPPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetXYSPPageList/', data);
}

// 获取待学工审批列表
export function getXGSPPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetXGSPPageList/', data);
}

// 获取待学工审批列表
export function sp(data) {
  return post('kns/SY_KNS_XSSQ/shengpi/', data);
}

// 获取当前批次
export function getCurrentPC(data) {
  return get('kns/SY_KNS_PCSZ/getCurrentPC/', data);
}

// 获取当前批次统计数据
export function tongji(data) {
  return get('kns/SY_KNS_XSSQ/tongji/', data);
}

// 获取公示列表
export function getGSPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetGSPageList/', data);
}

// 获取公示列表
export function rending(data) {
  return post('kns/SY_KNS_XSSQ/rending/', data);
}

// 获取辅导员查询列表
export function getFDYCXPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetFDYCXPageList/', data);
}

// 获取学工查询列表
export function getXYCXPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetXYCXPageList/', data);
}

// 获取学工查询列表
export function getXGCXPageList(data) {
  return get('kns/SY_KNS_XSSQ/GetXGCXPageList/', data);
}

// 获取批次
export function getListAndCurrent(data) {
  return get('kns/SY_KNS_PCSZ/GetListAndCurrent/', data);
}
