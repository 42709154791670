import {
  get,
    post,
  excel
} from '../utils/http';

// ==========================节假日设置=================================
/**
 * 节假日设置分页
 * @param {*} data
 * @returns
 */
function getjjrpagelist(data) {
   return get('/jqgl/SY_JQGL_JJR/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function getjjrformdata(data) {
  return get('/jqgl/SY_JQGL_JJR/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
function deljjr(data) {
  return post('/jqgl/SY_JQGL_JJR/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
function savejjr(data) {
  return post('/jqgl/SY_JQGL_JJR/Save', data);
}

// 学生节假日去向登记
/**
 * 可登记去向假日
 * @param {*} data
 * @returns
 */
function getkdjpagelist(data) {
   return get('/jqgl/SY_JQGL_JJR/GetKDJPageList', data);
}

/**
 * 保存去向登记
 * @param {object} data 表单数据
 * @returns
 */
function savejrqxdj(data) {
  return post('/jqgl/SY_JQGL_QXDJ/Save', data);
}

/**
 * 去向登记
 * @param {*} data
 * @returns
 */
function getstuqxdjpagelist(data) {
   return get('/jqgl/SY_JQGL_QXDJ/GetStuPageList', data);
}

/**
 * 去向登记
 * @param {*} data
 * @returns
 */
function getqxdjpagelist(data) {
   return get('/jqgl/SY_JQGL_QXDJ/GetPageList', data);
}

/**
 * 获取节假日列表
 * @param {*} data
 * @returns
 */
function getjjrlist(data) {
   return get('/jqgl/SY_JQGL_JJR/GetList', data);
}

// 获取请假管理流程
function GetStep() {
    return get('/jqgl/SY_JQGL_JJR/GetStep/');
}

// 提交违纪流程配置
function LCPZSave(data) {
  return post('/jqgl/SY_JQGL_JJR/LCPZSave/', data);
}

// 可申请留校假期
/**
 * 可登记去向假日
 * @param {*} data
 * @returns
 */
function getKLXPageList(data) {
   return get('/jqgl/SY_JQGL_JJR/GetKLXPageList', data);
}

// 提交留校申请
function LXSQSave(data) {
  return post('/jqgl/SY_JQGL_LXSQ/Save/', data);
}

/**
 * 已提交的留校申请
 * @param {*} data
 * @returns
 */
function getstulxsqpagelist(data) {
   return get('/jqgl/SY_JQGL_LXSQ/GetStuPageList', data);
}

// 留校申请审批
function getlxsqsppagelist(data) {
   return get('/jqgl/SY_JQGL_LXSQ/GetPageList', data);
}

/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function getlxsqformdata(data) {
  return get('/jqgl/SY_JQGL_LXSQ/GetFormData', data);
}
// 保存审批意见
function saveSP(data) {
  return post('/jqgl/SY_JQGL_LXSQ/saveSP/', data);
}
/**
 * 获取留校数据
 * @param {*} data
 * @returns
 */
function getlxdata(data) {
  return get('/jqgl/SY_JQGL_LXSQ/GetLXPageList', data);
}

/**
 * 获取审批记录
 * @param {*} data
 * @returns
 */
function getSPJL(data) {
  return get('/jqgl/SY_JQGL_LXSQ/GetSPJL', data);
}

// 获取去向导出数据
function exportQXExcel(data) {
  return excel('/jqgl/SY_JQGL_QXDJ/ExportExcel/', data);
}

// 获取去向导出数据
function exportLXExcel(data) {
  return excel('/jqgl/SY_JQGL_LXSQ/ExportExcel/', data);
}

export default {
    getjjrpagelist,
    getjjrformdata,
    deljjr,
    savejjr,
    getkdjpagelist,
    savejrqxdj,
    getstuqxdjpagelist,
    getqxdjpagelist,
    getjjrlist,
    GetStep,
    LCPZSave,
    getKLXPageList,
    LXSQSave,
    getstulxsqpagelist,
    getlxsqsppagelist,
    getlxsqformdata,
    saveSP,
    getlxdata,
    getSPJL,
    exportQXExcel,
    exportLXExcel
}
