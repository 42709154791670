<template>
  <div class="container"></div>
</template>

<script>
import store from "../../store/index";
import storex from "../../store/vuex";
import { getModuleList } from "../../api/system";
import { router, routepage } from "../../router";
import { GetMHTOAuth, MHTCallBack } from "../../api/login";
export default {
  data() {
    return {
      Code: "",
      errmsg: null,
    };
  },
  created() {
    const loading = this.$loading({
      lock: true,
      text: "正在登录...",
      spinner: "el-icon-loading",
    });
    var query = this.$route.query;
    this.Code = query.code;
    if (!this.Code) {
      loading.close();
      GetMHTOAuth()
        .then((res) => {
          if (res.code === 200) {
            location.href = res.data.codeurl;
          } else {
            this.errmsg = res.message;
            loading.close();
          }
        })
        .catch((error) => {
          console.log(error);
           this.errmsg = error;
          loading.close();
        });
    } else {
      MHTCallBack({ code: this.Code }).then((res) => {
        if (res.code === 200) {
          // 保存Token
          store.commit("setToken", res.data ? res.data : res);
          getModuleList()
            .then((res) => {
              var data = res.data;
              var items = ["/index", "/profile"];
              data.forEach((item) => {
                if (
                  item.f_UrlAddress.trim() !== "" &&
                  item.f_UrlAddress.trim() !== "&nbsp;"
                ) {
                  items.push(item.f_UrlAddress);
                }
              });
              var newroutes = [];
              var rt = this.$router.options.routes;
              routepage.forEach((item) => {
                items.forEach((n) => {
                  if (n === item.path) {
                    newroutes.push(item);
                  }
                });
              });
              rt[2].children = newroutes;
              store.commit("setroutes", items);
              storex.commit("setroutes", items);
              router.addRoutes(rt);
              this.$message.success("登录成功");
              loading.close();
              this.$router.push("/home");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          loading.close();
          this.$router.push("/login");
          this.$message.error(res.message);
        }
      });
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>
