<!--困难生认定-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">助学金管理</div>
    <!--=====================================工具栏========================================-->
    <div class="btngroup">
      <el-button
        type="primary"
        size="mini"
        @click="deletemd"
      >删除</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="dialogImportVisible = true"
      >导入</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="showExport"
      >导出Excel</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="getDataPageList"
      >查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">学年</div>
          <el-select
            v-model="xn"
            size="mini"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in xnList"
              :key="index"
              :label="item.xn"
              :value="item.xn"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">学院</div>
          <el-select
            v-model="bmbh"
            size="mini"
            clearable
            @change="bmchange"
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业</div>
          <el-select
            v-model="zybh"
            size="mini"
            clearable
            @change="zychange"
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级</div>
          <el-select
            v-model="bjbh"
            size="small"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">学号</div>
          <el-input
            size="mini"
            v-model="xh"
            style="width: 178px"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">姓名</div>
          <el-input
            size="mini"
            v-model="xm"
            style="width: 178px"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">类型</div>
          <el-select
            v-model="lx"
            size="mini"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in lxList"
              :key="index"
              :label="item.zxjmc"
              :value="item.zxjmc"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        > </el-table-column>
        <el-table-column
          prop="xh"
          label="学号"
        > </el-table-column>
        <el-table-column
          prop="xm"
          label="姓名"
        > </el-table-column>
        <el-table-column
          prop="bmmc"
          label="学院"
        > </el-table-column>
        <el-table-column
          prop="zymc"
          label="专业"
        > </el-table-column>
        <el-table-column
          prop="bjmc"
          label="班级"
        > </el-table-column>
        <el-table-column
          prop="xn"
          label="学年"
        > </el-table-column>
        <el-table-column
          prop="zxjmc"
          label="助学金名称"
        >
        </el-table-column>
        <el-table-column
          prop="je"
          label="金额"
        >
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!--导出日志弹出框-->
    <el-dialog
      title="导出Excel数据"
      :visible.sync="dialogExcelVisible"
    >
      <div>
        <el-checkbox
          v-model="excelForm.XH"
          label="学号"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XM"
          label="姓名"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BMMC"
          label="学院"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.ZYMC"
          label="专业"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BJMC"
          label="班级"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XN"
          label="学年"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.ZXJMC"
          label="助学金名称"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.JE"
          label="金额"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogExcelVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="exportToExcel"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="助学金名单"
      :visible.sync="dialogImportVisible"
      top="5vh"
    >
      <div style="text-align: center">
        <el-upload
          class="upload-demo"
          drag
          :headers="headers"
          :action="uploadurl"
          :multiple="false"
          :on-success="handleSuccess"
          :show-file-list="false"
        >
          <el-icon class="el-icon-upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">请上传excel文件</div>
          </template>
        </el-upload>
        <div>
          <el-link
            type="primary"
            :href="downbaseurl + 'UploadFile/gjjxjmd.xlsx'"
          >模板下载</el-link>
          <br />
          <el-link
            type="danger"
            v-if="downerrurl"
            :href="downerrurl"
          >下载错误信息</el-link>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import {
  getZXJCXPageList,
  exportZXJ,
  getZXJSearchList,
  deleteZXJ,
} from '../../api/zxj'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import { baseUrl } from '../../utils/http'
import moment from 'moment'
import store from '../../store/index'
export default {
  data() {
    return {
      downurl: baseUrl,
      fileList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      splist: [], // 审批记录
      xsinfo: {},
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      lxList: [],
      lx: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      xh: '',
      xm: '',
      xn: '',
      spzt: '',
      xnList: [],
      ids: [],
      forminfo: {
        SPZT: null,
      },
      spinfo: {
        SPYJ: '',
        SPZT: 1,
        ZHCPPM: '',
        XJSS: null,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      dialogImportVisible: false,
      uploadurl: baseUrl + 'zxj/SY_ZXJ/upload',
      downbaseurl: baseUrl,
      headers: '',
      downerrurl: '',
      dialogExcelVisible: false,
      excelForm: {
        XH: true,
        XM: true,
        BMMC: true,
        ZYMC: true,
        BJMC: true,
        XN: true,
        ZXJMC: true,
        JE: true,
      },
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getZXJSearchList().then((res) => {
      if (res.code === 200) {
        this.xnList = res.data.xnlist
        this.lxList = res.data.lxlist
      }
    })
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getZXJCXPageList({
        queryJson: JSON.stringify({
          XH: this.xh,
          XM: this.xm,
          XN: this.xn,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
          LX: this.lx,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    showExport() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    handleSelectionChange(val) {
      let i = 0
      this.ids = []
      for (i = 0; i < val.length; i++) {
        this.ids.push(val[i].id)
      }
    },
    // 删除
    deletemd() {
      if (this.ids.length === 0) {
        this.$message.error('请选择需要删除的数据！')
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.tableloading = true
            deleteZXJ({ ids: this.ids }).then((res) => {
              this.tableloading = false
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {
            this.tableloading = false
          })
      }
    },
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.XH) {
        exportFiles.push({ name: 'XH', label: '学号' })
      }
      if (this.excelForm.XM) {
        exportFiles.push({ name: 'XM', label: '姓名' })
      }
      if (this.excelForm.BMMC) {
        exportFiles.push({ name: 'BMMC', label: '学院' })
      }
      if (this.excelForm.ZYMC) {
        exportFiles.push({ name: 'ZYMC', label: '专业' })
      }
      if (this.excelForm.BJMC) {
        exportFiles.push({ name: 'BJMC', label: '班级' })
      }
      if (this.excelForm.XN) {
        exportFiles.push({ name: 'XN', label: '学年' })
      }
      if (this.excelForm.ZXJMC) {
        exportFiles.push({ name: 'ZXJMC', label: '助学金名称' })
      }
      if (this.excelForm.JE) {
        exportFiles.push({ name: 'JE', label: '金额' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }

      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportZXJ({
        queryJson: JSON.stringify({
          XH: this.xh,
          XM: this.xm,
          XN: this.xn,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
          LX: this.lx,
        }),
        fileName: '等级奖学金',
        exportField: JSON.stringify(exportFiles),
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.$message.success(response.message)
        this.dialogImportVisible = false
        this.getDataPageList()
      } else {
        this.$message.error(response.message)
        this.downerrurl = this.downbaseurl + response.data.url
      }
    },
  },
}
</script>
  <style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
  text-align: right;
  padding-right: 5px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
