// 获取图标数组

const icons = [
  'fa fa-adjust',
  'fa fa-adn',
  'fa fa-amazon',
  'fa fa-ambulance',
  'fa fa-anchor',
  'fa fa-android',
  'fa fa-angellist',
  'fa fa-apple',
  'fa fa-archive',
  'fa fa-arrows',
  'fa fa-asterisk',
  'fa fa-at',
  'fa fa-automobile',
  'fa fa-backward',
  'fa fa-ban',
  'fa fa-bandcamp',
  'fa fa-bank',
  'fa fa-barcode',
  'fa fa-bars',
  'fa fa-bath',
  'fa fa-bathtub',
  'fa fa-battery',
  'fa fa-bed',
  'fa fa-beer',
  'fa fa-behance',
  'fa fa-bell',
  'fa fa-bicycle',
  'fa fa-binoculars',
  'fa fa-bitbucket',
  'fa fa-bitcoin',
  'fa fa-blind',
  'fa fa-bluetooth',
  'fa fa-bold',
  'fa fa-bolt',
  'fa fa-bomb',
  'fa fa-book',
  'fa fa-bookmark',
  'fa fa-braille',
  'fa fa-briefcase',
  'fa fa-btc',
  'fa fa-bug',
  'fa fa-building',
  'fa fa-bullhorn',
  'fa fa-bullseye',
  'fa fa-bus',
  'fa fa-buysellads',
  'fa fa-cab',
  'fa fa-calculator',
  'fa fa-calendar',
  'fa fa-camera',
  'fa fa-car',
  'fa fa-cc',
  'fa fa-certificate',
  'fa fa-chain',
  'fa fa-check',
  'fa fa-child',
  'fa fa-chrome',
  'fa fa-circle',
  'fa fa-clipboard',
  'fa fa-clone',
  'fa fa-close',
  'fa fa-cloud',
  'fa fa-cny',
  'fa fa-code',
  'fa fa-codepen',
  'fa fa-codiepie',
  'fa fa-coffee',
  'fa fa-cog',
  'fa fa-cogs',
  'fa fa-columns',
  'fa fa-comment',
  'fa fa-commenting',
  'fa fa-comments',
  'fa fa-compass',
  'fa fa-compress',
  'fa fa-connectdevelop',
  'fa fa-contao',
  'fa fa-copy',
  'fa fa-copyright',
  'fa fa-crop',
  'fa fa-crosshairs',
  'fa fa-css3',
  'fa fa-cube',
  'fa fa-cubes',
  'fa fa-cut',
  'fa fa-cutlery',
  'fa fa-dashboard',
  'fa fa-dashcube',
  'fa fa-database',
  'fa fa-deaf',
  'fa fa-deafness',
  'fa fa-dedent',
  'fa fa-delicious',
  'fa fa-desktop',
  'fa fa-deviantart',
  'fa fa-diamond',
  'fa fa-digg',
  'fa fa-dollar',
  'fa fa-download',
  'fa fa-dribbble',
  'fa fa-dropbox',
  'fa fa-drupal',
  'fa fa-edge',
  'fa fa-edit',
  'fa fa-eercast',
  'fa fa-eject',
  'fa fa-empire',
  'fa fa-envelope',
  'fa fa-envira',
  'fa fa-eraser',
  'fa fa-etsy',
  'fa fa-eur',
  'fa fa-euro',
  'fa fa-exchange',
  'fa fa-exclamation',
  'fa fa-expand',
  'fa fa-expeditedssl',
  'fa fa-eye',
  'fa fa-eyedropper',
  'fa fa-facebook',
  'fa fa-fax',
  'fa fa-feed',
  'fa fa-female',
  'fa fa-file',
  'fa fa-film',
  'fa fa-filter',
  'fa fa-fire',
  'fa fa-firefox',
  'fa fa-flag',
  'fa fa-flash',
  'fa fa-flask',
  'fa fa-flickr',
  'fa fa-folder',
  'fa fa-font',
  'fa fa-fonticons',
  'fa fa-forumbee',
  'fa fa-forward',
  'fa fa-foursquare',
  'fa fa-gamepad',
  'fa fa-gavel',
  'fa fa-gbp',
  'fa fa-ge',
  'fa fa-gear',
  'fa fa-gears',
  'fa fa-genderless',
  'fa fa-gg',
  'fa fa-gift',
  'fa fa-git',
  'fa fa-github',
  'fa fa-gitlab',
  'fa fa-gittip',
  'fa fa-glass',
  'fa fa-glide',
  'fa fa-globe',
  'fa fa-google',
  'fa fa-gratipay',
  'fa fa-grav',
  'fa fa-group',
  'fa fa-hashtag',
  'fa fa-header',
  'fa fa-headphones',
  'fa fa-heart',
  'fa fa-heartbeat',
  'fa fa-history',
  'fa fa-home',
  'fa fa-hotel',
  'fa fa-hourglass',
  'fa fa-houzz',
  'fa fa-html5',
  'fa fa-ils',
  'fa fa-image',
  'fa fa-imdb',
  'fa fa-inbox',
  'fa fa-indent',
  'fa fa-industry',
  'fa fa-info',
  'fa fa-inr',
  'fa fa-instagram',
  'fa fa-institution',
  'fa fa-intersex',
  'fa fa-ioxhost',
  'fa fa-italic',
  'fa fa-joomla',
  'fa fa-jpy',
  'fa fa-jsfiddle',
  'fa fa-key',
  'fa fa-krw',
  'fa fa-language',
  'fa fa-laptop',
  'fa fa-lastfm',
  'fa fa-leaf',
  'fa fa-leanpub',
  'fa fa-legal',
  'fa fa-link',
  'fa fa-linkedin',
  'fa fa-linode',
  'fa fa-linux',
  'fa fa-list',
  'fa fa-lock',
  'fa fa-magic',
  'fa fa-magnet',
  'fa fa-male',
  'fa fa-map',
  'fa fa-mars',
  'fa fa-maxcdn',
  'fa fa-meanpath',
  'fa fa-medium',
  'fa fa-medkit',
  'fa fa-meetup',
  'fa fa-mercury',
  'fa fa-microchip',
  'fa fa-microphone',
  'fa fa-minus',
  'fa fa-mixcloud',
  'fa fa-mobile',
  'fa fa-modx',
  'fa fa-money',
  'fa fa-motorcycle',
  'fa fa-music',
  'fa fa-navicon',
  'fa fa-neuter',
  'fa fa-odnoklassniki',
  'fa fa-opencart',
  'fa fa-openid',
  'fa fa-opera',
  'fa fa-outdent',
  'fa fa-pagelines',
  'fa fa-paperclip',
  'fa fa-paragraph',
  'fa fa-paste',
  'fa fa-pause',
  'fa fa-paw',
  'fa fa-paypal',
  'fa fa-pencil',
  'fa fa-percent',
  'fa fa-phone',
  'fa fa-photo',
  'fa fa-pinterest',
  'fa fa-plane',
  'fa fa-play',
  'fa fa-plug',
  'fa fa-plus',
  'fa fa-podcast',
  'fa fa-print',
  'fa fa-qq',
  'fa fa-qrcode',
  'fa fa-question',
  'fa fa-quora',
  'fa fa-ra',
  'fa fa-random',
  'fa fa-ravelry',
  'fa fa-rebel',
  'fa fa-recycle',
  'fa fa-reddit',
  'fa fa-refresh',
  'fa fa-registered',
  'fa fa-remove',
  'fa fa-renren',
  'fa fa-reorder',
  'fa fa-repeat',
  'fa fa-reply',
  'fa fa-resistance',
  'fa fa-retweet',
  'fa fa-rmb',
  'fa fa-road',
  'fa fa-rocket',
  'fa fa-rouble',
  'fa fa-rss',
  'fa fa-rub',
  'fa fa-ruble',
  'fa fa-rupee',
  'fa fa-s15',
  'fa fa-safari',
  'fa fa-save',
  'fa fa-scissors',
  'fa fa-scribd',
  'fa fa-search',
  'fa fa-sellsy',
  'fa fa-send',
  'fa fa-server',
  'fa fa-share',
  'fa fa-shekel',
  'fa fa-sheqel',
  'fa fa-shield',
  'fa fa-ship',
  'fa fa-shirtsinbulk',
  'fa fa-shower',
  'fa fa-signal',
  'fa fa-signing',
  'fa fa-simplybuilt',
  'fa fa-sitemap',
  'fa fa-skyatlas',
  'fa fa-skype',
  'fa fa-slack',
  'fa fa-slash',
  'fa fa-sliders',
  'fa fa-slideshare',
  'fa fa-snapchat',
  'fa fa-sort',
  'fa fa-soundcloud',
  'fa fa-spinner',
  'fa fa-spoon',
  'fa fa-spotify',
  'fa fa-square',
  'fa fa-star',
  'fa fa-steam',
  'fa fa-stethoscope',
  'fa fa-stop',
  'fa fa-strikethrough',
  'fa fa-stumbleupon',
  'fa fa-subscript',
  'fa fa-subway',
  'fa fa-suitcase',
  'fa fa-superpowers',
  'fa fa-superscript',
  'fa fa-support',
  'fa fa-table',
  'fa fa-tablet',
  'fa fa-tachometer',
  'fa fa-tag',
  'fa fa-tags',
  'fa fa-tasks',
  'fa fa-taxi',
  'fa fa-telegram',
  'fa fa-television',
  'fa fa-terminal',
  'fa fa-th',
  'fa fa-themeisle',
  'fa fa-thermometer',
  'fa fa-ticket',
  'fa fa-times',
  'fa fa-tint',
  'fa fa-trademark',
  'fa fa-train',
  'fa fa-transgender',
  'fa fa-trash',
  'fa fa-tree',
  'fa fa-trello',
  'fa fa-tripadvisor',
  'fa fa-trophy',
  'fa fa-truck',
  'fa fa-try',
  'fa fa-tty',
  'fa fa-tumblr',
  'fa fa-tv',
  'fa fa-twitch',
  'fa fa-twitter',
  'fa fa-umbrella',
  'fa fa-underline',
  'fa fa-undo',
  'fa fa-university',
  'fa fa-unlink',
  'fa fa-unlock',
  'fa fa-unsorted',
  'fa fa-upload',
  'fa fa-usb',
  'fa fa-usd',
  'fa fa-user',
  'fa fa-users',
  'fa fa-vcard',
  'fa fa-venus',
  'fa fa-viacoin',
  'fa fa-viadeo',
  'fa fa-vimeo',
  'fa fa-vine',
  'fa fa-vk',
  'fa fa-warning',
  'fa fa-wechat',
  'fa fa-weibo',
  'fa fa-whatsapp',
  'fa fa-wheelchair',
  'fa fa-wifi',
  'fa fa-windows',
  'fa fa-won',
  'fa fa-wordpress',
  'fa fa-wpbeginner',
  'fa fa-wpexplorer',
  'fa fa-wpforms',
  'fa fa-wrench',
  'fa fa-xing',
  'fa fa-yahoo',
  'fa fa-yc',
  'fa fa-yelp',
  'fa fa-yen',
  'fa fa-yoast',
  'fa fa-youtube',
];
export default {
  icons
}
