import {
  get,
  post
} from '../utils/http';

// 获取数据库表信息
export function getlist() {
  return get('system/DatabaseTable/GetList/');
}

// 获取数据库表具体信息
export function getfields(data) {
  return get('system/DatabaseTable/GetFieldList/', data);
}
// 获取配置信息
export function getcodeconfig() {
  return get('code/TemplatePC/GetCodeConfig/');
}
// 预览代码
export function getlookfastcode(config) {
  return post('code/TemplatePC/LookFastCode/', config);
}
// 创建文件
export function CreateFastCode(config) {
  return post('code/TemplatePC/CreateFastCode/', config);
}
